import {
  ChevronSmall,
  Disclaimer,
  Div,
  EditPenIcon,
  H5,
  Icon,
  P,
  PlusIcon,
  Separator,
  TrashIconRed,
  centsToDollars,
  convert24HourTo12Hour,
  formatter,
  lightTheme,
  renderDayOfWeekFrom1,
} from '@vgrubs/components';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  SubTitleWithTooltipIcon,
  TextWithTooltipIcon,
  TitleWithTooltipIcon,
} from '../Transactions/TransactonsStyled';

export const FundingTipTimeScheduleComponent = ({
  item,
  setFundedTipAddEditModal,
  setFundedTipSchedule,
}) => {
  const handleRemoveSchedule = () => {
    setFundedTipSchedule((prev) => {
      return prev.filter((it, i) => i !== item.index);
    });
  };

  return (
    <>
      <Separator />
      <Div $p="16px 20px" $flex $between $alignCenter>
        <div>
          <Disclaimer $mb="4">
            {renderDayOfWeekFrom1(item.dayOfTheWeek)}
          </Disclaimer>
          <P $m="0">
            {convert24HourTo12Hour(item.startTime)} -{' '}
            {convert24HourTo12Hour(item.endTime)}
          </P>
        </div>

        <Div $flex $alignCenter $between $gap="20px">
          <Icon $pointer onClick={() => setFundedTipAddEditModal(item)}>
            <EditPenIcon />
          </Icon>
          <Icon $pointer onClick={handleRemoveSchedule}>
            <TrashIconRed />
          </Icon>
        </Div>
      </Div>
    </>
  );
};

export const FundingTipComponent = ({
  fundedTipSchedule,
  setFundedTipSchedule,
  setFundedTipAddEditModal,
  setFundedTipAdd,
  setFundingMultiple,
}) => {
  const fundedRef = useRef(null);
  const [fundedCollapse, setFundedCollapse] = useState(false);
  const [fundedElementHeight, setFundedElementHeight] = useState(
    fundedRef?.current?.clientHeight
  );

  const amount = fundedTipSchedule?.[0]?.amount || 0;

  useEffect(() => {
    setFundedElementHeight(fundedRef?.current?.clientHeight);
  }, [fundedTipSchedule]);

  const handleOpenMultiple = () => setFundingMultiple(fundedTipSchedule);

  return (
    <Div>
      <Div $flex $alignCenter $between $box $p="24px">
        <Div
          $width="100%"
          $pointer
          onClick={() => setFundedCollapse((prev) => !prev)}
        >
          <SubTitleWithTooltipIcon
            bold
            mb="0"
            text={'Tip Funding'}
            tooltip={
              'Tip funded by restaurant owner to prevent delivery driver tips being $0 or below a certain amount. This increases likelihood of successful delivery, and decreases assignment time drastically.'
            }
          />
        </Div>

        {fundedTipSchedule.length > 0 ? (
          <Div $flex $gap="20px" $flexNone>
            <H5
              $pointer
              $mt="4"
              $mb="0"
              onClick={() => setFundedCollapse((prev) => !prev)}
            >
              {formatter.format(centsToDollars(amount))}
            </H5>
            <Icon $pointer $shadow onClick={handleOpenMultiple}>
              <EditPenIcon />
            </Icon>
            <Icon
              $pointer
              $rotate={fundedCollapse ? '180deg' : '0'}
              onClick={() => setFundedCollapse((prev) => !prev)}
            >
              <ChevronSmall />
            </Icon>
          </Div>
        ) : (
          <Icon $shadow onClick={handleOpenMultiple}>
            <PlusIcon />
          </Icon>
        )}
      </Div>
      <CollapsibleFunded
        expanded={fundedCollapse}
        elementHeight={fundedElementHeight}
      >
        <Div ref={fundedRef} $flex $column $gap="4px">
          {fundedTipSchedule.map((item, i) => {
            return (
              <FundingTipTimeScheduleComponent
                key={i}
                item={{
                  index: i,
                  ...item,
                }}
                setFundedTipAddEditModal={setFundedTipAddEditModal}
                setFundedTipSchedule={setFundedTipSchedule}
              />
            );
          })}
          {fundedTipSchedule.length > 0 && (
            <Div
              $flex
              $alignCenter
              $justifyEnd
              $gap="8px"
              $pointer
              onClick={() => setFundedTipAdd(true)}
            >
              <Icon>
                <PlusIcon />
              </Icon>

              <H5 $m="0" $color={lightTheme.primary}>
                Add Another Day
              </H5>
            </Div>
          )}
        </Div>
      </CollapsibleFunded>
    </Div>
  );
};

export const CollapsibleFunded = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  /* margin-top: ${(props) => (props.expanded ? '16px' : '0px')}; */
  transition: all 300ms;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
