import { LoginAdmin, OtpAdmin } from '../adminPages';

export const ADMIN_ROOT = '';
export const ADMIN_LOGIN = `${ADMIN_ROOT}/login`;
export const ADMIN_OTP = `${ADMIN_ROOT}/otp`;

export const ADMIN_DASHBOARD = `${ADMIN_ROOT}/dashboard`;

export const ADMIN_VERIFICATIONS = `${ADMIN_ROOT}/verifications`;
export const ADMIN_VERIFICATIONS_RESTAURANT = `${ADMIN_ROOT}/verifications/restaurant/:id`;
export const ADMIN_VERIFICATIONS_V_DRIVE = `${ADMIN_ROOT}/verifications/v-drive/:id`;
export const ADMIN_VERIFICATIONS_AGENT = `${ADMIN_ROOT}/verifications/agent/:id`;
export const ADMIN_USERS_AND_ACCESS = `${ADMIN_ROOT}/users-and-access`;
export const ADMIN_TRANSACTIONS = `${ADMIN_ROOT}/transactions/:id`;

export const ADMIN_LOCATIONS = `${ADMIN_ROOT}/locations`;
export const ADMIN_LOCATIONS_DETAILS = `${ADMIN_ROOT}/locations/:id`;
export const ADMIN_LOCATIONS_V_DRIVE = `${ADMIN_ROOT}/locations/v-drive/:id`;
export const ADMIN_LOCATIONS_ONBOARDING_V_DRIVE = `${ADMIN_ROOT}/locations/v-drive/onboarding/:id`;
export const ADMIN_BRAND_DETAILS = `${ADMIN_ROOT}/brand/:id`;
export const ADMIN_BRAND_V_DRIVE = `${ADMIN_ROOT}/brand/v-drive/:id`;
export const ADMIN_MENU_DETAILS = `${ADMIN_ROOT}/menu/:id`;
export const ADMIN_LOCATION_MANAGE_PROVIDERS = `${ADMIN_ROOT}/locations/manage-providers/:id`;
export const ADMIN_BRAND_MANAGE_PROVIDERS = `${ADMIN_ROOT}/brands/manage-providers/:id`;

export const ADMIN_MENU = `${ADMIN_ROOT}/menu/:locationId/location/:brandId/brand`;
export const ADMIN_MENU_PROVIDER = `${ADMIN_ROOT}/menu/:locationId/location/:brandId/brand/:provider`;

export const ADMIN_WEBSITES = `${ADMIN_ROOT}/websites`;
export const ADMIN_WEBSITES_DETAILS = `${ADMIN_ROOT}/websites/:id`;
export const ADMIN_WEBSITES_ADD = `${ADMIN_ROOT}/websites/add`;
export const ADMIN_BLOGS = `${ADMIN_ROOT}/blogs`;
export const ADMIN_BLOGS_ADD = `${ADMIN_ROOT}/blogs/add`;
export const ADMIN_BLOGS_EDIT = `${ADMIN_ROOT}/blogs/:id`;
export const ADMIN_BRANDS = `${ADMIN_ROOT}/brands`;
export const ADMIN_BRANDS_ADD = `${ADMIN_ROOT}/brands/add`;
export const ADMIN_CONCIERGE = `${ADMIN_ROOT}/concierge`;
export const ADMIN_PRIVACY_POLICY = `${ADMIN_ROOT}/privacy-policy`;
export const ADMIN_TERMS_AND_CONDITIONS = `${ADMIN_ROOT}/terms-and-conditions`;
export const ADMIN_PAYOUTS = `${ADMIN_ROOT}/payouts`;
export const ADMIN_ORDERS = `${ADMIN_ROOT}/orders`;
export const ADMIN_ORDER_DETAILS = `${ADMIN_ROOT}/orders/:id`;
export const ADMIN_ADD_MANUAL_ORDER = `${ADMIN_ROOT}/orders/add-manual`;
export const ADMIN_EDIT_MANUAL_ORDER = `${ADMIN_ROOT}/orders/edit-manual`;
export const ADMIN_SETTINGS = `${ADMIN_ROOT}/settings`;
export const ADMIN_CLOSING_DAYS = `${ADMIN_ROOT}/closing-days`;
export const ADMIN_AUDIT_LOG = `${ADMIN_ROOT}/audit-log`;
export const ADMIN_WORKING_HOURS = `${ADMIN_ROOT}/working-hours`;

export const adminAuthRoutes = [
  {
    path: ADMIN_LOGIN,
    Page: LoginAdmin,
  },
  {
    path: ADMIN_OTP,
    Page: OtpAdmin,
  },
];
