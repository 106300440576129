import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  AdminSpinner,
  AdminSwitch,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  H5,
  Icon,
  InfoIcon,
  StripeLogo,
  SvgInlineWrapper,
  VDriveLogo,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import { PaymentCreditCard } from './CreditCardComponent';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

export const AddCardVDrive = ({
  isVDriveCard,
  setIsVDriveCard,
  setStripeToken,
  setStep,
}) => {
  const PUBLISHABLE = process.env.NX_STRIPE_PUBLISHABLE_KEY;
  const [stripePromise] = useState(() => loadStripe(PUBLISHABLE));

  return (
    <Elements stripe={stripePromise}>
      <Form
        isVDriveCard={isVDriveCard}
        setIsVDriveCard={setIsVDriveCard}
        setStripeToken={setStripeToken}
        setStep={setStep}
      />
    </Elements>
  );
};

const Form = ({ isVDriveCard, setIsVDriveCard, setStripeToken, setStep }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const handleSubmitStripe = async () => {
    // if (!agree) {
    //   ErrorToast(
    //     'You must agree with Terms & Conditions and Privacy Policy to subscribe'
    //   );
    //   return;
    // }

    // if (!name) {
    //   ErrorToast('Please check your Full Name');
    //   return;
    // }

    // if (!email) {
    //   ErrorToast('Please check your Email');
    //   return;
    // }

    setLoading(true);

    const cardNumberElement = elements?.getElement('cardNumber');

    if (stripe && cardNumberElement) {
      const { token, error } = await stripe.createToken(cardNumberElement);

      if (error) {
        ErrorToast(error.message || '');
        setLoading(false);
      }

      if (token) {
        setLoading(false);
        setStripeToken(token);
        setStep(2);
      }
    }
  };

  return (
    <>
      {loading && <AdminSpinner />}
      <Div $width="100%">
        <Div $flex $center>
          <VDriveLogo />
        </Div>
        <H3 $center $m="40px 0">
          <span>Add Card</span>
        </H3>
        <PaymentCreditCard />
        <Div $box $p="16px" $flex $between $alignCenter>
          <H5 $m="0">
            vDrive Credit Card{' '}
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-html="vDrive cards cost you an additional 2% on every transaction! Please change this card to your own to avoid this fee."
              data-tooltip-place="top"
              style={{ color: lightTheme.darkGray }}
            >
              <SvgInlineWrapper>
                <InfoIcon />
              </SvgInlineWrapper>
            </a>
          </H5>

          <AdminSwitch
            checked={isVDriveCard}
            onChange={() => setIsVDriveCard((prev) => !prev)}
          />
        </Div>

        <Div $flex $center $gap="8px" $mt="24">
          <Disclaimer $m="0">Powered by:</Disclaimer>
          <StripeLogo />
        </Div>

        <Button
          $selected
          $w="100%"
          $wSM="100%"
          $mt="24"
          onClick={handleSubmitStripe}
        >
          Add Card
        </Button>
      </Div>

      <Div $desktop>
        <Tooltip
          id="my-tooltip"
          style={{
            maxWidth: '500px',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
      <Div $mobile>
        <Tooltip
          id="my-tooltip"
          style={{
            maxWidth: '95%',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
    </>
  );
};
