import {
  Div,
  H3,
  H5,
  InfoIcon,
  P,
  SvgInlineWrapper,
  lightTheme,
} from '@vgrubs/components';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

export const Collapsible = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  margin-top: ${(props) => (props.expanded ? '8px' : '0px')};
  margin-bottom: ${(props) => (props.expanded ? '20px' : '0px')};
  transition: all 300ms;

  @media (min-width: 3500px) {
    margin-top: ${(props) => (props.expanded ? '16px' : '0px')};
    margin-bottom: ${(props) => (props.expanded ? '40px' : '0px')};
  }

  @media (max-width: 800px) {
    margin-top: ${(props) => (props.expanded ? '8px' : '0px')};
    margin-bottom: ${(props) => (props.expanded ? '16px' : '0px')};
  }
`;

export const WithdrawalStatusCircle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  margin: 2px 8px 2px 0;
  display: inline-block;
`;

export const IconIncome = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7497 10.985C21.7497 6.27704 28.9064 2.45996 37.7341 2.45996C46.5618 2.45996 53.7185 6.27704 53.7185 10.985C53.7185 15.6929 46.5618 19.51 37.7341 19.51C28.9064 19.51 21.7497 15.6929 21.7497 10.985ZM37.7341 28.035C46.5618 28.035 53.7185 24.218 53.7185 19.51V15.2475C53.7185 19.9554 46.5618 23.7725 37.7341 23.7725C28.9064 23.7725 21.7497 19.9554 21.7497 15.2475V19.51C21.7497 24.218 28.9064 28.035 37.7341 28.035ZM15.3559 28.035C8.29291 28.035 2.56836 33.7596 2.56836 40.8226C2.56836 47.8856 8.29291 53.6101 15.3559 53.6101C22.4189 53.6101 28.1434 47.8856 28.1434 40.8226C28.1434 33.7596 22.4189 28.035 15.3559 28.035ZM17.4872 40.8226V47.2163H13.2246V40.8226H7.99454L13.7212 35.0959C14.61 34.2072 16.0507 34.2072 16.9394 35.0959L22.6682 40.8226H17.4872ZM37.7341 49.4563C35.1723 49.4563 32.7512 49.1345 30.605 48.5633C29.9699 49.8271 29.1856 51.0036 28.2692 52.065C30.9183 53.105 34.1919 53.7209 37.7341 53.7209C46.5618 53.7209 53.7185 49.9039 53.7185 45.1959V40.9334C53.7185 45.6413 46.5618 49.4563 37.7341 49.4563ZM37.7341 32.4062C34.6395 32.4062 31.7517 31.9374 29.305 31.1254C30.3493 32.6066 31.1613 34.2604 31.692 36.0379C33.5568 36.445 35.5964 36.6688 37.7341 36.6688C46.5618 36.6688 53.7185 32.8517 53.7185 28.1437V23.8812C53.7185 28.5892 46.5618 32.4062 37.7341 32.4062ZM37.7341 40.9313C35.8628 40.9313 34.0683 40.7586 32.3996 40.4432C32.4038 40.6052 32.4059 40.7672 32.4059 40.9313C32.4059 42.2015 32.2653 43.4376 32.0031 44.629C33.7827 44.9934 35.7137 45.1938 37.7341 45.1938C46.5618 45.1938 53.7185 41.3767 53.7185 36.6688V32.4062C53.7185 37.1142 46.5618 40.9313 37.7341 40.9313Z"
        fill="#A9A9A9"
      />
    </svg>
  );
};

export const IconWithdrawal = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18149_43691)">
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M30.5628 54.7241V39.8765C30.5628 38.852 29.6951 38.0205 28.6261 38.0205C27.5571 38.0205 26.6895 38.852 26.6895 39.8765V54.7241C26.6895 55.7486 27.5571 56.5801 28.6261 56.5801C29.6951 56.5801 30.5628 55.7486 30.5628 54.7241Z"
          fill="#A9A9A9"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M24.83 46.1386L28.6258 42.5034L32.4216 46.1386C33.1756 46.8636 34.4048 46.8636 35.1588 46.1386C35.9154 45.416 35.9154 44.2381 35.1588 43.5155L29.9944 38.5663C29.2378 37.8412 28.0138 37.8412 27.2572 38.5663L22.0928 43.5155C21.3362 44.2381 21.3362 45.416 22.0928 46.1386C22.8468 46.8636 24.076 46.8636 24.83 46.1386Z"
          fill="#A9A9A9"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M52.511 15.749H4.74023V29.978C4.74023 32.371 6.76468 34.3086 9.25909 34.3086H47.9921C50.4865 34.3086 52.511 32.371 52.511 29.978V15.749ZM14.4235 26.8848H19.5879C20.6569 26.8848 21.5245 26.0533 21.5245 25.0288C21.5245 24.0043 20.6569 23.1729 19.5879 23.1729H14.4235C13.3545 23.1729 12.4868 24.0043 12.4868 25.0288C12.4868 26.0533 13.3545 26.8848 14.4235 26.8848ZM4.74023 12.0371H52.511V7.70654C52.511 5.3136 50.4865 3.37598 47.9921 3.37598H9.25909C6.76468 3.37598 4.74023 5.3136 4.74023 7.70654V12.0371Z"
          fill="#A9A9A9"
        />
      </g>
      <defs>
        <clipPath id="clip0_18149_43691">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.625 0.580078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconDeposit = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18198_20493)">
        <path
          d="M21.9609 11.8063C21.9609 7.09833 29.1177 3.28125 37.9454 3.28125C46.773 3.28125 53.9298 7.09833 53.9298 11.8063C53.9298 16.5142 46.773 20.3313 37.9454 20.3313C29.1177 20.3313 21.9609 16.5142 21.9609 11.8063ZM37.9454 28.8563C46.773 28.8563 53.9298 25.0392 53.9298 20.3313V16.0688C53.9298 20.7767 46.773 24.5938 37.9454 24.5938C29.1177 24.5938 21.9609 20.7767 21.9609 16.0688V20.3313C21.9609 25.0392 29.1177 28.8563 37.9454 28.8563ZM37.9454 50.2776C35.3836 50.2776 32.9625 49.9558 30.8163 49.3846C30.1812 50.6484 29.3969 51.8249 28.4805 52.8862C31.1296 53.9263 34.4032 54.5422 37.9454 54.5422C46.773 54.5422 53.9298 50.7252 53.9298 46.0172V41.7547C53.9298 46.4626 46.773 50.2776 37.9454 50.2776ZM37.9454 33.2275C34.8508 33.2275 31.9629 32.7587 29.5162 31.9466C30.5606 33.4279 31.3726 35.0817 31.9032 36.8592C33.7681 37.2663 35.8077 37.49 37.9454 37.49C46.773 37.49 53.9298 33.673 53.9298 28.965V24.7025C53.9298 29.4105 46.773 33.2275 37.9454 33.2275ZM37.9454 41.7526C36.0741 41.7526 34.2796 41.5799 32.6108 41.2645C32.6151 41.4265 32.6172 41.5885 32.6172 41.7526C32.6172 43.0228 32.4766 44.2589 32.2144 45.4503C33.994 45.8147 35.9249 46.0151 37.9454 46.0151C46.773 46.0151 53.9298 42.198 53.9298 37.49V33.2275C53.9298 37.9355 46.773 41.7526 37.9454 41.7526Z"
          fill="#A9A9A9"
        />
        <path
          d="M15.2305 28.0918C11.5319 28.0918 7.9849 29.561 5.36964 32.1763C2.75439 34.7915 1.28516 38.3386 1.28516 42.0371C1.28516 45.7356 2.75439 49.2827 5.36964 51.8979C7.9849 54.5132 11.5319 55.9824 15.2305 55.9824C18.929 55.9824 22.476 54.5132 25.0913 51.8979C27.7065 49.2827 29.1758 45.7356 29.1758 42.0371C29.1758 38.3386 27.7065 34.7915 25.0913 32.1763C22.476 29.561 18.929 28.0918 15.2305 28.0918ZM21.16 43.8965H17.0898V47.9667C17.1008 48.42 16.9526 48.8629 16.671 49.2183C16.3894 49.5738 15.9922 49.8194 15.5484 49.9125C15.2814 49.958 15.0077 49.9448 14.7463 49.8738C14.4849 49.8027 14.2421 49.6756 14.0349 49.5012C13.8276 49.3267 13.6609 49.1093 13.5462 48.8638C13.4316 48.6184 13.3718 48.351 13.3711 48.0801V43.8965H9.30092C8.84757 43.9074 8.4047 43.7593 8.04924 43.4777C7.69377 43.1961 7.44817 42.7989 7.35509 42.3551C7.30956 42.088 7.32277 41.8143 7.39381 41.5529C7.46484 41.2915 7.592 41.0487 7.76641 40.8415C7.94083 40.6343 8.15832 40.4675 8.40374 40.3529C8.64917 40.2382 8.91663 40.1785 9.1875 40.1777H13.3711V36.1076C13.3601 35.6542 13.5083 35.2113 13.7899 34.8559C14.0715 34.5004 14.4687 34.2548 14.9125 34.1617C15.1795 34.1162 15.4533 34.1294 15.7147 34.2004C15.9761 34.2715 16.2188 34.3986 16.4261 34.5731C16.6333 34.7475 16.8001 34.965 16.9147 35.2104C17.0293 35.4558 17.0891 35.7233 17.0898 35.9941V40.1777H21.2734C21.5443 40.1785 21.8118 40.2382 22.0572 40.3529C22.3026 40.4675 22.5201 40.6343 22.6945 40.8415C22.8689 41.0487 22.9961 41.2915 23.0671 41.5529C23.1382 41.8143 23.1514 42.088 23.1059 42.3551C23.0128 42.7989 22.7672 43.1961 22.4117 43.4777C22.0562 43.7593 21.6134 43.9074 21.16 43.8965Z"
          fill="#A9A9A9"
        />
      </g>
      <defs>
        <clipPath id="clip0_18198_20493">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.355469 0.912109)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconTransactions = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M52.4563 36.8105H4.68555V51.0396C4.68555 53.4325 6.70999 55.3701 9.2044 55.3701H47.9374C50.4318 55.3701 52.4563 53.4325 52.4563 51.0396V36.8105ZM14.3688 47.9463H19.5332C20.6022 47.9463 21.4699 47.1148 21.4699 46.0903C21.4699 45.0658 20.6022 44.2344 19.5332 44.2344H14.3688C13.2998 44.2344 12.4322 45.0658 12.4322 46.0903C12.4322 47.1148 13.2998 47.9463 14.3688 47.9463ZM4.68555 33.0986H52.4563V28.7681C52.4563 26.3751 50.4318 24.4375 47.9374 24.4375H9.2044C6.70999 24.4375 4.68555 26.3751 4.68555 28.7681V33.0986Z"
        fill="#A9A9A9"
      />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M30.5081 18.0142V3.1665C30.5081 2.14202 29.6404 1.31055 28.5714 1.31055C27.5024 1.31055 26.6348 2.14202 26.6348 3.1665V18.0142C26.6348 19.0386 27.5024 19.8701 28.5714 19.8701C29.6404 19.8701 30.5081 19.0386 30.5081 18.0142Z"
        fill="#A9A9A9"
      />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M24.7733 9.4286L28.5692 5.7934L32.365 9.4286C33.119 10.1537 34.3481 10.1537 35.1021 9.4286C35.8587 8.70602 35.8587 7.5281 35.1021 6.80551L29.9377 1.8563C29.1811 1.13123 27.9572 1.13123 27.2006 1.8563L22.0362 6.80551C21.2796 7.5281 21.2796 8.70602 22.0362 9.4286C22.7902 10.1537 24.0193 10.1537 24.7733 9.4286Z"
        fill="#A9A9A9"
      />
    </svg>
  );
};

export const showTransactionIcon = (type) => {
  switch (type) {
    case 'Incomes':
      return <IconIncome />;
    case 'Withdrawals':
      return <IconWithdrawal />;
    case 'Deposits':
      return <IconDeposit />;
    case 'Transactions':
      return <IconTransactions />;

    default:
      break;
  }
};

export const showTransactionTitle = (type) => {
  switch (type) {
    case 'Incomes':
      return 'Income';
    case 'Withdrawals':
      return 'Withdrawal';
    case 'Deposits':
      return 'Deposit';
    case 'Transactions':
      return 'Transaction';

    default:
      break;
  }
};

export const showNoDataText = (type) => {
  switch (type) {
    case 'Incomes':
      return 'No Income Transactions!';
    case 'Withdrawals':
      return 'No Withdrawal Transactions!';
    case 'Deposits':
      return 'No vDrive Deposit Transactions!';
    case 'Transactions':
      return 'No vDrive Transactions!';

    default:
      break;
  }
};

export const showPlusMinus = (type) => {
  switch (type) {
    case 'Incomes':
      return '+';
    case 'Withdrawals':
      return '-';
    case 'Deposits':
      return '+';
    case 'Transactions':
      return '-';

    default:
      break;
  }
};

export const showStatusColor = (type) => {
  switch (type) {
    case 'pending':
      return lightTheme.yellow;
    case 'processed':
      return lightTheme.green;
    case 'cancelled':
      return lightTheme.primary;
    case 'failed':
      return lightTheme.primary;

    default:
      break;
  }
};

export const showAmountColor = (type) => {
  switch (type) {
    case 'Incomes':
      return lightTheme.green;
    case 'Withdrawals':
      return '';
    case 'Deposits':
      return lightTheme.green;
    case 'Transactions':
      return lightTheme.primary;

    default:
      break;
  }
};

export const TextWithTooltipIcon = ({ text, tooltip, bold }) => {
  const random = Math.random();
  return (
    <>
      <P $m="0" $bold={bold}>
        {text}{' '}
        <a
          data-tooltip-id={random}
          data-tooltip-html={tooltip}
          data-tooltip-place="top"
          style={{ color: lightTheme.darkGray }}
        >
          <SvgInlineWrapper>
            <InfoIcon />
          </SvgInlineWrapper>
        </a>
      </P>
      <Div $desktop>
        <Tooltip
          clickable
          id={random}
          style={{
            maxWidth: '500px',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
      <Div $mobile>
        <Tooltip
          id={random}
          style={{
            maxWidth: '95%',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
    </>
  );
};

export const TitleWithTooltipIcon = ({ text, tooltip, textColor, mb }) => {
  return (
    <>
      <H3 $color={textColor} $mb={mb}>
        {text}{' '}
        <a
          data-tooltip-id={tooltip}
          data-tooltip-html={tooltip}
          data-tooltip-place="top"
          style={{ color: lightTheme.darkGray }}
        >
          <SvgInlineWrapper>
            <InfoIcon />
          </SvgInlineWrapper>
        </a>
      </H3>
      <Div $desktop>
        <Tooltip
          clickable
          id={tooltip}
          style={{
            maxWidth: '500px',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
      <Div $mobile>
        <Tooltip
          id={tooltip}
          style={{
            maxWidth: '95%',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
    </>
  );
};

export const SubTitleWithTooltipIcon = ({ text, tooltip, textColor, mb }) => {
  return (
    <>
      <H5 $color={textColor} $mb={mb}>
        {text}{' '}
        <a
          data-tooltip-id={tooltip}
          data-tooltip-html={tooltip}
          data-tooltip-place="top"
          style={{ color: lightTheme.darkGray }}
        >
          <SvgInlineWrapper>
            <InfoIcon />
          </SvgInlineWrapper>
        </a>
      </H5>
      <Div $desktop>
        <Tooltip
          clickable
          id={tooltip}
          style={{
            maxWidth: '500px',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
      <Div $mobile>
        <Tooltip
          id={tooltip}
          style={{
            maxWidth: '95%',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
    </>
  );
};
