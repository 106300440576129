import { P, lightTheme } from '../../styles';
import { Radio } from '../Radio/Radio';
import styled from 'styled-components';

const AdminRadioInputStyled = styled.div`
  width: 100%;
  border: none;
  border-radius: 200px;
  line-height: 18px;
  height: 45px;
  appearance: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  color: ${({ $checked, theme }) =>
    $checked ? theme.primary : theme.darkGray};

  ${({ $shadowDesign, theme }) =>
    $shadowDesign &&
    `
    font-weight: 400;
    padding: 0 12px 0 24px;
    box-shadow: ${theme.shadows.shadow1};
    background: ${theme.white};
  `}

  @media (max-width: 800px) {
    font-size: 16px;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const AdminRadioInput = ({ checked, onClick, shadowDesign, label }) => {
  return (
    <AdminRadioInputStyled
      $shadowDesign={shadowDesign}
      onClick={onClick}
      $checked={checked}
    >
      <P $m="0" $color={checked && lightTheme.primary}>
        {label}
      </P>
      <Radio checked={checked} />
    </AdminRadioInputStyled>
  );
};
