import { Div, P } from '@vgrubs/components';
import CurrencyInput from './currencyInput';
import styled from 'styled-components';

const AdminInputStyled = styled(CurrencyInput)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.darkGray}80;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 45px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: none;

  ${({ shadowDesign, theme }) =>
    shadowDesign &&
    `
  
  border: 1px solid #e3e3e3;
  box-shadow: ${theme.shadows.shadow1};

  `}

  ${({ search }) => search && `padding-right: 50px`};
  ${({ $textCenter }) => $textCenter && `text-align: center`};

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.primary};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const AdminDollarInput = (props) => {
  return (
    <Div
      $relative
      $width="100%"
      $maxWidth={props?.maxWidth}
      $maxWidthSM={props?.maxWidthSM}
    >
      {props.label && (
        <P $mb="8" $ml="24" $mr="24">
          <b>{props.label}</b>
        </P>
      )}
      <AdminInputStyled {...props} />
    </Div>
  );
};
