import { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useMatch, useNavigate } from 'react-router-dom';

import {
  AdminEditor,
  AdminHeader,
  AdminImageInput,
  AdminInput,
  AdminSpinner,
  ErrorToast,
  FormSeparator,
} from '../../adminComponents';

import {
  api,
  ADMIN_BLOGS,
  ADMIN_BLOGS_EDIT,
  AnimatedDiv,
  Button,
  Div,
  H5,
} from '@vgrubs/components';
import { blogsAddInitialState } from './data';

export const BlogsAdd = () => {
  const [blog, setBlog] = useState(blogsAddInitialState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const match = useMatch(ADMIN_BLOGS_EDIT);
  const paramsId = match.params.id;

  async function getBlog() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/blogs/${paramsId}`);

      setBlog(data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (paramsId !== 'add') {
      getBlog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, setState, name) => {
    setState((prev) => {
      const copy = { ...prev };
      copy[name] = value;
      return copy;
    });
  };

  async function handleAddEdit() {
    const { imageUrl, id, createdAt, ...blogRest } = blog;

    let formData = new FormData();
    if (typeof imageUrl !== 'string') {
      formData.append('image', imageUrl);
    }
    formData.append('blog', JSON.stringify(blogRest));

    try {
      setLoading(true);

      if (paramsId === 'add') {
        await api.post(`/v1/blogs`, formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
      } else {
        await api.put(`/v1/blogs/${id}`, formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
      }

      setLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error.response);
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!blog.imageUrl) return ErrorToast('Please select Image');
    if (!blog.title) return ErrorToast('Please enter Title');
    if (!blog.content) return ErrorToast('Please enter Content');
    if (!blog.shortText) return ErrorToast('Please enter Short text');
    if (!blog.metaContent) return ErrorToast('Please enter Meta content');
    if (!blog.metaTitle) return ErrorToast('Please enter Meta title');
    if (!blog.urlPath) return ErrorToast('Please enter URL path');

    handleAddEdit();
  }

  if (loading) return <AdminSpinner />;

  return (
    <>
      <AdminHeader />
      <AnimatedDiv>
        <H5 $m="20px 0">
          {paramsId !== 'add' ? `Edit blog ${blog.title}` : 'Add new blog'}
        </H5>
        <form onSubmit={handleSubmit}>
          <Div $flex $column $gap="32px">
            <AdminImageInput
              image={blog.imageUrl}
              onChange={(img) => handleChange(img, setBlog, 'imageUrl')}
              label="Logo"
            />

            <AdminInput
              label="Title"
              type="text"
              value={blog.title}
              onChange={({ target }) =>
                handleChange(target.value, setBlog, 'title')
              }
              maxLength={70}
            />

            <AdminInput
              label="Meta Title"
              type="text"
              value={blog.metaTitle}
              onChange={({ target }) =>
                handleChange(target.value, setBlog, 'metaTitle')
              }
            />

            <AdminInput
              label="Meta Content"
              type="text"
              value={blog.metaContent}
              onChange={({ target }) =>
                handleChange(target.value, setBlog, 'metaContent')
              }
            />

            <AdminInput
              label="Meta Keywords"
              type="text"
              value={blog.metaTags}
              onChange={({ target }) =>
                handleChange(target.value, setBlog, 'metaTags')
              }
            />

            <AdminInput
              label="URL Path"
              type="text"
              value={blog.urlPath}
              onChange={(e) => {
                handleChange(e.target.value.trim(), setBlog, 'urlPath');
              }}
            />

            <AdminInput
              label="Short text"
              type="text"
              value={blog.shortText}
              onChange={({ target }) =>
                handleChange(target.value, setBlog, 'shortText')
              }
              maxLength={150}
            />

            <AdminEditor
              label="Content"
              onChange={(data) => handleChange(data, setBlog, 'content')}
              value={blog.content}
            />
          </Div>
          <FormSeparator />
          <Div $flex $between>
            <Button type="button" onClick={() => navigate(ADMIN_BLOGS)} $gray>
              Cancel
            </Button>
            <Button type="submit">Save Blog</Button>
          </Div>
        </form>
      </AnimatedDiv>
    </>
  );
};
