import {
  ADMIN_LOCATIONS_V_DRIVE,
  AdminDollarInput2,
  AdminHeader,
  AdminInput,
  AdminSpinner,
  AdminSwitch,
  AnimatedDiv,
  Button,
  ChevronSmall,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  H5,
  Icon,
  Img,
  P,
  Separator,
  SuccessToast,
  VDriveLogo,
  api,
  groupFundingTipSchedule,
  lightTheme,
  renderProviderIcon,
  renderProviderName,
  unGroupFundingTipSchedule,
  vDriveDeactivated,
} from '@vgrubs/components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import styled from 'styled-components';
import { AddEditFundingTip } from './AddEditFundingTip';
import { DeactivateVDriveModal } from './DeactivateVDriveModal';
import { FundingTipComponent } from './FundingTipComponents';
import { VDriveCreditCard } from './VDriveCreditCard';
import {
  AutoRechargeComponent,
  AutoRechargeWhenComponent,
} from './onboarding/SetAutoRecharge';
import { FundingTipAmountAndAddMultiple } from './FundingTipAmountAndAddMultiple';

export const VDriveLocation = () => {
  const [loading, setLoading] = useState('');
  const [vDriveVerification, setVDriveVerification] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [achCustomerId, setAchCustomerId] = useState('');
  const [enableVgrubsBrands, setEnableVgrubsBrands] = useState(false);
  const [autoAssignDriver, setAutoAssignDriver] = useState(false);
  const [providers, setProviders] = useState([]);
  const navigate = useNavigate();
  const [autoRechargeAmount, setAutoRechargeAmount] = useState(false);
  const [autoRechargeWhenAmount, setAutoRechargeWhenAmount] = useState(false);
  const [isVDriveCard, setIsVDriveCard] = useState(false);
  const [balance, setBalance] = useState(0);
  const [rechargeCollapse, setRechargeCollapse] = useState(false);
  const [vGrubsFee, setVGrubsFee] = useState(25);
  const [vDriveFee, setVDriveFee] = useState(200);
  const [fundedTip, setFundedTip] = useState(0);

  const [fundedTipSchedule, setFundedTipSchedule] = useState([]);
  const [fundedTipAddEditModal, setFundedTipAddEditModal] = useState(false);
  const [fundedTipAdd, setFundedTipAdd] = useState(false);

  const [fundingMultiple, setFundingMultiple] = useState(false);

  const [deactivateModal, setDeactivateModal] = useState(false);
  const [isVDriveDeactivated, setIsVDriveDeactivated] = useState(false);

  const ref = useRef(null);
  const elementHeight = ref?.current?.clientHeight;

  const id = useMatch(ADMIN_LOCATIONS_V_DRIVE).params.id;

  async function handleUpdateVDrive() {
    if (!merchantId) {
      ErrorToast('Please add Delivery Merchant ID');
      return;
    }
    if (Number(vDriveFee) < 80) {
      ErrorToast(`vDrive Fee can't be less than $0.80`);
      return;
    }
    if (Number(vGrubsFee) < 0) {
      ErrorToast(`vGrubs Fee can't be less than $0.00`);
      return;
    }

    const providersDisabled = providers
      .filter(({ enabled }) => enabled === false)
      .map(({ provider }) => provider);

    const body = {
      merchantId,
      enableVgrubsBrands,
      autoAssignDriver,
      providersDisabled,
      autoRechargeAmount,
      autoRechargeWhenAmount,
      achCustomerId,
      vDriveFee,
      vGrubsFee,
      fundedTipSchedule: groupFundingTipSchedule(fundedTipSchedule),
    };

    try {
      setLoading(true);
      const { data } = await api.put(`/v1/vdrive/location/${id}`, body);
      SuccessToast(data.message);
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleGetVDrive() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/vdrive/location/${id}`);
      setVDriveVerification(data.data);
      setAutoAssignDriver(data?.data?.autoAssign || false);
      setEnableVgrubsBrands(data?.data?.vGrubsBrandsEnabled || false);
      setMerchantId(data?.data?.merchantId || '');
      setProviders(data?.data?.providers || []);
      setAutoRechargeAmount(data?.data?.autoRechargeAmount || false);
      setAutoRechargeWhenAmount(data?.data?.autoRechargeWhenAmount || false);
      setIsVDriveCard(data?.data?.isVDriveCard || false);
      setBalance(data?.data?.totalBalance - data?.data?.reservedBalance || 0);
      setAchCustomerId(data?.data?.achCustomerId || '');
      setVGrubsFee(data?.data?.vGrubsFee || 0);
      setVDriveFee(data?.data?.vDriveFee || 0);
      setFundedTip(data?.data?.fundedTip || 0);
      setFundedTipSchedule(
        data?.data?.fundedTipSchedule
          ? unGroupFundingTipSchedule(data?.data?.fundedTipSchedule)
          : []
      );
      if (data?.data?.status === 'disabled') {
        setIsVDriveDeactivated(true);
      }
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      handleGetVDrive();
    }
  }, []);

  async function handleDeactivateReactivate() {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/vdrive/${vDriveVerification?.id}/${
          isVDriveDeactivated ? 'reactivate' : 'disable'
        }`
      );
      setIsVDriveDeactivated((prev) => !prev);
      setDeactivateModal(false);

      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // const locationState = useLocation().state;
  // const location = locationState;
  // const { owner } = location;

  const { location, owner, cardFailed } = vDriveVerification;

  const SaveButton = () => (
    <Button $selected onClick={handleUpdateVDrive}>
      Save
    </Button>
  );

  const DeactivateButton = () => (
    <Button
      $gray={!isVDriveDeactivated}
      $selected={isVDriveDeactivated}
      onClick={() => setDeactivateModal(true)}
    >
      {isVDriveDeactivated ? 'Reactivate' : 'Deactivate'} vDrive
    </Button>
  );

  const handleValueVDriveFee = useCallback((val) => {
    setVDriveFee(val);
  }, []);

  const handleValueVGrubsFee = useCallback((val) => {
    setVGrubsFee(val);
  }, []);

  const handleValueTipFunding = useCallback((val) => {
    setFundedTip(val);
  }, []);

  return (
    <>
      <AdminHeader title={'Manage vDrive Location'}></AdminHeader>

      {loading && <AdminSpinner />}
      {vDriveVerification && !isVDriveDeactivated && (
        <AnimatedDiv>
          <Div
            $m="40px 0"
            $mSM="20px 0"
            $flex
            $alignCenter
            $between
            $columnSM
            $gap="48px"
            $gapSM="20px"
          >
            <Div
              $flex
              $alignCenter
              $gap="30px"
              $gapSM="16px"
              $width="70%"
              $widthSM="100%"
            >
              <VDriveLogo width="125" height="125" />
              <Div>
                <H3 $mb="8" $color={lightTheme.primary}>
                  {location.name}
                </H3>
                <P $mb="8">
                  {location.address}
                  <br />
                  Owner: {owner.firstName} {owner.lastName} <br />
                  Phone Number: {location.phoneNumber || '/'}
                </P>
              </Div>
            </Div>
            <Div
              $flex
              $column
              $alignEnd
              $desktop
              $width="30%"
              $widthSM="100%"
              $gap="16px"
            >
              <SaveButton />
              <DeactivateButton />
            </Div>
          </Div>
          <Div $flex $gap="48px" $gapSM="0" $columnSM $m="20px 0">
            <Div $width="500px" $widthSM="100%" $flex $column $gap="24px">
              <VDriveCreditCard
                balance={balance}
                setBalance={setBalance}
                location={location}
                isVDriveCard={isVDriveCard}
                setIsVDriveCard={setIsVDriveCard}
                loading={loading}
                setLoading={setLoading}
                vDriveVerification={vDriveVerification}
                handleGetVDrive={handleGetVDrive}
                cardFailed={cardFailed}
              />

              <AdminInput
                tooltip="First Delivery Merchant ID used to connect vDrive."
                label="Delivery Merchant ID"
                placeholder="Enter Here"
                $textCenter
                value={merchantId}
                onChange={(e) => setMerchantId(e.target.value)}
              />
              <AdminInput
                tooltip="The GoCardless Customer ID will be used for processing client payments related to outstanding balances on the vDrive credit card. A 2% fee will be applied for each balance top-up. Payments will be automatically charged every Monday and Thursday night."
                $textCenter
                label="ACH Customer ID"
                placeholder="Enter here"
                value={achCustomerId}
                onChange={(e) => setAchCustomerId(e.target.value)}
              />

              <Div $flex $gap="24px" $gapSM="12px">
                <Div $width="50%">
                  <AdminDollarInput2
                    label="vDrive Fee"
                    onValueChange={handleValueVDriveFee}
                    value={vDriveFee}
                    $textCenter
                  />
                </Div>

                <Div $width="50%">
                  <AdminDollarInput2
                    label="vGrubs Fee"
                    onValueChange={handleValueVGrubsFee}
                    value={vGrubsFee}
                    $textCenter
                  />
                </Div>
              </Div>

              <Div $box $flex $column $p="24px">
                <Div
                  $pointer
                  $flex
                  $alignCenter
                  $between
                  onClick={() => setRechargeCollapse((prev) => !prev)}
                >
                  <H5 $mb="0">Set Auto Recharge</H5>
                  <Icon $rotate={rechargeCollapse ? '180deg' : '0'}>
                    <ChevronSmall />
                  </Icon>
                </Div>
                <Collapsible
                  expanded={rechargeCollapse}
                  elementHeight={elementHeight}
                >
                  <Div ref={ref} $flex $column $gap="4px">
                    <AutoRechargeComponent
                      autoRechargeAmount={autoRechargeAmount}
                      setAutoRechargeAmount={setAutoRechargeAmount}
                    />
                    <AutoRechargeWhenComponent
                      autoRechargeWhenAmount={autoRechargeWhenAmount}
                      setAutoRechargeWhenAmount={setAutoRechargeWhenAmount}
                    />
                  </Div>
                </Collapsible>
              </Div>
              <Div $flex $column $gap="8px">
                <Div
                  $width="100%"
                  $box
                  $p=" 0 24px"
                  $height="80px"
                  $heightSM="80px"
                  $flex
                  $alignCenter
                  $between
                >
                  <H5 $m="0">Enable vDrive for vGrubs Brands</H5>{' '}
                  <AdminSwitch
                    checked={enableVgrubsBrands}
                    onChange={() => setEnableVgrubsBrands((prev) => !prev)}
                  />
                </Div>
                <Div
                  $width="100%"
                  $box
                  $p=" 0 24px"
                  $height="80px"
                  $heightSM="80px"
                  $flex
                  $alignCenter
                  $between
                >
                  <Div>
                    <H5 $mb="4">Automatically Assign Driver</H5>
                    <Disclaimer $m="0" $color={lightTheme.lightGray}>
                      (Based on cheapest and fastest routing)
                    </Disclaimer>
                  </Div>
                  <AdminSwitch
                    checked={autoAssignDriver}
                    onChange={() => setAutoAssignDriver((prev) => !prev)}
                  />
                </Div>
              </Div>
            </Div>
            <Div $width="calc(100% - 500px)" $widthSM="100%">
              <Separator $m="0 0 16px 0" />
              <H3 $mb="16" $color={lightTheme.primary}>
                Providers
              </H3>
              {providers.map(({ provider, enabled }, i) => (
                <Div
                  key={i}
                  $box
                  $p="12px 16px"
                  $mb="8"
                  $flex
                  $between
                  $alignCenter
                >
                  <Div $flex $alignCenter $gap="20px">
                    <Img width="32px" src={renderProviderIcon(provider)} />
                    <H5 $m="0">{renderProviderName(provider)}</H5>
                  </Div>
                  <AdminSwitch
                    checked={enabled}
                    onChange={() =>
                      setProviders((prev) =>
                        prev.map((p) => {
                          if (p.provider === provider) {
                            return { provider, enabled: !p.enabled };
                          } else return p;
                        })
                      )
                    }
                  />
                </Div>
              ))}
              <Div $mt="40"></Div>
              <FundingTipComponent
                setFundedTipSchedule={setFundedTipSchedule}
                fundedTipSchedule={fundedTipSchedule}
                setFundedTipAddEditModal={setFundedTipAddEditModal}
                setFundedTipAdd={setFundedTipAdd}
                setFundingMultiple={setFundingMultiple}
              />
            </Div>
          </Div>

          <Div $mobile $mt="40" $flex $column $gap="16px">
            <SaveButton />
            <DeactivateButton />
          </Div>
        </AnimatedDiv>
      )}

      {isVDriveDeactivated && (
        <AnimatedDiv $flex $column $center $minHeight="80vh">
          <Img $width="180px" src={vDriveDeactivated} $mb="24" />
          <H3 $mb="24" $center>
            vDrive Deactivated!
          </H3>
          <P $mb="32" $center>
            vDrive service for this location is deactivated. <br />
            Click on button and activate it again.
          </P>
          <DeactivateButton />
        </AnimatedDiv>
      )}

      <DeactivateVDriveModal
        modalIsOpen={deactivateModal}
        setModalIsOpen={setDeactivateModal}
        handleDeactivateReactivate={handleDeactivateReactivate}
        isVDriveDeactivated={isVDriveDeactivated}
      />

      <AddEditFundingTip
        modalIsOpen={fundedTipAddEditModal}
        setModalIsOpen={setFundedTipAddEditModal}
        setFundedTipSchedule={setFundedTipSchedule}
        edit
      />

      <AddEditFundingTip
        modalIsOpen={fundedTipAdd}
        setModalIsOpen={setFundedTipAdd}
        setFundedTipSchedule={setFundedTipSchedule}
      />

      <FundingTipAmountAndAddMultiple
        modalIsOpen={fundingMultiple}
        setModalIsOpen={setFundingMultiple}
        setFundedTipSchedule={setFundedTipSchedule}
      />
    </>
  );
};

export const Collapsible = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  margin-top: ${(props) => (props.expanded ? '24px' : '0px')};
  transition: all 300ms;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
