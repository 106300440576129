import {
  Button,
  Div,
  EditPenIconDarkGray,
  Icon,
  Img,
  TrashIconRed,
  grubster,
} from '@vgrubs/components';

export const GrubconConnect = ({
  isGrubconConnected,
  setGrubconConnectModal,
  setSip,
  setCrmApiKey,
}) => {
  const handleOnDelete = () => {
    setSip(null);
    setCrmApiKey(null);
  };

  return (
    <>
      <Div
        $box
        $p="0 16px"
        $pSM="0 12px"
        mb="50px"
        $mt="24"
        $flex
        $alignCenter
        $between
        $gap="20px"
        $gapSM="4px"
        $heightSM="62px"
      >
        <Img src={grubster} alt="grubster" $height="72px" $heightSM="45px" />
        {isGrubconConnected ? (
          <Div $flex $gap="20px" $gapSM="12px">
            <Icon $pointer onClick={() => setGrubconConnectModal(true)}>
              <EditPenIconDarkGray />
            </Icon>

            <Icon $pointer onClick={handleOnDelete}>
              <TrashIconRed />
            </Icon>
          </Div>
        ) : (
          <Div $flex $flexNone>
            <Button
              $gray
              $selected
              $small
              $w="90px"
              $wSM="80px"
              onClick={() => setGrubconConnectModal(true)}
            >
              Connect
            </Button>
          </Div>
        )}
      </Div>
    </>
  );
};
