import {
  H5,
  Modal,
  P,
  Separator,
  centsToDollars,
  formatter,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import {
  TextWithTooltipIcon,
  TooltipWithIcon,
  WithdrawalStatusCircle,
  showStatusColor,
} from '../TransactonsStyled';

export const WithdrawalDetailModal = ({ modalIsOpen, setModalIsOpen }) => {
  const transaction = modalIsOpen.object;

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      textAlign={'left'}
      header={'Withdrawal Details'}
    >
      <P $m="0">Date & Time</P>
      <H5 $m="0">
        {DateTime.fromISO(transaction?.createdAt).toFormat(
          'MMM dd, yyyy - hh:mm a'
        )}
      </H5>
      <Separator $m="20px 0" />
      <P $m="0">Description</P>
      <H5 $m="0">Withdrawal to Your Account</H5>
      <Separator $m="20px 0" />
      <P $m="0">Status</P>
      <H5 $m="0" $capitalize>
        {' '}
        <WithdrawalStatusCircle
          $color={showStatusColor(transaction?.status)}
        />{' '}
        {transaction?.status}
      </H5>
      <Separator $m="20px 0" />
      <P $m="0">Withdrawal amount</P>
      <H5 $m="0">
        {' '}
        {formatter.format(
          centsToDollars(transaction?.amount - transaction?.fee || 0)
        )}
      </H5>
      <Separator $m="20px 0" />
      <TextWithTooltipIcon
        text="Processing Fee"
        tooltip={
          'The processing fee you selected when withdrawing your funds in either "Minutes" or "1 Day".'
        }
      />
      <H5 $m="0">{formatter.format(centsToDollars(transaction?.fee || 0))}</H5>
      <Separator $m="20px 0" />
      <P $m="0">Total</P>
      <H5 $m="0">
        {formatter.format(
          centsToDollars(transaction?.amount || 0 + transaction?.fee || 0)
        )}
      </H5>
    </Modal>
  );
};
