import styled from 'styled-components';

export const ItemStyled = styled.div`
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  border: ${({ theme }) => theme.borders.border1};
  transition: ${({ theme }) => theme.transitions.ease300ms};
  cursor: pointer;

  h5,
  > div > div > p {
    transition: ${({ theme }) => theme.transitions.ease300ms};
  }

  :hover {
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    h5,
    > div > div > p {
      color: ${({ theme }) => theme.primary};
    }
  }

  /* @media (min-width: 3500px) {
  border-radius: 40px;
  } */
`;

export const ItemImage = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background-image: ${({ image }) => `url(${image})`};
  background-size: ${({ imageSize }) => `${imageSize}`};
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 800px) {
    aspect-ratio: 16/11;
  }
`;

export const ItemDetails = styled.div`
  width: 100%;
  padding: 20px 24px;

  @media (max-width: 800px) {
    padding: 16px 20px;
  }

  > div > p {
    transition: ${({ theme }) => theme.transitions.ease300ms};
    :hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const ItemSeparator = styled.div`
  width: 100%;
  border-bottom: ${({ theme }) => theme.borders.border1};
`;
