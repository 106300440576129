import { Button, H3, Modal, P } from '@vgrubs/components';

export const DeleteModifierModal = ({
  deleteModal,
  setDeleteModal,
  handleDeleteItem,
}) => {
  return (
    <Modal isOpen={deleteModal} setIsOpen={setDeleteModal} logo={false}>
      <H3 $mb="24">
        <span>Are you sure</span>
      </H3>
      <P $mb="40">Are you sure you want to delete this modifier?</P>

      <Button $selected $w="100%" $mb="8" onClick={handleDeleteItem}>
        Confirm
      </Button>

      <Button $selected $w="100%" $gray onClick={() => setDeleteModal(false)}>
        Cancel
      </Button>
    </Modal>
  );
};
