import {
  ADMIN_LOCATIONS_ONBOARDING_V_DRIVE,
  AdminHeader,
  AdminSpinner,
  Div,
  ErrorToast,
  H5,
  MotionDiv,
  SuccessToast,
  api,
  handleSetUrlSearchParams,
} from '@vgrubs/components';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useMatch } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AddBalance } from './AddBalance';
import { AddCardVDrive } from './AddCard';
import { AlmostReady } from './AlmostReady';
import { GetStartedVDrive } from './GetStartedVDrive';
import { SetAutoRecharge, autoRechargeAmounts } from './SetAutoRecharge';

export const VDriveLocationOnboarding = () => {
  const [loading, setLoading] = useState('');

  const id = useMatch(ADMIN_LOCATIONS_ONBOARDING_V_DRIVE).params.id;

  let [searchParams, setSearchParams] = useSearchParams();

  const step = Number(searchParams.get('step'));

  const [isVDriveCard, setIsVDriveCard] = useState(false);

  const [stripeToken, setStripeToken] = useState('');

  // const [amount, setAmount] = useState(5000);

  const [autoRechargeAmount, setAutoRechargeAmount] = useState(
    autoRechargeAmounts[1].value
  );
  const [autoRechargeWhenAmount, setAutoRechargeWhenAmount] = useState(
    autoRechargeAmounts[0].value
  );

  async function handleSubmitVDrive() {
    if (!stripeToken) {
      ErrorToast('Please add your card to proceed');
      setStep(1);
      return;
    }

    // if (amount < 2500) {
    //   ErrorToast('Minimum amount is $25');
    //   setStep(2);
    //   return;
    // }

    const body = {
      stripeToken: stripeToken?.id,
      amount: 2500,
      isVDriveCard,
      autoRechargeAmount: Number(autoRechargeAmount),
      autoRechargeWhenAmount: Number(autoRechargeWhenAmount),
    };

    console.log(body);

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/vdrive/${id}/start`, body);
      SuccessToast(data.message);
      setStep(3);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const setStep = (step) => {
    handleSetUrlSearchParams('step', step, setSearchParams);
  };

  const variants = {
    open: {
      opacity: 1,
      transition: {
        duration: 0.3,
        type: 'ease',
      },
      display: 'block',
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 0.2,
        type: 'ease',
      },
      display: 'none',
    },
  };

  return (
    <>
      <AdminHeader title={'vDrive Onboarding'}>
        {step > 0 && step < 3 && (
          <H5 $m="0" $right>
            <span>Step {step}/2</span>
          </H5>
        )}
      </AdminHeader>
      {loading && <AdminSpinner />}

      <Div $flex $center $minHeight="calc(100vh - 150px)">
        <Div $maxWidth="400px" $width="100%">
          <AnimatePresence mode="wait">
            <MotionDiv
              key={0}
              animate={step === 0 ? 'open' : 'closed'}
              variants={variants}
            >
              <GetStartedVDrive setStep={setStep} />
            </MotionDiv>
            <MotionDiv
              key={1}
              animate={step === 1 ? 'open' : 'closed'}
              variants={variants}
            >
              <AddCardVDrive
                isVDriveCard={isVDriveCard}
                setIsVDriveCard={setIsVDriveCard}
                stripeToken={stripeToken}
                setStripeToken={setStripeToken}
                setStep={setStep}
              />
            </MotionDiv>
            {/* <MotionDiv
              key={2}
              animate={step === 2 ? 'open' : 'closed'}
              variants={variants}
            >
              <AddBalance
                amount={amount}
                setAmount={setAmount}
                setStep={setStep}
              />
            </MotionDiv> */}
            <MotionDiv
              key={2}
              animate={step === 2 ? 'open' : 'closed'}
              variants={variants}
            >
              <SetAutoRecharge
                autoRechargeAmount={autoRechargeAmount}
                setAutoRechargeAmount={setAutoRechargeAmount}
                autoRechargeWhenAmount={autoRechargeWhenAmount}
                setAutoRechargeWhenAmount={setAutoRechargeWhenAmount}
                handleSubmitVDrive={handleSubmitVDrive}
                setStep={setStep}
              />
            </MotionDiv>
            <MotionDiv
              key={3}
              animate={step === 3 ? 'open' : 'closed'}
              variants={variants}
            >
              <AlmostReady />
            </MotionDiv>
          </AnimatePresence>
        </Div>
      </Div>
    </>
  );
};
