import styled from 'styled-components';

export const UserCreditCardStyled = styled.div`
  padding: 32px;
  background: ${({ theme }) => theme.blue};
  border-radius: 30px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  flex: none;
  width: 100%;
  max-width: 450px;
  min-width: 450px;
  min-height: 270px;

  @media (max-width: 800px) {
    padding: 24px;
    max-width: 400px;
    min-width: auto;
    min-height: 240px;
  }
`;
