import { overlayAnimationVariant } from 'components/src/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const DropdownStyled = styled(motion.div)`
  padding: 12px 12px 12px 12px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background: ${({ theme }) => theme.white};
  border: 1px solid #f0f0f0;
  position: absolute;
  ${({ $toLeft }) => ($toLeft ? 'right: 0px;' : 'left: 0px;')}
  overflow: hidden;
  z-index: 10;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 3500px) {
    width: 700px;
    top: 120px;
  }
  top: 60;
  transition: top 0.3s ease 0s;

  max-height: calc(100vh - 100px);
  ${({ $maxHeight }) => `max-height: ${$maxHeight}`};
  ${({ $padding }) => `padding: ${$padding}`};
  ${({ $fullWidth }) => $fullWidth && `right: 0;`};

  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TriggerStyled = styled(motion.div)``;

export const Dropdown = ({
  children,
  isOpen,
  maxHeight,
  padding,
  fullWidth,
  toLeft,
  triggerComponent,
  spacing = 12,
}) => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const positionDropdown = () => {
    const triggerElement = triggerRef.current;
    const dropdownElement = dropdownRef.current;

    if (!triggerElement || !dropdownElement) return;

    const triggerRect = triggerElement.getBoundingClientRect();
    const dropdownRect = dropdownElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    let top = triggerRect.bottom;

    // Adjust if dropdown goes out of the bottom of the viewport
    if (top + dropdownRect.height > viewportHeight) {
      dropdownElement.style.top = `-${dropdownRect.height + spacing}px`;
    } else {
      dropdownElement.style.top = `${triggerRect.height + spacing}px`;
    }
  };

  useEffect(() => {
    if (isOpen) {
      positionDropdown();
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('resize', positionDropdown);
    window.addEventListener('scroll', positionDropdown);

    return () => {
      window.removeEventListener('resize', positionDropdown);
      window.removeEventListener('scroll', positionDropdown);
    };
  }, []);

  return (
    <AnimatePresence>
      <TriggerStyled ref={triggerRef}>{triggerComponent}</TriggerStyled>
      {isOpen && (
        <DropdownStyled
          ref={dropdownRef}
          key="DropdownMenu"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          $maxHeight={maxHeight}
          $padding={padding}
          $fullWidth={fullWidth}
          $toLeft={toLeft}
        >
          {children}
        </DropdownStyled>
      )}
    </AnimatePresence>
  );
};
