import styled from 'styled-components';

export const RestaurantStyled = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  background-color: ${({ theme }) => theme.white};
  padding: 12px 24px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 70px;
  gap: 16px;

  :hover {
    h5 {
      color: ${({ theme }) => theme.primary};
      transition: all 0.3s ease;
    }
  }
`;

export const BrandStyled = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  background-color: ${({ theme }) => theme.white};
  padding: 12px 24px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 70px;
  gap: 16px;

  :hover {
    h5 {
      color: ${({ theme }) => theme.primary};
      transition: all 0.3s ease;
    }
  }
`;

export const CollapsibleBrands = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  margin-top: ${(props) => (props.expanded ? '16px' : '0px')};
  margin-bottom: ${(props) => (props.expanded ? '40px' : '0px')};
  transition: all 300ms;

  @media (min-width: 3500px) {
    margin-top: ${(props) => (props.expanded ? '40px' : '0px')};
    margin-bottom: ${(props) => (props.expanded ? '80px' : '0px')};
  }

  @media (max-width: 800px) {
    margin-top: ${(props) => (props.expanded ? '10px' : '0px')};
  }
`;

export const GridStats = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 40px;
  overflow-x: auto;
  padding: 10px 50px 40px 50px;
  margin: 0 -50px 0 -50px;

  > div {
    min-width: 22%;
  }

  @media (max-width: 800px) {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    padding: 10px 30px 40px 30px;
    margin: 0 -30px 0 -30px;

    > div {
      min-width: 70vw;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const GridBrands = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 50px 40px 50px;
  gap: 24px;
  margin: 0 -50px 40px -50px;

  > div {
    padding: 80px 12px 20px 12px;
    text-align: center;
    margin-top: 65px;
    min-width: 22%;
  }
  > div > div {
    position: absolute;
    height: 130px;
    width: 130px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* width: 100%; */

  @media (max-width: 800px) {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    padding: 10px 30px 40px 30px;
    margin: 0 -30px 0 -30px;

    > div {
      min-width: 200px;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const BrandImage = styled.div`
  position: relative;

  > img {
    width: 130px;
    -webkit-filter: drop-shadow(0px 10px 10px #00000033);
    filter: drop-shadow(0px 10px 10px #00000033);
  }
  > div {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      /* mix-blend-mode: multiply; */
    }
  }
`;

export const ProviderLogoWrapper = styled.div`
  width: 153px;
  height: 153px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135px;
    height: 135px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  }
`;

export const ProviderStatus = styled.div`
  padding: 0 20px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $color }) => $color};
  border-radius: 100px;
  width: 100px;

  @media (max-width: 800px) {
    padding: 0 10px;
    /* width: auto; */
  }
`;
