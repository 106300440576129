import {
  ADMIN_ROOT,
  AdminLayoutRoot,
  NotFound,
  adminAuthRoutes,
  useAuth,
} from '@vgrubs/components';
import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router';

function PrivateRoute({ children, permissions, routes }) {
  const role = useAuth((state) => state?.user?.role);

  const firstAvailableRoute = routes.find(({ permissions }) =>
    permissions.includes(role)
  );

  return permissions.includes(role) ? (
    <>{children}</>
  ) : (
    <Navigate to={firstAvailableRoute.path} />
  );
}

export const AppRouter = ({ routes }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path={ADMIN_ROOT} element={<AdminLayoutRoot routes={routes} />}>
          {adminAuthRoutes.map(({ path, Page }, i) => (
            <Route key={i} path={path} element={<Page />} />
          ))}

          {routes.map(({ path, Page, permissions }, i) => {
            return (
              <Route
                key={i}
                path={path}
                element={
                  <PrivateRoute routes={routes} permissions={permissions}>
                    <Page />
                  </PrivateRoute>
                }
              />
            );
          })}

          <Route path={'/*'} element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
