import {
  AdminDollarInput,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  Img,
  Modal,
  P,
  StripeLogo,
  SuccessToast,
  api,
  vDriveMoneyCoins,
} from '@vgrubs/components';
import { useState } from 'react';

export const EditBalance = ({
  setBalance,
  modalIsOpen,
  setModalIsOpen,
  id,
  setLoading,
}) => {
  const [amount, setAmount] = useState(5000);

  const handleSubmit = async () => {
    if (amount < 2500) return ErrorToast(`Minimum amount  $25`);
    const body = {
      amount: Number(amount),
    };

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/vdrive/${id}/balance`, body);
      setBalance((prev) => Number(prev) + Number(amount));
      setModalIsOpen(false);
      SuccessToast(data.message);
      setAmount(5000);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      header="Add Balance"
      logo={false}
      setIsOpen={setModalIsOpen}
      isOpen={modalIsOpen}
    >
      <Div $flex $column $alignCenter>
        <Img $width="200px" $mb="20" src={vDriveMoneyCoins} />

        <P $mb="20" $center>
          Enter Amount that the client will be needing for vDrive
        </P>

        <AdminDollarInput
          // label="Enter Recovery Amount"
          $textCenter
          prefix="$"
          value={amount / 100}
          onChange={(_, value) => setAmount(value * 100)}
        />

        <P $mt="20" $mb="50" $center>
          This balance will cover approx{' '}
          <span>{Math.floor(amount / 100 / 7)} Deliveries.</span>
        </P>

        <Div $flex $center $gap="8px" $mt="24">
          <Disclaimer $m="0">Powered by:</Disclaimer>
          <StripeLogo />
        </Div>

        <P $mb="0" $center $mt="24">
          Balance will be instantly available.
          <br /> Processing fees apply, see{' '}
          <a
            href="https://vgrubs.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Terms & Conditions.</span>
          </a>
        </P>

        <Button $selected $w="100%" $wSM="100%" $mt="24" onClick={handleSubmit}>
          Add vDrive Balance Now
        </Button>
      </Div>
    </Modal>
  );
};
