import {
  AnimatedDiv,
  Disclaimer,
  Div,
  ErrorToast,
  H5,
  Img,
  Modal,
  P,
  api,
  centsToDollars,
  formatter,
  lightTheme,
  menuItems,
  renderProviderIcon,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const MenuDetails = ({
  setLoading,
  id,
  modalIsOpen,
  setModalIsOpen,
  name,
  type,
}) => {
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);

  async function getMenuDetails() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/${type}/${id}/menu`);
      setData(data.data);
      setFetched(true);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    setFetched(false);
    if (modalIsOpen && id) {
      getMenuDetails();
    }
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Menu"
      scrollable
    >
      {data.length > 0 && fetched && (
        <>
          <Div $flex $between $mb="16" $alignCenter>
            <H5 $m="0">{name}</H5>
            <Disclaimer $m="0">Total items: {data.length}</Disclaimer>
          </Div>
          {data.map((menu, i) => {
            return <MenuComponent key={i} menu={menu} />;
          })}
        </>
      )}
      {data.length < 1 && fetched && (
        <Div $flex $center $column>
          <Img src={menuItems} alt="no menu items" $width="300px" />
          <P $bold>No Menu Items</P>
        </Div>
      )}
    </Modal>
  );
};

const MenuComponent = ({ menu }) => {
  return (
    <AnimatedDiv $box $flex $alignCenter $between $p="16px" $mb="16">
      <Div>
        <H5 $mb="24px">{menu.name}</H5>
        <Div $flex $gap="16px 32px" $wrap>
          {menu.providers.map((p, i) => {
            return (
              <Div $flex $gap="12px" $alignCenter key={i}>
                <Img $width="32px" src={renderProviderIcon(p.provider)} />
                <P $m="0" $color={lightTheme.primary}>
                  {formatter.format(centsToDollars(p.price))}
                </P>
              </Div>
            );
          })}
        </Div>
      </Div>
      <Div></Div>
    </AnimatedDiv>
  );
};
