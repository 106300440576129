import {
  Button,
  Div,
  ErrorToast,
  H3,
  Modal,
  ModalIconStyle,
  P,
  SuccessToast,
  TrashIcon,
  api,
} from '@vgrubs/components';

export const UsersAndAccessDeleteModal = ({
  setLoading,
  modalIsOpen,
  setModalIsOpen,
  setUsers,
  setUserDetailsModal,
}) => {
  const user = modalIsOpen;

  async function handleDeleteUser() {
    try {
      setLoading(true);
      const { data } = await api.delete(`/v1/users/${user?.id}`);
      setUsers((prev) => {
        const filtered = prev.filter((u) => u.user.id !== user.id);
        return filtered;
      });
      SuccessToast(data.message);
      setModalIsOpen(false);
      setUserDetailsModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} logo={false}>
      <Div $flex $justifyCenter>
        <ModalIconStyle>
          <TrashIcon />
        </ModalIconStyle>
      </Div>
      <H3 $mb="8">Delete User?</H3>
      <P $mb="16">
        Are you sure tou want to delete this user? This cannot be undone.
      </P>

      <Div $flex $gap="20px">
        <Div $width="50%">
          <Button $selected $w="100%" $mt="24" onClick={handleDeleteUser}>
            Yes
          </Button>
        </Div>
        <Div $width="50%">
          <Button
            $selected
            $gray
            $w="100%"
            $mt="24"
            onClick={() => setModalIsOpen(false)}
          >
            No
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
