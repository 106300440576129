import {
  Button,
  Div,
  ErrorToast,
  H3,
  Modal,
  ModalIconStyle,
  P,
  SuccessToast,
  TrashIcon,
  api,
  useUsers,
} from '@vgrubs/components';

export const UserCreditCardDelete = ({ id }) => {
  const { deleteBankModal, setDeleteBankModal, setLoading, setBank, bank } =
    useUsers((state) => state);

  async function handleDeleteBank() {
    try {
      setLoading(true);
      const { data } = await api.delete(`/v1/users/bank/${id}`);
      setBank({ ...bank, isBankConnected: false });
      SuccessToast(data.message);
      setDeleteBankModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={deleteBankModal} setIsOpen={setDeleteBankModal} logo={false}>
      <Div $flex $justifyCenter>
        <ModalIconStyle>
          <TrashIcon />
        </ModalIconStyle>
      </Div>
      <H3 $mb="8">Delete Bank Account?</H3>
      <P $mb="16">
        Are you sure tou want to delete this bank account? This cannot be
        undone.
      </P>

      <Div $flex $gap="20px">
        <Div $width="50%">
          <Button $selected $w="100%" $mt="24" onClick={handleDeleteBank}>
            Yes
          </Button>
        </Div>
        <Div $width="50%">
          <Button
            $selected
            $gray
            $w="100%"
            $mt="24"
            onClick={() => setDeleteBankModal(false)}
          >
            No
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
