import styled from 'styled-components';

export const FormSeparator = styled.div`
  width: 100%;
  border-bottom: 2px dashed #e3e3e3;
  margin: ${({ m }) => (m ? m : '50px 0')};
`;

export const VerticalNavSeparator = styled.div`
  height: 40px;
  width: 0px;
  border-right: 1px solid #e3e3e3;
`;
