import {
  AdminInput,
  AnimatedDiv,
  BrandsIcon,
  Button,
  Disclaimer,
  Div,
  DropdownMenuComponent,
  ErrorToast,
  H5,
  Icon,
  Img,
  Overlay,
  P,
  Radio,
  lightTheme,
  restaurantImage,
  useDisableBodyScroll,
  overlayAnimationVariant,
  X,
  IconCheckmarkX,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { LocationsPicker, ScrollableWrapper } from './AdminBrandsPickerStyled';

export const AdminSingleBrandPicker = ({
  selectedLocation,
  selectedBrand,
  setSelectedBrand,
  preselectIfEmpty,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState('');

  useDisableBodyScroll(dropdown);

  const data = selectedLocation?.brands;

  const handleSelectLocation = (newItem) => {
    setSelectedBrand(newItem);
    setDropdown((prev) => !prev);
  };

  useEffect(() => {
    if (selectedLocation && preselectIfEmpty) {
      setSelectedBrand(data[0]);
    }
  }, [selectedLocation]);

  function isLocationSelected(item) {
    return selectedBrand?.id === item.id;
  }

  function renderLocationInputPlaceholder() {
    if (data?.length === 0) {
      return 'No Brands';
    } else if (selectedBrand) {
      return selectedBrand.name;
    } else return 'Select Brand';
  }

  const filteredBrands = data?.filter((brand) =>
    brand.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleOpen = () => {
    if (!selectedLocation) {
      ErrorToast('Please select a location');
    } else if (data?.length === 0) {
      ErrorToast('This location has no brands');
    } else {
      setDropdown((prev) => !prev);
    }
  };

  return (
    <AnimatePresence>
      <Div $relative $height="45px" $heightSM="45px">
        <LocationsPicker $opened={dropdown}>
          <Div
            onClick={handleOpen}
            $width="100%"
            $height="45px"
            $flex
            $alignCenter
          >
            {renderLocationInputPlaceholder()}
          </Div>

          <Div $flexNone $flex $alignCenter $gap="12px">
            {selectedBrand && (
              <Icon $size onClick={() => setSelectedBrand(null)}>
                <X />
              </Icon>
            )}

            <Icon onClick={handleOpen}>
              <BrandsIcon />
            </Icon>
          </Div>
        </LocationsPicker>

        <DropdownMenuComponent
          isOpen={dropdown}
          setIsOpen={setDropdown}
          maxHeight="70vh"
          padding="0"
          fullWidth
        >
          <Div $p="20px">
            <AdminInput
              search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Brand"
            />
          </Div>
          <ScrollableWrapper>
            {filteredBrands?.length === 0 && (
              <Div
                $flex
                $center
                $column
                $height="calc(70vh - 300px)"
                $heightSM="calc(60vh - 300px)"
              >
                <Img src={restaurantImage} alt="restaurant" $width="250px" />
                <H5>No Brands</H5>
              </Div>
            )}
            {filteredBrands?.map((l, i) => (
              <Div
                key={i}
                $box
                $p="18px 24px"
                $mb="8"
                $flex
                $alignCenter
                $between
                onClick={() => handleSelectLocation(l)}
                $gap="16px"
                $pointer
              >
                <div>
                  <P
                    $bold
                    $mb="4"
                    $color={isLocationSelected(l) && lightTheme.primary}
                  >
                    {l.name}
                  </P>
                  <Disclaimer $mb="0">{l.address}</Disclaimer>
                </div>

                <Radio checked={isLocationSelected(l)} />
              </Div>
            ))}
          </ScrollableWrapper>
        </DropdownMenuComponent>
      </Div>

      {dropdown && (
        <Overlay
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};
