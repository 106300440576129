import styled from 'styled-components';

export const FormGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: end;

  @media (min-width: 3500px) {
    gap: 60px;
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  }
  @media (max-width: 1450px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

export const ImagesGrid = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (min-width: 3500px) {
    gap: 60px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1450px) {
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
