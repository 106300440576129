import {
  ADMIN_MENU_PROVIDER,
  Chevron,
  Disclaimer,
  Div,
  EditPenIcon,
  ErrorToast,
  H5,
  Icon,
  P,
  PlusIcon,
  Separator,
  SuccessToast,
  TrashIconRed,
  api,
  convert24HourTo12Hour,
  lightTheme,
  renderDayOfWeek,
} from '@vgrubs/components';
import { useEffect, useRef, useState } from 'react';
import { Collapsible } from '../../MenyStyled';
import { AddEditAvailability } from './AddEditAvailability';
import { DeleteAvailabilityModal } from './DeleteAvailabilityModal';
import { useMatch } from 'react-router';
import { DeleteScheduleModal } from './DeleteScheduleModal';
import { AddEditSchedule } from './AddEditSchedule';

export const ModifierGroupComponent = ({ item, setLoading, setMenu, menu }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
  }, [menu]);

  const [expanded, setExpanded] = useState(false);

  const [addScheduleModal, setAddScheduleModal] = useState(false);
  const [editAvailabilityModal, setEditAvailabilityModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { params } = match;
  const { provider, locationId, brandId } = params;

  async function handleDeleteAvailability() {
    try {
      setLoading(true);
      const { data } = await api.delete(
        `/v1/menu/${locationId}/schedule/${brandId}/provider/${provider}/delete/${deleteModal}`
      );

      setMenu(data.data);

      SuccessToast(data.message);
      setDeleteModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Div
        $box
        $p="16px 24px"
        $flex
        $alignCenter
        $between
        $mb="8"
        // $background={expanded && lightTheme.primary}
      >
        <Div
          $flex
          $alignCenter
          $gap="8px"
          onClick={() => setExpanded((prev) => !prev)}
          $width="100%"
          $pointer
        >
          <P
            $bold
            $m="0"
            //   $color={expanded && lightTheme.white}
          >
            {item.name}
          </P>
        </Div>
        <Div $flex $alignCenter $between $gap="20px">
          <Icon $shadow $pointer onClick={() => setEditAvailabilityModal(item)}>
            <EditPenIcon />
          </Icon>
          <Icon $pointer onClick={() => setDeleteModal(item?.id)}>
            <TrashIconRed />
          </Icon>
          <Icon
            $pointer
            $rotate={expanded ? '360deg' : '180deg'}
            onClick={() => setExpanded((prev) => !prev)}
            // color={expanded ? lightTheme.white : lightTheme.darkGray}
          >
            <Chevron />
          </Icon>
        </Div>
      </Div>
      <Collapsible expanded={expanded} elementHeight={height}>
        <div ref={ref}>
          {item.hours.map((it, i) => {
            return (
              <ModifierComponent
                item={it}
                key={i}
                availability={item}
                setMenu={setMenu}
                setLoading={setLoading}
              />
            );
          })}
          {item.hours.length === 0 ? (
            <P $m="0" $center>
              No modifiers
            </P>
          ) : null}

          <Div
            $flex
            $alignCenter
            $justifyEnd
            $gap="8px"
            $pointer
            onClick={() => setAddScheduleModal(item)}
          >
            <Icon>
              <PlusIcon />
            </Icon>

            <H5 $m="0" $color={lightTheme.primary}>
              Add Another Day
            </H5>
          </Div>
        </div>
      </Collapsible>

      <DeleteAvailabilityModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDeleteAvailability={handleDeleteAvailability}
      />

      <AddEditSchedule
        modalIsOpen={addScheduleModal}
        setModalIsOpen={setAddScheduleModal}
        setLoading={setLoading}
        setMenu={setMenu}
      />

      <AddEditAvailability
        modalIsOpen={editAvailabilityModal}
        setModalIsOpen={setEditAvailabilityModal}
        setLoading={setLoading}
        setMenu={setMenu}
        edit
      />
    </>
  );
};

export const ModifierComponent = ({
  item,
  availability,
  setLoading,
  setMenu,
}) => {
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { params } = match;
  const { provider, locationId, brandId } = params;

  const handleDeleteSchedule = async () => {
    let hrs = [];

    availability.hours
      .filter((h) => h.id !== deleteModal)
      .forEach((element) =>
        hrs.push({
          day: element.day,
          startTime: element.startTime,
          endTime: element.endTime,
        })
      );

    const body = {
      name: availability.name,
      hours: hrs,
    };

    try {
      setLoading(true);
      const { data } = await api.put(
        `/v1/menu/${locationId}/schedule/${brandId}/provider/${provider}/update/${availability?.id}`,
        body
      );
      setMenu(data.data);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setDeleteModal(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Separator />
      <Div $p="16px 20px" $flex $between $alignCenter>
        <div>
          <Disclaimer $mb="4">{renderDayOfWeek(item.day)}</Disclaimer>
          <P $m="0">
            {convert24HourTo12Hour(item.startTime)} -{' '}
            {convert24HourTo12Hour(item.endTime)}
          </P>
        </div>

        <Div $flex $alignCenter $between $gap="20px">
          <Icon $pointer onClick={() => setAddModal(availability)}>
            <EditPenIcon />
          </Icon>
          <Icon $pointer onClick={() => setDeleteModal(item?.id)}>
            <TrashIconRed />
          </Icon>
        </Div>
      </Div>

      <AddEditSchedule
        modalIsOpen={addModal}
        setModalIsOpen={setAddModal}
        setLoading={setLoading}
        setMenu={setMenu}
        availability={availability}
        item={item}
        edit
      />

      <DeleteScheduleModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDeleteSchedule={handleDeleteSchedule}
      />
    </>
  );
};
