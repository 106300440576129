import {
  AdminInput,
  AdminPhoneInput,
  AdminSelect,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const UsersAndAccessEditModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setUsers,
  setUserDetailsModal,
}) => {
  const user = modalIsOpen;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  // const [achCustomerId, setAchCustomerId] = useState('');

  const showRolePicker = role === 'admin' || role === 'support';

  const roles = [
    { name: 'Admin', value: 'admin' },
    { name: 'Support', value: 'support' },
  ];

  const typeOptions = (
    <optgroup label="Select Role">
      {roles.map((r, i) => (
        <option value={r.value} key={i}>
          {r.name}
        </option>
      ))}
    </optgroup>
  );

  async function handleVerifyLocation() {
    if (!firstName && !lastName && !email && !phoneNumber && !role) {
      ErrorToast('Please fill in all information');
      return;
    }

    const body = {
      firstName,
      lastName,
      phoneNumber,
      role,
      email,
      // achCustomerId,
    };

    try {
      setLoading(true);
      const { data } = await api.patch(`/v1/users/${user.id}`, body);
      SuccessToast(data.message);
      setUserDetailsModal(data.data);
      setUsers((prevState) => {
        // Map over the previous state array
        return prevState.map((item) => {
          // If the ID matches, update the properties
          if (item.user.id === user.id) {
            return { ...item, user: data.data };
          }
          // If the ID doesn't match, return the original item
          return item;
        });
      });
      setModalIsOpen(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (modalIsOpen) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setRole(user.role);
      // setAchCustomerId(user.achCustomerId);
    }
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Edit User"
    >
      <Div $flex $column $gap="20px" $gapSM="16px">
        <AdminInput
          label="First Name"
          placeholder="Enter here"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <AdminInput
          label="Last Name"
          placeholder="Enter here"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <AdminInput
          label="Email"
          placeholder="Enter here"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* <AdminInput
          tooltip="The GoCardless Customer ID will be used for processing client payments related to outstanding balances on the vDrive credit card. A 2% fee will be applied for each balance top-up. Payments will be automatically charged every Monday and Thursday night."
          label="ACH Customer ID"
          placeholder="Enter here"
          value={achCustomerId}
          onChange={(e) => setAchCustomerId(e.target.value)}
        /> */}

        <AdminPhoneInput
          value={phoneNumber}
          onChange={setPhoneNumber}
          label="Phone"
        />

        {showRolePicker && (
          <AdminSelect
            value={role}
            options={typeOptions}
            onChange={({ target }) => setRole(target.value)}
            placeholder="Select location"
            label="Role"
            chevron
          />
        )}
      </Div>

      <Button
        $selected
        $w="100%"
        $mt="50"
        $mtSM="30"
        onClick={handleVerifyLocation}
      >
        Edit
      </Button>
    </Modal>
  );
};
