import {
  ArrowRed,
  Div,
  H5,
  Icon,
  Modal,
  P,
  Separator,
  centsToDollars,
  formatter,
  lightTheme,
  useOrders,
} from '@vgrubs/components';
import { DateTime } from 'luxon';

export const IncomeDetailModal = ({ modalIsOpen, setModalIsOpen }) => {
  const transaction = modalIsOpen.object;
  const { setDetailsModal } = useOrders((state) => state);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      textAlign={'left'}
      header={'Income Details'}
    >
      <P $m="0">Date & Time</P>
      <H5 $m="0">
        {DateTime.fromISO(transaction?.createdAt).toFormat(
          'MMM dd, yyyy - hh:mm a'
        )}
      </H5>
      <Separator $m="20px 0" />
      <Div $flex $alignCenter $between>
        <Div>
          <P $m="0">Order ID</P>
          <H5 $m="0">#{transaction?.orderId}</H5>
        </Div>
        <Div $pointer onClick={() => setDetailsModal(transaction?.orderId)}>
          <Icon>
            <ArrowRed />
          </Icon>
        </Div>
      </Div>

      <Separator $m="20px 0" />
      <P $m="0">Description</P>
      <H5 $m="0">Order Income</H5>
      <Separator $m="20px 0" />
      <P $m="0">Amount</P>
      <H5 $color={lightTheme.green} $m="0">
        {formatter.format(centsToDollars(transaction?.amount || 0))}
      </H5>
    </Modal>
  );
};
