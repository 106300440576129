import { lightTheme } from '@vgrubs/components';
import styled from 'styled-components';

export const PaymentPlanComponent = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.darkGray};
  border-radius: 20px;
  display: flex;

  > div:nth-child(1) {
    width: 50%;
    padding: 0 12px;
  }

  > div:nth-child(2) {
    width: 50%;
    background-color: #f9f4ee;
    border-radius: 20px;
    padding: 16px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    > div:nth-child(1) {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  }
`;

export const FirstMonthFree = styled.div`
  width: 173px;
  height: 30px;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  background-color: ${({ theme }) => theme.green};
  margin: 24px auto;
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.darkGray};
  margin: 20px;
`;

export const IncludePrinterStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  margin-top: 16px;
`;

export const StripeInputStyled = styled.div`
  height: 45px;
  width: 100%;
  border-radius: 200px;
  box-shadow: 0 0 0 1px #e3e3e3;
  /* background-color: ${({ theme }) => theme.white}; */

  border: none;
  font-size: 14px;
  padding: 0 24px;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  input {
    border: none;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.darkGray};
    background: none;
    width: 100%;
  }

  input::placeholder {
    color: ${({ theme }) => theme.darkGray}4D;
  }
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  input:focus {
    outline: none !important;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const INPUT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      color: lightTheme.darkGray,
      '::placeholder': { color: `${lightTheme.darkGray}4D` },
      fontSize: '14px',
      fontWeight: 'normal',
    },
    complete: { color: lightTheme.darkGray },
    invalid: { color: 'rgb(230, 30, 41)' },
  },
};
