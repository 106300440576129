import { useState } from 'react';
import { Modal, ModalFullScreen } from '../../components';
import { useDisableBodyScroll } from '../../helpers';
import { Img } from '../../styles';

// const ImageFullStyled = styled.div`
//   img {
//     max-width: 100%;
//     max-height: 100vh;
//     z-index: 1000;
//   }
//   z-index: 1000;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: fixed;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
// `;

// const XButton = styled.div`
//   z-index: 1000;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: fixed;
//   top: 20px;
//   right: 20px;
//   cursor: pointer;
// `;

export const ImagePreview = ({ image, style, header }) => {
  const [isOpen, setIsOpen] = useState(false);

  useDisableBodyScroll(isOpen);

  return (
    <>
      <Img
        src={image}
        $width="100%"
        onClick={() => setIsOpen(true)}
        style={{ ...style, cursor: 'pointer' }}
        header={header}
      />
      {/* <AnimatePresence mode="wait">
        {isOpen && (
          <ImageFullStyled>
            <img src={image} />
          </ImageFullStyled>
        )}

        {isOpen && (
          <ModalOverlay
            onClick={() => setIsOpen(false)}
            key="modalOverlay"
            variants={overlayAnimationVariant}
            initial="initial"
            animate="animate"
            exit="exit"
          />
        )}

        {isOpen && (
          <XButton onClick={() => setIsOpen(false)}>
            <Icon $background={lightTheme.white} color={lightTheme.primary}>
              <X />
            </Icon>
          </XButton>
        )}
      </AnimatePresence> */}

      <Modal
        width="80vw"
        logo={false}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        header={header}
      >
        <Img src={image} $maxWidth="100%" $maxHeight="70vh" />
      </Modal>
    </>
  );
};
