import {
  Button,
  Disclaimer,
  Div,
  H3,
  H5,
  Icon,
  P,
  Separator,
  VDriveIcon,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import styled from 'styled-components';
import { EditCard } from './EditCard';

export const UserCreditCardStyled = styled.div`
  padding: 20px 24px;
  background: ${({ theme }) => theme.transparentBlue};
  border-radius: 30px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  flex: none;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: ${({ theme }) => theme.borders.border1};
  /* max-width: 450px;
  min-width: 450px;
  min-height: 270px; */
  aspect-ratio: 16/10;

  @media (max-width: 800px) {
    padding: 16px 20px;

    /* padding: 24px; */
    /* max-width: 400px;
    min-width: auto;
    min-height: 240px; */
  }
`;
export const UserCreditCardAlertStyled = styled.div`
  padding: 16px 20px;
  background: ${({ theme }) => theme.primary};
  border-radius: 0 0 30px 30px;
  flex: none;
  width: calc(100% - 60px);
  margin: 0 30px;

  @media (max-width: 800px) {
    padding: 16px 20px;
    margin: 0 30px;
  }
`;

export const BakUpCard = ({ backupCard, setBackupCard, setLoading }) => {
  const [editModal, setEditModal] = useState(false);

  return (
    <div>
      <H3 $mt="40">Backup Card Details</H3>
      <UserCreditCardStyled>
        <Div $flex $alignCenter $between $gap="50px" $gapSM="8px">
          <Div $flex $column>
            <H5 $color={lightTheme.darkBlue} $mb="16" $fs="60px">
              vDrive Backup Card
            </H5>
            <P $mb="4" $fs="100px">
              Backup credit card used for <br /> vDrive transactions.
            </P>
          </Div>
          <Icon $size={120} $sizeSM={90}>
            <VDriveIcon />
          </Icon>
        </Div>
        <Separator
          $m="16px 0"
          $mSM="8px 0"
          $color={lightTheme.lightGray + '20'}
        />
        <Div $flex $alignCenter $between $gap="24px" $gapSM="8px">
          <Div $flex $column $gap="8px" $gapSM="4px">
            <Disclaimer $m="0" $color={lightTheme.darkBlue}>
              Card Number:
            </Disclaimer>
            <Disclaimer $m="0" $bold $color={lightTheme.darkBlue}>
              **** **** **** {backupCard}
            </Disclaimer>
          </Div>

          <Button
            $selected
            $w="150px"
            $wSM="125px"
            onClick={() => setEditModal(true)}
          >
            Change Card
          </Button>
        </Div>
      </UserCreditCardStyled>
      <EditCard
        modalIsOpen={editModal}
        setModalIsOpen={setEditModal}
        setLoading={setLoading}
        setBackupCard={setBackupCard}
      />
    </div>
  );
};
