import {
  AdminRadioInput,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H3,
  Icon,
  Modal,
  ModalIconStyle,
  PrinterIcon,
  SuccessToast,
  api,
  lightTheme,
  useOrders,
} from '@vgrubs/components';
import { useState } from 'react';

const printOptions = [
  { name: 'Front Receipt', value: true },
  { name: 'Kitchen Receipt', value: false },
];

export const PrintReceiptModal = () => {
  const [loading, setLoading] = useState(false);
  const { printOrderModal, setPrintOrderModal } = useOrders((state) => state);
  const [print, setPrint] = useState(printOptions[0].value);

  const order = printOrderModal;
  const id = order?.id;

  async function handlePrintOrder() {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/orders/${id}/manual-print`, {
        isFront: print,
      });
      setPrintOrderModal(null);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <AdminSpinner />}

      <Modal
        isOpen={printOrderModal}
        setIsOpen={() => setPrintOrderModal(null)}
        logo={false}
        zIndex={110}
      >
        <Div $flex $justifyCenter>
          <ModalIconStyle>
            <Icon color={lightTheme.white} $size="48">
              <PrinterIcon />
            </Icon>
          </ModalIconStyle>
        </Div>
        <H3 $mb="24px 0 40px 0">Print Receipt</H3>

        <Div $flex $column $gap="8px">
          {printOptions.map(({ name, value }, i) => (
            <AdminRadioInput
              key={i}
              shadowDesign
              label={name}
              checked={print === value}
              onClick={() => setPrint(value)}
            />
          ))}
        </Div>

        <Div $flex $gap="20px" $mt="40">
          <Div $width="50%">
            <Button $selected $w="100%" onClick={handlePrintOrder}>
              Confirm
            </Button>
          </Div>
          <Div $width="50%">
            <Button
              $selected
              $gray
              $w="100%"
              onClick={() => setPrintOrderModal(null)}
            >
              Cancel
            </Button>
          </Div>
        </Div>
      </Modal>
    </>
  );
};
