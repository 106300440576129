import {
  ADMIN_BRAND_MANAGE_PROVIDERS,
  ADMIN_LOCATION_MANAGE_PROVIDERS,
  AdminHeader,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H3,
  P,
  api,
  read,
  store,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useLocation, useMatch } from 'react-router';
import { PauseAllProvidersModal } from './PauseAllProviders';
import { ProviderComponent } from './ProviderComponent';
import { provider, providerStatus } from './helpers';
import { useSearchParams } from 'react-router-dom';

export async function handleDisconnect(
  pathPrefix,
  id,
  provider,
  setConnectionStatuses,
  setStatuses,
  setLoading,
  success
) {
  try {
    setLoading(true);
    const res = await api.post(`/v1/${pathPrefix}/${id}/provider/disconnect`, {
      provider,
    });

    store('provider-connect', JSON.stringify(null));

    setConnectionStatuses((prev) =>
      prev.filter((item) => item.provider !== provider)
    );

    setStatuses((prev) => prev.filter((item) => item.provider !== provider));

    if (success !== null) {
      success();
    }

    setLoading(false);
  } catch (error) {
    ErrorToast(error?.response?.data?.message);
    setLoading(false);
  }
}

export const ManageProviders = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [connectionStatuses, setConnectionStatuses] = useState([]);
  const [pauseAllModal, setPauseAllModal] = useState(false);

  const [providerCredentials, setProviderCredentials] = useState([]);

  const isLocations = window.location.pathname.startsWith('/locations');

  const { key } = useLocation();

  const id = useMatch(
    isLocations ? ADMIN_LOCATION_MANAGE_PROVIDERS : ADMIN_BRAND_MANAGE_PROVIDERS
  )?.params?.id;

  const pathPrefix = isLocations ? 'locations' : 'brands';

  let [searchParams] = useSearchParams();
  const connectedProvider = searchParams.get('cp');

  async function getProvidersStatuses() {
    try {
      setLoading(true);
      const statusesData = await api.get(
        `/v1/${pathPrefix}/${id}/providers-statuses`
      );

      const connectionStatusesData = await api.get(
        `/v1/${pathPrefix}/${id}/provider-connection-statuses`
      );

      const lcpc = JSON.parse(read('provider-connect'));
      // console.log(lcpc);

      setStatuses(statusesData.data.data);
      setConnectionStatuses(connectionStatusesData.data.data);

      if (
        lcpc &&
        id === lcpc.id &&
        pathPrefix === lcpc.pathPrefix &&
        !connectedProvider
      ) {
        handleDisconnect(
          lcpc.pathPrefix,
          lcpc.id,
          lcpc.provider,
          setConnectionStatuses,
          setStatuses,

          setLoading,
          null
        );
      } else {
        setLoading(false);
        store('provider-connect', JSON.stringify(null));
      }
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function getProvidersCredentials() {
    try {
      setLoading2(true);
      const { data } = await api.get(
        `/v1/${pathPrefix}/${id}/provider/configs`
      );

      setProviderCredentials(data.data);

      setLoading2(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading2(false);
    }
  }
  useEffect(() => {
    getProvidersStatuses();
    getProvidersCredentials();
  }, []);

  const hasOnline = statuses.some(
    (item) => item.status === providerStatus.ONLINE
  );

  function removeProviders(objects) {
    // Check if any object has provider "gloriafood" and isConnected true
    const hasGloriafoodConnected = connectionStatuses.some(
      (obj) => obj?.provider === 'gloriafood' && obj?.isConnected
    );

    // Check if any object has provider "localForYou" and isConnected true
    const hasLocalForYouConnected = connectionStatuses.some(
      (obj) => obj?.provider === 'localForYou' && obj?.isConnected
    );

    // Remove objects based on conditions
    if (hasGloriafoodConnected) {
      return objects.filter((obj) => obj !== 'localForYou');
    } else if (hasLocalForYouConnected) {
      return objects.filter((obj) => obj !== 'gloriafood');
    } else {
      return objects; // Return the original array if no matching conditions
    }
  }

  return (
    <>
      {(loading || loading2) && <AdminSpinner />}

      <AdminHeader />
      <H3 $m="40px 0" $mSM="24px 0">
        All Providers
      </H3>

      {removeProviders(Object.values(provider)).map((p, i) => {
        const status = statuses.find((s) => s.provider === p);
        const connectionStatus = connectionStatuses.find(
          (s) => s.provider === p
        );
        const credentials = providerCredentials?.find((s) => s.provider === p);
        return (
          <ProviderComponent
            key={i}
            provider={p}
            id={id}
            status={status}
            connectionStatus={connectionStatus}
            pathPrefix={pathPrefix}
            setConnectionStatuses={setConnectionStatuses}
            setStatuses={setStatuses}
            credentials={credentials}
          />
        );
      })}

      {hasOnline && (
        <>
          <P $m="40px 0 24px 0" $right $centerSM>
            Pausing all providers includes pausing virtual brand providers if
            there are any.{' '}
          </P>
          <Div $flex $justifyEnd $centerSM $width="100%">
            <Div
              $flex
              $justifyEnd
              $centerSM
              $maxWidth="300px"
              $maxWidthSM="100%"
              $width="100%"
            >
              <Button
                $w="100%"
                $selected
                $gray
                onClick={() => setPauseAllModal(true)}
              >
                Pause All Providers
              </Button>
            </Div>
          </Div>
        </>
      )}

      <PauseAllProvidersModal
        modalIsOpen={pauseAllModal}
        setModalIsOpen={setPauseAllModal}
        setLoading={setLoading}
        id={id}
        setStatuses={setStatuses}
      />
    </>
  );
};
