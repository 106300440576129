import {
  AdminInput,
  AdminPhoneInput,
  Button,
  Div,
  ErrorToast,
  Modal,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const ConnectGrubconModal = ({
  modalIsOpen,
  setModalIsOpen,
  setSip,
  setCrmApiKey,
  sipForEdit,
  crmApiKeyForEdit,
  type = 'add',
}) => {
  const [apiKey, setApiKey] = useState('');
  const [server, setServer] = useState('');
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const sip = {
    server,
    user,
    password,
    phoneNumber,
  };

  useEffect(() => {
    if (type === 'edit') {
      console.log(crmApiKeyForEdit);
      setApiKey(crmApiKeyForEdit);
      setServer(sipForEdit?.server);
      setUser(sipForEdit?.user);
      setPassword(sipForEdit?.password);
      setPhoneNumber(sipForEdit?.phoneNumber);
    }
  }, [modalIsOpen]);

  const handleSetSip = () => {
    if (!apiKey) {
      ErrorToast('Please provide all form information');
      return;
    }

    setSip(sip);
    setCrmApiKey(apiKey);
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Connect Grubcon"
      zIndex={100}
    >
      <Div $flex $column $gap="20px" $gapSM="16px">
        <AdminInput
          label="CRM API Key"
          placeholder="Enter here"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />

        <AdminInput
          label="SIP User"
          placeholder="Enter here"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />

        <AdminInput
          label="SIP Password"
          placeholder="Enter here"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <AdminInput
          label="SIP Server"
          placeholder="Enter here"
          value={server}
          onChange={(e) => setServer(e.target.value)}
        />

        <AdminPhoneInput
          value={phoneNumber}
          onChange={setPhoneNumber}
          label="Phone"
        />
      </Div>

      <Button $selected $w="100%" $mt="50" onClick={handleSetSip}>
        Connect
      </Button>
    </Modal>
  );
};
