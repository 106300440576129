import {
  ADMIN_BRAND_DETAILS,
  AdminDatePicker,
  AdminHeader,
  AdminSpinner,
  AnimatedDiv,
  Button,
  Disclaimer,
  Div,
  EditPenIconRed,
  ErrorToast,
  H3,
  H5,
  HeaderIconWrapper,
  NoteIconRed,
  VDriveIcon,
  V_DRIVE_STATUS,
  api,
  lightTheme,
  setTimeTo,
  useAuth,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { LocationComponent } from '../Dashboard/LocationComponent';
import { BrandAddModal } from './BrandAdd';
import { BrandImageComponent } from './BrandImageComponent';
import { BrandNoteModal } from './BrandNoteModal';
import { GridStats, ProviderStatus } from './LocationStyled';
import { MenuDetails } from './ManuDetails';
import { providerStatus, statusColor, statusName } from './helpers';
import { AllProvidersModalComponent } from '../Dashboard/AllProvidersModalComponent';
import { DeleteLocationBrandModal } from './DeleteLocationBrandModal';
import {
  AddBrandCampaignModal,
  BrandCampaignModal,
  BrandCampaignPill,
} from './BrandCampaignModal';

export const BrandDetails = () => {
  const navigate = useNavigate();

  const [addBrandModal, setAddBrandModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const id = useMatch(ADMIN_BRAND_DETAILS).params.id;

  const [menuModal, setMenuModal] = useState(false);

  const [data, setData] = useState(null);
  const [details, setDetails] = useState(null);
  const [statuses, setStatuses] = useState([]);

  const [date, setDate] = useState([today, today]);
  const [dateInput, setDateInput] = useState([today, today]);

  const [allProvidersModal, setAllProvidersModal] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const [campaigns, setCampaigns] = useState([]);
  const [campaignModal, setCampaignModal] = useState(false);
  const [addCampaignModal, setAddCampaignModal] = useState(false);
  const [editCampaignModal, setEditCampaignModal] = useState(false);

  const { role } = useAuth((state) => state.user);

  const dateFrom = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[0]);

  const dateTo = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[1]);

  const handleDateChange = (date) => {
    setDateInput(date);
  };

  const handleDateApply = () => {
    if (dateInput.length < 2) {
      setDate([dateInput[0], dateInput[0]]);
      setDateInput([dateInput[0], dateInput[0]]);
    } else {
      setDate(dateInput);
    }
  };

  async function getBrandSales() {
    const queryString = new URLSearchParams({
      from: setTimeTo(date[0], 'start'),
      to: setTimeTo(date[1], 'end'),
    }).toString();

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/brands/${id}/sales?${queryString}`);
      setData(data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function getBrandDetails() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/brands/${id}`);
      setDetails(data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function getProvidersStatuses() {
    try {
      setLoading(true);
      const statusesData = await api.get(`/v1/brands/${id}/providers-statuses`);

      setStatuses(statusesData.data.data);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function getCampaigns() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/brands/${id}/campaigns`);
      if (data.data.length > 0) {
        setCampaignModal(true);
        setCampaigns(data.data);
      } else {
        setAddCampaignModal(true);
      }
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getBrandSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    getBrandDetails();
    getProvidersStatuses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasOffline = statuses.some(
    (item) => item.status === providerStatus.OFFLINE
  );

  const hasUnknown = statuses.some(
    (item) => item.status === providerStatus.UNKNOWN
  );

  const hasOnline = statuses.some(
    (item) => item.status === providerStatus.ONLINE
  );

  const ProviderStatusComponent = ({ status }) => {
    return (
      <ProviderStatus $color={statusColor(status)}>
        <Disclaimer $m="0" $color={lightTheme.white}>
          {statusName(status)}
        </Disclaimer>
      </ProviderStatus>
    );
  };

  const RenderStatus = () => {
    if (hasUnknown) {
      return <ProviderStatusComponent status={providerStatus.UNKNOWN} />;
    } else if (hasOffline) {
      return <ProviderStatusComponent status={providerStatus.OFFLINE} />;
    } else if (hasOnline) {
      return <ProviderStatusComponent status={providerStatus.ONLINE} />;
    } else return null;
  };

  const handleVDriveOnClick = (status, type) => {
    if (status === null && type === 'vGrubs') {
      return navigate(`/brand/v-drive/${id}`, {
        state: details,
      });
    }

    switch (status) {
      case V_DRIVE_STATUS.active:
        return navigate(`/brand/v-drive/${id}`, {
          state: { brandName: details.name, brandType: details.type },
        });
      case V_DRIVE_STATUS.inactive:
        return ErrorToast(
          'vDrive is not connected. Partner needs to activate it from the restaurant app.'
        );
      case V_DRIVE_STATUS.inProgress:
        return ErrorToast(
          'vDrive is under verification progress. Visit Verifications section to activate it.'
        );
      case V_DRIVE_STATUS.rejected:
        return ErrorToast(
          'vDrive has been rejected by vGrubs. To activate it, partner needs to activate it from the restaurant app. '
        );

      case null:
        return ErrorToast(
          'vDrive is not connected. Partner needs to activate it from the restaurant app.'
        );

      default:
        break;
    }

    // navigate(`/brand/v-drive/${id}`, {
    //   state: details,
    // });
  };

  const ActionButtons = () => (
    <Div $flex $column $gap="16px" $widthSM="100%">
      <Link to={`/menu/${details?.locationId}/location/${id}/brand/delivery`}>
        <Button
          $w="100%"
          $wSM="100%"
          $selected
          //  onClick={() => setMenuModal(true)}
        >
          View Menu
        </Button>
      </Link>

      <Button $w="100%" $gray $selected onClick={getCampaigns}>
        Brand Campaigns
      </Button>

      <Div $flex $gap="16px" $columnSM>
        <Link to={`/brands/manage-providers/${id}`}>
          <Button $wSM="100%" $gray>
            Manage Providers
          </Button>
        </Link>
        {(role === 'admin' || role === 'support') && (
          <Button $wSM="100%" onClick={() => setDeleteModal(id)}>
            Delete Brand
          </Button>
        )}
      </Div>
    </Div>
  );

  return (
    <>
      {loading && <AdminSpinner />}

      <AdminHeader title="Brand Details">
        <Div $flex $alignCenter $gap="32px" $gapSM="16px">
          <HeaderIconWrapper onClick={() => setNoteModal(true)}>
            <NoteIconRed />
          </HeaderIconWrapper>
          <HeaderIconWrapper onClick={() => setAddBrandModal(true)}>
            <EditPenIconRed />
          </HeaderIconWrapper>
          <HeaderIconWrapper
            onClick={() =>
              handleVDriveOnClick(details?.vDriveStatus, details?.type)
            }
          >
            <VDriveIcon />
          </HeaderIconWrapper>
        </Div>
      </AdminHeader>

      {details && (
        <AnimatedDiv
          $m="40px 0"
          $flex
          $alignCenter
          $between
          $columnSM
          $gap="30px"
        >
          <Div $flex $alignCenter $gap="30px" $gapSM="16px" $widthSM="100%">
            <BrandImageComponent image={details.imageUrl} />
            <Div $flex $column $gap="8px">
              <H3 $m="0" $color={lightTheme.primary}>
                {details?.name}
              </H3>
              <RenderStatus />

              {details?.activeCampaignPercentage && (
                <div>
                  <BrandCampaignPill
                    campaign={`Active Campaign ${details?.activeCampaignPercentage}%`}
                  />
                </div>
              )}
            </Div>
          </Div>
          <Div $desktop>
            <ActionButtons />
          </Div>

          <MenuDetails
            modalIsOpen={menuModal}
            setModalIsOpen={setMenuModal}
            id={id}
            setLoading={setLoading}
            name={details.name}
            type="brands"
          />

          <BrandNoteModal
            setModalIsOpen={setNoteModal}
            modalIsOpen={noteModal}
            setLoading={setLoading}
            setData={setDetails}
            data={details}
            pathPrefix="brands"
          />
        </AnimatedDiv>
      )}

      {data && (
        <AnimatedDiv>
          <Div $flex $between $mb="16" $alignCenter>
            <H5 $m="0">Overview</H5>

            <AdminDatePicker
              mode="range"
              value={dateInput}
              onChange={handleDateChange}
              click={handleDateApply}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </Div>
          <GridStats>
            <LocationComponent
              title="Total Sales"
              data={data?.total}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Orders"
              data={data?.count}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Taxes"
              data={data?.tax}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Recovered"
              data={data?.recovered}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Canceled"
              data={data?.cancelled}
              setAllProvidersModal={setAllProvidersModal}
            />
          </GridStats>
        </AnimatedDiv>
      )}

      <Div $mobile $m="20px 0">
        <ActionButtons />
      </Div>

      <BrandAddModal
        setLoading={setLoading}
        modalIsOpen={addBrandModal}
        setModalIsOpen={setAddBrandModal}
        setData={setDetails}
        data={details}
        type="edit"
      />

      <DeleteLocationBrandModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        setLoading={setLoading}
        type={'brands'}
      />

      <AllProvidersModalComponent
        allProvidersModal={allProvidersModal}
        setAllProvidersModal={setAllProvidersModal}
      />

      <BrandCampaignModal
        modalIsOpen={campaignModal}
        setModalIsOpen={setCampaignModal}
        setLoading={setLoading}
        campaigns={campaigns}
        setCampaigns={setCampaigns}
        setAddModalIsOpen={setAddCampaignModal}
        brandId={id}
        setEditCampaignModal={setEditCampaignModal}
      />
      <AddBrandCampaignModal
        modalIsOpen={addCampaignModal}
        setModalIsOpen={setAddCampaignModal}
        setLoading={setLoading}
        setCampaigns={setCampaigns}
        setCampaignModal={setCampaignModal}
        setDetails={setDetails}
      />

      <AddBrandCampaignModal
        modalIsOpen={editCampaignModal}
        setModalIsOpen={setEditCampaignModal}
        setLoading={setLoading}
        setCampaigns={setCampaigns}
        setDetails={setDetails}
        setCampaignModal={setCampaignModal}
        edit
      />
    </>
  );
};
