import { Button, Div, H3, Img, P, noAvailability } from '@vgrubs/components';
import { useState } from 'react';
import { ModifierGroupComponent } from './ModifierComponents';
import { AddEditAvailability } from './AddEditAvailability';

export const Availability = ({ menu, setMenu, setLoading }) => {
  const [addModal, setAddModal] = useState(false);

  const AddAvailabilityButton = () => {
    return (
      <Button $selected onClick={() => setAddModal(true)}>
        Add Availability
      </Button>
    );
  };

  return (
    <>
      <H3 $mb="20">Availability ({menu?.results?.length})</H3>

      {menu?.availability?.map((item, i) => (
        <ModifierGroupComponent
          key={i}
          item={item}
          menu={menu}
          setMenu={setMenu}
          setLoading={setLoading}
        />
      ))}

      {menu?.availability?.length < 1 && (
        <Div $flex $column $alignCenter>
          <Img $width="180px" src={noAvailability} $mb="24" />
          <H3 $mb="24" $center>
            No Availability
          </H3>
          <P $mb="32" $center>
            This provider currently don’t have <br /> menu availability.
          </P>
          <AddAvailabilityButton />
        </Div>
      )}

      {menu?.availability?.length > 0 && (
        <>
          <Div
            $mobile
            style={{
              position: 'fixed',
              right: '30px',
              bottom: '30px',
              left: '30px',
            }}
            $flex
            $justifyEnd
            $mt="40"
          >
            <AddAvailabilityButton />
          </Div>
          <Div
            $desktop
            style={{ position: 'fixed', right: '50px', bottom: '50px' }}
            $flex
            $justifyEnd
            $mt="40"
          >
            <AddAvailabilityButton />
          </Div>
        </>
      )}

      <AddEditAvailability
        modalIsOpen={addModal}
        setModalIsOpen={setAddModal}
        setLoading={setLoading}
        setMenu={setMenu}
      />
    </>
  );
};
