import { ErrorToast } from '../../adminComponents';
import { api } from '../../helpers/api';

export async function getPayouts(
  date,
  page,
  limit,
  hasMore,
  setPayouts,
  setPage,
  setLoading,
  setHasMore,
  setDropdown,
  selectedCustomer,
  setDropdownCustomer
) {
  const dateFrom = date[0].toISOString().split('T')[0];
  const dateTo = date[1].toISOString().split('T')[0];

  if (!hasMore) return;

  try {
    setLoading(true);
    const { data } = await api.get(
      `/v1/wallet/transactions-dwolla?limit=${limit}&page=${page}&from=${dateFrom}&to=${dateTo}${
        selectedCustomer.customerId !== null
          ? `&customerId=${selectedCustomer.customerId}`
          : ''
      }`
    );

    if (data.data[0]) {
      setPage((prevPage) => prevPage + 1);
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    setDropdown(false);
    setDropdownCustomer(false);

    setPayouts((prevItems) => [...prevItems, ...data.data]);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    setHasMore(false);
    ErrorToast(error?.response?.data?.message);
  }
}

export async function getCustomers(
  page,
  limit,
  hasMore,
  setCustomers,
  setPage,
  setLoading,
  setHasMore
) {
  if (!hasMore) return;

  try {
    setLoading(true);
    const { data } = await api.get(
      `/v1/wallet/customers?limit=${limit}&page=${page}`
    );

    if (data.data[0]) {
      setPage((prevPage) => prevPage + 1);
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    setCustomers((prevItems) => [...prevItems, ...data.data]);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    setHasMore(false);
    ErrorToast(error?.response?.data?.message);
  }
}
