import {
  AdminDollarInput,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  Img,
  P,
  StripeLogo,
  vDriveMoneyCoins,
} from '@vgrubs/components';

export const AddBalance = ({ setStep, amount, setAmount }) => {
  const handleSubmit = () => {
    if (amount < 2500) return ErrorToast(`Minimum amount  $25`);
    setStep(3);
  };

  return (
    <>
      <Div $flex $column $alignCenter>
        <Img $width="200px" $mb="20" src={vDriveMoneyCoins} />

        <H3 $center $m="8px 0 40px 0">
          <span>Add Balance</span>
        </H3>

        <P $mb="20" $center>
          Enter Amount that the client will be needing for vDrive
        </P>

        <AdminDollarInput
          // label="Enter Recovery Amount"
          $textCenter
          prefix="$"
          value={amount / 100}
          onChange={(_, value) => setAmount(value * 100)}
        />

        <P $mt="20" $mb="50" $center>
          This balance will cover approx{' '}
          <span>{Math.floor(amount / 100 / 7)} Deliveries.</span>
        </P>

        <Div $flex $center $gap="8px" $mt="24">
          <Disclaimer $m="0">Powered by:</Disclaimer>
          <StripeLogo />
        </Div>

        <P $mb="0" $center $mt="24">
          Balance will be instantly available.
          <br /> Processing fees apply, see{' '}
          <a
            href="https://vgrubs.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Terms & Conditions.</span>
          </a>
        </P>

        <Button $selected $w="100%" $wSM="100%" $mt="24" onClick={handleSubmit}>
          Add vDrive Balance Now
        </Button>
      </Div>
    </>
  );
};
