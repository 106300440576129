import {
  Div,
  H3,
  H5,
  Img,
  P,
  VDriveCancelledIcon,
  VDriveLogo,
  VDrivePendingIcon,
  VDriveSuccessIcon,
  VerticalSeparator,
  centsToDollars,
  formatter,
  lightTheme,
  renderVDriveImage,
  useOrders,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useSearchParams } from 'react-router-dom';
import { providerLogo } from '../Locations/helpers';
import { OrderImage, StatusMark } from './OrdersStyled';
import { RecoveryStatusBadge } from './RecoveryStatusBadge';

const orderType = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  DINEIN: 'dinein',
};

const orderStatus = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

const renderOrderType = (t) => {
  switch (t) {
    case orderType.DELIVERY:
      return 'Delivery';
    case orderType.PICKUP:
      return 'Pickup';
    case orderType.DINEIN:
      return 'Dine in';

    default:
      break;
  }
};

const renderOrderStatusBorder = (order) => {
  if (order.status === orderStatus.ACCEPTED) {
    return lightTheme.green;
  }
  if (order.status === orderStatus.NEW) {
    return lightTheme.primary;
  }
  return '';
};

const renderTextColor = (order) => {
  if (order.status === orderStatus.CANCELLED) {
    return { color: lightTheme.white };
  }
  return {};
};

const renderOrderTitle = (order) => {
  if (order.status === orderStatus.NEW) {
    return 'New Orders';
  } else if (order.status === orderStatus.ACCEPTED) {
    return 'Orders in Progress';
  }
};

const renderOrderTitleColor = (order) => {
  if (order.status === orderStatus.NEW) {
    return lightTheme.primary;
  } else if (order.status === orderStatus.ACCEPTED) {
    return lightTheme.green;
  }
};

export const OrderComponent = ({ data, index }) => {
  const isCancelled = data.status === orderStatus.CANCELLED;
  const borderColor = renderOrderStatusBorder(data);
  const isRestaurant = data.deliveryType === 'restaurant';
  const { setDetailsModal, dataOrders } = useOrders((state) => state);

  const [searchParams] = useSearchParams();

  const statusFilter = searchParams.get('status');

  const prevOrder = dataOrders[index - 1];

  return (
    // <Link to={`/orders/${data.id}`}>

    <>
      {statusFilter === 'active' && (
        <>
          {index === 0 && (
            <H3 $color={renderOrderTitleColor(data)}>
              {renderOrderTitle(data)}
            </H3>
          )}
          {index !== 0 && prevOrder?.status !== data?.status && (
            <H3 $mt="40" $color={renderOrderTitleColor(data)}>
              {renderOrderTitle(data)}
            </H3>
          )}
        </>
      )}

      <Div
        onClick={() => setDetailsModal(data.id)}
        $box
        style={{
          boxShadow: 'none',
          border: borderColor && `1px solid ${borderColor}`,
          backgroundColor: isCancelled && lightTheme.primary,
        }}
        // key={i}
        $p="16px"
        $pSM="12px"
        $mb="8"
        $flex
        $relative
        $pointer
      >
        <StatusMark $color={borderColor} />
        <OrderImage $cancelled={isCancelled}>
          <Img
            src={providerLogo(data.provider)}
            $width="65px"
            $widthSM="45px"
          />

          {data.deliveryType === 'restaurant' &&
            data.vDriveProvider !== null && (
              <Div $flex $gap="12px" $gapSM="4px" $center>
                <VDriveIconStatus status={data.vDrive.status} />

                <Img
                  src={renderVDriveImage(data.vDriveProvider)}
                  $width="42px"
                  $widthSM="32px"
                />
              </Div>
            )}
        </OrderImage>

        {!isCancelled && (
          <VerticalSeparator $m="0 24px 0 0" $mSM="0 16px 0 0" />
        )}
        <Div $width="100%">
          <Div $flex $between $width="100%">
            <Div>
              <H5 style={renderTextColor(data)} $mb="8">
                {data.customerName}
              </H5>
              <P style={renderTextColor(data)} $m="0">
                Order ID: {data.id}
              </P>
              <P style={renderTextColor(data)} $m="0">
                Number: {data.number}
              </P>

              <>
                {isCancelled && (
                  <P $m="0" $color={lightTheme.white}>
                    Cancelled
                  </P>
                )}

                {!isCancelled &&
                  isRestaurant &&
                  data.vDriveProvider === null && (
                    <P $m="0" $color={lightTheme.primary}>
                      Self Delivery
                    </P>
                  )}

                {!isCancelled &&
                  isRestaurant &&
                  data.vDriveProvider !== null && <P $m="0">vDrive</P>}

                {!isCancelled && !isRestaurant && (
                  <P $m="0">{renderOrderType(data.type)}</P>
                )}
              </>

              {!data.isFromLocation && (
                <P
                  style={renderTextColor(data)}
                  $color={lightTheme.primary}
                  $m="0"
                >
                  {data.brandName}
                </P>
              )}
            </Div>
            <Div>
              <Div $flex $alignCenter $justifyEnd>
                <Div $desktop $mr="30" $mb="8">
                  <RecoveryStatusBadge recovery={data?.recovery} />
                </Div>
                <H5
                  style={renderTextColor(data)}
                  $right
                  $color={lightTheme.primary}
                  $mb="8"
                >
                  {formatter.format(centsToDollars(data.totalPrice))}
                </H5>
              </Div>

              <P style={renderTextColor(data)} $right $m="0">
                {DateTime.fromISO(data.date)
                  .setZone(data.timeZone)
                  .toFormat('hh:mm a')}
              </P>
              <P
                $right
                $m="0"
                $color={data.paid ? lightTheme.green : lightTheme.primary}
                style={renderTextColor(data)}
              >
                {data.paid ? 'PAID' : 'UNPAID'}
              </P>
            </Div>
          </Div>
          <Div $mobile $mt="8">
            <RecoveryStatusBadge recovery={data?.recovery} />
          </Div>
        </Div>
      </Div>
    </>
    // </Link>
  );
};

const VDriveIconStatus = ({ status }) => {
  switch (status) {
    case 'completed':
      return (
        <>
          <Div $desktop>
            <VDriveSuccessIcon width="32" height="32" />
          </Div>
          <Div $mobile>
            <VDriveSuccessIcon width="24" height="24" />
          </Div>
        </>
      );

    case 'assigned':
    case 'notAssigned':
    case 'canBeCancelled':
      return (
        <>
          <Div $desktop>
            <VDrivePendingIcon width="32" height="32" />
          </Div>
          <Div $mobile>
            <VDrivePendingIcon width="24" height="24" />
          </Div>
        </>
      );

    case 'cancelled':
      return (
        <>
          <Div $desktop>
            <VDriveCancelledIcon width="32" height="32" />
          </Div>
          <Div $mobile>
            <VDriveCancelledIcon width="24" height="24" />
          </Div>
        </>
      );

    default:
      return (
        <>
          <Div $desktop>
            <VDriveLogo width="32" height="32" />
          </Div>
          <Div $mobile>
            <VDriveLogo width="24" height="24" />
          </Div>
        </>
      );
  }
};
