import styled from 'styled-components';

const AuthLayoutStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: calc(100% - 30px);
    max-width: 400px;
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.white};
  }
`;

export const AdminAuthLayout = ({ children }) => {
  return (
    <AuthLayoutStyled>
      <div>{children}</div>
    </AuthLayoutStyled>
  );
};
