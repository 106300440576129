import { Div, H2, H3, Img, P, unavailableImage } from '@vgrubs/components';

export const Unavailable = () => {
  return (
    <Div $flex $justifyCenter $mt="50">
      <Div $flex $column $alignCenter $width="100%" $maxWidth="300px">
        <Img $width="200px" src={unavailableImage} alt="unavailable" />
        <H3 $center>Unavailable</H3>
        <P $center>
          Working hours is currently not available for connected providers.
          Please reach out to your 3rd party provider.
        </P>
      </Div>
    </Div>
  );
};
