import {
  api,
  Div,
  GrubconLogo,
  H4,
  P,
  displayCentsInDollars,
  handleSetUrlSearchParams,
  isAdmin,
  isPartner,
  isSales,
  lightTheme,
  setDocumentTitle,
  useAuth,
  renderMonthName,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AdminSpinner,
  ErrorToast,
  AdminSelect,
  AdminHeader,
} from '../../adminComponents';
import { InfoBox } from './concierge.styled';
import { Section1 } from './sections/section1';
import { Section2 } from './sections/section2';
import { AdminMonthYearPicker } from 'components/src/adminComponents/AdminMonthYearPicker/AdminMonthYearPicker';

export const Concierge = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chart, setChart] = useState([]);

  const date = new Date();
  const month = date.getUTCMonth() + 1;

  const INITIAL_PARAMS = {
    name: 'all',
    from: 1,
    to: month,
    fromYear: '2024',
    toYear: '2024',
  };

  const [fromMonth, setFromMonth] = useState(1);
  const [toMonth, setToMonth] = useState(1);
  const [fromYear, setFromYear] = useState(2022);
  const [toYear, setToYear] = useState(2024);

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);

  const restaurantName = searchParams.get('name');
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  // const fromYear = searchParams.get('fromYear');
  // const toYear = searchParams.get('toYear');

  const user = useAuth((state) => state.user);

  const chartEmptyState = [
    {
      month: 1,
      totalSales: 0,
      virtualOrders: 0,
      recoveries: 0,
      virtualSales: 0,
      amountRecovered: 0,
      cInteractions: 0,
      monthlyDue: 0,
      monthlyPaid: 0,
    },
  ];

  const typeOptions = (
    <optgroup label="Select Location">
      <option value="all">Select All</option>
      {restaurants.map((r, i) => (
        <option value={r.name} key={i}>
          {r.name}
        </option>
      ))}
    </optgroup>
  );

  async function getRestaurants() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/analytics`);
      const filtered = data.data.filter((location) => location.name !== '');
      setRestaurants(filtered);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function getAnaytics() {
    const params = searchParams;

    if (params.get('name') === 'all') {
      params.delete('name');
    }

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/analytics/name`, {
        params: {
          name: restaurantName === 'all' ? undefined : restaurantName,
          from: fromMonth,
          to: toMonth,
          fromYear,
          toYear,
        },
      });

      const sortedData = data?.data;
      // .map((obj) => ({
      //   ...obj,
      //   months: obj.months.sort((a, b) => a.month - b.month),
      // }));

      setAnalytics(sortedData);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user) {
      getRestaurants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && restaurants.length) {
      getAnaytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, fromMonth, fromYear, toMonth, toYear, restaurants]);

  useEffect(() => {
    if (analytics) {
      setChart(summedChart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  const handleParamsFrom = (fromValue) => {
    if (Number(fromValue) > Number(to)) {
      setSearchParams((prevParams) => {
        prevParams.set('from', fromValue);
        prevParams.set('to', fromValue);
        return prevParams;
      });
    } else {
      setSearchParams((prevParams) => {
        prevParams.set('from', fromValue);
        return prevParams;
      });
    }
  };

  const summedChart = () => {
    if (analytics) {
      return analytics?.reduce((result, location) => {
        location?.months?.forEach((monthObj) => {
          const existingMonthObj = result.find(
            (mergedMonthObj) =>
              mergedMonthObj.month === monthObj.month &&
              mergedMonthObj.year === monthObj.year
          );
          if (existingMonthObj) {
            // prettier-ignore

            existingMonthObj.totalSales += monthObj.totalSales;
            existingMonthObj.virtualSales += monthObj.virtualSales;
            existingMonthObj.cInteractions += monthObj.cInteractions;
            existingMonthObj.recoveries += monthObj.recoveries;
            existingMonthObj.amountRecovered += monthObj.amountRecovered;
            existingMonthObj.monthlyPaid += monthObj.monthlyPaid;
            existingMonthObj.monthlyDue += monthObj.monthlyDue;
            existingMonthObj.virtualOrders += monthObj.virtualOrders;
          } else {
            result.push({
              monthYear: `${renderMonthName(monthObj.month)}, ${monthObj.year}`,
              year: monthObj.year,
              month: monthObj.month,
              totalSales: monthObj.totalSales,
              virtualSales: monthObj.virtualSales,
              cInteractions: monthObj.cInteractions,
              recoveries: monthObj.recoveries,
              amountRecovered: monthObj.amountRecovered,
              monthlyPaid: monthObj.monthlyPaid,
              monthlyDue: monthObj.monthlyDue,
              virtualOrders: monthObj.virtualOrders,
            });
          }
        });

        const addPhysicalSales = result.map((obj) => ({
          ...obj,
          physicalSales: obj.totalSales - obj.virtualSales,
        }));

        return addPhysicalSales;
      }, []);
    }
  };

  const monthlyPaid = chart.reduce(
    (result, item) => result + item.monthlyPaid,
    0
  );

  const monthlyDue = chart.reduce(
    (result, item) => result + item.monthlyDue,
    0
  );

  setDocumentTitle('Concierge | vGrubs Partner');

  if (isAdmin) setDocumentTitle('Concierge | vGrubs Admin');
  if (isPartner) setDocumentTitle('Concierge | vGrubs Partner');
  if (isSales) setDocumentTitle('Concierge | vGrubs Sales');

  return (
    <>
      <AdminHeader />

      {loading && <AdminSpinner />}

      <Div $flex $between $m="0" $mt="20" $gap="50px" $gapSM="20px" $columnSM>
        <Div
          $width="70%"
          $widthSM="100%"
          $flex
          $gap="24px"
          $gapSM="16px"
          $columnSM
        >
          <Div $width="35%" $widthMD="40%" $widthSM="100%">
            <AdminSelect
              options={typeOptions}
              value={restaurantName || ''}
              onChange={({ target }) =>
                handleSetUrlSearchParams('name', target.value, setSearchParams)
              }
              placeholder="Select location"
              chevron
              shadowDesign
            />
          </Div>

          <Div
            $width="65%"
            $widthMD="60%"
            $widthSM="100%"
            $gap="16px"
            $gapSM="16px"
            $flex
            $column
          >
            <Div $flex $gap="16px" $alignCenter $columnSM $width="100%">
              <AdminMonthYearPicker
                month={fromMonth}
                year={fromYear}
                setMonth={setFromMonth}
                setYear={setFromYear}
              />

              <Div $desktop>
                <P $m="0">-</P>
              </Div>

              <AdminMonthYearPicker
                month={toMonth}
                year={toYear}
                setMonth={setToMonth}
                setYear={setToYear}
              />
            </Div>
          </Div>
        </Div>

        <Div $width="30%" $widthSM="100%">
          <InfoBox style={{ margin: 0 }}>
            <Div $width="calc(50% - 20px)">
              <P $m="0" $caps>
                SaaS Fee Paid
              </P>
              <P $m="0" $bold $color={lightTheme.green}>
                <b>{displayCentsInDollars(monthlyPaid)}</b>
              </P>
            </Div>
            <Div $width="calc(50% + 20px)">
              <P $m="0">SaaS Fee Due</P>
              <P $m="0" $bold $color={lightTheme.primary}>
                <b>{displayCentsInDollars(monthlyDue)}</b>
              </P>
            </Div>
          </InfoBox>
        </Div>
      </Div>

      <Section1 data={chart.length ? chart : chartEmptyState} />
      <Section2 data={chart.length ? chart : chartEmptyState} />

      <Div $flex $alignEnd $column>
        <H4>powered by</H4>
        <a href="https://grubcon.com/" target="_blank" rel="noreferrer">
          <GrubconLogo />
        </a>
      </Div>
    </>
  );
};
