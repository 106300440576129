import {
  AnimatedDiv,
  Disclaimer,
  Div,
  H5,
  centsToDollars,
  formatDate,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const PayoutComponent = ({ createdAt, status, amount, bankName }) => {
  const chooseColor = (status) => {
    if (status === 'pending') return lightTheme.yellow;
    if (status === 'processed') return lightTheme.green;
    if (status === 'cancelled') return lightTheme.lightGray;
    if (status === 'failed') return lightTheme.primary;
  };

  return (
    <AnimatedDiv $box $p="16px 24px" $pSM="12px 20px" $mb={'16'}>
      <Div $flex $between $center>
        <Div $flex $column $gap="8px" $gapSM="6px">
          <H5 $m="0" $lineHeight={1}>
            {formatDate(createdAt)}
          </H5>
          {bankName && (
            <Disclaimer $m="0" $lineHeight={1}>
              {bankName}
            </Disclaimer>
          )}
        </Div>
        <Div $flex $column $textAlignRight $gap="8px" $gapSM="6px">
          <H5 $color={chooseColor(status)} $m="0" $lineHeight={1}>
            {formatter.format(centsToDollars(amount))}
          </H5>
          <Disclaimer $capitalize $m="0" $lineHeight={1}>
            {status}
          </Disclaimer>
        </Div>
      </Div>
    </AnimatedDiv>
  );
};
