import {
  AdminInput,
  Button,
  DeleteIcon,
  Div,
  ErrorToast,
  H3,
  Modal,
  P,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const DeleteLocationBrandModal = ({
  isOpen,
  setIsOpen,
  setLoading,
  type,
}) => {
  const navigate = useNavigate();
  const [deleteText, setDeleteText] = useState('');

  const id = isOpen;

  async function deleteLocation() {
    if (deleteText !== 'DELETE')
      return ErrorToast('Please enter DELETE in the field below');
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/${type}/${id}/delete`);
      SuccessToast(data.message);
      setLoading(false);
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      logo={false}
      exitButton={false}
    >
      <Div $mb="16">
        <DeleteIcon />
      </Div>

      <H3 $m="10px" $center $capitalize>
        Delete {type === 'locations' ? 'Location' : 'Brand'}
      </H3>
      <P $mb="24" $center>
        If you want to delete your {type === 'locations' ? 'location' : 'brand'}{' '}
        enter DELETE in field below?
      </P>

      <AdminInput
        placeholder="Enter Here"
        $textCenter
        value={deleteText}
        onChange={({ target }) => setDeleteText(target.value)}
      />

      <Div $flex $gap="16px" $mt="32">
        <Button onClick={deleteLocation} $w="100%" $selected>
          Confirm
        </Button>
        <Button $w="100%" onClick={() => setIsOpen(false)} $gray $selected>
          Cancel
        </Button>
      </Div>
    </Modal>
  );
};
