import {
  AdminEditor,
  AdminHeader,
  AdminSpinner,
  AnimatedDiv,
  Button,
  Div,
  ErrorToast,
  FormSeparator,
  SuccessToast,
  api,
  setDocumentTitle,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useLocation, useNavigate } from 'react-router';
import { privacyPolicyInitialState } from './data';

export const PrivacyPolicyEdit = () => {
  const [content, setContent] = useState(privacyPolicyInitialState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // const match = useMatch(ADMIN_BLOGS_EDIT);
  // const paramsId = match.params.id;

  const policyType =
    location.pathname === '/privacy-policy'
      ? 'privacyPolicy'
      : 'termsAndConditions';

  const policyTypeName =
    location.pathname === '/privacy-policy'
      ? 'Privacy Policy'
      : 'Terms and Conditions';

  async function getContent() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/policies/${policyType}`);
      setContent({ content: data.data.content });
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, setState, name) => {
    setState((prev) => {
      const copy = { ...prev };
      copy[name] = value;
      return copy;
    });
  };

  async function handleAddEdit() {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/policies/${policyType}`, content);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!content.content) return ErrorToast(`Please enter ${policyTypeName}`);
    handleAddEdit();
  }

  if (loading) return <AdminSpinner />;

  setDocumentTitle(`${policyTypeName} | vGrubs Admin`);

  return (
    <>
      <AdminHeader />
      <AnimatedDiv>
        <form onSubmit={handleSubmit}>
          <Div $mt="32">
            <AdminEditor
              onChange={(data) => handleChange(data, setContent, 'content')}
              value={content.content}
            />
          </Div>
          <FormSeparator />
          <Div $flex $between>
            <Button type="button" onClick={() => navigate(-1)} $gray>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Div>
        </form>
      </AnimatedDiv>
    </>
  );
};
