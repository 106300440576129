import { Div, P } from '@vgrubs/components';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';

export const PhoneInputStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.darkGray}80;
  background: none;
  border-radius: 200px;
  padding: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
  width: 100%;

  input {
    font-size: 14px;
    margin-left: 24px;
    width: 100%;
    border: none;
    background: none;
    :focus {
      outline: none;
    }
  }

  position: relative;

  .PhoneInput {
    width: 100% !important;
  }

  .PhoneInputCountry {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);

    select {
      height: 35px;
    }

    .PhoneInputCountryIcon {
      height: 27px;
      width: 27px;

      @media (max-width: 800px) {
        height: 25px;
        width: 25px;
      }

      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
      }

      border: none;
      box-shadow: none;
      background-color: none;
    }
    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  @media (max-width: 800px) {
    height: 40px;
    font-size: 14px;

    .PhoneInputCountry {
      left: 8px;

      .PhoneInputCountryIcon {
        height: 25px;
        width: 25px;
      }
    }
  }

  :focus-visible {
    outline: ${({ theme }) => theme.primary} 1px;
  }
`;

export const AdminPhoneInput = (props) => {
  return (
    <Div
      $relative
      $width="100%"
      $maxWidth={props?.maxWidth}
      $maxWidthSM={props?.maxWidthSM}
    >
      {props.label && (
        <P $mb="8" $ml="24" $mr="24">
          <b>{props.label}</b>
        </P>
      )}

      <PhoneInputStyled>
        <PhoneInput
          placeholder="Enter here..."
          defaultCountry="US"
          addInternationalOption={false}
          international
          focusInputOnCountrySelection={true}
          {...props}
        />
      </PhoneInputStyled>
    </Div>
  );
};
