import styled from 'styled-components';

export const ButtonStyled = styled.button`
  position: relative;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.white};
  border-radius: 100px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.primary};
  border: none;
  width: ${({ $w }) => ($w ? $w : '180px')};
  overflow: hidden;
  transition: all 100ms ease-in;
  font-weight: bold;
  z-index: 1;
  cursor: pointer;
  font-size: 15px;
  padding: 0 12px;
  ${({ $p }) => $p && 'padding: 0 30px;'};

  @media (min-width: 3500px) {
    padding: 0 60px;
    font-size: 26px;
    width: ${({ $w }) => ($w ? $w : '300px')};
    height: 70px;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.primary};
  }

  @media (max-width: 800px) {
    width: ${({ $wSM }) => ($wSM ? $wSM : '150px')};
  }

  @media (max-width: 380px) {
    height: 40px;
    font-size: 12px;
    width: ${({ $wSM }) => ($wSM ? $wSM : '120px')};
  }

  ::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    z-index: -1;
    left: 0;
    right: 0;
    transition: all 0.2s ease-out;
    transform: translateX(-110%);
    background: ${({ theme }) => theme.primary};
    border-radius: 100px;
  }

  :hover {
    color: ${({ theme }) => theme.white};
  }

  :hover::before {
    transform: translateX(0);
  }

  :active {
    transform: translateY(3px);
  }

  ${({ $negative }) =>
    $negative &&
    `
    border: 1px solid #f2f2f2;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  `}

  ${({ $green, theme }) =>
    $green &&
    `
    border: none;
    ::before {
      background: ${theme.green};
      }
    box-shadow: none;
  `};

  ${({ $gray, theme }) =>
    $gray &&
    `
    color: ${theme.darkGray};
    box-shadow: 0 0 0 1px ${theme.darkGray};

    ::before {  
      background: ${theme.darkGray};
      color: ${theme.white};
    };

  `};

  ${({ $yellow, theme }) =>
    $yellow &&
    `
    color: ${theme.yellow};
    box-shadow: 0 0 0 1px ${theme.yellow};

    ::before {
      background: ${theme.yellow};
      color: ${theme.white};
    };

  `};

  ${({ $darkBlue, theme }) =>
    $darkBlue &&
    `
    color: ${theme.darkBlue};
    box-shadow: 0 0 0 1px ${theme.darkBlue};

    ::before {
      background: ${theme.darkBlue};
      color: ${theme.white};
    };

  `};

  ${({ $selected, theme }) =>
    $selected &&
    `
     color: ${theme.white};
     ::before {
      transform: translateX(0);
      }
  `}

  ${({ $whiteBlue, theme, $selected }) =>
    $whiteBlue &&
    `
    background: ${theme.blue};
    color: ${theme.white};
    box-shadow: 0 0 0 1px ${theme.white};

    ::before {
      background: ${theme.white};
      color: ${theme.blue};
    };

    :hover {
      color: ${theme.blue};
    }

    ${$selected && ` color: ${theme.blue};`}

    
   

  `};

  ${({ $whiteDarkBlue, theme }) =>
    $whiteDarkBlue &&
    `
    color: ${theme.darkBlue};
    box-shadow: 0 0 0 1px ${theme.darkBlue};

    ::before {
      background: ${theme.white};
      color: ${theme.darkBlue};
    };


  :hover {
    color: ${theme.darkBlue};
  }

  `};

  ${({ $medium, $w }) =>
    $medium &&
    `
    font-weight: normal;
    height: 40px;
    width: ${$w ? $w : 'auto'};
    font-size: 15px;
  `}

  ${({ $small, $w }) =>
    $small &&
    `
    height: 35px;
    width: ${$w ? $w : '140px'};
    font-size: 14px;
  `}

  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt}px;
  margin-bottom: ${(props) => props.$mb}px;
  margin-left: ${(props) => props.$ml}px;
  margin-right: ${(props) => props.$mr}px;
  max-width: ${(props) => props.$maxWidth};

  @media (max-width: 800px) {
    width: ${({ $wSM }) => ($wSM ? $wSM : '100%')};
    ${({ $pSM }) => $pSM && 'padding: 0 15px;'};

    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    max-width: ${(props) => props.$maxWidthSM};

    ${({ $medium, $w }) =>
      $medium &&
      `
    font-weight: normal;
    height: 40px;
    width: ${$w ? $w : 'auto'};
    font-size: 14px;
  `}

    ${({ $small, $w }) =>
      $small &&
      `
    height: 35px;
    width: ${$w ? $w : '140px'};
    font-size: 14px;
    flex: none;
  `}
  }
`;
