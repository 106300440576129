import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Div, H1, H2, H3, H4, H5, P } from '../../styles';

export const AnimationStyled = styled(motion.div)``;

export const Animation = ({ children }) => {
  return (
    <AnimationStyled
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2,
        type: 'ease',
      }}
      // layout
    >
      {children}
    </AnimationStyled>
  );
};

export const AdminAnimation = ({ children }) => {
  return (
    <AnimationStyled
      style={{ width: '100%' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2,
        type: 'ease',
      }}
      // layout
    >
      {children}
    </AnimationStyled>
  );
};

export const SliderAnimation = ({ children, ...props }) => {
  return (
    <AnimationStyled
      {...props}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{
        duration: 0.2,
        type: 'ease',
      }}
    >
      {children}
    </AnimationStyled>
  );
};

export const Visibility = () => ({
  in: {
    style: {
      visibility: (e) => `${e > 0 ? 'visible' : 'hidden'}`,
    },
  },
  out: {
    style: {
      // visibility: (e) => `${e  1 ? 'visible' : 'hidden'}`,
    },
  },
});

const textVariants = {
  offscreen: {
    y: 100,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      type: 'ease',
    },
  },
  exitScreen: {
    opacity: 0,
    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
};

const textVariantsNoY = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 0.6,
      type: 'ease',
    },
  },
  exitScreen: {
    opacity: 0,
    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
};

const viewport = {
  once: true,
  amount: 'some',
};

const initial = 'offscreen';
const whileInView = 'onscreen';
const exitScreen = 'exitScreen';

export const AnimatedP = ({ children, ...props }) => {
  return (
    <P
      {...props}
      initial={initial}
      whileInView={whileInView}
      exit={exitScreen}
      viewport={viewport}
      variants={textVariants}
    >
      {children}
    </P>
  );
};

export const AnimatedH1 = ({ children, ...props }) => {
  return (
    <H1
      {...props}
      initial={initial}
      whileInView={whileInView}
      exit={exitScreen}
      viewport={viewport}
      variants={textVariants}
    >
      {children}
    </H1>
  );
};

export const AnimatedH2 = ({ children, ...props }) => {
  return (
    <H2
      {...props}
      initial={initial}
      whileInView={whileInView}
      exit={exitScreen}
      viewport={viewport}
      variants={textVariants}
    >
      {children}
    </H2>
  );
};

export const AnimatedH3 = ({ children, ...props }) => {
  return (
    <H3
      {...props}
      initial={initial}
      whileInView={whileInView}
      exit={exitScreen}
      viewport={viewport}
      variants={textVariants}
    >
      {children}
    </H3>
  );
};

export const AnimatedH4 = ({ children, ...props }) => {
  return (
    <H4
      {...props}
      initial={initial}
      whileInView={whileInView}
      exit={exitScreen}
      viewport={viewport}
      variants={textVariants}
    >
      {children}
    </H4>
  );
};

export const AnimatedH5 = ({ children, ...props }) => {
  return (
    <H5
      {...props}
      initial={initial}
      whileInView={whileInView}
      exit={exitScreen}
      viewport={viewport}
      variants={textVariants}
    >
      {children}
    </H5>
  );
};

export const AnimatedDiv = ({ children, delay, noY, ...props }) => {
  return (
    <Div
      {...props}
      initial={initial}
      whileInView={whileInView}
      exit={exitScreen}
      viewport={delay ? { once: true, amount: delay } : viewport}
      variants={noY ? textVariantsNoY : textVariants}
    >
      {children}
    </Div>
  );
};

export const AnimatedTabletLeft = ({ children, ...props }) => {
  const fromLeft = {
    offscreen: {
      opacity: 0,
      transform: 'rotateY(60deg) translateX(-300px)',
    },
    onscreen: {
      opacity: 1,
      transform: 'rotateY(0deg) translateX(0px)',
      transition: {
        duration: 0.8,
        type: 'ease',
      },
    },
  };

  return (
    <Div
      {...props}
      initial={initial}
      whileInView={whileInView}
      viewport={viewport}
      variants={fromLeft}
    >
      {children}
    </Div>
  );
};

export const AnimatedTabletRight = ({ children, ...props }) => {
  const fromLeft = {
    offscreen: {
      opacity: 0,
      transform: 'rotateY(-60deg) translateX(300px)',
    },
    onscreen: {
      opacity: 1,
      transform: 'rotateY(0deg) translateX(0px)',
      transition: {
        duration: 0.8,
        type: 'ease',
      },
    },
  };

  return (
    <Div
      {...props}
      initial={initial}
      whileInView={whileInView}
      viewport={viewport}
      variants={fromLeft}
    >
      {children}
    </Div>
  );
};
