import {
  AdminInput,
  Button,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const BrandNoteModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setData,
  data,
  pathPrefix,
}) => {
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (data?.notes) {
      setNotes(data.notes);
    }
  }, []);

  async function handleNote() {
    try {
      setLoading(true);
      const res = await api.put(`/v1/${pathPrefix}/${data.id}/notes`, {
        notes,
      });

      setData((prev) => {
        return { ...prev, notes };
      });

      // setData(res.data.data);
      SuccessToast(res.data.message);
      setModalIsOpen(false);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Notes"
    >
      <AdminInput
        type="textarea"
        placeholder="Enter your notes here.."
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />

      <Button $selected $w="100%" $mt="40" onClick={() => handleNote()}>
        Save
      </Button>
    </Modal>
  );
};
