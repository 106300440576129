import {
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  H5,
  Img,
  Modal,
  P,
  Radio,
  api,
  displayCentsInDollars,
  lightTheme,
  renderVDriveImage,
  useOrders,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useState } from 'react';

export const VDriveAssignDriver = ({
  order,
  setOrder,
  modalIsOpen,
  setModalIsOpen,
  noDriverModal,
  setNoDriverModal,
  setLoading,
}) => {
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const { assignVDriveProviderOnList } = useOrders((state) => state);

  async function handleAssignDriver() {
    if (selectedDriver === null) return ErrorToast('Select driver first');

    const body = {
      price: selectedDriver.price,
      provider: selectedDriver.provider,
    };

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/orders/${order.id}/vdrive`, body);

      setOrder(data.data);
      assignVDriveProviderOnList(order.id, selectedDriver.provider);
      setLoading(false);
      setModalIsOpen(false);
      setSuccessModalIsOpen(true);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const handleSelectDriver = (newItem) => {
    setSelectedDriver(newItem);
  };

  function isDriverSelected(item) {
    return selectedDriver?.id === item.id;
  }

  const isVGrubsBrand =
    !order.brand.isLocation && order.brand.type === 'vGrubs';

  // const amount = () => {
  //   if (isVGrubsBrand) {
  //     return selectedDriver.price;
  //   }
  //   return selectedDriver.price + order.charges.tips;
  // };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        vDriveLogo={true}
        zIndex={110}
        footer={
          <>
            <Button
              $m="32px 0 12px 0"
              $w="100%"
              $selected
              onClick={handleAssignDriver}
            >
              Assign Driver{' '}
              {/* {selectedDriver && `(${displayCentsInDollars(amount())})`} */}
            </Button>
            <Button
              $w="100%"
              $selected
              $gray
              onClick={() => setModalIsOpen(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <H3 $mb="24px">Assign Driver</H3>
        {modalIsOpen !== false &&
          modalIsOpen.map((l, i) => (
            <Div
              key={i}
              $box
              $p="14px 24px"
              $mb="8"
              $flex
              $alignCenter
              $between
              onClick={() => handleSelectDriver(l)}
              $gap="16px"
              $pointer
            >
              <Div $flex $alignCenter $gap="20px">
                <Img src={renderVDriveImage(l.provider)} $width="60px" />
                <div>
                  <H5
                    $left
                    $bold
                    $mb="0"
                    $color={isDriverSelected(l) && lightTheme.primary}
                  >
                    Drop Off Time: {l.deliveryMinutes} min
                  </H5>
                  <P $left $mb="0">
                    Delivery Fee: {displayCentsInDollars(l.price + l.tip)}
                  </P>
                  <P $left $mb="0">
                    Distance: {l.distance} miles
                  </P>
                </div>
              </Div>

              <Radio checked={isDriverSelected(l)} />
            </Div>
          ))}

        {!isVGrubsBrand && (
          <Disclaimer $mt="24">
            Client will be charged for the total amount owed which is both the
            delivery fee and tip for the delivery driver.
          </Disclaimer>
        )}

        {/* {dataOrders.length < 1 && (
          <Div $flex $center $column>
            <Img src={menuItems} alt="no menu items" $width="300px" />
            <H3 $color={lightTheme.primary} $mb="20">
              No Orders!
            </H3>
            <P $bold>You currently don’t have {status} orders.</P>
          </Div>
        )} */}
      </Modal>

      <Modal
        isOpen={successModalIsOpen}
        setIsOpen={setSuccessModalIsOpen}
        logo={false}
        vDriveLogo={true}
      >
        <H3 $mb="12">Driver Assigned</H3>

        <P $mb="0">
          {order.timestamps.scheduledFor !== null
            ? `This driver will be assigned for scheduled order tomorrow 
            ${DateTime.fromISO(order.timestamps.scheduledFor)
              .setZone(order.timeZone)
              .toFormat('hh:mm a')}`
            : 'Driver assigned successfully'}
        </P>
        <Button
          $m="32px 0 12px 0"
          $w="100%"
          $selected
          onClick={() => setSuccessModalIsOpen(false)}
        >
          Okay
        </Button>
      </Modal>

      <Modal
        isOpen={noDriverModal}
        setIsOpen={setNoDriverModal}
        logo={false}
        vDriveLogo={true}
        zIndex={110}
      >
        <H3 $mb="12">No Driver</H3>

        <P $mb="0">There are no available drivers at the moment.</P>
        <Button
          $m="32px 0 12px 0"
          $w="100%"
          $selected
          onClick={() => setNoDriverModal(false)}
        >
          Okay
        </Button>
      </Modal>
    </>
  );
};
