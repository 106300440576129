import {
  AdminInput,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const BrandFooterMessageModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setData,
  data,
  id,
}) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (data) {
      setMessage(data.message);
    }
  }, [data]);

  async function handleBrandFooterMessage() {
    try {
      setLoading(true);
      const res = await api.put(`/v1/brands/${id}/footer-message`, {
        message,
      });

      setData((prev) => {
        return { message };
      });

      // setData(res.data.data);
      SuccessToast(res.data.message);
      setModalIsOpen(false);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Printing Settings"
    >
      <AdminInput
        label="Footer Message"
        type="textarea"
        placeholder="Enter your message here.."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <Button $selected $w="100%" $mt="40" onClick={handleBrandFooterMessage}>
        Update
      </Button>
    </Modal>
  );
};
