import {
  ADMIN_VERIFICATIONS_AGENT,
  AdminDollarInput,
  AdminRadioInput,
  Button,
  Div,
  ErrorToast,
  Modal,
  P,
  Separator,
} from '@vgrubs/components';
import { useState } from 'react';
import { useMatch } from 'react-router';

const earningPlansOptions = {
  YEAR_PLAN: 1,
  LIFETIME_PLAN: 2,
  FLAT_AMOUNT_PLAN: 3,
};

function findObjectWithHighestYear(earningPlans) {
  if (earningPlans.length === 0) {
    return null; // Return null if the array is empty
  }

  let maxYearObject = earningPlans[0]; // Initialize with the first object
  for (let i = 1; i < earningPlans.length; i++) {
    if (earningPlans[i].year > maxYearObject.year) {
      maxYearObject = earningPlans[i]; // Update maxYearObject if a higher year is found
    }
  }
  return maxYearObject;
}

export const AddEarningPlan = ({
  modalIsOpen,
  setModalIsOpen,
  earningPlans,
  amountPerLocation,
  setLoading,
  setEarningPlans,
  setAmountPerLocation,
}) => {
  const id = useMatch(ADMIN_VERIFICATIONS_AGENT).params.id;

  const [selectedPlan, setSelectedPlan] = useState(
    earningPlansOptions.YEAR_PLAN
  );
  const [amount, setAmount] = useState(0);

  const addInState = () => {
    if (!amount) {
      ErrorToast('The amount must be greater than zero');
      return;
    }
    if (selectedPlan === earningPlansOptions.FLAT_AMOUNT_PLAN) {
      setAmountPerLocation(amount);
    } else if (selectedPlan === earningPlansOptions.LIFETIME_PLAN) {
      setEarningPlans((prev) => [
        ...prev,
        {
          year: null,
          amount,
        },
      ]);
    } else if (selectedPlan === earningPlansOptions.YEAR_PLAN) {
      const highestYearInArray = findObjectWithHighestYear(earningPlans).year;

      setEarningPlans((prev) => [
        ...prev,
        {
          year: highestYearInArray + 1,
          amount,
        },
      ]);
    }
    setModalIsOpen(false);
    setAmount(0);
    setSelectedPlan(earningPlansOptions.YEAR_PLAN);
  };

  function hasLifeTimePlan(earningPlans) {
    for (let i = 0; i < earningPlans.length; i++) {
      if (earningPlans[i].year === null) {
        return true; // Found an object with null year
      }
    }
    return false; // No object with null year found
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Add Earning Plan"
    >
      <Separator />
      <AdminRadioInput
        label="Add Year Plan"
        checked={selectedPlan === earningPlansOptions.YEAR_PLAN}
        onClick={() => setSelectedPlan(earningPlansOptions.YEAR_PLAN)}
      />
      <Separator />
      {!hasLifeTimePlan && (
        <>
          <AdminRadioInput
            label="Add Lifetime Plan"
            checked={selectedPlan === earningPlansOptions.LIFETIME_PLAN}
            onClick={() => setSelectedPlan(earningPlansOptions.LIFETIME_PLAN)}
          />
          <Separator />
        </>
      )}
      {amountPerLocation === 0 && (
        <>
          <AdminRadioInput
            label="Add Flat Amount Plan"
            checked={selectedPlan === earningPlansOptions.FLAT_AMOUNT_PLAN}
            onClick={() =>
              setSelectedPlan(earningPlansOptions.FLAT_AMOUNT_PLAN)
            }
          />
          <Separator />
        </>
      )}
      <Div $flex $alignCenter $between $m="16px 0">
        <P $m="0">Add Amount</P>
        <Div $maxWidth="120px">
          <AdminDollarInput
            prefix="$"
            value={amount / 100}
            onChange={(_, value) => setAmount(value * 100)}
          />
        </Div>
      </Div>

      <Button $selected $w="100%" $mt="20" onClick={addInState}>
        Confirm
      </Button>
    </Modal>
  );
};
