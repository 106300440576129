import { Button, H3, Modal, P, lightTheme } from '@vgrubs/components';

export const DeleteScheduleModal = ({
  deleteModal,
  setDeleteModal,
  handleDeleteSchedule,
}) => {
  return (
    <Modal isOpen={deleteModal} setIsOpen={setDeleteModal} logo={false}>
      <H3 $mb="24" $color={lightTheme.primary}>
        Are you sure
      </H3>
      <P $mb="40">Are you sure you want to delete this schedule?</P>

      <Button $selected $w="100%" $mb="8" onClick={handleDeleteSchedule}>
        Confirm
      </Button>

      <Button $selected $w="100%" $gray onClick={() => setDeleteModal(false)}>
        Cancel
      </Button>
    </Modal>
  );
};
