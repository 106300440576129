import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  background: '#f9f4ee',
  primary: '#E61E29',
  darkGray: '#4C4C4C',
  lightGray: '#A9A9A9',
  gray: '#f2f2f2',
  white: '#ffffff',
  green: '#0CAD4A',
  yellow: '#F6AE00',
  darkBlue: '#172A55',
  transparentBlue: '#E8EAEE',
  transparentGreen: '#E9F8EB',
  transparentRed: '#FFEDED',
  blue: '#0068EE',
  sizes: {
    s1: '4',
    s2: '8',
    s3: '12',
    s4: '16',
    s5: '20',
    s6: '24',
    s7: '28',
    s8: '32',
    s9: '36',
    s10: '40',
  },
  shadows: {
    shadow1: '0px 15px 30px rgba(0, 0, 0, 0.1)',
  },
  borders: {
    border1: '1px solid #e3e3e3',
  },
  transitions: {
    ease300ms: 'all 0.3s ease',
  },
  transparency: {
    t10: '1A',
    t20: '33',
    t30: '4D',
    t40: '66',
    t50: '80',
    t60: '99',
    t70: 'B3',
    t80: 'CC',
    t90: 'E6',
    t100: 'FF',
  },
};

export const GlobalStyles = createGlobalStyle`
  :root {
    --chat-widget-primary-color: ${({ theme }) => theme.primary};
    --chat-widget-active-color: ${({ theme }) => theme.primary};
    --chat-widget-bubble-color: ${({ theme }) => theme.primary};
  }

  body {
    margin: 0;
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${(theme) => theme.white} !important;
    background-color: #f9f4ee;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::-moz-selection {
    color: #fff;
    background: ${({ theme }) => theme.primary};
  }

  ::selection {
    color: ${(theme) => theme.white};
    background: ${({ theme }) => theme.primary}b2;
  }

  a:active {
    color: ${({ theme }) => theme.darkGray};
    text-decoration: none;
  }

  a:visited {
    color:  ${({ theme }) => theme.darkGray};
    text-decoration: none;
  }

  a:link {
    color:  ${({ theme }) => theme.darkGray};
    text-decoration: none;
  }

  .redSvgHover path {
    transition: all 0.2s ease-out;
  }

  .redSvgHover:hover path {
    fill: ${(theme) => theme.primary} !important;
  }

  .textHover {
    transition: color 0.2s ease !important;
  }

  .textHover:hover {
    color:${(theme) => theme.primary} !important;
  }

  .screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clipPath: inset(50%);
  clipPath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;
}

`;
