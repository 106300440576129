import {
  AdminRadioInput,
  Button,
  Div,
  DropdownMenuComponent,
  H3,
  HeaderIconWrapper,
  Overlay,
  Separator,
  SettingsIcon,
  overlayAnimationVariant,
  useDisableBodyScroll,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

export const AdminRolePicker = ({ onChange, value }) => {
  const [dropdown, setDropdown] = useState(false);
  const [role, setRole] = useState(value);

  useDisableBodyScroll(dropdown);

  useEffect(() => {
    setRole(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  const roleFilters = [
    { name: 'All Users', value: null },
    { name: 'Admin', value: 'admin' },
    { name: 'Support', value: 'support' },
    { name: 'Partner', value: 'partner' },
    { name: 'Manager', value: 'manager' },
    { name: 'Sales Agent', value: 'salesAgent' },
    { name: 'Sales Manager', value: 'salesManager' },
    { name: 'Staff', value: 'staff' },
    { name: 'Marketing', value: 'marketing' },
  ];

  return (
    <AnimatePresence>
      <Div $relative>
        <HeaderIconWrapper
          $opened={dropdown}
          onClick={() => setDropdown((prev) => !prev)}
        >
          <SettingsIcon />
        </HeaderIconWrapper>
        <DropdownMenuComponent isOpen={dropdown} setIsOpen={setDropdown} toLeft>
          <Div $width="300px" $widthSM="180px">
            <Div $flex $between $alignCenter>
              <H3 $m="4px 0 16px 0">
                <span>Filter Role</span>
              </H3>
            </Div>
            <Separator />
            {roleFilters.map(({ name, value }, i) => (
              <div key={i}>
                <AdminRadioInput
                  label={name}
                  checked={value === role}
                  onClick={() => setRole(value)}
                />
                <Separator />
              </div>
            ))}
          </Div>
          <Button
            $selected
            $w="100%"
            $mt="24"
            $wSM="100%"
            onClick={() => {
              onChange(role);
              setDropdown(false);
            }}
          >
            Confirm
          </Button>
        </DropdownMenuComponent>
      </Div>

      {dropdown && (
        <Overlay
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};
