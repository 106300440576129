import { DateTime } from 'luxon';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useOrders = create(
  devtools((set) => ({
    dataOrders: [],
    dataHistory: [],
    detailsModal: null,
    deleteManualOrderModal: null,
    printOrderModal: null,
    loading: false,
    orderToEdit: null,
    setOrderToEdit: (value) => set(() => ({ orderToEdit: value })),
    setDataOrders: (value) => set(() => ({ dataOrders: value })),
    setDataHistory: (value) => set(() => ({ dataHistory: value })),
    setLoading: (value) => set(() => ({ loading: value })),
    clearDataOrders: () => set(() => ({ dataOrders: [] })),
    setDetailsModal: (value) => set(() => ({ detailsModal: value })),
    setDeleteManualOrderModal: (value) =>
      set(() => ({ deleteManualOrderModal: value })),
    setPrintOrderModal: (value) => set(() => ({ printOrderModal: value })),

    removeHistoryOrderById: (order) =>
      set((state) => {
        console.log(state.dataHistory);
        const updatedDataHistory = state.dataHistory.map((day) => {
          const orderDate = DateTime.fromISO(order.date)
            .setZone(order.timeZone)
            .toISODate();

          if (day.salesDay === orderDate) {
            return {
              ...day,
              count: day.count - 1,
              total:
                day.total -
                day.orders.find((o) => o.id === order.id).totalPrice,
              orders: day.orders.filter((o) => o.id !== order.id),
            };
          } else return day;
        });

        return { ...state, dataHistory: updatedDataHistory };
      }),

    removeOrderById: (id) =>
      set((state) => {
        const updatedOrders = state.dataOrders.filter(
          (order) => order.id !== id
        );

        return { ...state, dataOrders: updatedOrders };
      }),

    assignVDriveProviderOnList: (id, provider) =>
      set((state) => {
        const updatedOrders = state.dataOrders.map((order) => {
          if (order.id === id) {
            return { ...order, vDriveProvider: provider };
          }
          return order;
        });

        return {
          ...state,
          dataOrders: updatedOrders,
        };
      }),

    changeVDriveOnList: (id, vDrive) =>
      set((state) => {
        const updatedOrders = state.dataOrders.map((order) => {
          if (order.id === id) {
            return { ...order, vDrive };
          }
          return order;
        });

        return {
          ...state,
          dataOrders: updatedOrders,
        };
      }),
  }))
);
