import { AnimatedDiv, Chevron, H5, Icon } from '@vgrubs/components';
import { Link } from 'react-router-dom';
import { RestaurantStyled } from '../VerificationsStyled';

export const AgentComponent = ({ agent }) => {
  return (
    <Link to={`/verifications/agent/${agent.id}`}>
      <AnimatedDiv>
        <RestaurantStyled>
          <H5 $mb="0">{agent.firstName + ' ' + agent.lastName}</H5>
          <Icon style={{ rotate: '90deg' }}>
            <Chevron />
          </Icon>
        </RestaurantStyled>
      </AnimatedDiv>
    </Link>
  );
};
