import {
  Disclaimer,
  Div,
  H3,
  H5,
  P,
  displayCentsInDollars,
  lightTheme,
  renderMonthName,
} from '@vgrubs/components';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FormSeparator } from '../../../adminComponents';
import { ChartTooltip, IconInt, IconRec, InfoBox } from '../concierge.styled';

export const Section2 = ({ data }) => {
  const cInteractions = data.reduce(
    (result, item) => result + item.cInteractions,
    0
  );

  const recoveries = data.reduce((result, item) => result + item.recoveries, 0);

  const amountRecovered = data.reduce(
    (result, item) => result + item.amountRecovered,
    0
  );

  const totalSales = data.reduce((result, item) => result + item.totalSales, 0);

  return (
    <>
      <Div $m="20px 0">
        <H5 $m="0">Interactions & Recoveries</H5>
      </Div>

      <Div $flex $gap="50px" $gapSM="20px" $reverseMD>
        <Div $width="70%" $widthMD="100%" $relative>
          {totalSales === 0 && (
            <InfoBox $absoluteCenter>
              <P $m="0">No Data Available</P>
            </InfoBox>
          )}
          <ResponsiveContainer width="100%" aspect={9 / 4} maxHeight={330}>
            <AreaChart data={data} fontSize={14}>
              <defs>
                <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={lightTheme.primary}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={lightTheme.primary}
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="yellow" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={lightTheme.yellow}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={lightTheme.yellow}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>

              <CartesianGrid strokeDasharray="3 3" />
              {totalSales !== 0 && (
                <>
                  <XAxis dataKey="monthYear" />
                  <YAxis />
                  <Tooltip content={<CustomChartTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="cInteractions"
                    stroke={lightTheme.primary}
                    fillOpacity={1}
                    fill="url(#red)"
                  />
                  <Area
                    type="monotone"
                    dataKey="recoveries"
                    stroke={lightTheme.yellow}
                    fillOpacity={1}
                    fill="url(#yellow)"
                  />
                </>
              )}
            </AreaChart>
          </ResponsiveContainer>
        </Div>
        <Div $width="30%" $widthMD="100%">
          <InfoBox>
            <Div>
              <IconInt />
            </Div>
            <Div>
              <P $m="0">Total interactions with Concierge Staff:</P>
              <H3 $m="0">{cInteractions}</H3>
            </Div>
          </InfoBox>
          <InfoBox>
            <Div>
              <IconRec />
            </Div>
            <Div>
              <P $m="0">Total Recoveries:</P>
              <H3 $m="0" $mb="12">
                {recoveries}
              </H3>
              <P $m="0">Total Value:</P>
              <H3 $m="0">{displayCentsInDollars(amountRecovered)}</H3>
            </Div>
          </InfoBox>
        </Div>
      </Div>
      <FormSeparator m="20px 0" />
    </>
  );
};

const CustomChartTooltip = (props) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <ChartTooltip>
        <div>
          <P $m="0">{props.label}</P>
          <Disclaimer
            $color={lightTheme.primary}
            $uppercase
            $m="0"
            $bold
          >{`INTERACTIONS : ${props.payload[0].value}`}</Disclaimer>
          <Disclaimer
            $color={lightTheme.yellow}
            $uppercase
            $m="0"
            $bold
          >{`${props.payload[1].name} : ${props.payload[1].value}`}</Disclaimer>
        </div>
      </ChartTooltip>
    );
  }

  return null;
};
