import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

const variants = {
  initial: {
    transform: 'scale(0)',
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: 0.8,
    },
  },
  animate: {
    transform: 'scale(1)',
  },

  exit: {
    transform: 'scale(0)',
    transition: {
      type: 'linear',
      duration: 0.3,
    },
  },
};

export const DropdownStyled = styled(motion.div)`
  /* transform-origin: top left; */
  /* width: 350px; */
  padding: 12px 12px 12px 12px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background: ${({ theme }) => theme.white};
  border: 1px solid #f0f0f0;
  position: absolute;
  ${({ $toLeft }) => ($toLeft ? 'right: 0px;' : 'left: 0px;')}
  transform-origin: ${({ $toLeft }) => ($toLeft ? 'top right' : 'top left')};
  top: 60px;
  overflow: hidden;
  z-index: 10;
  -webkit-overflow-scrolling: touch;

  ${({ $toTop }) =>
    $toTop &&
    `
    top: auto;
    bottom: -60px;


  `}

  @media (min-width: 3500px) {
    width: 700px;
    top: 120px;
  }

  @media (max-width: 1450px) {
    /* padding: 15px 25px; */
  }

  @media (max-width: 800px) {
    /* width: calc(100vw - 60px); */
  }

  max-height: calc(100vh - 100px);
  ${({ $maxHeight }) => `max-height: ${$maxHeight}`};
  ${({ $padding }) => `padding: ${$padding}`};
  ${({ $fullWidth }) => $fullWidth && `right: 0;`};

  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const DropdownMenuComponent = ({
  children,
  isOpen,
  maxHeight,
  padding,
  fullWidth,
  toLeft,
  toTop,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DropdownStyled
          key="DropdownMenu"
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            type: 'spring',
            bounce: 0.4,
            duration: 0.8,
          }}
          $maxHeight={maxHeight}
          $padding={padding}
          $fullWidth={fullWidth}
          $toLeft={toLeft}
          $toTop={toTop}
        >
          {children}
        </DropdownStyled>
      )}
    </AnimatePresence>
  );
};
