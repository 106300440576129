import {
  Chevron,
  Disclaimer,
  Div,
  GreenCheckMark,
  H5,
  Icon,
  Img,
  P,
  VerticalSeparator,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import { ProviderStatus } from './LocationStyled';
import { ProviderConnectModal } from './ProviderConnectModal';
import { ProviderDetailsModal } from './ProviderDetails';
import {
  providerLogo,
  providerName,
  providerPhone,
  statusColor,
  statusName,
} from './helpers';

export const ProviderComponent = ({
  provider,
  id,
  status,
  connectionStatus,
  pathPrefix,
  setConnectionStatuses,
  setStatuses,
  credentials,
}) => {
  const [providerDetailsModal, setProviderDetailsModal] = useState(false);
  const [providerConnectModal, setProviderConnectModal] = useState(false);

  return (
    <>
      <Div
        $p="16px"
        $pSM="12px"
        $mb="16"
        $box
        $flex
        $alignCenter
        $between
        onClick={() => {
          if (connectionStatus?.isConnected) {
            setProviderDetailsModal(true);
          } else {
            setProviderConnectModal(true);
          }
        }}
        $pointer
      >
        <Div $flex $alignCenter>
          <Img
            $width="65px"
            $widthSM="50px"
            src={providerLogo(provider)}
            alt="provider"
          />
          <VerticalSeparator $m="0 16px" $mSM="0 12px" $h="50px" />
          <Div>
            <H5 $mb="4">{providerName(provider)}</H5>
            <P $mb="0">{providerPhone(provider)}</P>
          </Div>
        </Div>
        <Div $flex $alignCenter $gap="24px" $gapSM="12px">
          {connectionStatus?.isConnected && status?.status ? (
            <ProviderStatus $color={statusColor(status.status)}>
              <Disclaimer $m="0" $color={lightTheme.white}>
                {statusName(status.status)}
              </Disclaimer>
            </ProviderStatus>
          ) : (
            <Icon style={{ rotate: '90deg' }}>
              <Chevron />
            </Icon>
          )}

          {connectionStatus?.isConnected && <GreenCheckMark />}
        </Div>
      </Div>
      <ProviderDetailsModal
        modalIsOpen={providerDetailsModal}
        setModalIsOpen={setProviderDetailsModal}
        provider={provider}
        id={id}
        pathPrefix={pathPrefix}
        setConnectionStatuses={setConnectionStatuses}
        setStatuses={setStatuses}
        status={status}
        credentials={credentials}
      />
      <ProviderConnectModal
        modalIsOpen={providerConnectModal}
        setModalIsOpen={setProviderConnectModal}
        provider={provider}
        id={id}
        pathPrefix={pathPrefix}
        setConnectionStatuses={setConnectionStatuses}
        setStatuses={setStatuses}
      />
    </>
  );
};
