import {
  Div,
  InfoIcon,
  P,
  SvgInlineWrapper,
  lightTheme,
} from '@vgrubs/components';
import CurrencyInput from './currencyInput';
import styled from 'styled-components';
import CurrencyInput2 from './currencyInput2';
import { Tooltip } from 'react-tooltip';

const AdminInputStyled = styled(CurrencyInput2)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.darkGray}80;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 45px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: none;

  ${({ shadowDesign, theme }) =>
    shadowDesign &&
    `
  
  border: 1px solid #e3e3e3;
  box-shadow: ${theme.shadows.shadow1};

  `}

  ${({ search }) => search && `padding-right: 50px`};
  ${({ $textCenter }) => $textCenter && `text-align: center`};

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.primary};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const AdminDollarInput2 = (props) => {
  return (
    <Div
      $relative
      $width="100%"
      $maxWidth={props?.maxWidth}
      $maxWidthSM={props?.maxWidthSM}
    >
      {props.label && (
        <P
          $mb="8"
          $ml="24"
          $mr="24"
          $bold
          $center={props.$textCenter}
          $color={lightTheme.darkGray}
        >
          {props.label}{' '}
          {props.tooltip && <InputLabelTooltip tooltip={props.tooltip} />}
        </P>
      )}
      <AdminInputStyled {...props} />

      {props.tooltip && (
        <>
          <Div $desktop>
            <Tooltip
              clickable
              id={props.tooltip}
              style={{
                maxWidth: '500px',
                background: lightTheme.white,
                color: lightTheme.darkGray,
                padding: 16,
                borderRadius: 20,
                boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
                zIndex: '1',
              }}
              border="1px solid #e3e3e3"
            />
          </Div>
          <Div $mobile>
            <Tooltip
              id={props.tooltip}
              style={{
                maxWidth: '95%',
                background: lightTheme.white,
                color: lightTheme.darkGray,
                padding: 16,
                borderRadius: 20,
                boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
                zIndex: '1',
              }}
              border="1px solid #e3e3e3"
            />
          </Div>
        </>
      )}
    </Div>
  );
};

const InputLabelTooltip = ({ tooltip }) => (
  <a
    data-tooltip-id={tooltip}
    data-tooltip-html={tooltip}
    data-tooltip-place="top"
    style={{ color: lightTheme.darkGray }}
  >
    <SvgInlineWrapper>
      <InfoIcon />
    </SvgInlineWrapper>
  </a>
);
