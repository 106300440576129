import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../helpers';

export const useVerifications = create(
  devtools((set) => ({
    loading: false,
    setLoading: (value) => set(() => ({ loading: value })),
    count: { locations: 0, agents: 0, vDrive: 0 },
    getCount: async () => {
      try {
        // set({ loading: true });
        const { data } = await api.get(`/v1/locations/verification-count`);
        set({ count: data.data, loading: false });
      } catch (error) {
        console.log(error.response);
      } finally {
        // set({ loading: false });
      }
    },
  }))
);
