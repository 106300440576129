import {
  AdminRadioInput,
  Button,
  Div,
  ErrorToast,
  Modal,
  Separator,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useState } from 'react';
import { providerStatus } from './helpers';

export const PauseAllProvidersModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setStatuses,
  id,
}) => {
  const [minutes, setMinutes] = useState(null);

  async function handlePauseAll() {
    try {
      setLoading(true);
      const res = await api.post(`/v1/locations/${id}/provider/pause-all`, {
        minutes,
      });

      setStatuses((prev) => {
        return prev.map((item) => {
          return {
            ...item,
            status: providerStatus.OFFLINE,
          };
        });
      });

      SuccessToast(res.data.message);
      setModalIsOpen(false);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const timeOptions = [
    { name: 'All Day', value: null },
    { name: '2 hours', value: 120 },
    { name: '1 hour', value: 60 },
    { name: '30 mins', value: 30 },
  ];

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Select Pause Time"
    >
      {timeOptions.map(({ name, value }, i) => (
        <div key={i}>
          <AdminRadioInput
            key={i}
            label={name}
            checked={value === minutes}
            onClick={() => setMinutes(value)}
          />
          <Separator />
        </div>
      ))}

      <Div $flex $gap="20px">
        <Button $selected $w="100%" $mt="40" onClick={() => handlePauseAll()}>
          Pause
        </Button>

        <Button
          $selected
          $gray
          $w="100%"
          $mt="40"
          onClick={() => setModalIsOpen(false)}
        >
          Cancel
        </Button>
      </Div>
    </Modal>
  );
};
