import {
  Div,
  H5,
  P,
  centsToDollars,
  formatDate,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import styled from 'styled-components';
import { OrderComponent } from './OrderComponent';
import { DateTime } from 'luxon';

const OrderHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
  padding: 15px 20px;
  margin: 0 0 8px 0;
`;

export const OrderHistoryComponent = ({ dataHistory }) => {
  return (
    <Div>
      {dataHistory.map((historyDay, i) => {
        if (historyDay.orders.length > 0)
          return (
            <Div $mb="32" key={i}>
              <OrderHeader>
                <H5 $color={lightTheme.white} $m="0">
                  {DateTime.fromISO(historyDay.salesDay).toFormat(
                    'MMM dd, yyyy'
                  )}
                </H5>
                <Div $flex $alignCenter $gap="20px" $gapSM="12px">
                  <P $color={lightTheme.white} $m="0">
                    <b>{formatter.format(centsToDollars(historyDay.total))}</b>{' '}
                    ({historyDay.count} orders)
                  </P>
                </Div>
              </OrderHeader>

              {historyDay.orders?.map((data, i) => {
                return <OrderComponent data={data} key={i} index={i} />;
              })}
            </Div>
          );
      })}
    </Div>
  );
};
