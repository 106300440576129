import {
  Button,
  Div,
  H3,
  Img,
  P,
  clockImage,
  unavailableImage,
} from '@vgrubs/components';

export const NoHolidayHours = ({ setAddDayModal }) => {
  return (
    <Div $flex $justifyCenter $mt="100">
      <Div $flex $column $alignCenter $width="100%" $maxWidth="300px">
        <Img $mb="24" $width="200px" src={clockImage} alt="unavailable" />
        <H3 $center>No Added Holiday Hours</H3>
        <P $center>This location currently doesn't have added holiday hours.</P>
        <Button $selected onClick={() => setAddDayModal(true)}>
          Add Holiday Hours
        </Button>
      </Div>
    </Div>
  );
};
