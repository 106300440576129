import {
  ADMIN_BRAND_DETAILS,
  AdminDatePickerInput2,
  AdminInput,
  Button,
  Disclaimer,
  Div,
  EditPenIconDarkGray,
  ErrorToast,
  H3,
  Icon,
  Modal,
  P,
  Separator,
  SuccessToast,
  TrashIconRed,
  api,
  lightTheme,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';

export const BrandCampaignModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  campaigns,
  setAddModalIsOpen,
  setCampaigns,
  brandId,
  setEditCampaignModal,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);

  // async function handleBrandCampaign() {
  //   try {
  //     setLoading(true);
  //     const res = await api.put(`/v1/${pathPrefix}/${data.id}/notes`, {
  //       notes,
  //     });

  //     setData((prev) => {
  //       return { ...prev, notes };
  //     });

  //     // setData(res.data.data);
  //     SuccessToast(res.data.message);
  //     setModalIsOpen(false);

  //     setLoading(false);
  //   } catch (error) {
  //     ErrorToast(error?.response?.data?.message);
  //     setLoading(false);
  //   }
  // }

  async function handleDeleteCampaign() {
    try {
      setLoading(true);
      const { data } = await api.delete(
        `/v1/brands/${brandId}/campaigns/${deleteModal}`
      );
      setCampaigns((prev) => {
        const filtered = prev.filter((d) => d.id !== deleteModal);
        return filtered;
      });
      SuccessToast(data.message);
      setDeleteModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleAdd = () => {
    setModalIsOpen(false);
    setAddModalIsOpen(true);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        header="Brand Campaigns"
        // overflow="visible"
      >
        {campaigns?.map((c, i) => {
          return (
            <Div
              $box
              $mb="16"
              $p="16px"
              key={i}
              $borderColor={c.status === 'active' && lightTheme.primary}
            >
              <Div $flex $alignCenter $between>
                <H3 $m="0" $color={BrandCampaignStatusColor(c.status)}>
                  {c.percentage}%
                </H3>

                <Div $flex $alignCenter $gap="12px">
                  {c.status !== 'expired' && (
                    <Icon $pointer onClick={() => setEditCampaignModal(c)}>
                      <EditPenIconDarkGray />
                    </Icon>
                  )}
                  <Icon $pointer onClick={() => setDeleteModal(c.id)}>
                    <TrashIconRed />
                  </Icon>
                  <BrandCampaignStatusPill status={c.status} />
                </Div>
              </Div>

              <Separator $m="16px 0 12px 0" />

              <Div $flex $alignCenter $between>
                <Disclaimer $m="0" $color={lightTheme.lightGray}>
                  Start Date: {c.startDate}
                </Disclaimer>
                <Disclaimer $m="0" $color={lightTheme.lightGray}>
                  Exp Date: {c.endDate}
                </Disclaimer>
              </Div>
            </Div>
          );
        })}

        <Button $selected $w="100%" $mt="32" onClick={handleAdd}>
          Add Campaign
        </Button>
      </Modal>

      <DeleteCampaignModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDeleteCampaign={handleDeleteCampaign}
      />
    </>
  );
};

export const AddBrandCampaignModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setCampaigns,
  setCampaignModal,
  setDetails,
  edit,
}) => {
  const [percentage, setPercentage] = useState('');

  const today = DateTime.now().toJSDate();

  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);

  const id = useMatch(ADMIN_BRAND_DETAILS).params.id;

  async function handleAddBrandCampaign() {
    if (Number(percentage) > 100)
      return ErrorToast(`Campaign Bracket Percent can't be more than 100%`);
    if (Number(percentage) < 1)
      return ErrorToast(`Campaign Bracket Percent can't be less than 1%`);

    const timestampStartDate = DateTime.fromJSDate(startDate[0]).toSeconds();
    const timestampEndDate = DateTime.fromJSDate(endDate[0]).toSeconds();

    if (timestampStartDate > timestampEndDate)
      return ErrorToast(
        `The start date must be earlier than the end date. Please adjust your input and try again.`
      );

    const body = {
      startDate: DateTime.fromJSDate(startDate[0]).toISODate(),
      endDate: DateTime.fromJSDate(endDate[0]).toISODate(),
      percentage: Number(percentage),
    };

    if (edit) body.id = modalIsOpen.id;

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/brands/${id}/campaigns`, body);

      if (edit) {
        setCampaigns((prev) => {
          return prev.map((c) => {
            if (c.id === data.data.id) return data.data;
            else return c;
          });
        });
      } else {
        setCampaigns((prev) => {
          return [...prev, data?.data];
        });
      }

      if (data?.data?.status === 'active') {
        setDetails((prev) => {
          return { ...prev, activeCampaignPercentage: data?.data?.percentage };
        });
      }

      SuccessToast(data.message);

      setModalIsOpen(false);

      setCampaignModal(true);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (edit) {
      const campaign = modalIsOpen;

      const sd = DateTime.fromISO(campaign.startDate).toJSDate();
      const ed = DateTime.fromISO(campaign.endDate).toJSDate();

      setPercentage(campaign.percentage);
      setStartDate([sd]);
      setEndDate([ed]);
    } else {
      setPercentage('');
      setStartDate('');
      setEndDate('');
    }
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header={`${edit ? 'Edit' : 'Add'} Campaign`}
      overflow="visible"
      modalOverflow="visible"
      zIndex={110}
    >
      <AdminInput
        placeholder="Enter here"
        value={percentage}
        onChange={(e) => setPercentage(e.target.value)}
        label="Campaign Bracket Percent"
        type="number"
        min="0"
        max="100"
        $textCenter
      />

      <Div
        $flex
        $alignCenter
        $columnSM
        $gap="24px"
        $gapSM="16px"
        $mt="24"
        $mtSM="16"
      >
        <Div $width="100%">
          <AdminDatePickerInput2
            label="Start Date"
            $textCenter
            centerFixed
            onChange={setStartDate}
            value={startDate}
            maxDate={endDate[0]}
          />
        </Div>
        <Div $width="100%">
          <AdminDatePickerInput2
            label="Exp Date"
            $textCenter
            centerFixed
            onChange={setEndDate}
            value={endDate}
            minDate={startDate[0]}
          />
        </Div>
      </Div>

      <Button $selected $w="100%" $mt="32" onClick={handleAddBrandCampaign}>
        Save
      </Button>
    </Modal>
  );
};

export const BrandCampaignPill = ({ campaign }) => {
  if (campaign) {
    return (
      <Div
        $inlineFlex
        $background={lightTheme.primary}
        $borderRadius="14px"
        $p="4px 16px"
      >
        <Disclaimer $m="0" $color={lightTheme.white}>
          {campaign}
        </Disclaimer>
      </Div>
    );
  } else {
    return (
      <Div $borderRadius="14px" $p="4px 16px">
        <Disclaimer $m="0">-</Disclaimer>
      </Div>
    );
  }
};

export const BrandCampaignStatusColor = (status) => {
  switch (status) {
    case 'active':
      return lightTheme.primary;

    case 'expired':
      return lightTheme.lightGray;

    case 'upcoming':
      return lightTheme.darkGray;

    default:
      return null;
  }
};

export const BrandCampaignStatusPill = ({ status }) => {
  return (
    <Div
      $background={BrandCampaignStatusColor(status)}
      $borderRadius="30px"
      $p="8px 16px"
    >
      <Disclaimer $m="0" $color={lightTheme.white} $capitalize>
        {status}
      </Disclaimer>
    </Div>
  );
};

export const DeleteCampaignModal = ({
  deleteModal,
  setDeleteModal,
  handleDeleteCampaign,
}) => {
  return (
    <Modal
      isOpen={deleteModal}
      setIsOpen={setDeleteModal}
      logo={false}
      zIndex={110}
    >
      <H3 $mb="24" $color={lightTheme.primary}>
        Are you sure
      </H3>
      <P $mb="40">Are you sure you want to delete this availability?</P>

      <Button $selected $w="100%" $mb="8" onClick={handleDeleteCampaign}>
        Confirm
      </Button>

      <Button $selected $w="100%" $gray onClick={() => setDeleteModal(false)}>
        Cancel
      </Button>
    </Modal>
  );
};
