export const heroImage = '/images/heroImage.webp';
export const contactImage = '/images/contactImage.jpg';
export const vGrubs = '/images/vGrubs.svg';
export const vGrubsWhite = '/images/vGrubsWhite.svg';

export { default as img404 } from './images/404.jpg';
export { default as chefPhoto } from './images/chefPhoto.jpg';
export { default as doordash } from './images/doordash.jpg';
export { default as grubhub } from './images/grubhub.jpg';
export { default as heroImage2 } from './images/heroImage2.jpg';
export { default as partnerPhone } from './images/partnerPhone.jpg';
export { default as phone1 } from './images/phone1.jpg';
export { default as phone2 } from './images/phone2.jpg';
export { default as requestImage } from './images/requestImage.jpg';
export { default as section7 } from './images/section7.jpg';
export { default as tablet1 } from './images/tablet1.jpg';
export { default as tablet2 } from './images/tablet2.jpg';
export { default as thankYouImage } from './images/thankYouImage.jpg';
export { default as uber } from './images/uber.jpg';
export { default as setupDevices } from './images/setupDevices.jpg';
export { default as pricing1 } from './images/pricing1.jpg';
export { default as pricing2 } from './images/pricing2.jpg';
export { default as success } from './images/success.jpg';
export { default as section3Logos } from './images/section3Logos.jpg';
export { default as sales } from './images/sales.jpg';
export { default as concierge1 } from './images/concierge1.jpg';
export { default as concierge2 } from './images/concierge2.jpg';
export { default as whyUs1 } from './images/whyUs1.jpg';
export { default as whyUs2 } from './images/whyUs2.jpg';
export { default as whyUs3 } from './images/whyUs3.jpg';
export { default as fsgLogo } from './images/FsgLogo.jpg';
export { default as bagel } from './images/bagel.jpg';
export { default as bagel2 } from './images/bagel2.jpg';
export { default as thankYouFsg } from './images/thankYouFsg.jpg';
export { default as islandBagel } from './images/islandBagel.jpg';
export { default as bagelBoss } from './images/bagelBoss.jpg';
export { default as whyUs1Fsg } from './images/whyUs1Fsg.jpg';
export { default as noImage } from './images/noImage.png';
export { default as virtualRestaurants1 } from './images/virtualRestaurants1.jpg';
export { default as virtualRestaurants2 } from './images/virtualRestaurants2.jpg';
export { default as virtualRestaurants3 } from './images/virtualRestaurants3.jpg';
export { default as virtualRestaurants4 } from './images/virtualRestaurants4.jpg';
export { default as virtualRestaurants5 } from './images/virtualRestaurants5.jpg';
export { default as money } from './images/money.jpg';
export { default as moneyAndCoins } from './images/moneyAndCoins.png';

export { default as successPNG } from './images/success.png';
export { default as menuItems } from './images/menuItems.png';

export { default as doordashLogo } from './images/providers/doordash.png';
export { default as uberLogo } from './images/providers/uber.png';
export { default as gloriaLogo } from './images/providers/gloria.png';
export { default as chowNowLogo } from './images/providers/chowNow.png';
export { default as grubhubLogo } from './images/providers/grubhub.png';
export { default as deliveryLogo } from './images/providers/delivery.png';
export { default as eatstreetLogo } from './images/providers/eatstreet.png';
export { default as menufyLogo } from './images/providers/menufy.png';
export { default as localForYouLogo } from './images/providers/localForYou.png';

export { default as doordashIcon } from './images/providers/doordashIcon.png';
export { default as uberIcon } from './images/providers/uberIcon.png';
export { default as gloriaIcon } from './images/providers/gloriaIcon.png';
export { default as chowNowIcon } from './images/providers/chowNowIcon.png';
export { default as grubhubIcon } from './images/providers/grubhubIcon.png';
export { default as deliveryIcon } from './images/providers/deliveryIcon.png';
export { default as eatstreetIcon } from './images/providers/eatstreetIcon.png';
export { default as menufyIcon } from './images/providers/menufyIcon.png';
export { default as localForYouIcon } from './images/providers/localForYouIcon.png';

export { default as relayLogo } from './images/vDriveProviders/relay.png';
export { default as allyLogo } from './images/vDriveProviders/ally.png';
export { default as cutcatsLogo } from './images/vDriveProviders/cutcats.png';
export { default as dlivrdLogo } from './images/vDriveProviders/dlivrd.png';
export { default as motoclickLogo } from './images/vDriveProviders/motoclick.png';

export { default as restaurantImage } from './images/restaurant.png';
export { default as agentImage } from './images/agent.png';
export { default as noVDrive } from './images/noVDrive.png';
export { default as getStartedVDrive } from './images/getStartedVDrive.png';
export { default as vDriveMoneyCoins } from './images/vDriveMoneyCoins.png';
export { default as vDriveAlmostReady } from './images/vDriveAlmostReady.png';

export { default as plate } from './images/plate.png';
export { default as grubster } from './images/grubster.png';
export { default as moneyCoins } from './images/moneyCoins.png';
export { default as calendarImage } from './images/calendarImage.png';

export { default as unavailableImage } from './images/unavailable.png';
export { default as clockImage } from './images/clockImage.png';
export { default as pauseImage } from './images/pause.png';

export { default as transactionsNoData } from './images/transactionsNoData.png';
export { default as noItems } from './images/noItems.png';
export { default as noModifierGroups } from './images/noModifierGroups.png';
export { default as noCategories } from './images/noCategories.png';
export { default as noAvailability } from './images/noAvailability.png';

export { default as vDriveDeactivated } from './images/vDriveDeactivated.png';

export * from './svg';
export * from './images/brands/logo';
export * from './images/brands/logoPng';
