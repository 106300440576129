export { default as chickenBar } from './chickenBar.jpg';
export { default as bagNWitch } from './bagNWitch.jpg';
export { default as smoothieCentral } from './smoothieCentral.jpg';
export { default as mikushi } from './mikushi.jpg';
export { default as nycBagles } from './nycBagles.jpg';
export { default as pizzatalia } from './pizzatalia.jpg';
export { default as saladAce } from './saladAce.jpg';
export { default as theBurger } from './theBurger.jpg';
export { default as buka } from './buka.jpg';
export { default as soupKettle } from './soupKettle.jpg';
export { default as burgerz } from './burgerz.jpg';
export { default as fishka } from './fishka.jpg';
export { default as gyroKing } from './gyroKing.jpg';
export { default as wingz } from './wingz.jpg';
export { default as heroMania } from './heroMania.jpg';
export { default as electicSoups } from './electicSoups.jpg';
export { default as chickNFries } from './chickNFries.jpg';
export { default as JuicyJuice } from './JuicyJuice.jpg';
export { default as wrap } from './wrap.jpg';
export { default as coffieZen } from './coffieZen.jpg';
export { default as omeletteStation } from './omeletteStation.jpg';
export { default as phillyCheez } from './phillyCheez.jpg';
export { default as paniniPoint } from './paniniPoint.jpg';
export { default as pizzaBagels } from './pizzaBagels.jpg';

export { default as bagels } from './bagels.jpg';
export { default as burritoBandito } from './burritoBandito.jpg';
export { default as coldCutz } from './coldCutz.jpg';
export { default as crazyTako } from './crazyTaco.jpg';
export { default as crunchyBites } from './crunchyBites.jpg';
export { default as fishNChips } from './fishNChips.jpg';
export { default as fishTime } from './fishTime.jpg';
export { default as greekPalace } from './greekPalace.jpg';
export { default as grillBar } from './grillBar.jpg';
export { default as grillTime } from './grillTime.jpg';
export { default as GrillStar } from './GrillStar.jpg';
export { default as italianos } from './italianos.jpg';
export { default as meatMe } from './meatMe.jpg';
export { default as meatz } from './meatz.jpg';
export { default as nycDinner } from './nycDinner.jpg';
export { default as pitaz } from './pitaz.jpg';
export { default as pizzaRoma } from './pizzaRoma.jpg';
export { default as platter } from './platter.jpg';
export { default as riseBowls } from './riseBowls.jpg';
export { default as skewers } from './skewers.jpg';
export { default as steaksNChops } from './steaksNChops.jpg';
export { default as tastyPanini } from './tastyPanini.jpg';
export { default as wrapz } from './wrapz.jpg';

export { default as americanBistro } from './americanBistro.jpg';
export { default as eatMyPasta } from './eatMyPasta.jpg';
export { default as hotDawgs } from './hotDawgs.jpg';
export { default as iceNCream } from './iceNCream.jpg';
export { default as juicyDogs } from './juicyDogs.jpg';
export { default as mexicanGrill } from './mexicanGrill.jpg';
export { default as pancakePlanet } from './pancakePlanet.jpg';
export { default as pastaLovers } from './pastaLovers.jpg';
export { default as pizza4Me } from './pizza4Me.jpg';
export { default as pizza888 } from './pizza888.jpg';
export { default as quesadillaFiesta } from './quesadillaFiesta.jpg';
export { default as saladSnack } from './saladSnack.jpg';
export { default as sandwichSpread } from './sandwichSpread.jpg';
export { default as shakeSpot } from './shakeSpot.jpg';
export { default as sultanKebab } from './sultanKebab.jpg';
export { default as veganOasisCafe } from './veganOasisCafe.jpg';
