import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalStyled = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.white};
  z-index: ${({ $zIndex }) => ($zIndex ? `${$zIndex + 1}` : '96')};
  border-radius: 30px;
  width: ${({ $width }) => $width ?? '100%'};
  /* max-width: 90%; */
  max-width: calc(100% - 100px);
  overflow: hidden;

  @media (max-width: 800px) {
    max-width: calc(100% - 60px);
    /* padding: 24px; */
    ${({ $header }) => $header && `padding-top: 105px;`}
    > svg {
      width: 100px;
    }
  }
`;

export const ModalScrollableStyled = styled(motion.div)`
  padding: 45px;
  max-height: calc(100vh - 100px);
  ${({ $footer }) => $footer && `padding-bottom: 200px;`}

  @media (max-width: 800px) {
    padding: 24px;
  }

  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const ModalOverlay = styled(motion.div)`
  background-color: ${({ theme }) => theme.darkGray}4D;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ $zIndex }) => ($zIndex ? `${$zIndex}` : '95')};
`;

export const SuccessImage = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
`;

export const ModalHeader = styled.div`
  background-color: ${({ theme }) => theme.primary};
  height: 100px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    height: 80px;
  }
`;

export const ModalFooter = styled.div`
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;

  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 30px;
  padding: 35px;
  @media (max-width: 800px) {
    height: 80px;
  }
`;
