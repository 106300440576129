import {
  AdminInput,
  Button,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
  setTimeTo,
} from '@vgrubs/components';
import {
  AdminDatePickerStyled,
  FlatpickrStyled,
} from 'components/src/adminComponents/AdminDatePicker/AdminDatePickerStyled';
import { DateTime } from 'luxon';
import { useState } from 'react';

export const AddClosingDays = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setData,
  locationId,
  handleAddSubmit,
  closingDays,
  timezone,
}) => {
  // const [from, setFrom] = useState('');
  // const [to, setTo] = useState('');
  const [date, setDate] = useState('');

  async function handleAddClosingDay() {
    const chosenDate = DateTime.fromJSDate(date[0]).toISODate();

    try {
      const closingDay = {
        locationId,
        from: chosenDate,
        to: chosenDate,
      };

      setLoading(true);
      const { data } = await api.post(`/v1/closing-days`, closingDay);

      // setData((prev) => {
      //   return [...prev, data.data];
      // });

      // setData(res.data.data);
      SuccessToast(data.message);
      setModalIsOpen(false);

      handleAddSubmit();

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const disabledDates = closingDays.map((day) => {
    return DateTime.fromISO(day.date).setZone(timezone).toISODate();
  });

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Add Closing Days"
      width="auto"
    >
      <FlatpickrStyled>
        <AdminDatePickerStyled
          value={date}
          onChange={(d) => setDate(d)}
          options={{
            mode: 'single',
            // dateFormat: 'M d, Y',
            dateFormat: 'Y-m-d',
            inline: true,
            minDate: 'today',
            disable: disabledDates,
          }}
          className="form-control"
        />
      </FlatpickrStyled>

      <Button
        $selected
        $w="100%"
        $mt="40"
        onClick={() => handleAddClosingDay()}
      >
        Confirm
      </Button>
    </Modal>
  );
};
