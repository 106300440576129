import { img404 } from '../../assets';
import { Div, H3, Img } from '../../styles';

export const NotFound = () => {
  return (
    <Div $flex $center $column $mt="50">
      <Img src={img404} alt="404" $width={'100vmin'} />
      <Div $textAlignCenter style={{ transform: 'translateY(-7vmin)' }}>
        <H3 $center>Page not found!</H3>
      </Div>
    </Div>
  );
};
