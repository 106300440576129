import { ErrorToast, Img, PlusIcon, plate } from '@vgrubs/components';
import Compressor from 'compressorjs';
import { useRef } from 'react';
import styled from 'styled-components';

const BrandImageInputStyled = styled.div`
  width: 130px;
  height: 130px;
  cursor: pointer;
  position: relative;
  margin-bottom: 40px;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input {
    visibility: hidden;
    height: 0;
    width: 0;
  }
`;

export const BrandImageInput = ({ image, onChange, disabled }) => {
  const imageUrl =
    typeof image === 'string' ? image : image && URL.createObjectURL(image);

  const inputFileRef = useRef();

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const handleChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    const maxSize = 2097152;

    console.log(file);

    if (files.length) {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 2500,
        maxHeight: 1500,
        convertSize: 2097152,
        success: (compressedResult) => {
          console.log(compressedResult.size);
          if (compressedResult.size > maxSize) {
            return ErrorToast('Image file too big');
          } else {
            console.log(compressedResult);
            onChange(compressedResult);
          }
        },
      });
    }
  };

  return (
    <BrandImageInputStyled
      onClick={!disabled ? onBtnClick : null}
      disabled={disabled}
    >
      <input ref={inputFileRef} type="file" onChange={handleChange} />

      <div>
        {image ? (
          <img src={imageUrl} alt="add brand" />
        ) : (
          <div>
            <PlusIcon />
          </div>
        )}
      </div>

      <Img src={plate} $width="130px" alt="add brand" />
    </BrandImageInputStyled>
  );
};
