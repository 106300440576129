import React from 'react';
import { DeleteIcon } from '@vgrubs/components';
import { Button, Modal } from '@vgrubs/components';
import { Div, H3, P } from '@vgrubs/components';

export const BlogsDeleteModal = ({
  setDeleteModal,
  deleteModal,
  handleBlogDelete,
}) => {
  return (
    <>
      <Modal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        logo={false}
        exitButton={false}
      >
        <Div $mb="16">
          <DeleteIcon />
        </Div>

        <H3 $m="10px" $center>
          Delete
        </H3>
        <P $mb="24" $center>
          Are you sure you want to delete blog?
        </P>

        <Div $flex $gap="16px">
          <Button
            onClick={() => handleBlogDelete(deleteModal)}
            $w="100%"
            $selected
          >
            Delete
          </Button>
          <Button
            $selected
            $gray
            $w="100%"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
        </Div>
      </Modal>
    </>
  );
};
