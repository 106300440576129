import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { Div, P } from '@vgrubs/components';
import { INPUT_OPTIONS, StripeInputStyled } from './VDrive.styled';
// import { Dispatch, SetStateAction } from 'react';

// interface PaymentCreditCardProps {
//   name: string;
//   setName: Dispatch<SetStateAction<string>>;
//   email: string;
//   setEmail: Dispatch<SetStateAction<string>>;
// }

export const PaymentCreditCard = () => {
  return (
    <div>
      <Div $mb="32">
        <Div $mb="20">
          <P $mb="10">
            <b>Card Number</b>
          </P>
          <StripeInputStyled>
            <CardNumberElement options={INPUT_OPTIONS} />
          </StripeInputStyled>
        </Div>
        <Div $flex $gap="1.6rem">
          <Div $width="50%">
            <P $mb="10">
              <b>Exp date</b>
            </P>
            <StripeInputStyled>
              <CardExpiryElement options={INPUT_OPTIONS} />
            </StripeInputStyled>
          </Div>
          <Div $width="50%">
            <P $mb="10">
              <b>CVC</b>
            </P>
            <StripeInputStyled>
              <CardCvcElement options={INPUT_OPTIONS} />
            </StripeInputStyled>
          </Div>
        </Div>
      </Div>
    </div>
  );
};
