import {
  ADMIN_MENU_PROVIDER,
  AdminInput,
  AdminRadioInput,
  AdminSelect,
  Button,
  Div,
  ErrorToast,
  H5,
  Modal,
  Separator,
  SuccessToast,
  api,
  convert24HourTo12Hour,
  daysOfWeek,
  times,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';

const timeFromOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      From
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

const timeToOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      To
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

export const AddEditSchedule = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setMenu,
  item,
  edit,
}) => {
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(null);
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');

  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { params } = match;
  const { provider, locationId, brandId } = params;

  const data = modalIsOpen;

  useEffect(() => {
    if (edit && item) {
      setSelectedDayOfWeek(item.day);
      setTimeFrom(item.startTime);
      setTimeTo(item.endTime);
    } else {
      setSelectedDayOfWeek(null);
      setTimeFrom('');
      setTimeTo('');
    }
  }, [data]);

  async function handleAdd() {
    if (selectedDayOfWeek === null || !timeFrom || !timeTo)
      return ErrorToast('Please fill in all information');

    if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(timeFrom))
      return ErrorToast('Time From should be in HH:mm format');
    if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(timeTo))
      return ErrorToast('Time To should be in hh:mm format');
    if (timeFrom >= timeTo)
      return ErrorToast('Time From must be earlier than Time To');

    let hrsEdit = [];

    if (edit) {
      data?.hours?.forEach((element) => {
        if (element.id === item.id) {
          hrsEdit.push({
            day: selectedDayOfWeek,
            startTime: timeFrom,
            endTime: timeTo,
          });
        } else
          return hrsEdit.push({
            day: element.day,
            startTime: element.startTime,
            endTime: element.endTime,
          });
      });
    }

    let hrsAdd = [];

    if (!edit) {
      data?.hours?.forEach((element) =>
        hrsAdd.push({
          day: element.day,
          startTime: element.startTime,
          endTime: element.endTime,
        })
      );

      hrsAdd.push({
        day: selectedDayOfWeek,
        startTime: timeFrom,
        endTime: timeTo,
      });
    }

    const bodyEdit = {
      name: data.name,
      hours: hrsEdit,
    };

    const bodyAdd = {
      name: data.name,
      hours: hrsAdd,
    };

    const body = edit ? bodyEdit : bodyAdd;

    try {
      setLoading(true);

      const { data } = await api.put(
        `/v1/menu/${locationId}/schedule/${brandId}/provider/${provider}/update/${modalIsOpen?.id}`,
        body
      );

      setMenu(data?.data);
      setModalIsOpen(false);
      SuccessToast(data.message);
      setSelectedDayOfWeek([]);
      setTimeFrom('');
      setTimeTo('');
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header={`${edit ? 'Edit' : 'Add'} Schedule`}
      textAlign="left"
      width="500px"
    >
      <H5 $center>Select Day</H5>
      <Separator />
      {daysOfWeek.map(({ name, value }) => (
        <div key={value}>
          <AdminRadioInput
            label={name}
            checked={selectedDayOfWeek === value}
            onClick={() => setSelectedDayOfWeek(value)}
          />
          <Separator />
        </div>
      ))}
      <H5 $mt="32" $mb="16" $center>
        Select Hours
      </H5>
      <Div $flex $gap="24px">
        <AdminSelect
          options={timeFromOptions}
          value={timeFrom}
          onChange={({ target }) => setTimeFrom(target.value)}
          placeholder="From"
          $textCenter
          label="Time From"
        />
        <AdminSelect
          options={timeToOptions}
          value={timeTo}
          onChange={({ target }) => setTimeTo(target.value)}
          placeholder="To"
          $textCenter
          label="Time To"
        />
      </Div>

      <Button $selected $w="100%" $mt="32" onClick={() => handleAdd()}>
        Confirm
      </Button>
      <Button
        $selected
        $gray
        $w="100%"
        $mt="16"
        onClick={() => setModalIsOpen(false)}
      >
        Cancel
      </Button>
    </Modal>
  );
};
