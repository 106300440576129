import {
  ADMIN_VERIFICATIONS_V_DRIVE,
  AdminDollarInput2,
  AdminHeader,
  AdminInput,
  AdminSpinner,
  AdminSwitch,
  AnimatedDiv,
  Button,
  DeleteIcon,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  H5,
  Modal,
  P,
  Separator,
  SuccessToast,
  VDriveLogo,
  api,
  lightTheme,
  useVerifications,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';

export const VDriveVerificationDetails = () => {
  const [loading, setLoading] = useState('');
  const [vDriveVerification, setLocation] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [achCustomerId, setAchCustomerId] = useState('');
  const [vGrubsFee, setVGrubsFee] = useState(25);
  const [vDriveFee, setVDriveFee] = useState(200);
  const [enableVgrubsBrands, setEnableVgrubsBrands] = useState(false);
  const [autoAssignDriver, setAutoAssignDriver] = useState(false);
  const navigate = useNavigate();

  const { getCount } = useVerifications((state) => state);

  const [rejectModal, setRejectModal] = useState(false);

  const id = useMatch(ADMIN_VERIFICATIONS_V_DRIVE).params.id;

  async function handleVerifyLocation() {
    if (!merchantId) {
      ErrorToast('Please add Delivery Merchant ID');
      return;
    }

    if (Number(vDriveFee) < 80) {
      ErrorToast(`vDrive Fee can't be less than $0.80`);
      return;
    }
    if (Number(vGrubsFee) < 20) {
      ErrorToast(`vGrubs Fee can't be less than $0.20`);
      return;
    }

    const body = {
      merchantId,
      enableVgrubsBrands,
      autoAssignDriver,
      achCustomerId,
      vDriveFee,
      vGrubsFee,
    };

    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/vdrive/${vDriveVerification.id}`,
        body
      );
      SuccessToast(data.message);
      getCount();
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleRejectVDrive() {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/vdrive/${vDriveVerification.id}/reject`
      );
      SuccessToast(data.message);
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleGetLocation() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/vdrive/location/${id}/verification`);
      setLocation(data.data);

      setAutoAssignDriver(data?.data?.autoAssign || false);
      setEnableVgrubsBrands(data?.data?.vGrubsBrandsEnabled || false);
      setMerchantId(data?.data?.merchantId || '');
      setAchCustomerId(data?.data?.achCustomerId || '');
      setVDriveFee(data?.data?.vDriveFee || '');
      setVGrubsFee(data?.data?.vGrubsFee || '');
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      handleGetLocation();
    }
  }, []);

  const handleValueVDriveFee = useCallback((val) => {
    setVDriveFee(val);
  }, []);

  const handleValueVGrubsFee = useCallback((val) => {
    setVGrubsFee(val);
  }, []);

  const { location, owner } = vDriveVerification;

  return (
    <>
      <AdminHeader title={'vDrive Verification Details'}></AdminHeader>

      {loading && <AdminSpinner />}
      {vDriveVerification && (
        <AnimatedDiv>
          <Div $m="40px 0" $flex $alignCenter $between $columnSM $gap="30px">
            <Div $flex $alignCenter $gap="30px" $gapSM="16px" $widthSM="100%">
              <VDriveLogo width="125" height="125" />
              <Div>
                <H3 $mb="8" $color={lightTheme.primary}>
                  {location.name}
                </H3>
                <P $mb="8">{location.address}</P>
              </Div>
            </Div>
          </Div>
          <Div $flex $gap="48px" $gapSM="0" $columnSM $m="20px 0">
            <Div $width="50%" $widthSM="100%">
              <Separator />
              <Div $flex $between $alignCenter $p="16px 0">
                <div>
                  <P $mb="4">Owner</P>
                  <H5 $mb="0">
                    {owner.firstName} {owner.lastName}
                  </H5>
                </div>
              </Div>
              <Separator />
            </Div>
            <Div $width="50%" $widthSM="100%">
              <Separator />
              <Div $flex $between $alignCenter $p="16px 0">
                <div>
                  <P $mb="4">Phone</P>
                  <H5 $mb="0">{location.phoneNumber || '/'}</H5>
                </div>
              </Div>
              <Separator />
            </Div>
          </Div>
          <Div
            $flex
            $gap="48px"
            $gapSM="24px"
            $columnSM
            $m="36px 0"
            $mSM="20px 0"
          >
            <Div
              $width="50%"
              $widthSM="100%"
              $box
              $p=" 0 24px"
              $height="80px"
              $heightSM="80px"
              $flex
              $alignCenter
              $between
            >
              <H5 $m="0">Enable vDrive for vGrubs Brands</H5>{' '}
              <AdminSwitch
                checked={enableVgrubsBrands}
                onChange={() => setEnableVgrubsBrands((prev) => !prev)}
              />
            </Div>
            <Div
              $width="50%"
              $widthSM="100%"
              $box
              $p=" 0 24px"
              $height="80px"
              $heightSM="80px"
              $flex
              $alignCenter
              $between
            >
              <Div>
                <H5 $mb="4">Automatically Assign Driver</H5>
                <Disclaimer $m="0" $color={lightTheme.lightGray}>
                  (Based on cheapest and fastest routing)
                </Disclaimer>
              </Div>
              <AdminSwitch
                checked={autoAssignDriver}
                onChange={() => setAutoAssignDriver((prev) => !prev)}
              />
            </Div>
          </Div>
          <Div
            $flex
            $gap="48px"
            $gapSM="24px"
            $columnSM
            $m="36px 0"
            $mSM="20px 0"
          >
            <Div $width="50%" $widthSM="100%">
              <AdminInput
                tooltip="First Delivery Merchant ID used to connect vDrive."
                label="Delivery Merchant ID"
                placeholder="Enter Here"
                $textCenter
                value={merchantId}
                onChange={(e) => setMerchantId(e.target.value)}
              />
            </Div>

            <Div
              $width="50%"
              $widthSM="100%"
              $columnSM
              $reverseSM
              $gap="32px"
              $gapSM="20px"
              $flex
              $justifyEnd
              $alignEnd
            >
              <AdminInput
                tooltip="The GoCardless Customer ID will be used for processing client payments related to outstanding balances on the vDrive credit card. A 2% fee will be applied for each balance top-up. Payments will be automatically charged every Monday and Thursday night."
                $textCenter
                label="ACH Customer ID"
                placeholder="Enter here"
                value={achCustomerId}
                onChange={(e) => setAchCustomerId(e.target.value)}
              />
            </Div>
          </Div>

          <Div
            $flex
            $gap="48px"
            $gapSM="24px"
            $columnSM
            $m="36px 0"
            $mSM="20px 0"
          >
            <Div $width="50%" $widthSM="100%" $flex $gap="24px" $gapSM="12px">
              <Div $width="50%">
                <AdminDollarInput2
                  label="vDrive Fee"
                  onValueChange={handleValueVDriveFee}
                  value={vDriveFee}
                  $textCenter
                />
              </Div>

              <Div $width="50%">
                <AdminDollarInput2
                  label="vGrubs Fee"
                  onValueChange={handleValueVGrubsFee}
                  value={vGrubsFee}
                  $textCenter
                />
              </Div>
            </Div>

            <Div
              $width="50%"
              $widthSM="100%"
              $columnSM
              $reverseSM
              $gap="32px"
              $gapSM="20px"
              $flex
              $justifyEnd
              $alignEnd
            >
              {/* <Button
                $selected
                $gray
                $w="100%"
                onClick={() => setRejectModal(id)}
              >
                Reject vDrive
              </Button> */}
              <Button
                $selected
                $w="350px"
                $wSM="100%"
                onClick={handleVerifyLocation}
              >
                Enable vDrive
              </Button>
            </Div>
          </Div>
        </AnimatedDiv>
      )}
      {/* <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        successImage
        exitButton={false}
        logo={false}
      >
        <H3 $m="0">
          {location.name} <br />
          <span>vDrive for Restaurant is Enabled!</span>
        </H3>
        <Separator $m="20px 0" />

        <Link to={-1}>
          <Button $selected $w="100%">
            Continue
          </Button>
        </Link>
      </Modal> */}

      {/* <Modal
        isOpen={rejectModal}
        setIsOpen={setRejectModal}
        logo={false}
        exitButton={false}
      >
        <Div $mb="16">
          <DeleteIcon />
        </Div>

        <H3 $m="10px" $center>
          Reject vDrive
        </H3>
        <P $mb="24" $center>
          Are you sure you want to reject vDrive for this restaurant?
        </P>

        <Div $flex $gap="16px">
          <Button onClick={handleRejectVDrive} $w="100%" $selected>
            Yes
          </Button>
          <Button $w="100%" onClick={() => setIsOpen(false)} $gray $selected>
            No
          </Button>
        </Div>
      </Modal> */}
    </>
  );
};
