import {
  AdminHeader,
  AdminSingleBrandPicker,
  AdminSingleLocationPicker,
  AdminSpinner,
  AnimatedDiv,
  Div,
  ErrorToast,
  HeaderIconWrapper,
  Icon,
  PlusIcon,
  api,
  firstWhereOrNull,
  read,
  store,
  useUIState,
} from '@vgrubs/components';
import { HolidayHours } from './sections/HolidayHours';
import { WorkingHours } from './sections/WorkingHours';
import { Tab } from '../Verifications/VerificationsStyled';
import { useEffect, useState } from 'react';
import { Unavailable } from './Unavailable';

export const WorkingHolidayHours = () => {
  const { workingHoursTab, setWorkingHoursTab } = useUIState((state) => state);
  const [addDayModal, setAddDayModal] = useState(false);

  const locationLSNode = 'vgrubs-admin-working-hours-location';
  const locationFromLS = JSON.parse(read(locationLSNode));
  const [selectedLocation, setSelectedLocation] = useState(
    locationFromLS || null
  );
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [loading, setLoading] = useState(false);

  const [unavailable, setUnavailable] = useState(false);

  const handleLocationsSubmit = (l) => {
    setSelectedBrand(null);
    store(locationLSNode, JSON.stringify(l));
  };

  async function getProvidersStatuses() {
    if (!selectedLocation) return;

    const locationOrBrand = selectedBrand ? 'brands' : 'locations';
    const locationOrBrandId = selectedBrand
      ? selectedBrand?.id
      : selectedLocation?.id;

    try {
      setLoading(true);

      const { data } = await api.get(
        `/v1/${locationOrBrand}/${locationOrBrandId}/providers-statuses`
      );

      const isUnavailable =
        firstWhereOrNull(data.data, (e) => e.provider === 'delivery') === null;

      setUnavailable(isUnavailable);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getProvidersStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand, selectedLocation]);

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminHeader title="Working Hours">
        {workingHoursTab === 2 && !unavailable && (
          <HeaderIconWrapper>
            <Icon onClick={() => setAddDayModal({ edit: null })}>
              <PlusIcon />
            </Icon>
          </HeaderIconWrapper>
        )}
      </AdminHeader>

      <Div $flex $gap="40px" $gapSM="20px" $columnSM $p="32px 0">
        <Div $width="50%" $widthSM="100%" $flex $column $gap="20px">
          <AdminSingleLocationPicker
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            handleSubmit={(l) => handleLocationsSubmit(l)}
          />
        </Div>
        <Div $width="50%" $widthSM="100%" $flex $column $gap="20px">
          <AdminSingleBrandPicker
            selectedLocation={selectedLocation}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
          />
        </Div>
      </Div>

      {!unavailable && (
        <AnimatedDiv $flex $gap="24px" $gapSM="16px">
          <Tab
            selected={workingHoursTab === 1}
            onClick={() => setWorkingHoursTab(1)}
          >
            Working Hours
          </Tab>
          <Tab
            selected={workingHoursTab === 2}
            onClick={() => setWorkingHoursTab(2)}
          >
            Holiday Hours
          </Tab>
        </AnimatedDiv>
      )}

      {workingHoursTab === 1 && !unavailable && !loading && (
        <WorkingHours
          selectedBrand={selectedBrand}
          selectedLocation={selectedLocation}
          unavailable={unavailable}
        />
      )}
      {workingHoursTab === 2 && !unavailable && !loading && (
        <HolidayHours
          selectedBrand={selectedBrand}
          selectedLocation={selectedLocation}
          setAddDayModal={setAddDayModal}
          addDayModal={addDayModal}
          unavailable={unavailable}
        />
      )}

      {unavailable && <Unavailable />}
    </>
  );
};
