import {
  Chevron,
  Div,
  Icon,
  P,
  Radio,
  Separator,
  TrashIconRed,
  centsToDollars,
  formatter,
} from '@vgrubs/components';
import { useRef, useState } from 'react';
import { Collapsible } from '../../MenyStyled';

export const AddModifierGroupComponent = ({
  item,
  edit,
  setModifierGroups,
  modifierGroups,
}) => {
  const ref = useRef(null);
  const [elementHeight, setHeight] = useState(ref?.current?.clientHeight);

  const [expanded, setExpanded] = useState(false);

  function isSelected(item) {
    return modifierGroups?.some((location) => location.id === item.id);
  }

  const handleSelect = (newItem) => {
    console.log(newItem);
    const index = modifierGroups?.findIndex((item) => item === newItem);
    if (index === -1) {
      // Item doesn't exist, so add it
      setModifierGroups((prevIds) => [...prevIds, newItem]);
    } else {
      // Item exists, so remove it
      setModifierGroups((prevIds) =>
        prevIds.filter((item) => item !== newItem)
      );
    }
  };

  return (
    <>
      <Div
        $box
        $p="16px 24px"
        $flex
        $alignCenter
        $between
        $mb="8"

        // $background={expanded && lightTheme.primary}
      >
        <Div
          $pointer
          $flex
          $alignCenter
          $gap="8px"
          onClick={() => handleSelect(item)}
        >
          {!edit && (
            <div>
              <Radio checked={isSelected(item)} />
            </div>
          )}

          <P
            $bold
            $m="0"
            $left
            //   $color={expanded && lightTheme.white}
          >
            {item.name}
          </P>
        </Div>
        <Div $flex $alignCenter $gap="16px">
          {edit && (
            <Icon $pointer onClick={() => handleSelect(item)}>
              <TrashIconRed />
            </Icon>
          )}

          <Icon
            $pointer
            $rotate={expanded ? '360deg' : '180deg'}
            onClick={() => setExpanded((prev) => !prev)}
            // color={expanded ? lightTheme.white : lightTheme.darkGray}
          >
            <Chevron />
          </Icon>
        </Div>
      </Div>
      <Collapsible expanded={expanded} elementHeight={elementHeight}>
        <div ref={ref}>
          {item?.children?.map((item, i) => {
            return <ModifierComponent item={item} key={i} />;
          })}
          {item?.children?.length === 0 ? (
            <P $m="0" $center>
              No modifiers
            </P>
          ) : null}
        </div>
      </Collapsible>
    </>
  );
};

export const ModifierComponent = ({ item }) => {
  return (
    <>
      <Separator />
      <Div $p="16px 20px" $flex $between $alignCenter>
        <P $m="0">{item.name}</P>
        <P $m="0">{formatter.format(centsToDollars(item.price))}</P>
      </Div>
    </>
  );
};
