import {
  CalendarIcon,
  Div,
  DropdownMenuComponent,
  Icon,
  useOnClickOutside,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { useRef, useState } from 'react';
import styled from 'styled-components';

const AdminDatePickerWrapperStyled = styled.div`
  position: relative;
  border-radius: 200px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 6px 50px 6px 6px;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.darkGray};
  border: 1px solid #e3e3e3;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  width: 100%;
  background-color: ${({ theme }) => theme.white};

  ${({ $opened, theme }) => $opened && `border: 1px solid ${theme.primary};`}

  @media (max-width: 800px) {
    width: 100%;
    flex: none;
  }

  > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const ChevronStyled = styled.div`
  position: absolute;
  right: 16px;
  left: auto;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  height: 16px;
  pointer-events: none;
`;

const Month = styled.div`
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.primary : '')};
  color: ${({ selected, theme }) => (selected ? theme.white : '')};
  cursor: pointer;
  border-radius: 100px;

  :hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.primary : theme.gray};
  }
`;

const MonthWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const YearWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 5px;
`;

const ChevronWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${({ theme }) => theme.gray};
  }
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

export const AdminMonthYearPicker = ({ month, year, setMonth, setYear }) => {
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => dropdown && setDropdown(false));

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Avg',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <AdminDatePickerWrapperStyled
      $opened={dropdown}
      onClick={() => setDropdown(true)}
      ref={dropdownRef}
    >
      <Div $alignCenter $flex>
        <Div $flex $center>
          <Icon>
            <CalendarIcon />
          </Icon>
          <Div $ml="12" $mb="0" $flex>
            {months[month - 1]}, {year}
          </Div>

          <DropdownMenuComponent isOpen={dropdown} setIsOpen={setDropdown}>
            <YearWrapper>
              <ChevronWrapper
                style={{ rotate: '90deg' }}
                onClick={() => setYear((prev) => prev - 1)}
              >
                <Chevron />
              </ChevronWrapper>
              <>{year}</>
              <ChevronWrapper
                style={{ rotate: '-90deg' }}
                onClick={() => setYear((prev) => prev + 1)}
              >
                <Chevron />
              </ChevronWrapper>
            </YearWrapper>
            <MonthWrapper>
              {months.map((m, i) => {
                return (
                  <Month
                    key={i}
                    selected={i + 1 === month}
                    onClick={() => setMonth(i + 1)}
                  >
                    {m}
                  </Month>
                );
              })}
            </MonthWrapper>
          </DropdownMenuComponent>
        </Div>

        <ChevronStyled>
          <Chevron />
        </ChevronStyled>
      </Div>
    </AdminDatePickerWrapperStyled>
  );
};

const Chevron = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.29365 12.7066C7.68428 13.0973 8.31865 13.0973 8.70928 12.7066L14.7093 6.70664C15.0999 6.31602 15.0999 5.68164 14.7093 5.29102C14.3187 4.90039 13.6843 4.90039 13.2937 5.29102L7.9999 10.5848L2.70615 5.29414C2.31553 4.90352 1.68115 4.90352 1.29053 5.29414C0.899902 5.68477 0.899902 6.31914 1.29053 6.70977L7.29053 12.7098L7.29365 12.7066Z"
        fill="#4C4C4C"
      />
    </svg>
  );
};
