import { Button, H3, Modal, P, lightTheme } from '@vgrubs/components';

export const DeactivateVDriveModal = ({
  modalIsOpen,
  setModalIsOpen,
  handleDeactivateReactivate,
  isVDriveDeactivated,
}) => {
  return (
    <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} logo={false}>
      <H3 $mb="24" $color={lightTheme.primary}>
        Are you sure
      </H3>
      <P $mb="40">
        Are you sure you want to{' '}
        {isVDriveDeactivated ? 'reactivate' : 'deactivate'} vDrive?
      </P>

      <Button $selected $w="100%" $mb="8" onClick={handleDeactivateReactivate}>
        Confirm
      </Button>

      <Button $selected $w="100%" $gray onClick={() => setModalIsOpen(false)}>
        Cancel
      </Button>
    </Modal>
  );
};
