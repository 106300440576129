import {
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H3,
  Modal,
  ModalIconStyle,
  P,
  SuccessToast,
  TrashIcon,
  api,
  useOrders,
} from '@vgrubs/components';
import { useState } from 'react';

export const DeleteOrderModal = ({ closeDetailsModal }) => {
  const [loading, setLoading] = useState(false);
  const {
    deleteManualOrderModal,
    setDeleteManualOrderModal,
    setDetailsModal,
    removeHistoryOrderById,
    removeOrderById,
  } = useOrders((state) => state);

  const order = deleteManualOrderModal;
  const id = order?.id;

  async function handleDeleteManualOrder() {
    try {
      setLoading(true);
      const { data } = await api.delete(`/v1/orders/${id}/manual`);

      removeHistoryOrderById(order);
      removeOrderById(id);
      closeDetailsModal();
      setDeleteManualOrderModal(null);

      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <AdminSpinner />}

      <Modal
        isOpen={deleteManualOrderModal}
        setIsOpen={() => setDeleteManualOrderModal(null)}
        logo={false}
        zIndex={110}
      >
        <Div $flex $justifyCenter>
          <ModalIconStyle>
            <TrashIcon />
          </ModalIconStyle>
        </Div>
        <H3 $mb="8">Delete Order?</H3>
        <P $mb="16">Are you sure you want to delete this manual order?</P>

        <Div $flex $gap="20px">
          <Div $width="50%">
            <Button
              $selected
              $w="100%"
              $mt="24"
              onClick={handleDeleteManualOrder}
            >
              Yes
            </Button>
          </Div>
          <Div $width="50%">
            <Button
              $selected
              $gray
              $w="100%"
              $mt="24"
              onClick={() => setDeleteManualOrderModal(null)}
            >
              No
            </Button>
          </Div>
        </Div>
      </Modal>
    </>
  );
};
