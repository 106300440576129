import {
  AdminInput,
  Button,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useState } from 'react';
import { useNavigate } from 'react-router';

export const RejectModal = ({ modalIsOpen, setModalIsOpen, setLoading }) => {
  const [reason, setReason] = useState('');
  const navigate = useNavigate();

  const id = modalIsOpen;

  async function handleRejectLocation() {
    const body = {
      reason,
    };

    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/locations/${id}/reject-verification`,
        body
      );
      SuccessToast(data.message);
      setModalIsOpen(false);
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Reject Restaurant"
    >
      <AdminInput
        type="textarea"
        placeholder="Enter rejection reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />

      <Button
        $selected
        $w="100%"
        $mt="40"
        onClick={() => handleRejectLocation()}
      >
        Save
      </Button>
    </Modal>
  );
};
