import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

export const useAuth = create(
  devtools(
    persist(
      (set) => ({
        user: null,
        tokens: null,
        updateAuthState: (data) =>
          set(() => ({ user: data.user, tokens: data.tokens })),
        updateNotificationStatus: (data) =>
          set((state) => ({
            ...state,
            user: { ...state.user, notificationsEnabled: data },
          })),
        removeAuthState: () => set(() => ({ user: null, tokens: null })),
      }),
      { name: 'vg-admin-auth' }
    )
  )
);

export const useSidebarState = create(
  devtools((set) => ({
    sidebarIsOpen: false,
    setSidebarIsOpen: (value) => set(() => ({ sidebarIsOpen: value })),
  }))
);

export const useUIState = create(
  devtools(
    persist(
      (set) => ({
        verificationsTab: 1,
        setVerificationsTab: (value) =>
          set(() => ({ verificationsTab: value })),
        settingsTab: 1,
        setSettingsTab: (value) => set(() => ({ settingsTab: value })),
        workingHoursTab: 1,
        setWorkingHoursTab: (value) => set(() => ({ workingHoursTab: value })),
        menuTab: 1,
        setMenuTab: (value) => set(() => ({ menuTab: value })),
      }),

      { name: 'vg-admin-ui-state' }
    )
  )
);
