import {
  ADMIN_LOGIN,
  ADMIN_VERIFICATIONS,
  Button,
  Div,
  File,
  H3,
  H5,
  LogoWhiteCircle,
  Logout,
  LogoutIcon,
  Modal,
  P,
  isPartner,
  lightTheme,
  renderRole,
  useAuth,
  useSidebarState,
  useVerifications,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AdminLogoutModal } from './AdminLogoutModal';

const AdminSidebarStyled = styled.div`
  width: 280px;
  flex: none;
  height: 100vh;
  background-color: ${({ theme }) => theme.primary};
  padding: 30px 24px;
  border-radius: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 1450px) {
    width: 270px;

    span {
      font-size: 16px;
    }
  }

  @media (max-width: 1200px) {
    height: auto;
    width: 70vw;
    max-width: 270px;
    z-index: 9;
    position: fixed;
    transition: ${({ theme }) => theme.transitions.ease300ms};
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    left: ${({ $sidebarIsOpen }) => ($sidebarIsOpen ? '0px' : '-310px')};
    top: 0;
    bottom: 0;
  }
`;

const AdminNavigationStyled = styled.nav`
  width: 100%;
`;

const NavLinkCircle = styled.nav`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: ${({ theme, selected }) =>
    selected ? theme.white : `${theme.white}${theme.transparency.t10}`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  transition: all 3s ease;

  svg {
    path {
      transition: all 3s ease;

      fill: ${({ theme, selected }) =>
        selected ? theme.primary : theme.white};
    }
  }
`;

export const MobileSidebarOverlay = styled.div`
  background-color: ${({ theme }) => theme.darkGray}4D;
  opacity: ${({ $sidebarIsOpen }) => ($sidebarIsOpen ? '1' : '0')};
  visibility: ${({ $sidebarIsOpen }) =>
    $sidebarIsOpen ? 'visible' : 'hidden'};
  transition: ${({ theme }) => theme.transitions.ease300ms};
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
`;

export const AdminSidebar = ({ routes }) => {
  const pathname = useLocation().pathname;

  const selected = (path) => {
    return pathname.startsWith(path);
  };

  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);

  const { role } = useAuth((state) => state?.user);

  const { sidebarIsOpen, setSidebarIsOpen } = useSidebarState((state) => state);
  const { count, getCount } = useVerifications((state) => state);

  useEffect(() => {
    getCount();
  }, []);

  return (
    <>
      <AdminSidebarStyled $sidebarIsOpen={sidebarIsOpen}>
        <Div $width="100%" $flex $column>
          <Div $flex $alignCenter $gap="24px" $mb="40">
            <LogoWhiteCircle />{' '}
            <H5 $m="0" $color={lightTheme.white}>
              {renderRole(role)}
            </H5>
          </Div>
          <AdminNavigationStyled>
            {routes?.map(({ path, name, Icon, permissions }, i) => {
              if (name && permissions.includes(role)) {
                return (
                  <CustomLink
                    to={path}
                    key={i}
                    onClick={() => setSidebarIsOpen(false)}
                  >
                    <Div $flex $alignCenter $mb="24">
                      <NavLinkCircle selected={selected(path)}>
                        {Icon ? <Icon /> : <File />}
                      </NavLinkCircle>
                      <span>
                        {name}{' '}
                        {path === ADMIN_VERIFICATIONS &&
                          `(${
                            count.locations + count.agents + count.vDrive
                          })`}{' '}
                      </span>
                    </Div>
                  </CustomLink>
                );
              } else return null;
            })}
          </AdminNavigationStyled>
        </Div>
        <Div $width="100%" $widthSM="100%">
          <Div
            $width="100%"
            $pointer
            $flex
            $alignCenter
            onClick={() => {
              setLogoutModal(true);
              setSidebarIsOpen(false);
            }}
          >
            <NavLinkCircle>
              <Logout />
            </NavLinkCircle>
            <span>Logout</span>
          </Div>
          {isPartner && (
            <a href="sms:8186007488" style={{ width: '100%' }}>
              <Button
                $negative
                $w="100%"
                $wSM="100%"
                $mt="20"
                $selected
                style={{ boxShadow: 'none' }}
              >
                Contact concierge via text
              </Button>
            </a>
          )}
        </Div>
      </AdminSidebarStyled>

      {/* SIDEBAR MODAL OVERLAY */}
      <MobileSidebarOverlay
        $sidebarIsOpen={sidebarIsOpen}
        onClick={() => setSidebarIsOpen(false)}
      />

      {/* LOGOUT MODAL */}
      <AdminLogoutModal isOpen={logoutModal} setIsOpen={setLogoutModal} />
    </>
  );
};

const CustomLink = ({ to, ...props }) => {
  const location = useLocation();

  const handleClick = (event) => {
    if (location.pathname === to) {
      event.preventDefault();
    }
  };

  if (location.pathname === to) {
    return <Div $pointer {...props}></Div>;
  }

  return <Link to={to} onClick={handleClick} {...props} />;
};
