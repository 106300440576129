import {
  AdminInput,
  AdminRadioInput,
  AdminSpinner,
  Button,
  ConnectGrubconModal,
  Div,
  ErrorToast,
  GrubconConnect,
  H5,
  Img,
  Modal,
  P,
  ScrollableModalDiv,
  SuccessToast,
  api,
  handleSetUrlSearchParams,
  useScrollToTop,
} from '@vgrubs/components';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';

export const TemplateBrandAdd = ({
  modalIsOpen,
  setModalIsOpen,
  setData,
  data,
  type = 'add',
  onCreateNonVGrubsBrand,
  onAddBrandSuccess,
}) => {
  const [grubconConnectModal, setGrubconConnectModal] = useState(false);
  const [sip, setSip] = useState(null);
  const [crmApiKey, setCrmApiKey] = useState(null);
  const [template, setTemplate] = useState(null);
  const [brandTemplates, setBrandTemplates] = useState([]);
  const isGrubconConnected = crmApiKey !== null;
  const [loading, setLoading] = useState(false);

  const [scrollRef, scrollToTop] = useScrollToTop();

  const emptyData = () => {
    setSip(null);
    setCrmApiKey(null);
    setTemplate(null);
  };

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();
  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('query');

  // const cancelTokenSourceRef = useRef(null); // Track the cancel token

  // const cancelPreviousRequest = () => {
  //   if (cancelTokenSourceRef.current) {
  //     cancelTokenSourceRef.current.cancel('Previous request canceled.');
  //   }
  //   cancelTokenSourceRef.current = axios.CancelToken.source(); // Create a new cancel token
  // };

  async function getAllLocations(search, page, hasMore) {
    if (!hasMore) return;

    // cancelPreviousRequest(); // Cancel any ongoing request before making a new one

    try {
      setLoading(true);

      const { data } = await api.get(`/v1/brand-templates`, {
        // cancelToken: cancelTokenSourceRef.current.token, // Attach the new cancel token
        params: {
          limit: 10,
          page: page,
          query: query?.length > 0 ? query : null,
        },
      });

      if (!search) {
        if (data.data[0]) {
          setHasMore(true);
          setBrandTemplates((prevItems) => [...prevItems, ...data.data]);
        } else {
          setHasMore(false);
        }
      } else {
        if (data.data[0]) {
          setHasMore(true);
          setBrandTemplates(() => [...data.data]);
        } else {
          setBrandTemplates(() => [...data.data]);
          setHasMore(false);
        }
        scrollToTop();
      }

      setPage((prevPage) => prevPage + 1);
      setLoading(false);
    } catch (error) {
      // if (axios.isCancel(error)) {
      //   console.log('Previous request canceled:', error.message);
      // } else {
      ErrorToast(error?.response?.data?.message);
      // }
      setLoading(false);
    }
  }

  useEffect(() => {
    setTemplate(null);
    handleSetUrlSearchParams('query', null, setSearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  useEffect(() => {
    if (modalIsOpen) {
      setHasMore(true);
      setPage(1);
      getAllLocations(true, 1, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, modalIsOpen]);

  useEffect(() => {
    if (inView && brandTemplates.length > 0) {
      getAllLocations(false, page, hasMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleChange = async (e) => {
    handleSetUrlSearchParams('query', e.target.value, setSearchParams);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  // async function getBrandTemplates() {
  //   try {
  //     setLoading(true);
  //     const { data } = await api.get(`/v1/brand-templates`, {
  //       params: { page: 1, limit: 10 },
  //     });

  //     setBrandTemplates(data.data);
  //     setLoading(false);
  //   } catch (error) {
  //     ErrorToast(error?.response?.data?.message);
  //     setLoading(false);
  //   }
  // }

  // useEffect(() => {
  //   if (modalIsOpen) {
  //     getBrandTemplates();
  //   }
  // }, [modalIsOpen]);

  async function handleAdd() {
    var brand = {
      templateId: template,
      locationId: data.id,
      crmApiKey,
      sip,
    };

    try {
      setLoading(true);
      const res = await api.post(`/v1/brands/with-template`, brand);
      SuccessToast(res.data.message);
      setModalIsOpen(false);

      setData((prev) => {
        return { ...prev, brands: [res.data.data, ...prev.brands] };
      });

      onAddBrandSuccess(res.data.data);
      setLoading(false);
      emptyData();
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!template) return ErrorToast('Please select Brand Template');
    handleAdd();
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        header={`${type === 'edit' ? 'Edit' : 'Add'} Brand`}
      >
        <form onSubmit={handleSubmit}>
          <H5>Choose vGrubs Brand</H5>
          <Div $mb="16">
            <AdminInput
              defaultValue={query}
              search
              placeholder="Search Brand"
              onChange={debouncedResults}
            />
          </Div>

          <Div $relative $minHeight="100px">
            <ScrollableModalDiv
              ref={scrollRef}
              style={{ position: 'relative' }}
            >
              {brandTemplates?.map(({ imageUrl, id, name }, i) => {
                return (
                  <Div
                    key={i}
                    $mb={brandTemplates.length === i + 1 ? '24' : '4'}
                    $box
                    $p="4px 16px 4px 8px"
                    $flex
                    $between
                    $alignCenter
                    onClick={() => setTemplate(id)}
                  >
                    <Div $flex $alignCenter $gap="16px" $flexNone>
                      <Img src={imageUrl} $width="50px" />
                      <P $left $m="0" $width="100%">
                        {name}
                      </P>
                    </Div>
                    <AdminRadioInput checked={template === id} />
                  </Div>
                );
              })}
              <div ref={ref}></div>
            </ScrollableModalDiv>
            {loading && <AdminSpinner absolute small />}
          </Div>

          <GrubconConnect
            isGrubconConnected={isGrubconConnected}
            setGrubconConnectModal={setGrubconConnectModal}
            setSip={setSip}
            setCrmApiKey={setCrmApiKey}
          />

          <Button $selected $w="100%" type="submit" $mt="24" $mtSM="24">
            Submit
          </Button>

          <Button
            $gray
            $selected
            $w="100%"
            type="button"
            onClick={onCreateNonVGrubsBrand}
            $mt="16"
            $mtSM="16"
          >
            Create Non vGrubs Brand
          </Button>
        </form>
      </Modal>

      <ConnectGrubconModal
        modalIsOpen={grubconConnectModal}
        setModalIsOpen={setGrubconConnectModal}
        setSip={setSip}
        setCrmApiKey={setCrmApiKey}
        sipForEdit={sip}
        crmApiKeyForEdit={crmApiKey}
        type={isGrubconConnected ? 'edit' : 'add'}
      />
    </>
  );
};
