import {
  AdminInput,
  Button,
  Div,
  ErrorToast,
  H3,
  Modal,
  P,
  Separator,
  StopVDriveLogo,
  api,
  useOrders,
} from '@vgrubs/components';
import { useState } from 'react';

export const CancelVDriveModal = ({
  cancelVDriveModal,
  setCancelVDriveModal,
  setData,
  setLoading,
}) => {
  const { detailsModal, changeVDriveOnList } = useOrders((state) => state);

  const [reason, setReason] = useState('');

  const id = detailsModal;

  async function cancelVDrive(reason) {
    const body = { reason: reason || null };

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/orders/${id}/cancel-vdrive`, body);
      setData(data.data);
      changeVDriveOnList(id, data.data.vDrive);
      setCancelVDriveModal(false);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        isOpen={cancelVDriveModal}
        setIsOpen={() => setCancelVDriveModal(null)}
        logo={false}
        zIndex={110}
      >
        <Div $flex $justifyCenter $mb="24">
          <StopVDriveLogo />
        </Div>
        <H3 $mb="8">Stop vDriver</H3>
        <P $mb="24">
          Are you sure you want to cancel <br /> vDriver for this order?
        </P>

        <Button
          $selected
          $w="100%"
          $mb="16"
          onClick={() =>
            cancelVDrive('The restaurant delivered it themselves.')
          }
        >
          Yes, I Will Deliver Myself
        </Button>

        <Button
          $selected
          $gray
          $w="100%"
          onClick={() => cancelVDrive('The customer has already picked it up.')}
        >
          Customer Already Picked Up
        </Button>

        <Separator $m="16px 0" />

        <AdminInput
          type="textarea"
          value={reason}
          onChange={({ target }) => setReason(target.value)}
          placeholder="Enter reason"
          label="Other"
          style={{ minHeight: 100, maxHeight: 100 }}
          $textCenter
        />

        <Button
          $selected
          $w="100%"
          $mt="24"
          onClick={() => cancelVDrive(reason)}
        >
          Confirm
        </Button>
      </Modal>
    </>
  );
};
