import {
  AbsoluteAddressDropdown,
  AdminInput,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
  lightTheme,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import styled from 'styled-components';

export const ChangeAddress = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setData,
}) => {
  const [address, setAddress] = useState('');
  const [aptNumber, setAptNumber] = useState('');

  async function handleChangeAddress() {
    if (!address) return ErrorToast('Please Choose Address');

    const body = {
      address,
      aptNumber,
    };

    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/orders/${modalIsOpen}/vdrive/change-address`,
        body
      );
      setData(data.data);
      setModalIsOpen(false);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setAddress('');
    setAptNumber('');
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      zIndex={110}
      header={'Change Address'}
    >
      <LocationSearchInput address={address} setAddress={setAddress} />

      <AdminInput
        $textCenter
        placeholder="Enter here"
        label="Apt. Number"
        value={aptNumber}
        onChange={({ target }) => setAptNumber(target.value)}
      />
      <Button $mt="24" $w="100%" $selected onClick={handleChangeAddress}>
        Confirm
      </Button>
      <Button
        $w="100%"
        $mt="16"
        $selected
        $gray
        onClick={() => setModalIsOpen(false)}
      >
        Cancel
      </Button>
    </Modal>
  );
};

const LocationSearchInput = ({ address, setAddress }) => {
  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Div $relative $mb="24">
          <AdminInput
            $textCenter
            {...getInputProps({
              label: 'New Delivery Address',
              placeholder: 'Choose Address',
            })}
          />
          {suggestions.length ? (
            <AbsoluteAddressDropdown $box $p="8px">
              {loading && <AdminSpinner absolute small />}
              {suggestions.map((suggestion, i) => {
                const style = suggestion.active
                  ? { color: lightTheme.primary, cursor: 'pointer' }
                  : { backgroundColor: '', cursor: 'pointer' };
                return (
                  <Div
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    $p="8px"
                  >
                    <span>{suggestion.description}</span>
                  </Div>
                );
              })}
            </AbsoluteAddressDropdown>
          ) : null}
        </Div>
      )}
    </PlacesAutocomplete>
  );
};
