import {
  ADMIN_BRAND_V_DRIVE,
  AdminHeader,
  AdminInput,
  AdminSpinner,
  AdminSwitch,
  AnimatedDiv,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  H5,
  Img,
  SuccessToast,
  VDriveLogo,
  api,
  groupFundingTipSchedule,
  lightTheme,
  renderProviderIcon,
  renderProviderName,
  unGroupFundingTipSchedule,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router';
import { AddEditFundingTip } from './AddEditFundingTip';
import { FundingTipComponent } from './FundingTipComponents';
import { FundingTipAmountAndAddMultiple } from './FundingTipAmountAndAddMultiple';

export const VDriveBrand = () => {
  const [loading, setLoading] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [applyFromPhysicalLocation, setApplyFromPhysicalLocation] =
    useState(false);
  const [autoAssignDriver, setAutoAssignDriver] = useState(false);
  const navigate = useNavigate();
  const [providers, setProviders] = useState(providersInitialMock);

  const [fundedTipAddEditModal, setFundedTipAddEditModal] = useState(false);
  const [fundedTipAdd, setFundedTipAdd] = useState(false);

  const [fundingMultiple, setFundingMultiple] = useState(false);

  const [fundedTipSchedule, setFundedTipSchedule] = useState([]);
  const [fundingTipOrigin, setFundingTipOrigin] = useState(false);

  const id = useMatch(ADMIN_BRAND_V_DRIVE).params.id;

  async function handleUpdateVDrive() {
    if (!applyFromPhysicalLocation && !merchantId) {
      ErrorToast('Please add Delivery Merchant ID');
      return;
    }

    const providersDisabled = providers
      .filter(({ enabled }) => enabled === false)
      .map(({ provider }) => provider);

    const body = {
      merchantId,
      applyFromPhysicalLocation,
      autoAssignDriver,
      providersDisabled,
      fundedTipSchedule: groupFundingTipSchedule(fundedTipSchedule),
      fundingTipOrigin,
    };

    try {
      setLoading(true);
      const { data } = await api.put(`/v1/vdrive/brand/${id}`, body);
      SuccessToast(data.message);
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleGetVDrive() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/vdrive/brand/${id}`);
      setAutoAssignDriver(data?.data?.autoAssign || false);
      setApplyFromPhysicalLocation(
        data?.data?.applyFromPhysicalLocation || false
      );
      setMerchantId(data?.data?.merchantId || '');
      setProviders(data?.data?.providers || []);
      setFundedTipSchedule(
        data?.data?.fundedTipSchedule
          ? unGroupFundingTipSchedule(data?.data?.fundedTipSchedule)
          : []
      );
      setFundingTipOrigin(data?.data?.fundingTipOrigin || false);
    } catch (error) {
      if (error.response.status !== 404) {
        ErrorToast(error?.response?.data?.message);
        setProviders(data?.data?.providers || []);
      }
      if (error.response.status === 404 && brandType === 'vGrubs') {
        handleGetFundingOrigin();
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleGetFundingOrigin() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/vdrive/brand/funding-tip-origin`);
      setFundedTipSchedule(
        data?.data ? unGroupFundingTipSchedule(data?.data) : []
      );
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      handleGetVDrive();
    }
  }, []);

  const locationState = useLocation().state;
  const { brandName, brandType } = locationState;

  return (
    <>
      <AdminHeader title={'Manage vDrive Brand'}></AdminHeader>

      {loading && <AdminSpinner />}
      <AnimatedDiv>
        <Div
          $m="40px 0"
          $mSM="20px 0"
          $flex
          $alignCenter
          $between
          $columnSM
          $gap="48px"
          $gapSM="20px"
        >
          <Div
            $flex
            $alignCenter
            $gap="30px"
            $gapSM="16px"
            $width="50%"
            $widthSM="100%"
          >
            <VDriveLogo width="125" height="125" />
            <Div>
              <H3 $mb="8" $color={lightTheme.primary}>
                {brandName}
              </H3>
            </Div>
          </Div>
          <Div $width="50%" $widthSM="100%"></Div>
        </Div>

        <Div $flex $gap="48px" $gapSM="24px">
          <Div
            $flex
            $gap="48px"
            $gapSM="24px"
            $column
            $width="50%"
            $widthSM="100%"
          >
            <Div
              $box
              $p=" 0 24px"
              $height="80px"
              $heightSM="80px"
              $flex
              $alignCenter
              $between
            >
              <H5 $m="0">Apply vDrive settings from physical location</H5>{' '}
              <AdminSwitch
                checked={applyFromPhysicalLocation}
                onChange={() => setApplyFromPhysicalLocation((prev) => !prev)}
              />
            </Div>
            {!applyFromPhysicalLocation && (
              <Div
                $box
                $p=" 0 24px"
                $height="80px"
                $heightSM="80px"
                $flex
                $alignCenter
                $between
              >
                <Div>
                  <H5 $mb="4">Automatically Assign Driver</H5>
                  <Disclaimer $m="0" $color={lightTheme.lightGray}>
                    (Based on cheapest and fastest routing)
                  </Disclaimer>
                </Div>
                <AdminSwitch
                  checked={autoAssignDriver}
                  onChange={() => setAutoAssignDriver((prev) => !prev)}
                />
              </Div>
            )}

            {!applyFromPhysicalLocation && (
              <AdminInput
                tooltip="First Delivery Merchant ID used to connect vDrive."
                label="Delivery Merchant ID"
                placeholder="Enter Here"
                $textCenter
                value={merchantId}
                onChange={(e) => setMerchantId(e.target.value)}
              />
            )}

            {!applyFromPhysicalLocation && (
              <FundingTipComponent
                setFundedTipSchedule={setFundedTipSchedule}
                fundedTipSchedule={fundedTipSchedule}
                setFundedTipAddEditModal={setFundedTipAddEditModal}
                setFundedTipAdd={setFundedTipAdd}
                setFundingMultiple={setFundingMultiple}
              />
            )}
          </Div>

          <Div $width="50%" $widthSM="100%">
            {!applyFromPhysicalLocation && (
              <H3 $color={lightTheme.primary}>Providers</H3>
            )}

            {!applyFromPhysicalLocation &&
              providers.map(({ provider, enabled }, i) => (
                <Div
                  key={i}
                  $box
                  $p="12px 16px"
                  $mb="8"
                  $flex
                  $between
                  $alignCenter
                >
                  <Div $flex $alignCenter $gap="20px">
                    <Img width="32px" src={renderProviderIcon(provider)} />
                    <H5 $m="0">{renderProviderName(provider)}</H5>
                  </Div>
                  <AdminSwitch
                    checked={enabled}
                    onChange={() =>
                      setProviders((prev) =>
                        prev.map((p) => {
                          if (p.provider === provider) {
                            return { provider, enabled: !p.enabled };
                          } else return p;
                        })
                      )
                    }
                  />
                </Div>
              ))}
          </Div>
        </Div>
        <Div $flex $justifyEnd $alignEnd $mt="40">
          <Button $selected onClick={handleUpdateVDrive}>
            Save
          </Button>
        </Div>
      </AnimatedDiv>

      <AddEditFundingTip
        modalIsOpen={fundedTipAddEditModal}
        setModalIsOpen={setFundedTipAddEditModal}
        setFundedTipSchedule={setFundedTipSchedule}
        edit
      />

      <AddEditFundingTip
        modalIsOpen={fundedTipAdd}
        setModalIsOpen={setFundedTipAdd}
        setFundedTipSchedule={setFundedTipSchedule}
      />

      <FundingTipAmountAndAddMultiple
        modalIsOpen={fundingMultiple}
        setModalIsOpen={setFundingMultiple}
        setFundedTipSchedule={setFundedTipSchedule}
        setFundingTipOrigin={setFundingTipOrigin}
        fundingTipOrigin={fundingTipOrigin}
        brandType={brandType}
      />
    </>
  );
};

const providersInitialMock = [
  {
    provider: 'doordash',
    enabled: true,
  },
  {
    provider: 'doordashPos',
    enabled: true,
  },
  {
    provider: 'grubhub',
    enabled: true,
  },
  {
    provider: 'grubhubPos',
    enabled: true,
  },
  {
    provider: 'gloriafood',
    enabled: true,
  },
  {
    provider: 'ubereats',
    enabled: true,
  },
  {
    provider: 'chownow',
    enabled: true,
  },
  {
    provider: 'delivery',
    enabled: true,
  },
  {
    provider: 'eatstreet',
    enabled: true,
  },
  {
    provider: 'menufy',
    enabled: true,
  },
  {
    provider: 'localForYou',
    enabled: true,
  },
];
