import {
  AnimatedDiv,
  Chevron,
  Div,
  H5,
  Icon,
  P,
  VDriveLogo,
} from '@vgrubs/components';
import { Link } from 'react-router-dom';
import { RestaurantStyled } from '../VerificationsStyled';

export const VDriveComponent = ({ vDriveVerification }) => {
  return (
    <Link to={`/verifications/v-drive/${vDriveVerification.location.id}`}>
      <AnimatedDiv>
        <RestaurantStyled>
          <Div $flex $gap="24px" $alignCenter>
            <VDriveLogo width="70" height="70" />
            <Div>
              <H5 $mb="4">{vDriveVerification.location.name}</H5>
              <P $mb="4">{vDriveVerification.location.address}</P>
              <P $m="0">
                Owner: {vDriveVerification.owner.firstName}{' '}
                {vDriveVerification.owner.lastName}
              </P>
            </Div>
          </Div>
          <Icon style={{ rotate: '90deg' }}>
            <Chevron />
          </Icon>
        </RestaurantStyled>
      </AnimatedDiv>
    </Link>
  );
};
