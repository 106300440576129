import {
  api,
  ADMIN_BLOGS,
  ADMIN_CONCIERGE,
  Button,
  Disclaimer,
  Div,
  H3,
  H5,
  P,
  Separator,
  isAdmin,
  isPartner,
  isSales,
  lightTheme,
  setDocumentTitle,
  getHasEmailFromLS,
  getPhoneFromLS,
  ADMIN_DASHBOARD,
} from '@vgrubs/components';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import OtpInput from 'react18-input-otp';
import styled from 'styled-components';
import { AdminSpinner, ErrorToast, SuccessToast } from '../../adminComponents';
import { useAuth } from '../../store/auth';

const Form = styled.form`
  width: 100%;
`;

export const OtpAdmin = () => {
  const { updateAuthState } = useAuth((state) => state);
  const [code, setCode] = useState('');
  const onChange = (value) => {
    setCode(value);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const phoneNumber = getPhoneFromLS();
  const hasEmail = getHasEmailFromLS();
  const ref = useRef(null);

  const role = () => {
    if (isAdmin) return 'admin';
    if (isSales) return 'salesManager';
    if (isPartner) return 'partner';
  };

  async function resendCode(isViaEmail) {
    try {
      setLoading(true);
      const { data } = await api.post(
        isViaEmail ? `/v1/auth/resend-otp-email` : `/v1/auth/sign-in`,
        {
          role: role(),
          phoneNumber,
        }
      );
      setLoading(false);
      SuccessToast(data?.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function signIn() {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/auth/verify-sms`, {
        phoneNumber,
        code,
      });

      setLoading(false);
      updateAuthState(data.data);

      isAdmin && navigate(ADMIN_DASHBOARD);
      isPartner && navigate(ADMIN_CONCIERGE);
      isSales && navigate(ADMIN_CONCIERGE);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  function handleSubmit() {
    if (code < 4) {
      ErrorToast('Enter 4 digit code');
    } else {
      signIn();
    }
  }

  useEffect(() => {
    if (code.length === 4) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (isAdmin) {
    setDocumentTitle('vGrubs Admin');
  }

  if (isPartner) {
    setDocumentTitle('vGrubs Partner');
  }

  if (isSales) {
    setDocumentTitle('vGrubs Sales');
  }

  return (
    <>
      {loading && <AdminSpinner />}

      <H3 $mb="30">
        <span>Confirm Phone Number</span>
      </H3>
      <Separator />

      <P $mt="30">
        We sent a 4-digit code to {phoneNumber} <br /> To validate your account
        insert received code below.
      </P>
      <H5>Enter Code</H5>

      <Form>
        <Div $flex $justifyCenter>
          <OtpInput
            isInputNum
            shouldAutoFocus
            ref={ref}
            value={code}
            onChange={onChange}
            numInputs={4}
            inputStyle={{
              height: 45,
              width: 45,
              borderRadius: '50%',
              margin: '0 8px',
              border: `1px solid ${lightTheme.darkGray}`,
              fontSize: '16px',
            }}
            focusStyle={{
              outline: 'none',
              border: `1px solid ${lightTheme.primary}`,
            }}
          />
        </Div>
        <Disclaimer $m="24px 0 16px 0">Did not receive the code?</Disclaimer>
        <Button
          type="button"
          onClick={() => resendCode(false)}
          $w="100%"
          $wSM="100%"
          $selected
          $gray
          $mb="16"
        >
          Resend Code via SMS
        </Button>
        {hasEmail && (
          <Button
            type="button"
            onClick={() => resendCode(true)}
            $w="100%"
            $wSM="100%"
            $gray
          >
            Resend Code via Email
          </Button>
        )}
      </Form>
    </>
  );
};
