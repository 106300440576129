import {
  ADMIN_MENU_PROVIDER,
  AdminDollarInput2,
  AdminInput,
  AdminSelect,
  Button,
  CheckCircleIcon,
  Div,
  ErrorToast,
  H5,
  Icon,
  Modal,
  PlusIcon,
  SuccessToast,
  XCircleIcon,
  api,
} from '@vgrubs/components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router';
import { Collapsible } from '../../MenyStyled';
import { ModifierBoxComponent } from './ModifierComponents';

const options2 = [
  { name: 'Price Group', value: 'PriceGroup' },
  { name: 'Option Group', value: 'OptionGroup' },
];

const typeOptions = (
  <optgroup label="Select Group Type">
    {options2.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

const requiredOptions = [
  { name: 'No', value: 'false' },
  { name: 'Yes', value: 'true' },
];

const requiredOptionsGroup = (
  <optgroup label="Select Group Type">
    {requiredOptions.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

const choicesOptions = [
  { name: 'Only One', value: 'Only One' },
  { name: 'Set a min-max', value: 'Set a min-max' },
];

const choicesOptionsGroup = (
  <optgroup label="Select Group Type">
    {choicesOptions.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

export const AddNewModifierGroupModal = ({
  addNewModal,
  setAddNewModal,
  setMenu,
  setLoading,
  edit,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [optionGroup, setOptionGroup] = useState(options2[0].value);
  const [isRequired, setIsRequired] = useState(requiredOptions[0].value);
  const [choices, setChoices] = useState(choicesOptions[0].value);

  const [selectionsMin, setSelectionsMin] = useState('');
  const [selectionsMax, setSelectionsMax] = useState('');

  const [optionName, setOptionName] = useState('');
  const [optionPrice, setOptionPrice] = useState(0);

  const handleValueTotalChange = useCallback((val) => {
    setOptionPrice(val);
  }, []);

  const [options, setOptions] = useState([]);

  const ref = useRef(null);
  const elementHeight = ref?.current?.clientHeight;
  const [expanded, setExpanded] = useState(false);

  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { params } = match;
  const { provider, locationId, brandId } = params;

  const required = isRequired === 'true';
  const isPriceGroup = optionGroup === 'PriceGroup';

  const data = addNewModal;

  useEffect(() => {
    if (edit) {
      setName(data?.name || '');
      setDescription(data?.description || '');
      setOptionGroup(data?.dpType || 'PriceGroup');
      setIsRequired(data?.isRequired);
      setOptions(data?.children || []);
      setSelectionsMin(data?.selectionsMin || '');
      setSelectionsMax(data?.selectionsMax || '');

      if (data?.isRequired) {
        if (data?.selectionsMin === 1 && data?.selectionsMax === 1) {
          setChoices('Only One');
        } else {
          setChoices('Set a min-max');
        }
      } else {
        if (data?.selectionsMin === 0 && data?.selectionsMax === 1) {
          setChoices('Only One');
        } else {
          setChoices('Set a min-max');
        }
      }
    } else {
      emptyForm();
    }
  }, [data]);

  const emptyForm = () => {
    setName('');
    setDescription('');
    setOptionGroup(options2[0].value);
    setIsRequired(requiredOptions[0].value);
    setChoices(choicesOptions[0].value);
    setOptions([]);
    setSelectionsMin('');
    setSelectionsMax('');
  };

  async function handleAddModifierGroup() {
    if (!name) return ErrorToast('Please enter modifier group name');
    if (!description) return ErrorToast('Please modifier group notes');
    if (options.length < 1) return ErrorToast('Please add options');
    if (selectionsMin > selectionsMax)
      return ErrorToast(
        'Maximum quantity must be grater then or equal to minimum'
      );

    if (required && selectionsMin < 1)
      return ErrorToast(
        `If it's required choice, Minimum Qty can't be les than 1`
      );

    const body = {
      name,
      description,
      isPriceGroup,
      options,
      isRequired: required,
      selectionsMin,
      selectionsMax,
    };

    let optionsEdit = [];

    if (edit) {
      options.forEach((e) => {
        if (e.id) {
          return optionsEdit.push({
            id: e.id,
            name: e.name,
            description: e.description,
            price: e.price ? e.price : 0,
          });
        } else
          return optionsEdit.push({
            name: e.name,
            description: e.description,
            price: e.price ? e.price : 0,
          });
      });
    }

    const bodyEdit = {
      name,
      description,
      isPriceGroup,
      options: optionsEdit,
      isRequired: required,
      selectionsMin,
      selectionsMax,
      existingModifier: data,
    };

    try {
      setLoading(true);

      const { data } = edit
        ? await api.put(
            `/v1/menu/${locationId}/modifier-group/${brandId}/provider/${provider}/update/${addNewModal?.id}`,
            bodyEdit
          )
        : await api.post(
            `/v1/menu/${locationId}/modifier-group/${brandId}/provider/${provider}`,
            body
          );

      emptyForm();
      setMenu(data?.data);
      SuccessToast(data?.message);
      setAddNewModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleAddOption = () => {
    if (!optionName) return ErrorToast('Please enter modifier name');
    setOptions((prev) => [...prev, { name: optionName, price: optionPrice }]);
    setOptionName('');
    setOptionPrice(0);
    setExpanded(false);
  };

  const handleRemoveOption = (i) => {
    let arr = [...options];

    if (i > -1) {
      arr.splice(i, 1);
    }

    setOptions(arr);
  };

  const handleChangeIsRequired = (v) => {
    const required = v === 'true';

    setIsRequired(v);
    if (v === 'true') {
      setSelectionsMin(1);
    } else {
      setSelectionsMin(0);
    }

    if (choices === 'Only One') {
      if (required) {
        setSelectionsMin(1);
        setSelectionsMax(1);
      } else {
        setSelectionsMin(0);
        setSelectionsMax(1);
      }
    }
    if (choices === 'Set a min-max') {
      if (required) {
        setSelectionsMin(1);
        setSelectionsMax(1);
      } else {
        setSelectionsMin(0);
        setSelectionsMax(0);
      }
    }
  };

  const handleChangeChoices = (v) => {
    console.log(v);
    setChoices(v);
    if (v === 'Only One') {
      if (required) {
        setSelectionsMin(1);
        setSelectionsMax(1);
      } else {
        setSelectionsMin(0);
        setSelectionsMax(1);
      }
    }
    if (v === 'Set a min-max') {
      if (required) {
        setSelectionsMin(1);
        setSelectionsMax(1);
      } else {
        setSelectionsMin(0);
        setSelectionsMax(0);
      }
    }
  };

  const handleChangeOptionGroup = (v) => {
    setOptionGroup(v);
    if (v === 'PriceGroup') {
      handleChangeIsRequired('true');
    }
    if (v === 'OptionGroup') {
      handleChangeIsRequired('false');
    }
  };

  const handleExpand = () => {
    setExpanded((prev) => !prev);
    handleValueTotalChange(0);
    setOptionName('');
  };

  return (
    <Modal
      isOpen={addNewModal}
      setIsOpen={setAddNewModal}
      header={`${edit ? 'Edit' : 'Add'} Modifier Group`}
      logo={false}
      zIndex={101}
      // width="850px"
    >
      <Div $flex $column $gap="24px">
        <Div $flex $column $alignCenter $gap="16px">
          <AdminInput
            placeholder="Enter Here"
            label="Group Name"
            $textCenter
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
          <AdminSelect
            value={optionGroup}
            options={typeOptions}
            onChange={({ target }) => handleChangeOptionGroup(target.value)}
            label="Choose Group Type"
            $textCenter
            disabled={edit}
          />{' '}
        </Div>
        <AdminInput
          placeholder="Add context or internal names for this modifier group. Notes won't be."
          label="Notes"
          $textCenter
          maxLength={30}
          value={description}
          onChange={({ target }) => setDescription(target.value)}
        />

        <div>
          <Div
            $mt="8"
            $flex
            $between
            $alignCenter
            $gap="16px"
            $mb={options.length > 0 ? '16' : '0'}
          >
            <H5 $m="0">Modifiers</H5>

            {!expanded && (
              <Icon $pointer onClick={handleExpand}>
                <PlusIcon />
              </Icon>
            )}
          </Div>

          <Collapsible expanded={expanded} elementHeight={elementHeight}>
            <div ref={ref}>
              <Div
                $box={expanded}
                $p="24px 16px"
                $flex
                $column
                $alignCenter
                $gap="16px"
                $width="100%"
              >
                <AdminInput
                  placeholder="Enter Here"
                  label="Modifier Name"
                  $textCenter
                  value={optionName}
                  onChange={({ target }) => setOptionName(target.value)}
                />

                <AdminDollarInput2
                  label="Modifier Price (Not Required)"
                  $textCenter
                  onValueChange={handleValueTotalChange}
                  value={optionPrice}
                />
                <Div $flex $gap="16px">
                  <Icon $pointer onClick={handleExpand}>
                    <XCircleIcon />
                  </Icon>
                  <Icon $pointer onClick={handleAddOption}>
                    <CheckCircleIcon />
                  </Icon>
                </Div>
              </Div>
            </div>
          </Collapsible>

          {options.length > 0 && (
            <Div $flex $column $gap="16px">
              {options.map((o, i) => {
                return (
                  <ModifierBoxComponent
                    option={o}
                    key={i}
                    handleRemoveOption={handleRemoveOption}
                    i={i}
                  />
                );
              })}
            </Div>
          )}
        </div>

        {optionGroup === 'OptionGroup' && (
          <>
            <AdminSelect
              value={isRequired}
              options={requiredOptionsGroup}
              onChange={({ target }) => handleChangeIsRequired(target.value)}
              label="Required Choice?"
              $textCenter
            />
            <AdminSelect
              value={choices}
              options={choicesOptionsGroup}
              onChange={({ target }) => {
                handleChangeChoices(target.value);
                console.log(target.value);
              }}
              label="How many choices can customer select?"
              $textCenter
            />
          </>
        )}

        {optionGroup === 'OptionGroup' && choices === 'Set a min-max' && (
          <Div $flex $alignCenter $gap="16px" $width="100%">
            <AdminInput
              placeholder="Enter Here"
              label="Minimum Qty"
              $textCenter
              value={selectionsMin}
              onChange={({ target }) => setSelectionsMin(target.value)}
              type="number"
              min={required ? '1' : '2'}
            />
            <AdminInput
              placeholder="Enter Here"
              label="Maximum Qty"
              $textCenter
              value={selectionsMax}
              onChange={({ target }) => setSelectionsMax(target.value)}
              type="number"
            />
          </Div>
        )}
        <div>
          <Button $selected $w="100%" onClick={handleAddModifierGroup}>
            Confirm
          </Button>
          <Button $selected $mt="16" $gray $w="100%">
            Cancel
          </Button>
        </div>
      </Div>
    </Modal>
  );
};
