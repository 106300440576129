import {
  ADMIN_MENU_PROVIDER,
  AdminInput,
  AdminRadioInput,
  AdminSelect,
  Button,
  Div,
  ErrorToast,
  H5,
  Modal,
  Separator,
  SuccessToast,
  api,
  convert24HourTo12Hour,
  daysOfWeek,
  times,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';

const timeFromOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      From
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

const timeToOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      To
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

export const AddEditAvailability = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setMenu,
  edit,
}) => {
  const [name, setName] = useState('');
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState([]);

  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');

  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { params } = match;
  const { provider, locationId, brandId } = params;

  const data = modalIsOpen;

  const emptyForm = () => {
    setName('');
    setSelectedDayOfWeek([]);
    setTimeFrom('');
    setTimeTo('');
  };

  useEffect(() => {
    if (edit && data) {
      setName(data.name);
    } else {
      emptyForm();
    }
  }, [data]);

  async function handleAdd() {
    if (!name) return ErrorToast('Please fill in all information');

    if (!edit) {
      if (!selectedDayOfWeek || !timeFrom || !timeTo)
        return ErrorToast('Please fill in all information');
      if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(timeFrom))
        return ErrorToast('Time From should be in HH:mm format');
      if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(timeTo))
        return ErrorToast('Time To should be in hh:mm format');
      if (timeFrom >= timeTo)
        return ErrorToast('Time From must be earlier than Time To');
    }

    let hrs = [];

    if (edit) {
      data?.hours.forEach((element) => {
        return hrs.push({
          day: element.day,
          startTime: element.startTime,
          endTime: element.endTime,
        });
      });
    }

    const bodyEdit = {
      name,
      hours: hrs,
    };

    const bodyAdd = {
      name,
      hours: selectedDayOfWeek.map((t) => ({
        day: t,
        startTime: timeFrom,
        endTime: timeTo,
      })),
    };

    try {
      setLoading(true);

      const { data } = edit
        ? await api.put(
            `/v1/menu/${locationId}/schedule/${brandId}/provider/${provider}/update/${modalIsOpen.id}`,
            bodyEdit
          )
        : await api.post(
            `/v1/menu/${locationId}/schedule/${brandId}/provider/${provider}`,
            bodyAdd
          );

      emptyForm();
      setModalIsOpen(false);
      setMenu(data?.data);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const toggleDaySelection = (day) => {
    setSelectedDayOfWeek(
      (prevSelected) =>
        prevSelected.includes(day)
          ? prevSelected.filter((d) => d !== day) // Remove if already selected
          : [...prevSelected, day] // Add if not selected
    );
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header={`${edit ? 'Edit' : 'Add'} Availability`}
      textAlign="left"
      width="500px"
    >
      <AdminInput
        value={name}
        onChange={({ target }) => setName(target.value)}
        label="Name"
        placeholder="Enter here"
        $textCenter
      />

      {!edit && (
        <>
          <H5 $mt={'32'} $center>
            Select Day
          </H5>
          <Separator />
          {daysOfWeek.map(({ name, value }) => (
            <div key={value}>
              <AdminRadioInput
                label={name}
                checked={selectedDayOfWeek.includes(value)}
                onClick={() => toggleDaySelection(value)}
              />
              <Separator />
            </div>
          ))}
          <H5 $mt="32" $mb="16" $center>
            Select Hours
          </H5>
          <Div $flex $gap="24px">
            <AdminSelect
              options={timeFromOptions}
              value={timeFrom}
              onChange={({ target }) => setTimeFrom(target.value)}
              placeholder="From"
              $textCenter
              label="Time From"
            />
            <AdminSelect
              options={timeToOptions}
              value={timeTo}
              onChange={({ target }) => setTimeTo(target.value)}
              placeholder="To"
              $textCenter
              label="Time To"
            />
          </Div>
        </>
      )}

      <Button $selected $w="100%" $mt="32" onClick={() => handleAdd()}>
        Confirm
      </Button>
      <Button
        $selected
        $gray
        $w="100%"
        $mt="16"
        onClick={() => setModalIsOpen(false)}
      >
        Cancel
      </Button>
    </Modal>
  );
};
