import {
  AnimatedDiv,
  BrandsRedIcon,
  Div,
  H5,
  Icon,
  Logo,
  P,
  lightTheme,
} from '@vgrubs/components';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BrandStyled,
  CollapsibleBrands,
  RestaurantStyled,
} from './LocationStyled';
import { BrandCampaignPill } from './BrandCampaignModal';

export const LocationComponent = ({ location }) => {
  const ref = useRef(null);
  const elementHeight = ref?.current?.clientHeight;
  const [expanded, setExpanded] = useState(false);

  const Campaign = ({ activeCampaignPercentage }) => {
    if (activeCampaignPercentage)
      return (
        <div>
          <BrandCampaignPill
            campaign={`Active Campaign ${activeCampaignPercentage}%`}
          />
        </div>
      );
  };

  return (
    <div>
      <AnimatedDiv>
        <RestaurantStyled>
          <Link to={`/locations/${location.id}`} style={{ width: '100%' }}>
            <Div $flex $gap="24px" $alignCenter $width="100%">
              <Div $desktop>
                <Logo width="70" height="70" />
              </Div>
              <Div>
                <H5 $mb="4">{location.name}</H5>
                <P $m="0">{location.address}</P>
              </Div>
            </Div>
          </Link>
          <Icon
            $rotate={expanded ? '270deg' : '90deg'}
            onClick={() => setExpanded((prev) => !prev)}
            color={expanded && lightTheme.darkGray}
            $background={lightTheme.background}
          >
            <Chevron />
          </Icon>
        </RestaurantStyled>
      </AnimatedDiv>

      <CollapsibleBrands expanded={expanded} elementHeight={elementHeight}>
        <div ref={ref}>
          {location.brands.map(({ name, id, activeCampaignPercentage }, i) => {
            return (
              <Link key={i} to={`/brand/${id}`}>
                <BrandStyled>
                  {/* DESKTOP */}
                  <Div $flex $gap="16px" $alignCenter $desktop>
                    <Icon>
                      <BrandsRedIcon />
                    </Icon>
                    <H5 $mb="0">{name}</H5>
                  </Div>
                  <Div $flex $gap="16px" $alignCenter $desktop>
                    <Campaign
                      activeCampaignPercentage={activeCampaignPercentage}
                    />
                    <Icon $background={lightTheme.background}>
                      <Chevron />
                    </Icon>
                  </Div>

                  {/* MOBILE */}

                  <Div $flex $gap="16px" $alignCenter $mobile>
                    <Icon>
                      <BrandsRedIcon />
                    </Icon>
                    <Div $flex $gap="4px" $column>
                      <H5 $mb="0">{name}</H5>
                      <Campaign
                        activeCampaignPercentage={activeCampaignPercentage}
                      />
                    </Div>
                  </Div>
                  <Div $flex $gap="16px" $alignCenter $mobile>
                    <Icon $background={lightTheme.background}>
                      <Chevron />
                    </Icon>
                  </Div>
                </BrandStyled>
              </Link>
            );
          })}
          {location.brands.length === 0 ? (
            <P $m="0" $center>
              No brands
            </P>
          ) : (
            ''
          )}
        </div>
      </CollapsibleBrands>
    </div>
  );
};

const Chevron = () => {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.535156 1.87659L5.44685 6.66562L0.535156 11.4546L2.04727 12.9258L8.48181 6.66562L2.04727 0.405456L0.535156 1.87659Z"
        fill="#E61E29"
      />
    </svg>
  );
};
