import { ADMIN_LOGIN } from '@vgrubs/components';
import axios from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  setNewAccessToken,
} from './localstorage-helper';

const BASE_URL = process.env.NX_API_URL;

let token = getAccessToken();
let refreshTokenPromise = null; // To track ongoing refresh requests

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'app-version': 'web-version-1.0.0',
    'content-type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const refreshToken = getRefreshToken();
    if (config.url === '/v1/auth/refresh') {
      console.log(refreshToken);
      config.headers['Authorization'] = 'Bearer ' + refreshToken;

      return config;
    }
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.config.url === '/v1/auth/verify-sms') {
      token = response.data.data.tokens.accessToken;
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the token refresh fails or the user is not authenticated
    if (
      (error.response.status === 401 || error.response.status === 417) &&
      originalRequest.url === '/v1/auth/refresh'
    ) {
      window.location.href = ADMIN_LOGIN;
      return Promise.reject(error);
    }

    // If the access token is expired (401 error) and we haven't retried yet
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Check if there's already a refresh token request in progress
      if (!refreshTokenPromise) {
        // token = getRefreshToken();
        // Create a new promise for the refresh token request
        refreshTokenPromise = api
          .post('/v1/auth/refresh')
          .then((res) => {
            if (res.status === 200) {
              const newAccessToken = res.data.data.accessToken;
              setNewAccessToken(newAccessToken);
              token = newAccessToken;
              refreshTokenPromise = null; // Clear the promise once the refresh is done
              return newAccessToken;
            }
          })
          .catch((refreshError) => {
            refreshTokenPromise = null; // Clear the promise on error
            window.location.href = ADMIN_LOGIN;
            return Promise.reject(refreshError);
          });
      }

      // Wait for the refresh token request to finish and retry the original request
      const newToken = await refreshTokenPromise;
      originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);
