import {
  AdminSpinner,
  AnimatedDiv,
  Div,
  EditPenIcon,
  ErrorToast,
  H3,
  H5,
  Icon,
  P,
  Separator,
  TrashIconRed,
  api,
  convert24HourTo12Hour,
  providers,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { AddHolidayHours } from '../AddHolidayHours';
import { DeleteHolidayHours } from '../DeleteHolidayHours';
import { NoHolidayHours } from '../NoHolidayHours';

export const HolidayHours = ({
  addDayModal,
  setAddDayModal,
  selectedBrand,
  selectedLocation,
}) => {
  const [loading, setLoading] = useState(false);
  const provider = providers.DELIVERY;

  const [holidayHours, setHolidayHours] = useState(null);
  const [deleteDayModal, setDeleteDayModal] = useState(false);

  async function getHolidayHours() {
    if (!selectedLocation) return;

    const locationOrBrand = selectedBrand ? 'brands' : 'locations';
    const locationOrBrandId = selectedBrand
      ? selectedBrand.id
      : selectedLocation.id;

    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/${locationOrBrand}/${locationOrBrandId}/holiday-hours`,
        { params: { provider } }
      );

      setHolidayHours(data.data);
    } catch (error) {
      if (
        error?.response?.data?.message === 'Provider delivery is not connected.'
      ) {
        return;
      } else {
        ErrorToast(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getHolidayHours();
  }, [selectedLocation, selectedBrand]);

  return (
    <>
      {loading && <AdminSpinner />}
      <AnimatedDiv>
        {Array.isArray(holidayHours) && (
          <>
            {holidayHours.map((day, i) => {
              return (
                <div key={i}>
                  <Div $flex $between $alignCenter $mt="40">
                    <div>
                      <H3 $m="0">
                        {DateTime.fromISO(day.date).toFormat('MMM dd, yyyy')}
                      </H3>
                    </div>

                    <Div $flex $between $alignCenter $gap="24px">
                      <Icon
                        $pointer
                        onClick={() =>
                          setAddDayModal({
                            edit: day,
                          })
                        }
                      >
                        <EditPenIcon />
                      </Icon>
                      <Icon
                        $pointer
                        onClick={() => setDeleteDayModal(day.date)}
                      >
                        <TrashIconRed />
                      </Icon>
                    </Div>
                  </Div>
                  <Separator $m="16px 0" />
                  <Div $flex $between $alignCenter>
                    <div>
                      <P $mb="4">Business</P>
                      <H5 $mb="0">
                        {convert24HourTo12Hour(day.business.startTime)} -{' '}
                        {convert24HourTo12Hour(day.business.endTime)}
                      </H5>
                    </div>
                  </Div>
                  <Separator $m="16px 0" />
                  <Div $flex $between $alignCenter>
                    <div>
                      <P $mb="4">Delivery</P>
                      <H5 $mb="0">
                        {convert24HourTo12Hour(day.delivery.startTime)} -{' '}
                        {convert24HourTo12Hour(day.delivery.endTime)}
                      </H5>
                    </div>
                  </Div>
                  <Separator $m="16px 0" />
                </div>
              );
            })}
          </>
        )}

        {holidayHours !== null && holidayHours.length < 1 && (
          <NoHolidayHours setAddDayModal={setAddDayModal} />
        )}
      </AnimatedDiv>

      <AddHolidayHours
        setLoading={setLoading}
        modalIsOpen={addDayModal}
        setModalIsOpen={setAddDayModal}
        selectedLocation={selectedLocation}
        selectedBrand={selectedBrand}
        getHolidayHours={getHolidayHours}
        provider={provider}
      />

      <DeleteHolidayHours
        setLoading={setLoading}
        modalIsOpen={deleteDayModal}
        setModalIsOpen={setDeleteDayModal}
        selectedLocation={selectedLocation}
        selectedBrand={selectedBrand}
        setHolidayHours={setHolidayHours}
        provider={provider}
      />
    </>
  );
};
