import {
  AdminDollarInput2,
  AdminRadioInput,
  AdminSelect,
  AdminSwitch,
  Button,
  Div,
  ErrorToast,
  H5,
  Modal,
  P,
  Separator,
  convert24HourTo12Hour,
  daysOfWeekFrom1,
  isTimeFromEarlier,
  times,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';

const timeFromOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      From
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

const timeToOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      To
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

export const FundingTipAmountAndAddMultiple = ({
  modalIsOpen,
  setModalIsOpen,
  setFundedTipSchedule,
  fundingTipOrigin,
  setFundingTipOrigin,
  brandType,
}) => {
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState([1]);
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');
  const [fundedTip, setFundedTip] = useState(0);

  const [toggle, setToggle] = useState(false);

  const handleValueTipFunding = useCallback((val) => {
    setFundedTip(val);
  }, []);

  const fundedTipSchedule = modalIsOpen;
  const edit = fundedTipSchedule.length > 0;

  useEffect(() => {
    if (edit) {
      setFundedTip(fundedTipSchedule?.[0]?.amount || 0);
    }
    setToggle(fundingTipOrigin || false);
  }, [modalIsOpen]);

  const toggleDaySelection = (day) => {
    setSelectedDayOfWeek(
      (prevSelected) =>
        prevSelected.includes(day)
          ? prevSelected.filter((d) => d !== day) // Remove if already selected
          : [...prevSelected, day] // Add if not selected
    );
  };

  const handleFinishAction = () => {
    setSelectedDayOfWeek([]);
    setTimeFrom('');
    setTimeTo('');
    setFundedTip(0);
    setModalIsOpen(false);
  };

  const handleSubmit = () => {
    if (!edit) {
      if (!timeFrom || !timeTo)
        return ErrorToast('Please fill in all information');
      if (!isTimeFromEarlier(timeFrom, timeTo))
        return ErrorToast('Time From must be earlier than Time To');
      if (selectedDayOfWeek.length === 0)
        return ErrorToast('Please select at least one day of the week');
    }

    if (!fundedTip) return ErrorToast('Please fill in all information');

    if (edit) {
      setFundedTipSchedule((prev) => {
        return prev.map((item) => ({
          ...item,
          amount: fundedTip,
        }));
      });
    } else {
      const multipleAdd = selectedDayOfWeek.map((day) => {
        return {
          amount: fundedTip,
          dayOfTheWeek: day,
          startTime: timeFrom,
          endTime: timeTo,
        };
      });
      setFundedTipSchedule(multipleAdd);
    }
    {
      brandType === 'vGrubs' && setFundingTipOrigin(toggle);
    }

    handleFinishAction();
  };

  console.log(brandType);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header={`${edit ? 'Edit' : 'Add'} Tip Funding`}
      textAlign="left"
      width="500px"
    >
      <Div $mb={!edit && '24'}>
        <AdminDollarInput2
          label="Tip Funding"
          onValueChange={handleValueTipFunding}
          value={fundedTip}
          $textCenter
        />
      </Div>

      {!edit && (
        <>
          <Separator />

          {daysOfWeekFrom1.map(({ name, value }) => (
            <div key={value}>
              <AdminRadioInput
                label={name}
                checked={selectedDayOfWeek.includes(value)}
                onClick={() => toggleDaySelection(value)}
              />
              <Separator />
            </div>
          ))}
          <H5 $mt="32" $mb="16" $center>
            Select Hours
          </H5>
          <Div $flex $gap="24px">
            <AdminSelect
              options={timeFromOptions}
              value={timeFrom}
              onChange={({ target }) => setTimeFrom(target.value)}
              placeholder="From"
              $textCenter
              label="Time From"
            />
            <AdminSelect
              options={timeToOptions}
              value={timeTo}
              onChange={({ target }) => setTimeTo(target.value)}
              placeholder="To"
              $textCenter
              label="Time To"
            />
          </Div>
        </>
      )}

      {brandType === 'vGrubs' && (
        <Div $flex $alignCenter $between $mt="32">
          <P $m="0">Apply Tip Funding Settings to All vGrubs Stores?</P>

          <AdminSwitch
            checked={toggle}
            onChange={() => setToggle((prev) => !prev)}
          />
        </Div>
      )}

      <Button $selected $w="100%" $mt="32" onClick={handleSubmit}>
        Confirm
      </Button>
      <Button $selected $gray $w="100%" $mt="16" onClick={handleFinishAction}>
        Cancel
      </Button>
    </Modal>
  );
};
