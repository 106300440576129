import {
  Chevron,
  Div,
  EditPenIcon,
  H5,
  Icon,
  P,
  Separator,
  TrashIconRed,
  centsToDollars,
  formatter,
} from '@vgrubs/components';
import { useRef, useState } from 'react';
import { Collapsible } from '../../MenyStyled';

export const ModifierBoxComponent = ({ option, handleRemoveOption, i }) => {
  return (
    <Div
      $box
      $p="12px 16px"
      $flex
      $alignCenter
      $between
      // $width="calc(50% - 8px)"
    >
      <div>
        <H5 $left $mb="4">
          {option.name}
        </H5>
        <P $left $m="0">
          {formatter.format(centsToDollars(option.price))}
        </P>
      </div>
      <Icon $pointer onClick={() => handleRemoveOption(i)}>
        <TrashIconRed />
      </Icon>
    </Div>
  );
};

export const ModifierGroupComponent = ({ item, onClickDelete, onClick }) => {
  const ref = useRef(null);
  const elementHeight = ref?.current?.clientHeight;
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Div
        $box
        $p="16px 24px"
        $flex
        $alignCenter
        $between
        $mb="8"
        // $background={expanded && lightTheme.primary}
      >
        <Div
          $flex
          $alignCenter
          $gap="8px"
          onClick={() => setExpanded((prev) => !prev)}
          $width="100%"
          $pointer
        >
          <P
            $bold
            $m="0"
            //   $color={expanded && lightTheme.white}
          >
            {item.name}
          </P>
        </Div>
        <Div $flex $alignCenter $between $gap="24px" $gapSM="12px">
          <Icon $shadow $pointer onClick={() => onClick(item)}>
            <EditPenIcon />
          </Icon>

          <Icon $pointer onClick={() => onClickDelete(item.id)}>
            <TrashIconRed />
          </Icon>
          <Icon
            $pointer
            $rotate={expanded ? '360deg' : '180deg'}
            onClick={() => setExpanded((prev) => !prev)}
            // color={expanded ? lightTheme.white : lightTheme.darkGray}
          >
            <Chevron />
          </Icon>
        </Div>
      </Div>
      <Collapsible expanded={expanded} elementHeight={elementHeight}>
        <div ref={ref}>
          {item.children.map((item, i) => {
            return <ModifierComponent item={item} key={i} />;
          })}
          {item.children.length === 0 ? (
            <P $m="0" $center>
              No modifiers
            </P>
          ) : null}
        </div>
      </Collapsible>
    </>
  );
};

export const ModifierComponent = ({ item }) => {
  return (
    <>
      <Separator />
      <Div $p="16px 20px" $flex $between $alignCenter>
        <P $m="0">{item.name}</P>
        <P $m="0">{formatter.format(centsToDollars(item.price))}</P>
      </Div>
    </>
  );
};
