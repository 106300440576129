import {
  AdminInput,
  Button,
  Div,
  H5,
  Icon,
  Modal,
  PlusIcon,
  lightTheme,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { AvailabilityComponent } from './AvailabilityComponent';

export const AddCategoryModal = ({
  addModal,
  setAddModal,
  availability,
  setAvailability,
  setAddAvailabilityModal,
  handleAddCategory,
  handleEditCategory,
  edit,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const data = addModal;

  useEffect(() => {
    if (edit) {
      setName(data?.name || '');
      setDescription(data?.description || '');
      setAvailability(data?.availability || []);
    }
  }, [data]);

  const emptyForm = () => {
    setName('');
    setDescription('');
    setAvailability([]);
  };

  const handleSubmit = () => {
    if (edit) {
      handleEditCategory(
        name,
        description,
        availability.map(({ id }) => id),
        emptyForm
      );
    } else {
      handleAddCategory(
        name,
        description,
        availability.map(({ id }) => id),
        emptyForm
      );
    }
  };

  return (
    <Modal
      isOpen={addModal}
      setIsOpen={setAddModal}
      header={edit ? 'Edit Category' : 'Add Category'}
      logo={false}
      footer={
        <>
          <Button $selected $w="100%" onClick={handleSubmit}>
            Confirm
          </Button>
          <Button
            $mt="16"
            $selected
            $gray
            $w="100%"
            onClick={() => setAddModal(false)}
          >
            Cancel
          </Button>
        </>
      }
      $numOfFloatingButtons={2}
    >
      <Div $flex $column $gap="24px">
        <AdminInput
          placeholder="Enter here"
          label="Category Name"
          value={name}
          onChange={({ target }) => setName(target.value)}
        />

        <AdminInput
          placeholder="Enter here"
          label="Description"
          type="textarea"
          value={description}
          onChange={({ target }) => setDescription(target.value)}
        />

        {availability?.length > 0 ? (
          <>
            <Div $flex $alignCenter $between>
              <H5 $mb="0">Availability</H5>
              <Icon $pointer onClick={() => setAddAvailabilityModal(true)}>
                <PlusIcon />
              </Icon>
            </Div>

            <div>
              {availability.map((item, i) => (
                <AvailabilityComponent
                  item={item}
                  key={i}
                  availability={availability}
                  setAvailability={setAvailability}
                />
              ))}
            </div>
          </>
        ) : (
          <Div
            $flex
            $center
            $gap="8px"
            $pointer
            onClick={() => setAddAvailabilityModal(true)}
          >
            <Icon>
              <PlusIcon />
            </Icon>

            <H5 $m="0" $color={lightTheme.primary}>
              Add Availability
            </H5>
          </Div>
        )}

        {/* <Separator />
          <Div $flex $gap="16px">
            <Button $gray $w="100%">
              Pause
            </Button>
            <Button $w="100%">Delete</Button>
          </Div> */}
      </Div>
    </Modal>
  );
};
