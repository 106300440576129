import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const PageWrapper = styled.div`
  @media (min-width: 3000px) {
    max-width: 1940px;
  }

  @media (min-width: 3500px) {
    max-width: 80%;
    ${({ home }) => !home && `padding-top: 300px;`}
  }

  width: 100%;
  padding: 0 100px;
  max-width: 1640px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 150px;
  min-height: calc(100vh + 50px);

  ${({ centeredContent }) =>
    centeredContent &&
    `
   display: flex;
  align-items: center;
  `}
  ${({ home }) => !home && `padding-top: 150px;`}

 

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: 0 30px;
    padding-bottom: 100px;
    ${({ home }) => !home && `padding-top: 100px;`}
  }

  @media (max-width: 800px) {
    padding: 0 30px;
    padding-bottom: 100px;
    ${({ home }) => !home && `padding-top: 100px;`}
  }
`;

export const WrapperCenterSimpler = styled.div`
  width: 100%;
  padding: 75px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : '100px')};

  @media (min-width: 3500px) {
    padding: 150px 0;
    gap: ${({ gapLG }) => (gapLG ? gapLG : '200px')};
  }

  @media (max-width: 1200px) {
    gap: 50px;
  }

  @media (max-width: 800px) {
    padding: 30px 0;
    gap: 50px;
    flex-direction: column;
    ${({ reverse }) => reverse && ' flex-direction: column-reverse;'}
  }
`;

export const WrapperCenterSimplerHome = styled.div`
  position: relative;
  padding: 75px 0;
  display: flex;
  align-items: center;
  /* gap: 50px; */
  min-height: 100vh;
  padding: 0;

  @media (max-width: 1200px) {
  }

  @media (max-width: 800px) {
    gap: 30px;
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media (max-height: 700px) {
    padding-top: 50px;
  }
  @media (max-width: 1000px) and (orientation: landscape) {
    padding-top: 80px;
    flex-direction: row;
  }
`;

export const Div = styled(motion.div)`
  ${({ $box }) =>
    $box &&
    `
   background: #ffffff;
  border: .5px solid #e3e3e3;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`}

  ${({ $outlineBox, theme }) =>
    $outlineBox &&
    `
  border: .5px solid ${theme.lightGray};
  border-radius: 20px;
`}

  ${({ $background }) => $background && `background: ${$background};`};
  ${({ $borderColor }) => $borderColor && `border-color: ${$borderColor};`};
  ${({ $borderRadius }) => $borderRadius && `border-radius: ${$borderRadius};`};

  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt}px;
  margin-bottom: ${(props) => props.$mb}px;
  margin-left: ${(props) => props.$ml}px;
  margin-right: ${(props) => props.$mr}px;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};

  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}`};
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}`};
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight}`};
  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight}`};

  padding: ${(props) => props.$p};

  ${({ $inlineFlex }) => $inlineFlex && 'display: inline-flex'};
  ${({ $flex }) => $flex && 'display: flex'};
  ${({ $flexNone }) => $flexNone && 'flex: none'};
  ${({ $center }) => $center && 'align-items: center; justify-content:center;'};
  ${({ $between }) => $between && 'justify-content: space-between;'};
  ${({ $gap, $flex, $grid }) => ($flex || $grid) && $gap && `gap:${$gap}`};
  ${({ $pointer }) => $pointer && 'cursor: pointer'};
  ${({ $relative }) => $relative && 'position: relative'};
  ${({ $absolute }) => $absolute && 'position: absolute'};
  ${({ $alignCenter }) => $alignCenter && 'align-items: center'};
  ${({ $alignEnd }) => $alignEnd && 'align-items: flex-end'};
  ${({ $justifyEnd }) => $justifyEnd && 'justify-content: flex-end'};
  ${({ $justifyCenter }) => $justifyCenter && 'justify-content: center'};
  ${({ $textAlignCenter }) => $textAlignCenter && 'text-align: center'};
  ${({ $textAlignRight }) => $textAlignRight && 'text-align: right'};
  ${({ $textAlignLeft }) => $textAlignLeft && 'text-align: left'};
  ${({ $wrap }) => $wrap && 'flex-wrap: wrap'};
  ${({ $borderBottom, theme }) =>
    $borderBottom && `border-bottom ${theme.borders.border1}`};

  ${({ $borderTop, theme }) =>
    $borderTop && `border-top ${theme.borders.border1}`};

  ${({ $borderLeft, theme }) =>
    $borderLeft && `border-left ${theme.borders.border1}`};

  ${({ $borderRight, theme }) =>
    $borderRight && `border-right ${theme.borders.border1}`};

  ${({ $grid }) => $grid && 'display: grid'};
  ${({ $grid, $gridTemplateColumns }) =>
    $grid &&
    $gridTemplateColumns &&
    `grid-template-columns:${$gridTemplateColumns}`};

  ${({ $column }) => $column && 'flex-direction: column'};

  @media (min-width: 3500px) {
    height: ${(props) => props.$heightLG};
    width: ${(props) => props.$widthLG};
    ${({ $maxWidthLG }) => $maxWidthLG && `max-width: ${$maxWidthLG}`};
    padding: ${(props) => props.$pLG};
    max-width: ${(props) => props.$maxWidthLG};
    min-width: ${(props) => props.$minWidthLG};
    ${({ $gapLG }) => $gapLG && `gap: ${$gapLG}`};
    margin: ${(props) => props.$mLG};
    margin-top: ${(props) => props.$mtLG}px;
    margin-bottom: ${(props) => props.$mbLG}px;
    margin-left: ${(props) => props.$mlLG}px;
    margin-right: ${(props) => props.$mrLG}px;
  }

  @media (max-width: 1450px) {
    width: ${(props) => props.$widthMD};
    padding: ${(props) => props.$pMD};
    ${({ $gapMD }) => $gapMD && `gap: ${$gapMD}`};
    ${({ $columnMD }) => $columnMD && 'flex-direction: column'};
    ${({ $reverseMD }) => $reverseMD && 'flex-direction: column-reverse'};
  }

  /* @media (max-width: 1000px) and (orientation: landscape) {
    height: auto;
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    min-width: ${(props) => props.$minWidthSM};

    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
    $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};
  } */

  @media (max-width: 800px) {
    height: auto;
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    min-width: ${(props) => props.$minWidthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
      $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};
  }

  ${({ $desktop }) =>
    $desktop &&
    `
    @media (max-width: 800px) {
    display: none;
  }

  `};

  ${({ $mobile }) =>
    $mobile &&
    `
    @media (min-width: 800px) {
    display: none;
  }
  
  `};
`;

export const ScrollableModalDiv = styled.div`
  overflow-y: scroll;
  max-height: 220px;

  margin: 0 -32px;
  padding: 0 32px 0px 32px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 800px) {
    margin: 0 -24px;
    padding: 0 24px 24px 24px;
    max-height: 150px;
  }
`;

export const MotionDiv = styled(motion.div)``;

export const Img = styled.img`
  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt}px;
  margin-bottom: ${(props) => props.$mb}px;
  margin-left: ${(props) => props.$ml}px;
  margin-right: ${(props) => props.$mr}px;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
  max-height: ${(props) => props.$maxHeight};
  border: ${(props) => props.$border};
  border-radius: ${(props) => props.$borderRadius};

  @media (max-width: 800px) {
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    height: ${(props) => props.$heightSM};
    max-width: ${(props) => props.$maxWidthSM};
    max-height: ${(props) => props.$maxHeightSM};
  }
`;

export const SplashScreen = styled(motion.div)`
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.primary};
`;

export const Icon = styled.div`
  margin: ${(props) => props.$m};
  display: ${({ display }) => (display ? display : 'flex')};

  align-items: center;

  svg {
    ${({ $verticalAlignBottom }) =>
      $verticalAlignBottom && `vertical-align: middle;`};
  }

  ${({ $shadow, theme }) =>
    $shadow &&
    `
    box-shadow:  ${theme.shadows.shadow1};
    border-radius: 50%;
  `}

  ${({ $background }) =>
    $background &&
    `
      background-color: ${$background};
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
  `}

  ${({ $rotate }) =>
    $rotate && `rotate: ${$rotate}; transition: rotate 0.3s ease;`}

  ${({ $pointer }) => $pointer && `cursor: pointer;`}
  ${({ color }) =>
    color &&
    `
      svg {
        path {
          fill: ${color};
        }
      }
  `}

  ${({ hoverColor, theme }) =>
    hoverColor &&
    `
      svg {
        path {
          transition: ${theme.transitions.ease300ms};
        }
        :hover {
          path {
            fill: ${hoverColor};
          }
        }
      }
  `}

${({ $size }) => `

svg {
  width: ${$size}px;
  height: ${$size}px;

}

`}

@media (max-width: 800px) {
    ${({ $sizeSM }) => `

    svg {
      width: ${$sizeSM}px;
      height: ${$sizeSM}px;

    }
  
  `}
  }

  @media (min-width: 3500px) {
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

export const SvgInlineWrapper = styled.span`
  svg {
    vertical-align: middle;
  }
`;

export const Calculator = styled.div`
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  gap: 24px;
  margin-bottom: 50px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
`;

export const CalculatorBottom = styled.div`
  background: ${({ theme }) => theme.green};
  width: 100%;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const StripLeft = styled.div`
  width: 40px;
  height: 400px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 20px 0 0 20px;
  border-right: 0;

  @media (min-width: 3500px) {
    width: 80px;
    height: 800px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const StripRight = styled.div`
  width: 40px;
  height: 400px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 0 20px 20px 0;
  border-left: 0;

  @media (min-width: 3500px) {
    width: 80px;
    height: 800px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ContentStyled = styled.div`
  width: 100%;
  padding: 0 50px 50px 50px;
  overflow: hidden;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 800px) {
    padding: 0 30px 30px 30px;
  }
`;

export const HeaderIconWrapperStyled = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  cursor: pointer;

  ${({ $opened }) => $opened && `z-index: 10;`}
`;

export const HeaderIconWrapper = ({ children, onClick, $opened }) => {
  return (
    <Div
      $relative
      $width="48px"
      $height="48px"
      $widthSM="48px"
      $heightSM="48px"
    >
      <HeaderIconWrapperStyled onClick={onClick} $opened={$opened}>
        {children}
      </HeaderIconWrapperStyled>
    </Div>
  );
};

export const IconButtonStyled = styled.div`
  width: ${({ $size }) => ($size ? $size : '48px')};
  height: ${({ $size }) => ($size ? $size : '48px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, $color }) => ($color ? $color : theme.white)};
  border-radius: 50%;
  cursor: pointer;
  flex: none;
`;

export const OverlayTransparent = styled(motion.div)`
  /* background-color: ${({ theme }) => theme.darkGray}4D; */
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

export const Overlay = styled(motion.div)`
  background-color: ${({ theme }) => theme.darkGray}4D;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

export const overlayAnimationVariant = {
  initial: {
    opacity: 0,
    transition: {
      type: 'ease',
      duration: 0.1,
    },
  },
  animate: {
    opacity: 1,
  },

  exit: {
    opacity: 0,
    transition: {
      type: 'ease',
      duration: 0.1,
    },
  },
};

export const ModalIconStyle = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UnderlineHoverLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

export const AbsoluteAddressDropdown = styled(Div)`
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.white};
  z-index: 200;
  max-height: 200px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  > div {
    :hover {
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
    }
  }
`;
