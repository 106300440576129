import React, { useCallback } from 'react';

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput2 = ({
  className = '',
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  style = {},
  value,
}) => {
  const isValidNumber = (num) =>
    num === 0 ||
    num === -0 ||
    (Number.isFinite(num) &&
      !Number.isNaN(num) &&
      Math.abs(num) === Math.trunc(Math.abs(num)));

  if (typeof value !== 'number' || !isValidNumber(value)) {
    throw new Error(`invalid value property`);
  }

  const handleKeyDown = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onValueChange(isNaN(nextValue) ? 0 : nextValue);
    },
    [max, onValueChange, value]
  );

  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);

  const valueDisplay = isNaN(value)
    ? '$0.00'
    : (value / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return (
    <input
      className={className}
      inputMode="numeric"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      style={style}
      value={valueDisplay}
    />
  );
};

export default CurrencyInput2;
