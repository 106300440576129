import {
  ADMIN_LOGIN,
  AdminSpinner,
  Button,
  DeleteIcon,
  Div,
  ErrorToast,
  H3,
  LogoutIcon,
  Modal,
  P,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const DeleteAccountModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function handleDeleteAccount() {
    try {
      setLoading(true);
      const { data } = await api.delete(`/v1/users/notification-status`);

      SuccessToast(data.message);
      navigate(ADMIN_LOGIN);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <AdminSpinner />}
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        logo={false}
        exitButton={false}
      >
        <Div $mb="16">
          <DeleteIcon />
        </Div>

        <H3 $m="10px" $center>
          Delete Account
        </H3>
        <P $mb="24" $center>
          Deleting your account is permanent, erases everything, and can't be
          undone. Are you sure?
        </P>

        <Div $flex $gap="16px">
          <Button onClick={handleDeleteAccount} $w="100%" $selected>
            Yes
          </Button>
          <Button $w="100%" onClick={() => setIsOpen(false)} $gray $selected>
            No
          </Button>
        </Div>
      </Modal>
    </>
  );
};
