import {
  A,
  Button,
  Div,
  H2,
  H3,
  Img,
  P,
  getStartedVDrive,
} from '@vgrubs/components';

export const GetStartedVDrive = ({ setStep }) => {
  return (
    <>
      <Div $flex $column $alignCenter>
        <Img $width="200px" $mb="40" src={getStartedVDrive} />

        <H3 $center $m="8px 0 24px 0">
          Get Started with vDrive
        </H3>

        <P $center>
          Activate client’s vDrive without any obligation in 3 simple steps and
          the client will receive a <span>free $10</span> balance that he can
          use to deliver orders.
          <br />
          <br />
          Once you are ready, click on Get Started below to add the card on file
          for client’s deliveries.
        </P>

        <Button
          $selected
          $w="100%"
          $wSM="100%"
          $mt="24"
          onClick={() => setStep(1)}
        >
          Get Started
        </Button>

        <a
          href="https://vgrubs.com/vdrive"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '100%' }}
        >
          <Button
            $selected
            $w="100%"
            $wSM="100%"
            $mt="24"
            // onClick={handleSubmitStripe}
            $darkBlue
          >
            Learn More About vDrive
          </Button>
        </a>
      </Div>
    </>
  );
};
