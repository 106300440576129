import styled from 'styled-components';

export const StatusMark = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 44px;
  width: 6px;
  border-radius: 0 10px 10px 0;
  ${({ $color }) => $color && `background: ${$color}`};
`;

export const OrderImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: none;
  ${({ $cancelled, theme }) => $cancelled && `background: ${theme.white}`};
  ${({ $cancelled }) => $cancelled && `margin-right: 24px;`};

  border-radius: 10px;
  /* padding: 0 20px; */
  width: 105px;
  gap: 8px;

  @media (max-width: 800px) {
    width: 70px;
    ${({ $cancelled }) => $cancelled && `margin-right: 16px;`};
    /* padding: 0 12px; */
  }
`;

export const OrderRecoveryStatusBadge = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px 10px 2px 5px;
  border-radius: 100px;
  background-color: ${({ $color }) => $color};
`;
