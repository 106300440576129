import {
  Div,
  H3,
  H5,
  Icon,
  P,
  VerticalSeparator,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import { IconIncome, IconWithdrawal } from './TransactonsStyled';
import { TransactionDateComponent } from './TransactionComponents';
import { useState } from 'react';
import { IncomeDetailModal } from './DetailModals/IncomeDetailModal';
import { WithdrawalDetailModal } from './DetailModals/WithdrawalDetailModal';

export const TransactionsVGrubs = ({
  transactions,
  sumTransactions,
  type,
  setType,
}) => {
  const [incomeModal, setIncomeModal] = useState(false);
  const [withdrawalModal, setWithdrawalModal] = useState(false);

  return (
    <div>
      <Div $flex $columnSM $gap="20px" $gapSM="16px" $mb="32">
        <Div
          $flex
          $alignCenter
          $pointer
          $p="24px"
          $width="50%"
          $widthSM="100%"
          $box={type === 'Incomes'}
          $outlineBox={type !== 'Incomes'}
          onClick={() => setType('Incomes')}
        >
          <Icon>
            <IconIncome />
          </Icon>
          <VerticalSeparator
            $m="0 32px"
            $mSM="0 24px"
            $h="57px"
            $color={lightTheme.lightGray}
          />
          <Div>
            <H5 $m="0">Total Income</H5>
            <H3 $m="0" $color={lightTheme.green}>
              {formatter.format(centsToDollars(sumTransactions?.vGrubsIncome))}
            </H3>
            <P $m="0">
              Last Income:{' '}
              {formatter.format(
                centsToDollars(sumTransactions?.lastVGrubsIncome)
              ) || 0}
            </P>
          </Div>
        </Div>
        <Div
          $flex
          $alignCenter
          $pointer
          $p="24px"
          $width="50%"
          $widthSM="100%"
          $box={type === 'Withdrawals'}
          $outlineBox={type !== 'Withdrawals'}
          onClick={() => setType('Withdrawals')}
        >
          <Icon>
            <IconWithdrawal />
          </Icon>
          <VerticalSeparator
            $m="0 32px"
            $mSM="0 24px"
            $h="57px"
            $color={lightTheme.lightGray}
          />
          <Div>
            <H5 $m="0">Withdrawals</H5>
            <H3 $m="0">
              {' '}
              {formatter.format(
                centsToDollars(sumTransactions?.vGrubsWithdrawals)
              )}
            </H3>
            <P $m="0">
              Last Withdrawal:{' '}
              {formatter.format(
                centsToDollars(sumTransactions?.lastVGrubsWithdrawal)
              )}
            </P>
          </Div>
        </Div>
      </Div>
      {transactions?.map((item, i) => {
        return (
          <TransactionDateComponent
            key={i}
            index={i}
            item={item}
            setIncomeModal={setIncomeModal}
            setWithdrawalModal={setWithdrawalModal}
          />
        );
      })}
      <IncomeDetailModal
        modalIsOpen={incomeModal}
        setModalIsOpen={setIncomeModal}
      />
      <WithdrawalDetailModal
        modalIsOpen={withdrawalModal}
        setModalIsOpen={setWithdrawalModal}
      />
    </div>
  );
};
