import { Button, H3, Modal, P } from '@vgrubs/components';

export const WhatIsThisModal = ({ modalIsOpen, setModalIsOpen }) => {
  return (
    <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} vDriveLogo>
      <H3>What is this?</H3>

      <P>
        Introducing vDrive - designed to simplify the way you assign drivers to
        your delivery orders, and all on demand! Just flip the switch and cut
        your delivery platform's percentage fees in nearly half with our pay-
        per-delivery solution, perfectly integrated and automated.
      </P>

      <Button $w="100%" $selected onClick={() => setModalIsOpen(false)}>
        Okay
      </Button>
    </Modal>
  );
};
