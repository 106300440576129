import {
  Div,
  Dropdown,
  OverlayTransparent,
  P,
  lightTheme,
  overlayAnimationVariant,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { AnimatePresence } from 'framer-motion';
import { DateTime } from 'luxon';
import { useState } from 'react';
import styled from 'styled-components';
import {
  AdminDatePickerStyled,
  FlatpickrStyled,
} from './AdminDatePickerStyled';

export const AdminDatePickerInput2 = ({
  click,
  centerFixed,
  minDate,
  maxDate,
  ...props
}) => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <AnimatePresence>
      <Div $relative>
        {props.label && (
          <P $mb="8" $ml="24" $mr="24" $bold $center={props.$textCenter}>
            {props.label}{' '}
          </P>
        )}

        <Dropdown
          isOpen={dropdown}
          setIsOpen={setDropdown}
          spacing={32}
          triggerComponent={
            <AdminInputStyled
              onClick={() => setDropdown(true)}
              {...props}
              style={{ color: !props?.value?.[0] && lightTheme.lightGray }}
            >
              {props?.value?.[0]
                ? DateTime.fromJSDate(props?.value?.[0]).toFormat('MM/dd/yyyy')
                : 'MM/DD/YYYY'}
              {/* <CalendarIconRed /> */}
            </AdminInputStyled>
          }
        >
          <FlatpickrStyled>
            <AdminDatePickerStyled
              placeholder="MM/DD/YYYY"
              {...props}
              onChange={(date) => {
                props?.onChange(date);
                setDropdown(false);
              }}
              options={{
                mode: 'single',
                dateFormat: 'M/d/Y',
                inline: true,
                locale: { rangeSeparator: ' - ' },
                maxDate: maxDate,
                minDate: minDate,
              }}
              className="form-control"
            />
          </FlatpickrStyled>
        </Dropdown>
      </Div>

      {dropdown && (
        <OverlayTransparent
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};

const AdminInputStyled = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.darkGray}80;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 45px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: none;

  ${({ shadowDesign, theme }) =>
    shadowDesign &&
    `
  background: ${theme.white};
  border: 1px solid #e3e3e3;
  box-shadow: ${theme.shadows.shadow1};
  `}

  ${({ $textCenter }) => $textCenter && `text-align: center`};

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.primary};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;
