import {
  Div,
  GrubconLogoSimbol,
  H3,
  H5,
  Img,
  P,
  Separator,
  centsToDollars,
  formatter,
  lightTheme,
  providers,
  renderProviderImage,
} from '@vgrubs/components';
import styled from 'styled-components';

export const LocationComponent = ({ title, data, setAllProvidersModal }) => {
  const filterProvider = (provider) => {
    return data?.find((item) => item.provider === provider);
  };

  const allProviders = [
    {
      provider: providers.UBER_EATS,
      value: filterProvider(providers.UBER_EATS)?.value || 0,
    },
    {
      provider: providers.GRUB_HUB,
      value:
        (filterProvider(providers.GRUB_HUB)?.value || 0) +
        (filterProvider(providers.GRUBHUBPOS)?.value || 0),
    },
    {
      provider: providers.DOORDASH,
      value:
        (filterProvider(providers.DOORDASH)?.value || 0) +
        (filterProvider(providers.DOORDASHPOS)?.value || 0),
    },
    {
      provider: providers.CHOW_NOW,
      value: filterProvider(providers.CHOW_NOW)?.value || 0,
    },
    {
      provider: providers.GLORIA_FOOD,
      value: filterProvider(providers.GLORIA_FOOD)?.value || 0,
    },
    {
      provider: providers.DELIVERY,
      value: filterProvider(providers.DELIVERY)?.value || 0,
    },
    {
      provider: providers.EAT_STREET,
      value: filterProvider(providers.EAT_STREET)?.value || 0,
    },
    {
      provider: providers.MENUFY,
      value: filterProvider(providers.MENUFY)?.value || 0,
    },
    {
      provider: providers.LOCAL_FOR_YOU,
      value: filterProvider(providers.LOCAL_FOR_YOU)?.value || 0,
    },
  ];

  const renderValue = (value) => {
    switch (title) {
      case 'Total Sales':
        return formatter.format(centsToDollars(value));
      case 'Total Orders':
        return value;
      case 'Total Taxes':
        return formatter.format(centsToDollars(value));
      case 'Total Canceled':
        return value;
      case 'Total Virtual':
        return formatter.format(centsToDollars(value));
      case 'Total Recovered':
        return formatter.format(centsToDollars(value));
      case 'Payout to Partner':
        return formatter.format(centsToDollars(value));

      default:
        break;
    }
  };

  const renderSumColor = () => {
    switch (title) {
      case 'Total Canceled':
        return lightTheme.primary;

      default:
        return lightTheme.green;
    }
  };

  const sum = allProviders?.reduce((result, item) => result + item.value, 0);

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue || 0;
  }

  const GrubLogo = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
  `;

  return (
    <Div $box $p="20px" $relative>
      {title === 'Total Recovered' && (
        <GrubLogo>
          <GrubconLogoSimbol />
        </GrubLogo>
      )}

      <H5 $mb="12">{title}</H5>
      <H3 $mb="20" $color={renderSumColor()}>
        {renderValue(sum)}
      </H3>
      {allProviders?.map(({ provider, value }, i) => {
        if (i < 3)
          return (
            <Div key={i}>
              <Div $flex $between $alignCenter $height="50px">
                <Img src={renderProviderImage(provider)} $width="60px" />
                <Div $flex $column>
                  <P $m="0" $right>
                    {title !== 'Total Canceled' && (
                      <>
                        {percentage(value, sum).toFixed(0)}%
                        <br />
                      </>
                    )}

                    {renderValue(value)}
                  </P>
                </Div>
              </Div>
              {i + 1 !== allProviders?.length && <Separator $m="5px 0" />}
            </Div>
          );
      })}

      <Div $pointer onClick={() => setAllProvidersModal({ title, data })}>
        <H5 $m="0" $mt="20" $right $color={lightTheme.primary} $pointer>
          See All
        </H5>
      </Div>
    </Div>
  );
};
