import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const IconWrap = styled.div`
  margin-right: 16px;
`;

export const IconMessageWrap = styled.div`
  display: flex;
  align-items: center;

  h5 {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 4px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.darkGray + theme.transparency.t50};
    margin: 0;
  }
`;

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    max-width: 400px;
    width: calc(100% - 40px);
    top: 20px;
    left: 50%;
    transform: translateX(-50%);

    .Toastify__toast {
      box-shadow: ${({ theme }) => theme.shadows.shadow1};
      border-radius: 16px;
      margin-bottom: 16px;
      padding: 6px 16px 6px 10px;
      display: flex;
      align-items: center;
    }

    .Toastify__toast-body {
      margin: none !important;
      padding: none !important;
    }

    .Toastify__progress-bar {
    }
    .Toastify__close-button {
      align-self: center;
      margin-right: 8px;
    }
    .Toastify__close-button > svg {
      height: 22px;
      width: 22px;
      /* fill: #c4c4c4; */
    }
  }

  @keyframes move-in {
    0% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .move-in {
    animation: move-in 0.4s ease-out both;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @keyframes move-out {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-200px);
    }
  }

  .move-out {
    animation: move-out 0.3s ease-in both;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;
