import {
  ADMIN_BLOGS,
  ADMIN_BRANDS,
  ADMIN_CONCIERGE,
  ADMIN_DASHBOARD,
  ADMIN_LOCATIONS,
  ADMIN_PAYOUTS,
  ADMIN_PRIVACY_POLICY,
  ADMIN_TERMS_AND_CONDITIONS,
  ADMIN_USERS_AND_ACCESS,
  ADMIN_VERIFICATIONS,
  ADMIN_WEBSITES,
  Div,
  H3,
  H5,
  MenuIcon,
  useSidebarState,
} from '@vgrubs/components';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const AdminHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  gap: 50px;
  border-bottom: ${({ theme }) => theme.borders.border1};

  @media (max-width: 800px) {
    height: 60px;
  }
`;

export const AdminHeader = ({ children, title }) => {
  const location = useLocation();

  const { sidebarIsOpen, setSidebarIsOpen } = useSidebarState((state) => state);

  const renderTitle = () => {
    const WEBSITES = 'Websites';
    const DASHBOARD = 'Dashboard';
    const VERIFICATIONS = 'Verifications';
    const USERS_AND_ACCESS = 'Users & Access';
    const LOCATIONS = 'Locations';
    const BLOGS = 'Blogs';
    const BRANDS = 'Brands';
    const CONCIERGE = 'Concierge';
    const PRIVACY_POLICY = 'Privacy Policy';
    const TERMS_AND_CONDITIONS = 'Terms & Conditions';
    const PAYOUTS = 'Payouts';

    const pathnameStartWith = (path) =>
      location.pathname.startsWith(path) ? location.pathname : null;

    switch (location.pathname) {
      case pathnameStartWith(ADMIN_DASHBOARD):
        return DASHBOARD;
      case pathnameStartWith(ADMIN_VERIFICATIONS):
        return VERIFICATIONS;
      case pathnameStartWith(ADMIN_USERS_AND_ACCESS):
        return USERS_AND_ACCESS;
      case pathnameStartWith(ADMIN_LOCATIONS):
        return LOCATIONS;
      case pathnameStartWith(ADMIN_WEBSITES):
        return WEBSITES;
      case pathnameStartWith(ADMIN_BLOGS):
        return BLOGS;
      case pathnameStartWith(ADMIN_BRANDS):
        return BRANDS;
      case pathnameStartWith(ADMIN_CONCIERGE):
        return CONCIERGE;
      case pathnameStartWith(ADMIN_PRIVACY_POLICY):
        return PRIVACY_POLICY;
      case pathnameStartWith(ADMIN_TERMS_AND_CONDITIONS):
        return TERMS_AND_CONDITIONS;
      case pathnameStartWith(ADMIN_PAYOUTS):
        return PAYOUTS;

      default:
        break;
    }
  };

  return (
    <AdminHeaderStyled>
      <Div $flex $gap="16px" $alignCenter>
        <MenuIcon
          isOpen={sidebarIsOpen}
          onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
        />

        <Div $desktop>
          <H3 $m="0">
            <span>{title || renderTitle()}</span>
          </H3>
        </Div>
        <Div $mobile $mt="5">
          <H5 $m="0">
            <span>{title || renderTitle()}</span>
          </H5>
        </Div>
      </Div>

      <Div $relative>{children}</Div>
    </AdminHeaderStyled>
  );
};
