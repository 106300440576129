import {
  Button,
  CalendarIconRed,
  Div,
  DropdownMenuComponent,
  HeaderIconWrapper,
  Overlay,
  useDisableBodyScroll,
  overlayAnimationVariant,
  Dropdown,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import {
  AdminDatePickerStyled,
  FlatpickrStyled,
} from './AdminDatePickerStyled';

export const AdminDatePicker = ({ click, dateFrom, dateTo, ...props }) => {
  const [dropdown, setDropdown] = useState(false);

  // useDisableBodyScroll(dropdown);

  useEffect(() => {
    props.onChange(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  return (
    <AnimatePresence>
      <Div $relative>
        {/* <HeaderIconWrapper
          $opened={dropdown}
          onClick={() => setDropdown((prev) => !prev)}
        >
          <CalendarIconRed />
        </HeaderIconWrapper> */}
        <Dropdown
          isOpen={dropdown}
          setIsOpen={setDropdown}
          toLeft
          triggerComponent={
            <HeaderIconWrapper
              $opened={dropdown}
              onClick={() => setDropdown((prev) => !prev)}
            >
              <CalendarIconRed />
            </HeaderIconWrapper>
          }
        >
          <FlatpickrStyled>
            <AdminDatePickerStyled
              {...props}
              options={{
                mode: 'range',
                dateFormat: 'M d, Y',
                inline: true,
                locale: { rangeSeparator: ' - ' },
              }}
              className="form-control"
            />
          </FlatpickrStyled>

          <Button
            $selected
            $w="100%"
            $mt="24"
            $wSM="100%"
            onClick={() => {
              click();
              setDropdown(false);
            }}
          >
            Apply
          </Button>
        </Dropdown>
      </Div>

      {dropdown && (
        <Overlay
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};
