import { Button, Div, Modal } from '@vgrubs/components';
import { AvailabilityComponent } from './AvailabilityComponent';

export const AddAvailabilityModal = ({
  addAvailabilityModal,
  setAddAvailabilityModal,
  availability,
  setAvailability,
  menu,
}) => {
  return (
    <Modal
      isOpen={addAvailabilityModal}
      setIsOpen={setAddAvailabilityModal}
      header="Add Availability"
      logo={false}
      zIndex={100}
      footer={
        <>
          <Button
            $selected
            $w="100%"
            $mb="16"
            onClick={() => setAddAvailabilityModal(false)}
          >
            Confirm
          </Button>
          {/* <Button $selected $gray $w="100%">
            Add New Modifier Group
          </Button> */}
        </>
      }
      $numOfFloatingButtons={1}
    >
      <Div $flex $column $gap="24px">
        <div>
          {menu?.availability?.map((item, i) => (
            <AvailabilityComponent
              item={item}
              key={i}
              setAvailability={setAvailability}
              availability={availability}
              edit
            />
          ))}
        </div>
        {/* 
        <Div $flex $center $gap="8px" $pointer>
          <Icon>
            <PlusIcon />
          </Icon>

          <H5 $m="0" $color={lightTheme.primary}>
            Add Availability
          </H5>
        </Div> */}

        {/* <Div $flex $gap="16px">
          <Button $selected $gray $w="100%">
            Cancel
          </Button>
          <Button $selected $w="100%">
            Confirm
          </Button>
        </Div>
        <Separator />
        <Div $flex $gap="16px">
          <Button $gray $w="100%">
            Pause
          </Button>
          <Button $w="100%">Delete</Button>
        </Div> */}
      </Div>
    </Modal>
  );
};
