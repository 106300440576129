import {
  api,
  ADMIN_BLOGS,
  ADMIN_BLOGS_ADD,
  AdminGridItem,
  AdminSpinner,
  AnimatedDiv,
  Button,
  Div,
  ErrorToast,
  FormSeparator,
  Grid,
  H4,
  H5,
  INITIAL_PAGINATION_PAGE,
  PAGINATION_LIMIT,
  SuccessToast,
  formatDate,
  handleShowPaginationButton,
  removeObjectWithId,
  setDocumentTitle,
  useAuth,
  AdminHeader,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { BlogsDeleteModal } from './blogsDelete';

export const BlogsAdmin = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(INITIAL_PAGINATION_PAGE);

  const user = useAuth((state) => state.user);

  async function handleBlogDelete(id) {
    try {
      setLoading(true);
      const { data } = await api.delete(`/v1/blogs/${id}`);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error.response.data.message);
    } finally {
      const removedWebsite = (arr) => removeObjectWithId(arr, id);
      setBlogs((prev) => removedWebsite(prev));
      setDeleteModal(false);
      setLoading(false);
    }
  }

  async function getBlogs() {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/blogs?page=${page}&limit=${PAGINATION_LIMIT}`
      );
      setBlogs((prev) => [...prev, ...data.data]);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user) {
      getBlogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = () => {
    setPage((prev) => prev + 1);
  };

  const showPaginationButton = handleShowPaginationButton(blogs.length, page);

  setDocumentTitle('Blogs | vGrubs Admin');

  return (
    <>
      <AdminHeader />
      {loading && <AdminSpinner />}

      <Div $flex $between $alignCenter $m="20px 0">
        <H5 $m="0">All Blogs</H5>
        <Button onClick={() => navigate(ADMIN_BLOGS_ADD)}>Add New</Button>
      </Div>
      <Grid>
        {blogs.map(({ createdAt, title, imageUrl, id }, i) => (
          <AnimatedDiv key={i}>
            <AdminGridItem
              id={id}
              title={title}
              image={imageUrl}
              info={formatDate(createdAt)}
              onClickDelete={() => setDeleteModal(id)}
              onClickEdit={() => navigate(`${ADMIN_BLOGS}/${id}`)}
            />
          </AnimatedDiv>
        ))}
      </Grid>

      <FormSeparator m="20px 0" />

      {!loading && !blogs.length && <H4 $center>No data to show</H4>}

      <AnimatedDiv $flex $justifyCenter $mt="20" $height="45px">
        {showPaginationButton && (
          <Button onClick={handlePagination}>Load More</Button>
        )}
      </AnimatedDiv>

      <BlogsDeleteModal
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        handleBlogDelete={handleBlogDelete}
      />
    </>
  );
};
