import {
  AdminSpinner,
  AnimatedDiv,
  ErrorToast,
  H3,
  Img,
  P,
  api,
  lightTheme,
  restaurantImage,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { RestaurantComponent } from '../components/RestaurantComponent';
import { useInView } from 'react-intersection-observer';

export const RestaurantsSection = () => {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();

  async function getUnverifiedLocations() {
    if (!hasMore) return;

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/locations/unverified`, {
        params: {
          limit: 20,
          page: page,
        },
      });

      if (data.data[0]) {
        setPage((prevPage) => prevPage + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      setLocations((prevItems) => [...prevItems, ...data.data]);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getUnverifiedLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && locations.length > 0) {
      getUnverifiedLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {loading && <AdminSpinner />}

      {locations.map((location, i) => {
        return <RestaurantComponent key={i} location={location} />;
      })}

      <div ref={ref}></div>

      {!hasMore && locations.length === 0 && (
        <AnimatedDiv $height="70vh" $flex $column $alignCenter $justifyCenter>
          <Img src={restaurantImage} $width="240px" />
          <H3 $center $mb="16" $mt="20" $color={lightTheme.primary}>
            No Restaurants!
          </H3>
          <P $center>
            You currently do not have any <br /> restaurants to verify.
          </P>
        </AnimatedDiv>
      )}
    </>
  );
};
