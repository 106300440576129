import {
  AdminDollarInput,
  AdminSelect,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H3,
  Img,
  Modal,
  P,
  SuccessToast,
  api,
  moneyCoins,
  useOrders,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const recoveryStatus = {
  RECOVERED: 'recovered',
  ADJUSTMENT: 'adjustment',
  PENDING: 'pending',
  NOT_ELIGIBLE: 'notEligible',
  EVIDENCE_NEEDED: 'evidenceNeeded',
};

export const RecoveryModal = ({
  modalIsOpen,
  setModalIsOpen,
  recovery,
  setData,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    detailsModal,
    dataOrders,
    setDataOrders,
    dataHistory,
    setDataHistory,
  } = useOrders((state) => state);

  const id = detailsModal;

  const [selectedRecoveryStatus, setSelectedRecoveryStatus] = useState(
    recoveryStatus.PENDING
  );
  const [amount, setAmount] = useState('');

  const statuses = [
    { name: 'Adjustment', value: recoveryStatus.ADJUSTMENT },
    { name: 'Evidence Needed', value: recoveryStatus.EVIDENCE_NEEDED },
    { name: 'Not Eligible', value: recoveryStatus.NOT_ELIGIBLE },
    { name: 'Pending', value: recoveryStatus.PENDING },
    { name: 'Recovered', value: recoveryStatus.RECOVERED },
  ];

  useEffect(() => {
    if (recovery !== null) {
      setAmount(recovery?.amount);
      setSelectedRecoveryStatus(recovery?.status);
    }
  }, [recovery]);

  const typeOptions = (
    <optgroup label="Recovery Status">
      {statuses.map((r, i) => (
        <option value={r.value} key={i}>
          {r.name}
        </option>
      ))}
    </optgroup>
  );

  // Function to toggle the paid status of an order
  const changeRecoveryOnHistoryOrder = (recovery) => {
    const updatedOrders = dataHistory.map((orderItem) => {
      // Find the order to update
      if (orderItem.orders.some((order) => order.id === id)) {
        return {
          ...orderItem,
          orders: orderItem.orders.map((order) =>
            order.id === id ? { ...order, recovery: recovery } : order
          ),
        };
      }
      return orderItem;
    });
    // Update state with modified orders
    setDataHistory(updatedOrders);
  };

  async function postOrderRecovery() {
    const body = {
      amount: amount?.toFixed(2),
      status: selectedRecoveryStatus,
    };

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/orders/${id}/recovery`, body);
      setLoading(false);
      setData((prev) => {
        return { ...prev, recovery: body };
      });

      setDataOrders(
        dataOrders.map((o) => {
          if (o.id === id) {
            return { ...o, recovery: body };
          }
          return o;
        })
      );

      changeRecoveryOnHistoryOrder(body);

      SuccessToast(data.message);
      setModalIsOpen(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function removeOrderRecovery() {
    try {
      setLoading(true);
      const { data } = await api.delete(`/v1/orders/${id}/recovery`);
      setLoading(false);
      setData((prev) => {
        return { ...prev, recovery: null };
      });
      SuccessToast(data.message);
      setModalIsOpen(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <AdminSpinner />}

      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        zIndex={110}
      >
        <Img $width="180px" src={moneyCoins} alt="recovery" />
        <H3 $mt="24">Recovery</H3>
        <Div $flex $column $gap="24px">
          <AdminSelect
            value={selectedRecoveryStatus}
            options={typeOptions}
            onChange={({ target }) => setSelectedRecoveryStatus(target.value)}
            placeholder="Select location"
            label="Choose Recovery Status"
            chevron
          />

          <AdminDollarInput
            label="Enter Recovery Amount"
            prefix="$"
            value={amount / 100}
            onChange={(_, value) => setAmount(value * 100)}
          />
        </Div>

        {/* <Separator $m="24px" /> */}

        <P $m="0" $left $mt="24">
          Before you click on recovery button make sure that:
          <br />
          <br />
          1. Check if tablet is online (esper, wifi)
          <br />
          2. Is the merchant aware of this order <br />
          (ask why it was cancelled)
          <br />
          3. Check driver instructions for the store
        </P>

        <Button onClick={postOrderRecovery} $w="100%" $mt="24" $selected>
          Save
        </Button>
        {recovery !== null && (
          <Button
            onClick={removeOrderRecovery}
            $w="100%"
            $mt="16"
            $selected
            $gray
          >
            Remove From Recoveries
          </Button>
        )}
      </Modal>
    </>
  );
};
