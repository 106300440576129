import {
  ArrowRed,
  Disclaimer,
  Div,
  H3,
  H5,
  Icon,
  InfoIcon,
  ModalFullScreen,
  P,
  Separator,
  SvgInlineWrapper,
  centsToDollars,
  formatter,
  lightTheme,
  useOrders,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import {
  TextWithTooltipIcon,
  TitleWithTooltipIcon,
} from '../TransactonsStyled';
import { Tooltip } from 'react-tooltip';

export const TransactionDetailModal = ({ modalIsOpen, setModalIsOpen }) => {
  const transaction = modalIsOpen.object;
  const { setDetailsModal } = useOrders((state) => state);

  const isCancelledOrder = transaction?.paidCancellationFee;

  return (
    <>
      <ModalFullScreen
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        textAlign={'left'}
        // header={'Transaction Details'}
      >
        <H3>Transaction Details</H3>
        <P $m="0">Date & Time</P>
        <H5 $m="0">
          {DateTime.fromISO(transaction?.createdAt).toFormat(
            'MMM dd, yyyy - hh:mm a'
          )}
        </H5>
        <Separator $m="20px 0" />
        <Div $flex $alignCenter $between>
          <Div>
            <P $m="0">Order ID</P>
            <H5 $m="0">#{transaction?.orderId}</H5>
          </Div>
          <Div $pointer onClick={() => setDetailsModal(transaction?.orderId)}>
            <Icon>
              <ArrowRed />
            </Icon>
          </Div>
        </Div>
        <Separator $m="20px 0" />
        <P $m="0">Description</P>
        <H5 $m="0">
          {isCancelledOrder ? 'Stop vDriver Assignment' : 'vDrive Transaction'}
        </H5>
        <Separator $m="20px 0" />

        {isCancelledOrder && (
          <Div $box $p="16px">
            <TextWithTooltipIcon
              text="Cancellation Fee"
              tooltip={`Reason: ${transaction.cancellationReason}`}
            />
            <H5 $m="0" $color={lightTheme.primary}>
              {formatter.format(
                centsToDollars(transaction?.paidCancellationFee || 0)
              )}
            </H5>
          </Div>
        )}

        {!isCancelledOrder && (
          <>
            <Div $flex $columnSM $gap="40px" $gapSM="0">
              <Div $width="50%" $widthSM="100%">
                <TitleWithTooltipIcon
                  text={'Deposit Report'}
                  tooltip={
                    'A report of how much total additional revenue was deposited into your bank from delivery providers. Without vDrive, this additional revenue would not have been deposited.'
                  }
                />
                <TextWithTooltipIcon
                  text="Delivery Fee"
                  tooltip={`The delivery fee the diner paid, which is additional income deposited into the restaurant owner's account with vDrive.`}
                />
                <H5 $m="0" $color={lightTheme.green}>
                  {formatter.format(
                    centsToDollars(transaction?.deliveryFee || 0)
                  )}
                </H5>
                <Separator $m="20px 0" />
                <TextWithTooltipIcon
                  text="Delivery Tip"
                  tooltip={`The delivery tip to be forwarded to the delivery driver.`}
                />
                <H5 $m="0" $color={lightTheme.green}>
                  {formatter.format(
                    centsToDollars(transaction?.deliveryTip || 0)
                  )}
                </H5>
                <Separator $m="20px 0" />
                <P $m="0">Total Amount</P>
                <H5 $m="0" $color={lightTheme.green}>
                  {formatter.format(
                    centsToDollars(transaction?.depositTotal || 0)
                  )}
                </H5>
                <Separator $m="20px 0" />
              </Div>
              <Div $width="50%" $widthSM="100%">
                <H3>Deductions</H3>
                <P $m="0">Delivery Network Fee</P>
                <H5 $m="0" $color={lightTheme.primary}>
                  {formatter.format(
                    centsToDollars(transaction?.networkFee || 0)
                  )}
                </H5>

                <Separator $m="20px 0" />

                {transaction?.fundedTip === null ? (
                  <>
                    <P $m="0">Delivery Tip</P>
                    <H5 $m="0" $color={lightTheme.primary}>
                      {formatter.format(
                        centsToDollars(transaction?.deliveryTip || 0)
                      )}
                    </H5>
                    <Separator $m="20px 0" />
                  </>
                ) : (
                  <Div $flex $columnSM $gap="40px" $gapSM="0">
                    <Div $width="50%" $widthSM="100%">
                      <P $m="0">Delivery Tip</P>
                      <H5 $m="0" $color={lightTheme.primary}>
                        {formatter.format(
                          centsToDollars(transaction?.deliveryTip || 0)
                        )}
                      </H5>
                      <Separator $m="20px 0" />
                    </Div>
                    <Div $width="50%" $widthSM="100%">
                      <TextWithTooltipIcon
                        text="Funded Tip"
                        tooltip={`Tip funded by restaurant owner to prevent delivery driver tips being $0 or below a certain amount. This increases likelihood of successful delivery, and decreases assignment time drastically.`}
                      />
                      <H5 $m="0" $color={lightTheme.primary}>
                        {formatter.format(
                          centsToDollars(transaction?.fundedTip || 0)
                        )}
                      </H5>
                      <Separator $m="20px 0" />
                    </Div>
                  </Div>
                )}

                <Div $flex $columnSM $gap="40px" $gapSM="0">
                  <Div $width="50%" $widthSM="100%">
                    <P $m="0">vGrubs Fee</P>
                    <H5 $m="0" $color={lightTheme.primary}>
                      {formatter.format(
                        centsToDollars(transaction?.vGrubsFee || 0)
                      )}
                    </H5>
                    <Separator $m="20px 0" />
                  </Div>
                  <Div $width="50%" $widthSM="100%">
                    <P $m="0">Total Amount</P>
                    <H5 $m="0" $color={lightTheme.primary}>
                      {formatter.format(
                        centsToDollars(transaction?.totalAmount || 0)
                      )}
                    </H5>
                    <Separator $m="20px 0" />
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div
              $flex
              $between
              $width="50%"
              $widthSM="100%"
              $m="16px 0"
              $mSM="8px 0"
            >
              <div>
                <H3 $mb="4">vDrive Real Cost:</H3>
                <Disclaimer $mb="0">(Deduction - Deposit)</Disclaimer>
              </div>
              <H5 $m="0" $color={lightTheme.primary}>
                {formatter.format(
                  centsToDollars(
                    (transaction?.totalAmount || 0) -
                      (transaction?.depositTotal || 0)
                  )
                )}
              </H5>
            </Div>
          </>
        )}
      </ModalFullScreen>
      <Div $desktop>
        <Tooltip
          id="my-tooltip"
          style={{
            maxWidth: '500px',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
      <Div $mobile>
        <Tooltip
          id="my-tooltip"
          style={{
            maxWidth: '95%',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '1',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
    </>
  );
};
