import {
  AdminInput,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H5,
  Img,
  Modal,
  P,
  SuccessToast,
  api,
  read,
  store,
} from '@vgrubs/components';
import { useState } from 'react';
import { ProviderLogoWrapper } from './LocationStyled';
import { providerLogo, providerName } from './helpers';
import { handleDisconnect } from './ManageProviders';

export const ProviderConnectModal = ({
  modalIsOpen,
  setModalIsOpen,
  setStatuses,
  setConnectionStatuses,
  id,
  provider,
  pathPrefix,
}) => {
  const [loading, setLoading] = useState(false);
  const [storeId, setStoreId] = useState('');

  const localhost = true;

  async function handleConnect() {
    try {
      setLoading(true);
      const res = await api.post(`/v1/${pathPrefix}/${id}/provider/connect`, {
        provider,
        storeId: provider === 'delivery' ? storeId : null,
        redirectUrl: `${
          localhost ? process.env.NX_ADMIN_URL : window.location.origin
        }/${pathPrefix}/manage-providers/${id}?cp=${provider}`,
      });

      if (provider !== 'delivery') {
        let data = { provider, id, pathPrefix };
        store('provider-connect', JSON.stringify(data));
        setLoading(false);
        setModalIsOpen(false);
        window.location = res.data.data;
      } else {
        SuccessToast(res.data.message);
        setModalIsOpen(false);
        setStoreId('');
        setLoading(false);
        setConnectionStatuses((prev) => {
          return [...prev, { provider, isConnected: true }];
        });
        setStatuses((prev) => {
          return [...prev, { provider, status: 'online' }];
        });
      }
    } catch (error) {
      if (
        error?.response?.data?.message === `${provider} is already connected.`
      ) {
        handleDisconnect(
          pathPrefix,
          id,
          provider,
          setConnectionStatuses,
          setStatuses,
          setLoading,
          () => handleConnect()
        );
      } else {
        setLoading(false);
        ErrorToast(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <AdminSpinner />}

      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        header="Connect Provider"
      >
        <Div $flex $center>
          <ProviderLogoWrapper>
            <span>
              <Img $width={'90px'} src={providerLogo(provider)} />
            </span>
          </ProviderLogoWrapper>
        </Div>

        {provider === 'delivery' ? (
          <AdminInput
            $textCenter
            value={storeId}
            onChange={(e) => setStoreId(e.target.value)}
            label="Enter your Store ID"
          />
        ) : (
          <>
            {' '}
            <H5 $mb="16">
              Click on button to connect {providerName(provider)} with our app
            </H5>
            <P $mb="24">You will be redirected to authorization page</P>
          </>
        )}

        <Button
          $selected
          $w="100%"
          $mt="20"
          onClick={() => handleConnect(true)}
        >
          Connect
        </Button>
      </Modal>
    </>
  );
};
