import {
  ADMIN_CONCIERGE,
  ADMIN_DASHBOARD,
  ADMIN_ROOT,
  ContentStyled,
  isAdmin,
  isPartner,
  isSales,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { AdminSidebar } from './AdminSidebar';

const AdminLayoutStyled = styled.div`
  display: flex;
`;

const ContentWrapperStyled = styled.div`
  width: calc(100% - 280px);
  margin-left: 280px;
  @media (max-width: 1450px) {
    margin-left: 270px;
    width: calc(100% - 280px);
  }

  @media (max-width: 1200px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const AdminLayout = ({ children, routes }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === ADMIN_ROOT && isAdmin) navigate(ADMIN_DASHBOARD);
    if (location.pathname === ADMIN_ROOT && isPartner)
      navigate(ADMIN_CONCIERGE);
    if (location.pathname === ADMIN_ROOT && isSales) navigate(ADMIN_CONCIERGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AdminLayoutStyled>
      <AdminSidebar routes={routes} />
      <ContentWrapperStyled>
        <ContentStyled>{children}</ContentStyled>
      </ContentWrapperStyled>
    </AdminLayoutStyled>
  );
};
