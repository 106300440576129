import {
  Div,
  H3,
  H5,
  Icon,
  P,
  VerticalSeparator,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import { IconDeposit, IconTransactions } from './TransactonsStyled';
import { TransactionDateComponent } from './TransactionComponents';
import { DepositDetailModal } from './DetailModals/DepositDetailModal';
import { useState } from 'react';
import { TransactionDetailModal } from './DetailModals/TransactionDetailModal';

export const TransactionsVDrive = ({
  transactions,
  sumTransactions,
  type,
  setType,
}) => {
  const [depositModal, setDepositModal] = useState(false);
  const [transactionsModal, setTransactionsModal] = useState(false);

  return (
    <div>
      <Div $flex $columnSM $gap="20px" $gapSM="16px" $mb="32">
        <Div
          $flex
          $alignCenter
          $pointer
          $p="24px"
          $width="50%"
          $widthSM="100%"
          $box={type === 'Deposits'}
          $outlineBox={type !== 'Deposits'}
          onClick={() => setType('Deposits')}
        >
          <Icon>
            <IconDeposit />
          </Icon>
          <VerticalSeparator
            $m="0 32px"
            $mSM="0 24px"
            $h="57px"
            $color={lightTheme.lightGray}
          />
          <Div>
            <H5 $m="0">Total Deposit</H5>
            <H3 $m="0" $color={lightTheme.green}>
              {formatter.format(
                centsToDollars(sumTransactions?.vDriveDeposits)
              )}
            </H3>
            <P $m="0">
              Last Added Deposit:{' '}
              {formatter.format(
                centsToDollars(sumTransactions?.lastVDriveDeposit)
              ) || 0}
            </P>
          </Div>
        </Div>
        <Div
          $flex
          $alignCenter
          $p="24px"
          $width="50%"
          $widthSM="100%"
          $pointer
          $box={type === 'Transactions'}
          $outlineBox={type !== 'Transactions'}
          onClick={() => setType('Transactions')}
        >
          <Icon>
            <IconTransactions />
          </Icon>
          <VerticalSeparator
            $m="0 32px"
            $mSM="0 24px"
            $h="57px"
            $color={lightTheme.lightGray}
          />
          <Div>
            <H5 $m="0">Total Transactions</H5>
            <H3 $m="0" $color={lightTheme.primary}>
              {formatter.format(
                centsToDollars(sumTransactions?.vDriveTransactions)
              )}
            </H3>
            <P $m="0">
              Deposit Reports:{' '}
              <span style={{ color: lightTheme.green }}>
                {formatter.format(
                  centsToDollars(sumTransactions?.vDriveDepositsReports)
                ) || 0}
              </span>
            </P>
          </Div>
        </Div>
      </Div>
      {transactions?.map((item, i) => {
        return (
          <TransactionDateComponent
            item={item}
            index={i}
            key={i}
            setDepositModal={setDepositModal}
            setTransactionsModal={setTransactionsModal}
          />
        );
      })}

      <DepositDetailModal
        modalIsOpen={depositModal}
        setModalIsOpen={setDepositModal}
      />
      <TransactionDetailModal
        modalIsOpen={transactionsModal}
        setModalIsOpen={setTransactionsModal}
      />
    </div>
  );
};
