import {
  ADMIN_LOCATIONS_DETAILS,
  AbsoluteAddressDropdown,
  AdminInput,
  AdminPhoneInput,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useMatch } from 'react-router';

export const ChangeLocationAddress = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setConfirmAddressModal,
}) => {
  const [address, setAddress] = useState('');
  const [placeId, setPlaceId] = useState('');

  const finished = () => {
    setAddress('');
    setPlaceId('');
    setModalIsOpen(false);
  };

  async function getLocationDetails() {
    if (!address) return ErrorToast('Please Choose Address');

    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/locations/${placeId}/parse-google-place`
      );

      setConfirmAddressModal(data.data);

      finished();
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      zIndex={110}
      header={'Change Address'}
      overflow="visible"
      modalOverflow="visible"
    >
      <LocationSearchInput
        address={address}
        setAddress={setAddress}
        setPlaceId={setPlaceId}
      />

      <Button $mt="24" $w="100%" $selected onClick={getLocationDetails}>
        Confirm
      </Button>
      <Button $w="100%" $mt="16" $selected $gray onClick={finished}>
        Cancel
      </Button>
    </Modal>
  );
};

export const ChangeLocationAddressConfirm = ({
  modalIsOpen,
  setModalIsOpen,
  setData,
  setLoading,
}) => {
  const id = useMatch(ADMIN_LOCATIONS_DETAILS).params.id;

  if (modalIsOpen === null) return;

  const { address, city, country, state, zipCode, coordinates, streetNumber } =
    modalIsOpen;

  const handleInputChange = ({ target: { name, value } }) => {
    setModalIsOpen((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function handleChangeAddress() {
    if (!address || !city || !country || !state || !zipCode || !streetNumber)
      return ErrorToast('Please fill in all information');

    try {
      setLoading(true);

      const body = {
        address,
        city,
        country,
        state,
        zipCode,
        coordinates,
        streetNumber,
      };

      const { data } = await api.put(`/v1/locations/${id}/address`, body);

      setData((prev) => {
        return {
          ...prev,
          address: `${streetNumber} ${address}`,
          city,
          country,
          state,
          zipCode,
        };
      });

      setModalIsOpen(null);

      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      zIndex={110}
      header={'Change Address'}
    >
      <Div $flex $column $gap="24px">
        <AdminInput
          $textCenter
          placeholder="Enter Here"
          label="Address"
          value={address}
          name="address"
          onChange={handleInputChange}
        />
        <AdminInput
          $textCenter
          placeholder="Enter Here"
          label="Street Number"
          value={streetNumber}
          name="streetNumber"
          onChange={handleInputChange}
        />
        <Div $flex $alignCenter $gap="16px" $width="100%">
          <AdminInput
            $textCenter
            placeholder="Enter Here"
            label="City"
            value={city}
            name="city"
            onChange={handleInputChange}
          />

          <AdminInput
            $textCenter
            placeholder="Enter Here"
            label="Country"
            value={country}
            name="country"
            onChange={handleInputChange}
          />
        </Div>
        <Div $flex $alignCenter $gap="16px" $width="100%">
          <AdminInput
            $textCenter
            placeholder="Enter Here"
            label="State"
            value={state}
            name="state"
            onChange={handleInputChange}
          />

          <AdminInput
            $textCenter
            placeholder="Enter Here"
            label="Zip Code"
            value={zipCode}
            name="zipCode"
            onChange={handleInputChange}
          />
        </Div>
      </Div>

      <Button $mt="24" $w="100%" $selected onClick={handleChangeAddress}>
        Confirm
      </Button>
      <Button
        $w="100%"
        $mt="16"
        $selected
        $gray
        onClick={() => setModalIsOpen(false)}
      >
        Cancel
      </Button>
    </Modal>
  );
};

const LocationSearchInput = ({ address, setAddress, setPlaceId }) => {
  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    setAddress(address);

    try {
      const results = await geocodeByAddress(address);

      if (results.length > 0) {
        const placeId = results[0].place_id;
        setPlaceId(placeId);
      }
    } catch (error) {
      console.error('Error fetching geocode results:', error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <Div $relative $mb="24">
            <AdminInput
              $textCenter
              {...getInputProps({
                label: 'New Delivery Address',
                placeholder: 'Choose Address',
              })}
            />
            {suggestions.length ? (
              <AbsoluteAddressDropdown $box $p="8px">
                {loading && <AdminSpinner absolute small />}
                {suggestions.map((suggestion, i) => {
                  const style = suggestion.active
                    ? { color: lightTheme.primary, cursor: 'pointer' }
                    : { backgroundColor: '', cursor: 'pointer' };
                  return (
                    <Div
                      key={i}
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                      $p="8px"
                    >
                      <span>{suggestion.description}</span>
                    </Div>
                  );
                })}
              </AbsoluteAddressDropdown>
            ) : null}
          </Div>
        );
      }}
    </PlacesAutocomplete>
  );
};
