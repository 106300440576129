import { useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import { Button } from '@vgrubs/components';
import { Div, P, Modal } from '@vgrubs/components';

const Color = styled.div`
  width: 100%;
  height: 45px;
  background-color: ${({ color }) => color};
  border-radius: 50px;
  border: 1px solid
    ${({ theme, focused }) => (focused ? theme.primary : theme.darkGray)};

  cursor: pointer;
`;

export const AdminColorInput = ({ onChange, color, label }) => {
  const [colorModal, setColorModal] = useState(false);

  return (
    <Div>
      <P $mb="10">
        <b>{label}</b>
      </P>
      <Color
        onClick={() => setColorModal(!colorModal)}
        color={color}
        focused={colorModal}
      />

      <Modal isOpen={colorModal} setIsOpen={setColorModal}>
        <SketchPicker
          width={'calc(100% - 20px)'}
          color={color}
          onChangeComplete={(e) => onChange(e.hex)}
        />
        <Button $mt="30" onClick={() => setColorModal(!colorModal)}>
          Done
        </Button>
      </Modal>
    </Div>
  );
};
