import {
  AdminInput,
  AnimatedDiv,
  BrandsIcon,
  Button,
  Disclaimer,
  Div,
  DropdownMenuComponent,
  ErrorToast,
  H5,
  Icon,
  Img,
  Overlay,
  P,
  Radio,
  lightTheme,
  restaurantImage,
  useDisableBodyScroll,
  overlayAnimationVariant,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { LocationsPicker, ScrollableWrapper } from './AdminBrandsPickerStyled';

export const AdminBrandsPicker = ({
  selectedLocations,
  selectedBrans,
  setSelectedBrands,
  handleSubmit,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState('');

  useDisableBodyScroll(dropdown);

  const data = selectedLocations[0]?.brands;

  const handleSelectLocation = (newItem) => {
    const index = selectedBrans.findIndex((item) => item === newItem);
    if (index === -1) {
      // Item doesn't exist, so add it
      setSelectedBrands((prevIds) => [...prevIds, newItem]);
    } else {
      // Item exists, so remove it
      setSelectedBrands((prevIds) =>
        prevIds.filter((item) => item !== newItem)
      );
    }
  };

  function isLocationSelected(item) {
    return selectedBrans.some((location) => location.id === item.id);
  }

  function renderLocationInputPlaceholder() {
    if (selectedBrans.length === 0) {
      return 'All Brands';
    } else if (selectedBrans.length === 1) {
      return selectedBrans[0].name;
    } else return `${selectedBrans.length} Brands`;
  }

  const filteredBrands = data?.filter((brand) =>
    brand.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <AnimatePresence>
      <Div $relative $height="45px" $heightSM="45px">
        <LocationsPicker
          $opened={dropdown}
          onClick={() => {
            if (selectedLocations.length === 1) {
              setDropdown((prev) => !prev);
            } else {
              ErrorToast('Please select only one location');
            }
          }}
        >
          <Div>{renderLocationInputPlaceholder()}</Div>

          <Icon>
            <BrandsIcon />
          </Icon>
        </LocationsPicker>

        <DropdownMenuComponent
          isOpen={dropdown}
          setIsOpen={setDropdown}
          maxHeight="70vh"
          padding="0"
          fullWidth
        >
          <Div $p="20px">
            <AdminInput
              search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Brand"
            />
          </Div>
          <ScrollableWrapper>
            {filteredBrands?.length === 0 && (
              <Div
                $flex
                $center
                $column
                $height="calc(70vh - 300px)"
                $heightSM="calc(60vh - 300px)"
              >
                <Img src={restaurantImage} alt="restaurant" $width="250px" />
                <H5>No Brands</H5>
              </Div>
            )}
            {filteredBrands?.map((l, i) => (
              <Div
                key={i}
                $box
                $p="18px 24px"
                $mb="8"
                $flex
                $alignCenter
                $between
                onClick={() => handleSelectLocation(l)}
                $gap="16px"
                $pointer
              >
                <div>
                  <P
                    $bold
                    $mb="4"
                    $color={isLocationSelected(l) && lightTheme.primary}
                  >
                    {l.name}
                  </P>
                  <Disclaimer $mb="0">{l.address}</Disclaimer>
                </div>

                <Radio checked={isLocationSelected(l)} />
              </Div>
            ))}
          </ScrollableWrapper>

          <Div $p="20px">
            <Button
              $selected
              $gray
              $w="100%"
              $wSM="100%"
              onClick={() => setSelectedBrands([])}
              $mb="16"
            >
              Reset Selection
            </Button>
            <Button
              $selected
              $w="100%"
              $wSM="100%"
              onClick={() => {
                setDropdown((prev) => !prev);
                handleSubmit();
              }}
            >
              Confirm
            </Button>
          </Div>
        </DropdownMenuComponent>
      </Div>

      {dropdown && (
        <Overlay
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};
