import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Text = (props) => `
  ${props.$right && `text-align: right`};
  ${props.$left && `text-align: left`};
  ${props.$caps && `text-transform: all-caps`};
  ${({ $lh }) => $lh && `line-height:${$lh}`};
  ${({ $relative }) => $relative && 'position: relative'};
  ${props.$bold && `font-weight:700`};
  ${({ $semiBold }) => $semiBold && `font-weight:500`};
  font-weight: ${props.$bold};
  color: ${props.theme.darkGray};
  margin: ${props.$m};
  margin-top: ${props.$mt}px;
  margin-bottom: ${props.$mb}px;
  margin-left: ${props.$ml}px;
  margin-right: ${props.$mr}px;
  max-width: ${props.$maxWidth};
  ${({ $width }) => $width && `width:${$width}`};

  ${({ $fs }) => $fs && `font-size: ${$fs}`};

  ${props.$color && `color:${props.$color}`};
  ${props.$center && `text-align:center`};
  ${props.$uppercase && `text-transform: uppercase`};
  ${props.$capitalize && `text-transform: capitalize`};
  ${props.$underline && `text-decoration: underline`};
  ${props.$pointer && `cursor: pointer`};
  ${props.$lineHeight && `line-height: ${props.$lineHeight}`};
  ${props.$fw && `font-weight: ${props.$fw}`};

  ${({ $hover, theme }) =>
    $hover &&
    `
  transition: color 0.2s ease !important;
  :hover {
    color: ${theme.primary} !important;
  }
`}

  

  @media (min-width: 3500px) {
    margin: ${props.$mLG};
    margin-top: ${props.$mtLG}px;
    margin-bottom: ${props.$mbLG}px;
    margin-left: ${props.$mlLG}px;
    margin-right: ${props.$mrLG}px;
    max-width: ${props.$maxWidthLG};
  ${props.$lineHeight && `line-height: ${props.$lineHeight}`};

  }

  @media (max-width: 1450px) {
    margin: ${props.$m};
    margin-top: ${props.$mt}px;
    margin-bottom: ${props.$mb}px;
    margin-left: ${props.$ml}px;
    margin-right: ${props.$mr}px;
    max-width: ${props.$maxWidth};
  ${props.$lineHeight && `line-height: ${props.$lineHeight}`};

  }



  @media (max-width: 800px) {
    margin: ${props.$mSM};
    margin-top: ${props.$mtSM}px;
    margin-bottom: ${props.$mbSM}px;
    margin-left: ${props.$mlSM}px;
    margin-right: ${props.$mrSM}px;
    max-width: ${props.$maxWidthSM};
  ${props.$lineHeight && `line-height: ${props.$lineHeight}`};
  ${props.$centerSM && `text-align:center`};


  }

  span {
    color: ${props.theme.primary};
  }

 
`;

export const H1 = styled(motion.h1)`
  font-weight: 700;
  font-size: 60px;
  line-height: 65px;
  margin: 0;
  margin-bottom: 40px;

  @media (min-width: 3500px) {
    font-size: 100px;
    line-height: 110px;
    margin-bottom: 60px;
  }

  @media (max-width: 1450px) {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    font-size: 34px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  @media (max-width: 400px) {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  ${Text}
`;

export const H2 = styled(motion.h2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  margin: 0;
  margin-bottom: 40px;

  @media (min-width: 3500px) {
    font-size: 84px;
    line-height: 96px;
    margin-bottom: 60px;
  }

  @media (max-width: 1450px) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  ${Text}
`;

export const H3 = styled(motion.h3)`
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  margin-bottom: 40px;

  @media (min-width: 3500px) {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 60px;
  }

  @media (max-width: 1450px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  ${Text}
`;

export const H4 = styled(motion.h4)`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: 3500px) {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  @media (max-width: 1450px) {
    font-size: 15px;
    line-height: 19px;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  ${Text}
  ${({ $color }) => $color && `color:${$color}`};
`;

export const H5 = styled(motion.h5)`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 16px;

  @media (min-width: 3500px) {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 32px;
  }

  @media (max-width: 1450px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  ${Text}
`;

export const P = styled(motion.p)`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  margin-bottom: 40px;

  @media (min-width: 3500px) {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 80px;
  }

  @media (max-width: 1450px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  ${({ $hoverUnderline }) =>
    $hoverUnderline &&
    `
    :hover {
      text-decoration: underline !important;
    }
  `}

  ${Text}
`;

export const Li = styled(motion.li)`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  margin-bottom: 10px;

  @media (min-width: 3500px) {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 20px;
  }

  @media (max-width: 1450px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 7px;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  ${Text}
`;

export const Disclaimer = styled(motion.p)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  margin-bottom: 40px;

  @media (min-width: 3500px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 80px;
  }

  @media (max-width: 1450px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  ${Text}
`;

export const A = styled(Link)`
  ${Text}
`;
