import {
  Div,
  DownloadCloudIcon,
  DownloadIcon,
  ErrorToast,
  Icon,
  ImagePreview,
  Img,
  SuccessToast,
  api,
  downloadImage,
} from '@vgrubs/components';
import Compressor from 'compressorjs';
import { useRef } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

const AdminInputStyled = styled.div`
  input {
    visibility: hidden;
    height: 0;
    width: 0;
  }
`;

export const ImageInput = ({ data, children, setData, setLoading }) => {
  const inputFileRef = useRef();

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const handleChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    const maxSize = 2097152;

    if (files.length) {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 2500,
        maxHeight: 1500,
        convertSize: 2097152,
        success: (compressedResult) => {
          console.log(compressedResult.size);
          if (compressedResult.size > maxSize) {
            return ErrorToast('Image file too big');
          } else {
            handleAddImage(compressedResult);
          }
        },
      });
    }
  };

  async function handleAddImage(img) {
    var imageData = {
      type: 'voidCheck',
    };

    let formData = new FormData();
    formData.append('image', img);
    formData.append('imageData', JSON.stringify(imageData));

    try {
      setLoading(true);

      // onAddBrandSuccess(brand.name);
      const res = await api.post(`/v1/locations/${data.id}/image`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });

      setData((prev) => {
        return {
          ...prev,
          images: [{ url: res.data.data, type: 'voidCheck' }, ...prev.images],
        };
      });
      SuccessToast(res.data.message);
      console.log(res.data.data);

      setLoading(false);
    } catch (error) {
      console.log(error.response);
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <AdminInputStyled onClick={onBtnClick}>
      {children}
      <input ref={inputFileRef} type="file" onChange={handleChange} />
    </AdminInputStyled>
  );
};

export const ImageWithDownload = ({ src, header }) => {
  return (
    <Div
      $flex
      $box
      $p="16px"
      $width="100%"
      $widthSM="100%"
      style={{ aspectRatio: '1/1' }}
      $relative
    >
      {/* <Img
        src={src}
        $width="100%"
        $height="auto"
        style={{ objectFit: 'contain' }}
      /> */}

      <ImagePreview
        image={src}
        style={{ objectFit: 'contain' }}
        // header={header}
      />

      <Icon
        style={{ position: 'absolute', top: '20px', right: '20px' }}
        $pointer
        onClick={() => saveAs(src)}
      >
        <DownloadCloudIcon />
      </Icon>
    </Div>
  );
};
