import {
  api,
  useAuth,
  ADMIN_OTP,
  AdminLogo,
  Button,
  H3,
  H4,
  P,
  isAdmin,
  isPartner,
  isSales,
  setDocumentTitle,
  setHasEmailLS,
  setPhoneNumberLS,
  store,
  Div,
} from '@vgrubs/components';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { AdminSpinner, ErrorToast, SuccessToast } from '../../adminComponents';

import ReCAPTCHA from 'react-google-recaptcha';

const AdminInput = styled.div`
  border: 1px solid ${({ theme }) => theme.darkGray}80;
  background: none;
  border-radius: 200px;
  padding: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;

  input {
    text-align: center;
    border: none;
    :focus {
      outline: none;
    }
  }

  position: relative;

  .PhoneInputCountry {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);

    select {
      height: 35px;
    }

    .PhoneInputCountryIcon {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
      }

      border: none;
      box-shadow: none;
      background-color: none;
    }
    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  :focus-visible {
    outline: ${({ theme }) => theme.primary} 1px;
  }
`;

const Form = styled.form`
  width: 100%;
`;

export const LoginAdmin = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const { removeAuthState } = useAuth((state) => state);

  const recaptcha = useRef();

  const role = () => {
    if (isAdmin) return 'admin';
    if (isSales) return 'salesManager';
  };

  async function signIn(role) {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/auth/sign-in`, {
        role,
        phoneNumber,
      });

      setLoading(false);
      setPhoneNumberLS(phoneNumber);
      setHasEmailLS(data.data.isEmailAvailable);
      navigate(ADMIN_OTP);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function verifyRecaptcha(token, role) {
    try {
      setLoading(true);
      await api.post(`/v1/auth/verify-recaptcha`, { token });
      signIn(role);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function handleSubmit(role) {
    if (!phoneNumber) return ErrorToast('Please enter your phone number');
    if (!isValidPhoneNumber(phoneNumber ?? ''))
      return ErrorToast('Check the phone number');

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      ErrorToast('Please verify the reCAPTCHA!');
    } else {
      verifyRecaptcha(captchaValue, role);
    }
  }

  useEffect(() => {
    store('redirected', true);
    removeAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAdmin) {
    setDocumentTitle('vGrubs Admin');
  }

  if (isPartner) {
    setDocumentTitle('vGrubs Partner');
  }

  if (isSales) {
    setDocumentTitle('vGrubs Sales');
  }

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminLogo />
      <H4 $m="24px">
        {isAdmin && 'Admin'}
        {isPartner && 'Partner'}
        {isSales && 'Sales'}
      </H4>
      <H3>
        <span>Sign in</span>
      </H3>
      <P $m="20px 0 8px 0">
        <b>Your Phone Number</b>
      </P>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          !isPartner && handleSubmit(role());
        }}
      >
        <AdminInput>
          <PhoneInput
            placeholder="Enter here"
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="US"
            addInternationalOption={false}
            international
            focusInputOnCountrySelection={true}
          />
        </AdminInput>

        <Div $flex $center>
          <ReCAPTCHA ref={recaptcha} sitekey={process.env.NX_APP_SITE_KEY} />
        </Div>

        {isPartner ? (
          <>
            <Button
              onClick={() => handleSubmit('partner')}
              $w="100%"
              $wSM="100%"
              $selected
              $mb="16"
            >
              Continue as Partner
            </Button>
            <Button
              onClick={() => handleSubmit('manager')}
              $w="100%"
              $wSM="100%"
              $selected
              $gray
            >
              Continue as Manager
            </Button>
          </>
        ) : (
          <Button type="submit" $w="100%" $wSM="100%" $selected $mt="24">
            Continue
          </Button>
        )}
      </Form>
    </>
  );
};
