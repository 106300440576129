import {
  ScrollToTop,
  StyledToastContainer,
  AppRouter,
} from '@vgrubs/components';
import { ROUTES_ADMIN } from './routes';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  return (
    <>
      <StyledToastContainer />
      <ScrollToTop>
        <AppRouter routes={ROUTES_ADMIN} />
      </ScrollToTop>
    </>
  );
};

export default App;
