import {
  ADMIN_LOGIN,
  Button,
  Div,
  H3,
  LogoutIcon,
  Modal,
  P,
} from '@vgrubs/components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const AdminLogoutModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      logo={false}
      exitButton={false}
    >
      <Div $mb="16">
        <LogoutIcon />
      </Div>

      <H3 $m="10px" $center>
        Logout
      </H3>
      <P $mb="24" $center>
        Are you sure you want to logout?
      </P>

      <Div $flex $gap="16px">
        <Button onClick={() => navigate(ADMIN_LOGIN)} $w="100%" $selected>
          Yes
        </Button>
        <Button $w="100%" onClick={() => setIsOpen(false)} $gray $selected>
          Cancel
        </Button>
      </Div>
    </Modal>
  );
};
