import { H5, Img, Modal, P, noImage } from '@vgrubs/components';
import { useState } from 'react';

export const CourierModal = ({ modalIsOpen, setModalIsOpen, courier }) => {
  const [image] = useState(noImage);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      textAlign={'left'}
      zIndex={110}
    >
      {courier?.photoUrl && (
        <Img
          $width="100%"
          src={courier?.photoUrl}
          alt="courier"
          onError={(e) => {
            e.target.src = image;
          }}
        />
      )}
      {courier?.name && (
        <H5 $mb="8" $mt="16">
          {courier?.name}
        </H5>
      )}
      {courier?.phoneNumber && <P $mb="8">{courier?.phoneNumber}</P>}
    </Modal>
  );
};
