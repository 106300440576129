import { lightTheme } from '@vgrubs/components';
import styled from 'styled-components';

export const StripeInputStyled = styled.div`
  height: 45px;
  width: 100%;
  border-radius: 200px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.darkGray}80;
  /* background-color: ${({ theme }) => theme.white}; */
  border: none;
  font-size: 14px;
  padding: 0 24px;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  input {
    border: none;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.darkGray};
    background: none;
    width: 100%;
  }

  input::placeholder {
    color: ${({ theme }) => theme.darkGray}4D;
  }
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  input:focus {
    outline: none !important;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const INPUT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      color: lightTheme.darkGray,
      '::placeholder': { color: `${lightTheme.darkGray}4D` },
      fontSize: '14px',
      fontWeight: 'normal',
    },
    complete: { color: lightTheme.darkGray },
    invalid: { color: 'rgb(230, 30, 41)' },
  },
};
