// import Cookies from 'js-cookie';

const localStorageNode = 'vg-admin-auth';
const localStoragePhoneNode = 'vg-admin-phone';
const localStorageHasEmailNode = 'vg-admin-has-email';

export const store = (key, value) => {
  return localStorage.setItem(key, value);
};

export const read = (key) => {
  return localStorage.getItem(key);
};

export const getTokens = () => {
  return JSON.parse(read(localStorageNode))?.state?.tokens;
};

export const getAccessToken = () => {
  return JSON.parse(read(localStorageNode))?.state?.tokens?.accessToken;
};

export const getRefreshToken = () => {
  return JSON.parse(read(localStorageNode))?.state?.tokens?.refreshToken;
};

export const getUserFromLS = () => {
  return JSON.parse(read(localStorageNode))?.state?.user;
};

export const getPhoneFromLS = () => {
  return JSON.parse(read(localStoragePhoneNode));
};

export const getHasEmailFromLS = () => {
  return JSON.parse(read(localStorageHasEmailNode));
};

export const setNewAccessToken = (accessToken) => {
  let data = JSON.parse(read(localStorageNode));
  const newToken = {
    ...data,
    state: { ...data.state, tokens: { ...data.state.tokens, accessToken } },
  };
  store(localStorageNode, JSON.stringify(newToken));
};

export const setLoginDataToLS = (data) => {
  store(localStorageNode, JSON.stringify(data));
};

export const setPhoneNumberLS = (data) => {
  store(localStoragePhoneNode, JSON.stringify(data));
};

export const setHasEmailLS = (data) => {
  store(localStorageHasEmailNode, JSON.stringify(data));
};

export const removeLoginDataFromLS = () => {
  localStorage.removeItem(localStorageNode);
  localStorage.removeItem(localStoragePhoneNode);
  localStorage.removeItem(localStorageHasEmailNode);
};
