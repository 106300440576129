import {
  Button,
  H3,
  Modal,
  P,
  lightTheme,
  pauseImage,
} from '@vgrubs/components';

export const PausedItemModal = ({ pausedModal, setPausedModal }) => {
  return (
    <Modal
      isOpen={pausedModal}
      setIsOpen={setPausedModal}
      logo={false}
      image={pauseImage}
    >
      <H3 $mb="24" $color={lightTheme.primary}>
        Item is paused!
      </H3>
      <P $mb="40">If you want to unpause item tap on resume button.</P>

      <Button $selected $w="100%" $mb="8">
        Resume
      </Button>

      <Button $selected $w="100%" $gray>
        Cancel
      </Button>
    </Modal>
  );
};
