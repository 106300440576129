import {
  ADMIN_PRIVACY_POLICY,
  ADMIN_TERMS_AND_CONDITIONS,
  AdminLogoutModal,
  AdminSpinner,
  AdminSwitch,
  AnimatedDiv,
  Button,
  Div,
  ErrorToast,
  H3,
  H5,
  Separator,
  SuccessToast,
  api,
  lightTheme,
  useAuth,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteAccountModal } from '../DeleteAccountModal';
import { BakUpCard } from '../backupCard/BackUpCard';

export const GeneralSettings = () => {
  const [loading, setLoading] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [backupCard, setBackupCard] = useState('');

  const { user, updateNotificationStatus } = useAuth((state) => state);

  const { role } = useAuth((state) => state.user);

  async function handleUpdateNotificationStatus() {
    try {
      setLoading(true);
      const { data } = await api.put(`/v1/users/notification-status`, {
        enabled: !user.notificationsEnabled,
      });
      updateNotificationStatus(data.data.notificationsEnabled);

      SuccessToast(data.message);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function getBackupCard() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/vdrive/backup-card`);
      setBackupCard(data.data.last4);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (role === 'admin') getBackupCard();
  }, []);

  return (
    <AnimatedDiv>
      {loading && <AdminSpinner />}

      <H3 $mb="24px">General Settings</H3>

      <Div $flex $gap="48px" $gapSM="0" $columnSM>
        <Div $width="50%" $widthSM="100%">
          <Separator />
          <Div $flex $alignCenter $between>
            <H5 $m="20px 0">Notifications</H5>
            <AdminSwitch
              checked={user.notificationsEnabled}
              onChange={handleUpdateNotificationStatus}
            />
          </Div>
          <Separator />
          <Link to={ADMIN_PRIVACY_POLICY}>
            <H5 $m="20px 0">Privacy Policy</H5>
          </Link>
          <Div $desktop>
            <Separator />
          </Div>
        </Div>
        <Div $width="50%" $widthSM="100%">
          <Separator />
          <Link to={ADMIN_TERMS_AND_CONDITIONS}>
            <H5 $m="20px 0">Terms & Conditions</H5>
          </Link>

          <Separator />
          <Div $pointer>
            <H5
              $m="20px 0"
              $color={lightTheme.primary}
              onClick={() => setLogoutModal(true)}
            >
              Logout
            </H5>
          </Div>
          <Separator />
        </Div>
      </Div>
      {role === 'admin' && (
        <Div $maxWidth="450px">
          <BakUpCard
            backupCard={backupCard}
            setBackupCard={setBackupCard}
            setLoading={setLoading}
          />
        </Div>
      )}

      <Div $mt="50" $flex $justifyEnd>
        <Button
          $selected
          $w="300px"
          onClick={() => setDeleteAccountModal(true)}
        >
          Delete Account
        </Button>
      </Div>

      <AdminLogoutModal isOpen={logoutModal} setIsOpen={setLogoutModal} />
      <DeleteAccountModal
        isOpen={deleteAccountModal}
        setIsOpen={setDeleteAccountModal}
      />
    </AnimatedDiv>
  );
};
