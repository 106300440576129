import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  AdminSwitch,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H5,
  InfoIcon,
  Modal,
  StripeLogo,
  SuccessToast,
  SvgInlineWrapper,
  VDriveLogo,
  api,
  lightTheme,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { PaymentCreditCard } from './CreditCardComponent';

export const EditCard = ({
  isVDriveCard,
  setIsVDriveCard,
  setModalIsOpen,
  modalIsOpen,
  id,
  setLoading,
  handleGetVDrive,
}) => {
  const PUBLISHABLE = process.env.NX_STRIPE_PUBLISHABLE_KEY;
  const [stripePromise] = useState(() => loadStripe(PUBLISHABLE));

  return (
    <Elements stripe={stripePromise}>
      <Form
        isVDriveCard={isVDriveCard}
        setIsVDriveCard={setIsVDriveCard}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        id={id}
        setLoading={setLoading}
        handleGetVDrive={handleGetVDrive}
      />
    </Elements>
  );
};

const Form = ({
  isVDriveCard,
  setIsVDriveCard,
  setModalIsOpen,
  modalIsOpen,
  id,
  setLoading,
  handleGetVDrive,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isVDriveCardToggle, setIsVDriveCardToggle] = useState(false);

  useEffect(() => {
    setIsVDriveCardToggle(isVDriveCard);
  }, [isVDriveCard, modalIsOpen]);

  const handleSubmitStripe = async () => {
    setLoading(true);

    const cardNumberElement = elements?.getElement('cardNumber');

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (error) {
      ErrorToast(error.message || '');
      setLoading(false);
    }

    if (token) {
      await handleUpdateVDriveCard(token.id);
    }
  };

  async function handleUpdateVDriveCard(stripeToken) {
    const body = {
      stripeToken,
      isVDriveCard: isVDriveCardToggle,
    };

    try {
      const { data } = await api.put(
        `/v1/vdrive/${id}/update-location-card`,
        body
      );
      handleGetVDrive();
      setIsVDriveCard(isVDriveCardToggle);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
      setModalIsOpen(false);
    }
  }

  return (
    <>
      <Modal
        header="Edit Card"
        logo={false}
        setIsOpen={setModalIsOpen}
        isOpen={modalIsOpen}
      >
        <Div $width="100%">
          <Div $flex $center $mb="40">
            <VDriveLogo />
          </Div>
          {/* <H3 $center $m="40px 0">
            <span>Add Card</span>
          </H3> */}
          <PaymentCreditCard />
          <Div $box $p="16px" $flex $between $alignCenter>
            <H5 $m="0">
              vDrive Credit Card{' '}
              <a
                data-tooltip-id="my-tooltip"
                data-tooltip-html="vDrive cards cost you an additional 2% on every transaction! Please change this card to your own to avoid this fee."
                data-tooltip-place="top"
                style={{ color: lightTheme.darkGray }}
              >
                <SvgInlineWrapper>
                  <InfoIcon />
                </SvgInlineWrapper>
              </a>
            </H5>

            <AdminSwitch
              checked={isVDriveCardToggle}
              onChange={() => setIsVDriveCardToggle((prev) => !prev)}
            />
          </Div>

          <Div $flex $center $gap="8px" $mt="24">
            <Disclaimer $m="0">Powered by:</Disclaimer>
            <StripeLogo />
          </Div>

          <Button
            $selected
            $w="100%"
            $wSM="100%"
            $mt="24"
            onClick={handleSubmitStripe}
          >
            Add Card
          </Button>
        </Div>

        <Div $desktop>
          <Tooltip
            id="my-tooltip"
            style={{
              maxWidth: '500px',
              background: lightTheme.white,
              color: lightTheme.darkGray,
              padding: 16,
              borderRadius: 20,
              boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
              zIndex: '1',
            }}
            border="1px solid #e3e3e3"
          />
        </Div>
        <Div $mobile>
          <Tooltip
            id="my-tooltip"
            style={{
              maxWidth: '95%',
              background: lightTheme.white,
              color: lightTheme.darkGray,
              padding: 16,
              borderRadius: 20,
              boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
              zIndex: '1',
            }}
            border="1px solid #e3e3e3"
          />
        </Div>
      </Modal>
    </>
  );
};
