import { Button, Div, VPayLogo, useUsers } from '@vgrubs/components';
import { UserCreditCardStyled } from '../UsersAndAccessStyled';

export const UserCreditCardNotConnected = () => {
  const { setAddBankModal } = useUsers((state) => state);

  return (
    <UserCreditCardStyled>
      <Div $flex $alignCenter $between $gap="50px" $gapSM="24px">
        <VPayLogo height="100px" width="100px" />
        <Div>
          <P $right $mb="4" $color={lightTheme.white}>
            Virtual Order <br /> Available Balance
          </P>
          <H2 $right $m="0" $color={lightTheme.white}>
            {formatter.format(
              bank.balance.availableBalance
                ? centsToDollars(bank.balance.availableBalance)
                : 0
            )}
          </H2>
        </Div>
      </Div>
      <Separator $m="24px 0" $color={lightTheme.white} />
      <Div $flex $alignCenter $between $gap="24px" $gapSM="16px">
        <Div></Div>

        <Button
          $selected
          $whiteBlue
          $w="150px"
          $wSM="110px"
          onClick={() => setAddBankModal(true)}
        >
          Connect Bank
        </Button>
      </Div>
    </UserCreditCardStyled>
  );
};
