import styled from 'styled-components';

export const Collapsible = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  margin-top: ${(props) => (props.expanded ? '8px' : '0px')};
  margin-bottom: ${(props) => (props.expanded ? '20px' : '0px')};
  transition: all 300ms;

  @media (min-width: 3500px) {
    margin-top: ${(props) => (props.expanded ? '16px' : '0px')};
    margin-bottom: ${(props) => (props.expanded ? '40px' : '0px')};
  }

  @media (max-width: 800px) {
    margin-top: ${(props) => (props.expanded ? '8px' : '0px')};
    margin-bottom: ${(props) => (props.expanded ? '16px' : '0px')};
  }
`;

export const ItemListImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
