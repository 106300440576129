import { Disclaimer, Div, H5, Separator } from '@vgrubs/components';

export const KeyValueInfo = ({ k, v }) => {
  return (
    <>
      <Div $p="16px 0">
        <Disclaimer $mb="4">{k}</Disclaimer>
        <H5 $mb="0">{v}</H5>
      </Div>
      <Separator />
    </>
  );
};
