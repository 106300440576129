import {
  Disclaimer,
  Div,
  EditPenIconDarkGray,
  Icon,
  P,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import styled from 'styled-components';
import { EditEarningPlan } from '../EditEarningPlan';

const EarningPlanStyled = styled.div`
  padding: 10px 24px;
  border: 1px solid
    ${({ $expired, $active, theme }) =>
      $expired ? theme.lightGray : $active ? theme.primary : 'none'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  margin-bottom: 16px;
  background-color: ${({ $expired, theme }) =>
    $expired ? 'none' : theme.white};

  box-shadow: ${({ $expired, theme }) =>
    $expired ? 'none' : theme.shadows.shadow1};
`;

const EarningPlanStatusStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 70px;
  background-color: ${({ $expired, theme }) =>
    $expired ? theme.lightGray : theme.primary};
  border-radius: 20px;
`;

const EarningPlanStatus = ({ active, expired }) => {
  if (active || expired) {
    return (
      <EarningPlanStatusStyled $expired={expired}>
        <Disclaimer $m="0" $color={lightTheme.white}>
          {expired ? 'Expired' : 'Active'}
        </Disclaimer>
      </EarningPlanStatusStyled>
    );
  } else return null;
};

export const EarningPlan = ({
  plan,
  setAmountPerLocation,
  setEarningPlans,
  index,
  handleEditEarningPlans,
}) => {
  const { active, expired } = plan;
  const isLifetime = plan.year === null;
  const isFlatAmount = plan.year === undefined;
  const amount = formatter.format(centsToDollars(plan?.amount));

  const [editModal, setEditModal] = useState();

  const renderPlanName = () => {
    if (isFlatAmount) {
      return 'Flat Amount Plan';
    } else if (isLifetime) {
      return 'Lifetime Plan';
    } else return `Year ${plan.year} Plan`;
  };

  const handleSetAmount = (amount) => {
    if (isFlatAmount) {
      setAmountPerLocation(amount);
    } else {
      setEarningPlans((prevState) => {
        // Make a copy of the array to avoid mutating state directly
        const updatedEarningPlans = [...prevState];
        // Update the amount property of the object at the specified index
        updatedEarningPlans[index] = {
          ...updatedEarningPlans[index],
          amount: amount,
        };
        return updatedEarningPlans;
      });
    }
  };

  const handleDeletePlan = () => {
    if (isFlatAmount) {
      setAmountPerLocation(0);
    } else {
      setEarningPlans((prevState) => {
        // Filter out the item with the specified index
        const updatedEarningPlans = prevState.filter((_, idx) => idx !== index);
        return updatedEarningPlans;
      });
    }
  };

  return (
    <EarningPlanStyled $expired={expired} $active={active}>
      <Div>
        <P $bold $mb="4">
          {renderPlanName()}
        </P>
        <Disclaimer $mb="4" color={!active && lightTheme.gray}>
          {amount}
        </Disclaimer>
      </Div>

      <Div $flex $alignCenter $gap="16px">
        {isLifetime && (
          <Disclaimer $m="0" $right $color={lightTheme.lightGray}>
            Plan that starts when
            <br />
            agent’s Year Plan Ends
          </Disclaimer>
        )}
        <EarningPlanStatus active={active} expired={expired} />
        {!expired && (
          <Icon $pointer onClick={() => setEditModal(true)}>
            <EditPenIconDarkGray />
          </Icon>
        )}
      </Div>
      <EditEarningPlan
        amount={plan.amount}
        modalIsOpen={editModal}
        setModalIsOpen={setEditModal}
        label={renderPlanName()}
        setAmount={handleSetAmount}
        handleEditEarningPlans={handleEditEarningPlans}
        handleDeletePlan={handleDeletePlan}
      />
    </EarningPlanStyled>
  );
};
