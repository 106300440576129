import {
  ADMIN_VERIFICATIONS,
  Button,
  Div,
  H3,
  Img,
  P,
  vDriveAlmostReady,
} from '@vgrubs/components';
import { Link } from 'react-router-dom';

export const AlmostReady = () => {
  return (
    <>
      <Div $flex $column $alignCenter>
        <Img $width="200px" $mb="20" src={vDriveAlmostReady} />

        <H3 $center $m="8px 0 40px 0">
          <span>Client’s vDrive is almost ready!</span>
        </H3>

        <P $mb="20" $center>
          Thank you for submitting client’s details.
          <br />
          <br />
          Please proceed with setting up their vDrive account. Note that the
          process duration may vary based on the delivery platform (UberEats,
          Grubhub, Doordash, etc.). Once the account setup is complete, kindly
          notify the client that their vDrive is ready for use. We appreciate
          your attention to this request.
        </P>

        <Link to={ADMIN_VERIFICATIONS} style={{ width: '100%' }}>
          <Button $selected $w="100%" $wSM="100%" $mt="24">
            Go To verifications
          </Button>
        </Link>
      </Div>
    </>
  );
};
