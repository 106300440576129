import {
  AdminDollarInput2,
  AdminInput,
  AdminSelect,
  Button,
  Div,
  H5,
  Icon,
  Modal,
  PlusIcon,
  lightTheme,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';
import { ImageInput } from '../../components/ImageInput';
import { AddModifierGroupComponent } from './ModifierGroupComponents';

export const AddItemModal = ({
  addModal,
  setAddModal,
  edit,
  setAddModifierGroupModal,
  menu,
  modifierGroups,
  setModifierGroups,
  handleAddItem,
  handleEditItem,
}) => {
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [category, setCategory] = useState('');
  const [price, setPrice] = useState(0);

  const data = addModal;

  const handleValueTotalChange = useCallback((val) => {
    setPrice(val);
  }, []);

  const typeOptions = (
    <optgroup label="Select Category">
      {menu?.categories?.map((r, i) => (
        <option value={r.id} key={i}>
          {r.name}
        </option>
      ))}
    </optgroup>
  );

  useEffect(() => {
    setCategory(menu?.categories?.[0]?.id || '');
    if (edit) {
      setName(data?.item?.name || '');
      setDescription(data?.item?.description || '');
      setCategory(data?.category?.id || menu?.categories?.[0]?.id);
      setPrice(data?.item?.price || 0);
      setModifierGroups(data?.item?.children || []);
      setImage(data?.item?.imageUrl || '');
    }
  }, [data]);

  const emptyForm = () => {
    setImage('');
    setName('');
    setDescription('');
    setCategory(menu?.categories?.[0]?.id);
    setModifierGroups([]);
    setPrice(0);
  };

  const handleSubmit = () => {
    if (edit) {
      handleEditItem(
        image,
        name,
        description,
        price,
        category,
        modifierGroups?.map((item) => Number(item.id)),
        data?.item,
        data?.category?.id,
        emptyForm
      );
    } else {
      console.log(category);
      handleAddItem(
        image,
        name,
        description,
        price,
        category,
        modifierGroups?.map((item) => item.id),
        emptyForm
      );
    }
  };

  return (
    <Modal
      isOpen={addModal}
      setIsOpen={setAddModal}
      header={edit ? 'Edit Item' : 'Add Item'}
      logo={false}
      footer={
        <>
          <Button $selected $w="100%" onClick={handleSubmit}>
            Confirm
          </Button>
        </>
      }
      $numOfFloatingButtons={1}
    >
      <Div $flex $center $mb="32">
        <ImageInput image={image} onChange={(img) => setImage(img)} />
      </Div>
      <Div $flex $column $gap="24px">
        <AdminInput
          placeholder="Enter here"
          label="Item Name"
          value={name}
          onChange={({ target }) => setName(target.value)}
        />

        <Div $flex $alignCenter $gap="24px">
          <Div $width="60%">
            {' '}
            <AdminSelect
              value={category}
              options={typeOptions}
              onChange={({ target }) => {
                setCategory(target.value);
                console.log(target.value);
              }}
              // placeholder="Select location"
              label="Choose Category"
            />{' '}
          </Div>
          <Div $width="40%">
            {' '}
            <AdminDollarInput2
              label="Price"
              onValueChange={handleValueTotalChange}
              value={price}
            />
          </Div>
        </Div>

        <AdminInput
          placeholder="Enter here"
          label="Description"
          type="textarea"
          value={description}
          onChange={({ target }) => setDescription(target.value)}
        />

        {modifierGroups?.length > 0 ? (
          <>
            <Div $flex $alignCenter $between>
              <H5 $mb="0">Modifier Groups</H5>
              <Icon $pointer onClick={() => setAddModifierGroupModal(true)}>
                <PlusIcon />
              </Icon>
            </Div>
            <div>
              {modifierGroups?.map((item, i) => (
                <AddModifierGroupComponent
                  item={item}
                  key={i}
                  edit
                  modifierGroups={modifierGroups}
                  setModifierGroups={setModifierGroups}
                />
              ))}
            </div>
          </>
        ) : (
          <Div
            $flex
            $center
            $gap="8px"
            $pointer
            onClick={() => setAddModifierGroupModal(true)}
          >
            <Icon>
              <PlusIcon />
            </Icon>

            <H5 $m="0" $color={lightTheme.primary}>
              Add Modifier Groups
            </H5>
          </Div>
        )}
      </Div>
    </Modal>
  );
};
