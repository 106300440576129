import {
  ADMIN_MENU_PROVIDER,
  Button,
  Div,
  ErrorToast,
  H3,
  Img,
  P,
  SuccessToast,
  api,
  noModifierGroups,
} from '@vgrubs/components';
import { useState } from 'react';
import { useMatch } from 'react-router';
import { ModifierGroupComponent } from './ModifierComponents';
import { AddNewModifierGroupModal } from './AddNewModifierGroupModal';
import { DeleteModifierModal } from './DeleteModifierModal';

const removeModifierGroupById = (data, idToRemove) => {
  return {
    ...data,
    modifierGroups: data.modifierGroups.filter((c) => c.id !== idToRemove),
  };
};

export const Modifiers = ({ menu, setMenu, setLoading }) => {
  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { params } = match;
  const { provider, locationId, brandId } = params;

  async function handleDeleteItem() {
    try {
      setLoading(true);
      const { data } = await api.delete(
        `/v1/menu/${locationId}/delete/${brandId}/provider/${provider}/item/${deleteModal}`
      );
      setMenu((prev) => removeModifierGroupById(prev, deleteModal));
      SuccessToast(data.message);
      setDeleteModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const AddModifierGroupButton = () => {
    return (
      <Button $selected onClick={() => setAddNewModal(true)}>
        Add Modifier Group
      </Button>
    );
  };

  return (
    <Div>
      <H3 $mb="20">
        {menu?.modifierGroups?.length < 1
          ? 'Modifier Group'
          : 'Modifier Groups'}{' '}
        ({menu?.modifierGroups?.length})
      </H3>

      {menu?.modifierGroups?.map((item, i) => (
        <ModifierGroupComponent
          key={i}
          item={item}
          onClickDelete={(id) => setDeleteModal(id)}
          onClick={(item) => setEditModal(item)}
        />
      ))}

      {menu?.modifierGroups?.length < 1 && (
        <Div $flex $column $alignCenter>
          <Img $width="180px" src={noModifierGroups} $mb="24" />
          <H3 $mb="24" $center>
            No Modifier Groups
          </H3>
          <P $mb="32" $center>
            This provider currently don’t have <br /> menu modifier groups.
          </P>
          <AddModifierGroupButton />
        </Div>
      )}

      {menu?.modifierGroups?.length > 0 && (
        <>
          <Div
            $mobile
            style={{
              position: 'fixed',
              right: '30px',
              bottom: '30px',
              left: '30px',
            }}
            $flex
            $justifyEnd
            $mt="40"
          >
            <AddModifierGroupButton />
          </Div>
          <Div
            $desktop
            style={{ position: 'fixed', right: '50px', bottom: '50px' }}
            $flex
            $justifyEnd
            $mt="40"
          >
            <AddModifierGroupButton />
          </Div>
        </>
      )}

      <AddNewModifierGroupModal
        addNewModal={addNewModal}
        setAddNewModal={setAddNewModal}
        setMenu={setMenu}
        setLoading={setLoading}
      />

      <AddNewModifierGroupModal
        addNewModal={editModal}
        setAddNewModal={setEditModal}
        setMenu={setMenu}
        setLoading={setLoading}
        edit
      />

      <DeleteModifierModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDeleteItem={handleDeleteItem}
      />
    </Div>
  );
};
