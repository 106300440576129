// import { BrandsAdmin } from '../adminPages/Brands/brands.admin';
// import { BrandsAdd } from '../adminPages/Brands/brandsAdd';

// import { WebsitesAdmin } from '../adminPages/Websites/websites.admin';
// import { WebsitesAdd } from '../adminPages/Websites/websitesAdd';
// import { WebsitesDetailsAdmin } from '../adminPages/Websites/websitesDetails.admin';

import {
  ADMIN_ADD_MANUAL_ORDER,
  ADMIN_AUDIT_LOG,
  ADMIN_BRAND_DETAILS,
  ADMIN_BRAND_MANAGE_PROVIDERS,
  ADMIN_BRAND_V_DRIVE,
  ADMIN_CLOSING_DAYS,
  ADMIN_DASHBOARD,
  ADMIN_EDIT_MANUAL_ORDER,
  ADMIN_LOCATIONS,
  ADMIN_LOCATIONS_DETAILS,
  ADMIN_LOCATIONS_ONBOARDING_V_DRIVE,
  ADMIN_LOCATIONS_V_DRIVE,
  ADMIN_LOCATION_MANAGE_PROVIDERS,
  ADMIN_MENU_DETAILS,
  ADMIN_MENU_PROVIDER,
  ADMIN_ORDERS,
  ADMIN_ORDER_DETAILS,
  ADMIN_SETTINGS,
  ADMIN_TRANSACTIONS,
  ADMIN_USERS_AND_ACCESS,
  ADMIN_VERIFICATIONS,
  ADMIN_VERIFICATIONS_AGENT,
  ADMIN_VERIFICATIONS_RESTAURANT,
  ADMIN_VERIFICATIONS_V_DRIVE,
  ADMIN_WORKING_HOURS,
  AuditLogIcon,
  BlogsAdd,
  BlogsAdmin,
  ClosingDaysPageIcon,
  DashboardIcon,
  LocationsIcon,
  OrderIcon,
  SettingsPageIcon,
  UserIcon,
  VerificationsIcon,
  WorkingHoursIcon,
} from '@vgrubs/components';

import {
  ADMIN_BLOGS,
  ADMIN_BLOGS_ADD,
  ADMIN_BLOGS_EDIT,
  ADMIN_PRIVACY_POLICY,
  ADMIN_TERMS_AND_CONDITIONS,
} from '@vgrubs/components';
import { PrivacyPolicyEdit } from '../adminPages';
import { ClosingDays } from '../adminPages/ClosingDays/ClosingDays';
import { Dashboard } from '../adminPages/Dashboard/Dashboard';
import { BrandDetails } from '../adminPages/Locations/BrandDetails';
import { LocationDetails } from '../adminPages/Locations/LocationDetails';
import { Locations } from '../adminPages/Locations/Locations';
import { ManageProviders } from '../adminPages/Locations/ManageProviders';
import { MenuDetails } from '../adminPages/Locations/ManuDetails';
import { MenuProvider } from '../adminPages/Menu/MenuProvider';
import { AddManualOrder } from '../adminPages/Orders/AddEditManualOrder';
import { OrderDetails } from '../adminPages/Orders/OrderDetails';
import { Orders } from '../adminPages/Orders/Orders';
import { Settings } from '../adminPages/Settings/Settings';
import { UsersAndAccess } from '../adminPages/UsersAndAccess/UsersAndAccess';
import { VDriveBrand } from '../adminPages/VDrive/VDriveBrand';
import { VDriveLocation } from '../adminPages/VDrive/VDriveLocation';
import { AgentDetails } from '../adminPages/Verifications/AgentDetails';
import { RestaurantDetails } from '../adminPages/Verifications/RestaurantDetails';
import { VDriveVerificationDetails } from '../adminPages/Verifications/VDriveVerificationDetails';
import { Verifications } from '../adminPages/Verifications/Verifications';
import { WorkingHolidayHours } from '../adminPages/WorkingHolidayHours/WorkingHolidayHours';
import { Transactions } from '../adminPages/Transactions/Transactions';
import { VDriveLocationOnboarding } from '../adminPages/VDrive/onboarding/VDriveLocationOnboarding';
import { AuditLog } from '../adminPages/AuditLog/AuditLog';

// import { WebsitesDetailsAdmin } from '../adminPages/Websites/websitesDetails.admin';

export const ROUTES_ADMIN = [
  {
    path: '/',
    Page: '',
    name: '',
    permissions: [],
  },
  {
    path: ADMIN_DASHBOARD,
    Page: Dashboard,
    name: 'Dashboard',
    Icon: DashboardIcon,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_VERIFICATIONS,
    Page: Verifications,
    name: 'Verifications',
    Icon: VerificationsIcon,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_USERS_AND_ACCESS,
    Page: UsersAndAccess,
    name: 'Users & Access',
    Icon: UserIcon,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_TRANSACTIONS,
    Page: Transactions,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_VERIFICATIONS_RESTAURANT,
    Page: RestaurantDetails,
    permissions: ['admin', 'support'],
  },

  {
    path: ADMIN_VERIFICATIONS_V_DRIVE,
    Page: VDriveVerificationDetails,
    permissions: ['admin', 'support'],
  },

  {
    path: ADMIN_VERIFICATIONS_AGENT,
    Page: AgentDetails,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_LOCATIONS,
    Page: Locations,
    name: 'Locations',
    Icon: LocationsIcon,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_LOCATIONS_DETAILS,
    Page: LocationDetails,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_LOCATIONS_V_DRIVE,
    Page: VDriveLocation,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_LOCATIONS_ONBOARDING_V_DRIVE,
    Page: VDriveLocationOnboarding,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_BRAND_V_DRIVE,
    Page: VDriveBrand,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_BRAND_DETAILS,
    Page: BrandDetails,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_MENU_DETAILS,
    Page: MenuDetails,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_LOCATION_MANAGE_PROVIDERS,
    Page: ManageProviders,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_BRAND_MANAGE_PROVIDERS,
    Page: ManageProviders,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_ORDERS,
    Page: Orders,
    name: 'Orders',
    Icon: OrderIcon,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_ORDER_DETAILS,
    Page: OrderDetails,
    permissions: ['admin', 'support'],
  },

  {
    path: ADMIN_ADD_MANUAL_ORDER,
    Page: AddManualOrder,
    permissions: ['admin', 'support'],
  },

  {
    path: ADMIN_EDIT_MANUAL_ORDER,
    Page: AddManualOrder,
    permissions: ['admin', 'support'],
  },

  {
    path: ADMIN_CLOSING_DAYS,
    Page: ClosingDays,
    name: 'Closing Days',
    Icon: ClosingDaysPageIcon,
    permissions: ['admin', 'support'],
  },
  {
    path: ADMIN_AUDIT_LOG,
    Page: AuditLog,
    name: 'Audit Log',
    Icon: AuditLogIcon,
    permissions: ['admin'],
  },
  {
    path: ADMIN_WORKING_HOURS,
    Page: WorkingHolidayHours,
    name: 'Working Hours',
    Icon: WorkingHoursIcon,
    permissions: ['admin', 'support'],
  },

  {
    path: ADMIN_SETTINGS,
    Page: Settings,
    name: 'Settings',
    Icon: SettingsPageIcon,
    permissions: ['admin', 'support'],
  },

  {
    path: ADMIN_BLOGS,
    Page: BlogsAdmin,
    name: 'Blogs',
    permissions: ['admin', 'support', 'marketing'],
  },

  {
    path: ADMIN_BLOGS_ADD,
    Page: BlogsAdd,
    permissions: ['admin', 'support', 'marketing'],
  },
  {
    path: ADMIN_BLOGS_EDIT,
    Page: BlogsAdd,
    permissions: ['admin', 'support', 'marketing'],
  },
  // {
  //   path: ADMIN_CONCIERGE,
  //   Page: Concierge,
  //   name: 'Concierge',
  //   Icon: ConciergeIcon,
  //   permissions: [
  //     'admin',
  //     'support',
  //     'partner',
  //     'manager',
  //     'sales',
  //     'salesAgent',
  //     'salesManager',
  //   ],
  // },

  // {
  //   path: ADMIN_PAYOUTS,
  //   Page: Payouts,
  //   name: 'Payouts',
  //   Icon: PayoutsIcon,
  //   permissions: ['partner', 'sales', 'salesAgent', 'salesManager', 'admin'],
  // },

  {
    path: ADMIN_TERMS_AND_CONDITIONS,
    Page: PrivacyPolicyEdit,
    permissions: ['admin'],
  },
  {
    path: ADMIN_PRIVACY_POLICY,
    Page: PrivacyPolicyEdit,
    permissions: ['admin'],
  },
  // {
  //   path: ADMIN_MENU,
  //   Page: Menu,
  //   name: 'Menu',
  //   Icon: LocationsIcon,
  //   permissions: ['admin', 'support'],
  // },
  {
    path: ADMIN_MENU_PROVIDER,
    Page: MenuProvider,
    permissions: ['admin', 'support'],
  },
];

// export const ALL_ROUTES = [
//   {
//     path: ADMIN_BLOGS,
//     Page: BlogsAdmin,
//     name: 'Blogs',
//     permissions: ['admin', 'support', 'marketing'],
//   },
//   {
//     path: ADMIN_BLOGS_ADD,
//     Page: BlogsAdd,
//     permissions: ['admin', 'support', 'marketing'],
//   },
//   {
//     path: ADMIN_BLOGS_EDIT,
//     Page: BlogsAdd,
//     permissions: ['admin', 'support', 'marketing'],
//   },
//   {
//     path: ADMIN_CONCIERGE,
//     Page: Concierge,
//     name: 'Concierge',
//     Icon: ConciergeIcon,
//     permissions: [
//       'admin',
//       'support',
//       'partner',
//       'manager',
//       'sales',
//       'salesAgent',
//       'salesManager',
//     ],
//   },

//   {
//     path: ADMIN_PAYOUTS,
//     Page: Payouts,
//     name: 'Payouts',
//     Icon: PayoutsIcon,
//     permissions: ['partner', 'sales', 'salesAgent', 'salesManager', 'admin'],
//   },

//   {
//     path: ADMIN_TERMS_AND_CONDITIONS,
//     Page: PrivacyPolicyEdit,
//     name: 'Terms & Conditions',
//     Icon: TermsIcon,
//     permissions: ['admin'],
//   },
//   {
//     path: ADMIN_PRIVACY_POLICY,
//     Page: PrivacyPolicyEdit,
//     name: 'Privacy Policy',
//     Icon: PrivacyIcon,
//     permissions: ['admin'],
//   },

//   {
//     path: ADMIN_WEBSITES,
//     Page: WebsitesAdmin,
//     name: 'Websites',
//   },
//   {
//     path: ADMIN_WEBSITES_DETAILS,
//     Page: WebsitesDetailsAdmin,
//   },
//   {
//     path: ADMIN_WEBSITES_ADD,
//     Page: WebsitesAdd,
//   },

//   {
//     path: ADMIN_BRANDS,
//     Page: BrandsAdmin,
//     name: 'Brands',
//   },
//   {
//     path: ADMIN_BRANDS_ADD,
//     Page: BrandsAdd,
//   },
// ];
