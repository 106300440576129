import {
  AdminSpinner,
  AnimatedDiv,
  ErrorToast,
  H3,
  Img,
  P,
  api,
  lightTheme,
  noVDrive,
  restaurantImage,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { VDriveComponent } from '../components/VDriveComponent';

export const VDriveVerifications = () => {
  const [loading, setLoading] = useState(false);
  const [vDriveVerifications, setVDriveVerifications] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();

  async function getVDriveVerifications() {
    if (!hasMore) return;

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/vDrive`, {
        params: {
          limit: 20,
          page: page,
        },
      });

      if (data.data[0]) {
        setPage((prevPage) => prevPage + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      setVDriveVerifications((prevItems) => [...prevItems, ...data.data]);

      console.log(data.data);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getVDriveVerifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && vDriveVerifications.length > 0) {
      getVDriveVerifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {loading && <AdminSpinner />}

      {vDriveVerifications.map((vDriveVerification, i) => {
        return (
          <VDriveComponent key={i} vDriveVerification={vDriveVerification} />
        );
      })}

      <div ref={ref}></div>

      {!hasMore && vDriveVerifications.length === 0 && (
        <AnimatedDiv $height="70vh" $flex $column $alignCenter $justifyCenter>
          <Img src={noVDrive} $width="240px" />
          <H3 $center $mb="16" $mt="20" $color={lightTheme.primary}>
            No vDrive!
          </H3>
          <P $center>
            You currently do not have any <br /> vDrive to verify.
          </P>
        </AnimatedDiv>
      )}
    </>
  );
};
