import { AdminInput, Button, Div, Modal } from '@vgrubs/components';
import { AddModifierGroupComponent } from './ModifierGroupComponents';
import { useState } from 'react';

export const AddModifierGroupModal = ({
  addModifierGroupModal,
  setAddModifierGroupModal,
  setModifierGroups,
  modifierGroups,
  menu,
  setAddNewModifierGroupModal,
}) => {
  const [search, setSearch] = useState('');

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
  };

  const filteredItems = menu?.modifierGroups?.filter((m) =>
    m.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Modal
      isOpen={addModifierGroupModal}
      setIsOpen={setAddModifierGroupModal}
      header="Add/Edit Modifier Groups"
      logo={false}
      zIndex={100}
      footer={
        <>
          {' '}
          <Button
            $selected
            $w="100%"
            $mb="16"
            $mt="32"
            onClick={() => setAddModifierGroupModal(false)}
          >
            Confirm
          </Button>
          <Button
            $selected
            $gray
            $w="100%"
            onClick={() => setAddNewModifierGroupModal(true)}
          >
            Add New Modifier Group
          </Button>
        </>
      }
    >
      <div>
        <Div $mb="20">
          <AdminInput
            placeholder="Search Modifier Group"
            search
            value={search}
            onChange={handleInputChange}
          />
        </Div>
        {filteredItems?.map((item, i) => (
          <AddModifierGroupComponent
            item={item}
            key={i}
            modifierGroups={modifierGroups}
            setModifierGroups={setModifierGroups}
          />
        ))}
      </div>
    </Modal>
  );
};
