import { Button, H3, Modal, P, lightTheme } from '@vgrubs/components';

export const DeleteCategoryModal = ({
  deleteModal,
  setDeleteModal,
  handleDeleteCategory,
}) => {
  return (
    <Modal isOpen={deleteModal} setIsOpen={setDeleteModal} logo={false}>
      <H3 $mb="24" $color={lightTheme.primary}>
        Are you sure
      </H3>
      <P $mb="40">
        Are you sure you want to delete this category? <br /> This will delete
        items within the category as well.
      </P>

      <Button $selected $w="100%" $mb="8" onClick={handleDeleteCategory}>
        Confirm
      </Button>

      <Button $selected $w="100%" $gray onClick={() => setDeleteModal(false)}>
        Cancel
      </Button>
    </Modal>
  );
};
