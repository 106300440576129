import {
  H5,
  Modal,
  P,
  Separator,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { TextWithTooltipIcon } from '../TransactonsStyled';

export const DepositDetailModal = ({ modalIsOpen, setModalIsOpen }) => {
  const transaction = modalIsOpen.object;

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      textAlign={'left'}
      header={'Deposit Details'}
    >
      <P $m="0">Date & Time</P>
      <H5 $m="0">
        {DateTime.fromISO(transaction?.createdAt).toFormat(
          'MMM dd, yyyy - hh:mm a'
        )}
      </H5>
      <Separator $m="20px 0" />
      <P $m="0">Description</P>
      <H5 $m="0">Deposit added to vDrive Balance</H5>
      <Separator $m="20px 0" />

      <TextWithTooltipIcon
        text="Deposit amount"
        tooltip={'Total deposited for vDrive balance.'}
      />
      <H5 $color={lightTheme.green} $m="0">
        {formatter.format(centsToDollars(transaction?.amount || 0))}
      </H5>
      <Separator $m="20px 0" />
      <TextWithTooltipIcon
        text="Processing Fee (2.9% + 30c)"
        tooltip={'Card Processing Fee.'}
      />
      <H5 $m="0">
        {formatter.format(centsToDollars(transaction?.stripeFee || 0))}
      </H5>
      <Separator $m="20px 0" />
      <TextWithTooltipIcon
        text="vDrive Credit Card Fee (2%)"
        tooltip={
          'vDrive cards cost you an additional 2% on every transaction! Please change this card to your own to avoid this fee.'
        }
      />
      <H5 $m="0">
        {' '}
        {formatter.format(centsToDollars(transaction?.vDriveFee || 0))}
      </H5>
      <Separator $m="20px 0" />
      <P $m="0">Total</P>
      <H5 $m="0">
        {formatter.format(centsToDollars(transaction?.amounWithFee || 0))}
      </H5>
    </Modal>
  );
};
