import {
  Disclaimer,
  Div,
  Icon,
  Img,
  VDriveIcon,
  renderVDriveImage,
  renderVDriveProviderName,
} from '@vgrubs/components';

export const PoweredByComponent = ({ data }) => {
  return (
    <>
      <Div
        $desktop
        $borderRight
        $borderLeft
        $p="0 16px"
        $gap="16px"
        $justifyCenter
      >
        <Disclaimer $mb="8">Powered by:</Disclaimer>
        <Div $flex $alignCenter $gap="16px">
          <Icon $size={'70'}>
            <VDriveIcon />
          </Icon>

          <Div $outlineBox $borderColor={'#e3e3e3'} $p="4px 8px" $flex $center>
            <Img src={renderVDriveImage(data.vDriveProvider)} $width="80px" />
          </Div>
        </Div>
      </Div>

      <Div $mobile $flex $alignCenter $gap="16px" $between>
        <Div $flex $alignCenter>
          <Div $borderRight $mr="16" $p="0 16px 0 0">
            <Icon $sizeSM={'50'}>
              <VDriveIcon />
            </Icon>
          </Div>

          <Disclaimer $mb="0">Powered by:</Disclaimer>
        </Div>

        <Div $outlineBox $borderColor={'#e3e3e3'} $p="4px 8px" $flex $center>
          <Img src={renderVDriveImage(data.vDriveProvider)} $widthSM="60px" />
        </Div>
      </Div>
    </>
  );
};
