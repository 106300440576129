import {
  AdminInput,
  AdminSpinner,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H5,
  Icon,
  Img,
  Modal,
  P,
  Radio,
  ReferralIcon,
  agentImage,
  api,
  lightTheme,
} from '@vgrubs/components';
import debouce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export const EditReferralModal = ({
  modalIsOpen,
  setModalIsOpen,
  setReferralUser,
  referralUser,
}) => {
  const [query, setQuery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();
  const [referral, setReferral] = useState(null);

  async function getAllUsers(search, page, hasMore) {
    if (!hasMore) return;

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/users/multi-roles`, {
        params: {
          limit: 20,
          page: page,
          query: query.length > 0 ? query : null,
          roles: ['salesAgent', 'salesManager'],
        },
      });

      if (!search) {
        if (data.data[0]) {
          setHasMore(true);
          setUsers((prevItems) => [...prevItems, ...data.data]);
        } else {
          setHasMore(false);
        }
      } else {
        if (data.data[0]) {
          setHasMore(true);
          setUsers(() => [...data.data]);
        } else {
          setUsers(() => [...data.data]);
          setHasMore(false);
        }
      }

      setPage((prevPage) => prevPage + 1);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (modalIsOpen) {
      setReferral(referralUser);
      setHasMore(true);
      setPage(1);
      getAllUsers(true, 1, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, modalIsOpen]);

  useEffect(() => {
    if (inView && users.length > 0) {
      getAllUsers(false, page, hasMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleChange = async (e) => {
    setQuery(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleSetReferralUser = () => {
    if (!referral) return ErrorToast('Choose referral');

    const objectToSet = {
      firstName: referral.firstName,
      lastName: referral.lastName,
      id: referral.id,
    };

    setReferralUser(objectToSet);
    setModalIsOpen(false);
  };

  return (
    <>
      {loading && <AdminSpinner />}
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        header="Select Referral"
        zIndex={101}
        footer={
          <Button
            $mt="40"
            $w="100%"
            $selected
            onClick={() => handleSetReferralUser()}
          >
            Confirm
          </Button>
        }
      >
        <AdminInput
          maxWidth="100%"
          maxWidthSM="100%"
          search
          placeholder="Search User"
          onChange={debouncedResults}
        />

        {users.map((user, i) => {
          return (
            <ReferralUserComponent
              key={i}
              referral={referral}
              setReferral={setReferral}
              user={user.user}
            />
          );
        })}

        {users.length === 0 && (
          <Div
            $flex
            $center
            $column
            $height="calc(70vh - 300px)"
            $heightSM="calc(60vh - 300px)"
          >
            <Img
              src={agentImage}
              alt="restaurant"
              $width="200px"
              $widthSM="150px"
            />
            <H5 $mt="16">No users were found</H5>
          </Div>
        )}

        <div ref={ref}></div>
      </Modal>
    </>
  );
};

const ReferralUserComponent = ({ referral, setReferral, user }) => {
  const referralIsSelected = referral?.id === user.id;

  return (
    <Div
      $box
      $p="18px 24px"
      $mb="8"
      $flex
      $alignCenter
      $between
      onClick={() => setReferral(user)}
      $gap="16px"
      $pointer
      $m="16px 0"
    >
      <div>
        <P $bold $mb="4" $color={referralIsSelected && lightTheme.primary}>
          {user?.firstName} {user?.lastName}
        </P>
      </div>

      <Radio checked={referralIsSelected} />
    </Div>
  );
};

export const ReferralComponent = ({ setModalIsOpen, referral }) => {
  return (
    <Div $box $flex $alignCenter $between $gap="16px" $p="16px">
      {referral && (
        <div>
          <P $bold $left $m="0">
            {referral.firstName} {referral.lastName}
          </P>
          <Disclaimer $color={lightTheme.lightGray} $left $m="0">
            Referral
          </Disclaimer>
        </div>
      )}

      {referral && (
        <Icon $pointer onClick={() => setModalIsOpen(true)}>
          <ReferralIcon />
        </Icon>
      )}

      {!referral && (
        <Disclaimer $left $m="0">
          This location doesn’t have referral.
        </Disclaimer>
      )}

      {!referral && (
        <Button
          $small
          $selected
          $w="140px"
          onClick={() => setModalIsOpen(true)}
        >
          Select Referral
        </Button>
      )}
    </Div>
  );
};
