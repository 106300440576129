import { useRef } from 'react';
import styled from 'styled-components';
import { P } from '@vgrubs/components';
import { UploadedImage } from '../UploadedImage/UploadedImage';
import { ErrorToast } from '../Toasts/Toasts';
import Compressor from 'compressorjs';

const AdminInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  input {
    visibility: hidden;
    height: 0;
    width: 0;
  }
`;

export const AdminImageInput = ({ image, onChange, label }) => {
  const inputFileRef = useRef();

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const handleChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    const maxSize = 2097152;

    if (files.length) {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 2500,
        maxHeight: 1500,
        convertSize: 2097152,
        success: (compressedResult) => {
          console.log(compressedResult.size);
          if (compressedResult.size > maxSize) {
            return ErrorToast('Image file too big');
          } else {
            onChange(compressedResult);
          }
        },
      });
    }
  };

  return (
    <AdminInputStyled onClick={onBtnClick}>
      <P $mb="10">
        <b>{label}</b>
      </P>

      <UploadedImage image={image} />

      <input ref={inputFileRef} type="file" onChange={handleChange} />
    </AdminInputStyled>
  );
};
