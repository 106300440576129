import {
  AdminDollarInput,
  AdminDollarInput2,
  AdminInput,
  Button,
  Div,
  ErrorToast,
  H3,
  Img,
  Modal,
  SuccessToast,
  api,
  moneyCoins,
  useUsers,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';

export const UserCreditCardEditBalance = ({ id }) => {
  const [amount, setAmount] = useState('');
  const [adminPassword, setAdminPassword] = useState('');

  const { editBalanceModal, setEditBalanceModal, setLoading, setBank, bank } =
    useUsers((state) => state);

  const availableBalance = bank?.balance?.availableBalance;

  async function handleAddBank() {
    if (!amount || !adminPassword) {
      return ErrorToast('Please fill in all information');
    }

    try {
      setLoading(true);

      if (amount === availableBalance)
        return ErrorToast('Change Balance to proceed');

      if (amount < availableBalance) {
        const amountToDeduct = availableBalance - amount;

        const body = {
          amount: amountToDeduct,
          adminPassword,
        };

        const { data } = await api.put(
          `/v1/users/bank/${id}/deduct-balance`,
          body
        );
        SuccessToast(data.message);
        setBank({
          ...bank,
          balance: {
            ...bank.balance,
            availableBalance: availableBalance - amountToDeduct,
          },
        });
      }

      if (amount > availableBalance) {
        const amountToIncrease = amount - availableBalance;

        const body = {
          amount: amountToIncrease,
          adminPassword,
        };

        const { data } = await api.put(
          `/v1/users/bank/${id}/increase-balance`,
          body
        );
        SuccessToast(data.message);
        setBank({
          ...bank,
          balance: {
            ...bank.balance,
            availableBalance: availableBalance + amountToIncrease,
          },
        });
      }

      setEditBalanceModal(false);
      setAmount('');

      // getBankByUserId(id, setLoading, setBank);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (bank) {
      setAmount(bank.balance.availableBalance);
    }
  }, [bank]);

  const handleAmountChange = useCallback((val) => {
    setAmount(val);
  }, []);

  console.log(amount);

  return (
    <Modal
      isOpen={editBalanceModal}
      setIsOpen={setEditBalanceModal}
      logo={false}
      // header="Add Bank"
    >
      <Img width={230} src={moneyCoins} alt="edit balance" />
      <H3 $m="24px 0">Edit Balance</H3>
      <Div $flex $column $gap="20px" $gapSM="16px">
        <AdminDollarInput2
          label="Available Balance"
          onValueChange={handleAmountChange}
          value={amount}
          $textCenter
        />

        <AdminInput
          label="Admin Password"
          placeholder="Enter here"
          value={adminPassword}
          onChange={(e) => setAdminPassword(e.target.value)}
          $password
          $textCenter
          type="text"
          autoComplete="off"
        />
      </Div>

      <Button $selected $w="100%" $mt="50" $mtSM="30" onClick={handleAddBank}>
        Save
      </Button>
      <Button $selected $gray $w="100%" $mt="16" onClick={handleAddBank}>
        Cancel
      </Button>
    </Modal>
  );
};
