import {
  AdminSelect,
  AdminSingleLocationPicker,
  AdminSpinner,
  AnimatedDiv,
  Button,
  Div,
  ErrorToast,
  H3,
  H5,
  P,
  SuccessToast,
  api,
  lightTheme,
  read,
  store,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { PrintingSettingsModal } from '../PrintingSettings';

export const LocationSettings = () => {
  const [loading, setLoading] = useState(false);
  const [printingSettingsModal, setPrintingSettingsModal] = useState(false);
  const [locationDetails, setLocationDetails] = useState('');
  const locationLSNode = 'vgrubs-admin-settings-location';
  const locationFromLS = JSON.parse(read(locationLSNode));

  const [selectedLocation, setSelectedLocation] = useState(
    locationFromLS || null
  );

  async function getLocationSettings() {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/locations/${selectedLocation.id}/settings`
      );
      const res = await api.get(
        `/v1/locations/${selectedLocation.id}/footer-message`
      );
      setLocationDetails({ ...data.data, message: res.data.data.message });
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedLocation) {
      getLocationSettings();
    }
  }, [selectedLocation]);

  async function handleUpdatePrepTime(prepTime) {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/locations/${selectedLocation.id}/auto-accept`,
        { prepTime: Number(prepTime) }
      );

      SuccessToast(data.message);

      setLocationDetails((prev) => {
        return { ...prev, prepTime: Number(prepTime) };
      });

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const handleLocationsSubmit = (l) => {
    store(locationLSNode, JSON.stringify(l));
  };

  return (
    <>
      {loading && <AdminSpinner />}

      <AnimatedDiv>
        <H3 $mb="24px">Location Settings</H3>

        <AdminSingleLocationPicker
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          handleSubmit={(l) => handleLocationsSubmit(l)}
        />

        <>
          <Div $box $p="24px 16px" $m="8px 0">
            <Div $flex $alignCenter $between>
              <div>
                <H5 $color={lightTheme.primary} $mb="4">
                  Auto Accept
                </H5>
                <P $color={lightTheme.lightGray} $mb="0">
                  Automatically accept incoming orders
                </P>
              </div>
              <Div>
                <AdminSelect
                  value={
                    locationDetails?.prepTime !== null
                      ? locationDetails?.prepTime
                      : ''
                  }
                  options={minutesOptions}
                  onChange={({ target }) => handleUpdatePrepTime(target.value)}
                />
              </Div>
            </Div>
          </Div>

          <Div $box $p="24px 16px" $m="8px 0">
            <Div $flex $alignCenter $between>
              <H5 $color={lightTheme.primary} $mb="0">
                Printing Settings
              </H5>
              <Button
                $gray
                $selected
                $small
                $w="100px"
                onClick={() => setPrintingSettingsModal(true)}
              >
                Edit
              </Button>
            </Div>
          </Div>
          <PrintingSettingsModal
            modalIsOpen={printingSettingsModal}
            setModalIsOpen={setPrintingSettingsModal}
            setLoading={setLoading}
            loading={loading}
            data={locationDetails}
            setData={setLocationDetails}
            id={selectedLocation?.id}
          />
        </>
      </AnimatedDiv>
    </>
  );
};

const minutes = [
  { name: 'Off', value: null },
  { name: '5 mins', value: 5 },
  { name: '10 mins', value: 10 },
  { name: '15 mins', value: 15 },
  { name: '20 mins', value: 20 },
  { name: '25 mins', value: 25 },
  { name: '30 mins', value: 30 },
  { name: '35 mins', value: 35 },
  { name: '40 mins', value: 40 },
  { name: '45 mins', value: 45 },
  { name: '50 mins', value: 50 },
  { name: '55 mins', value: 55 },
  { name: '60 mins', value: 60 },
];

const minutesOptions = (
  <optgroup label="Choose option">
    {minutes.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);
