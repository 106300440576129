import {
  AdminSingleBrandPicker,
  AdminSingleLocationPicker,
  AdminSpinner,
  AnimatedDiv,
  Button,
  Div,
  ErrorToast,
  H3,
  H5,
  api,
  lightTheme,
  store,
  read,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { BrandFooterMessageModal } from '../BrandFooterMessageModal';

export const BrandSettings = () => {
  const [loading, setLoading] = useState(false);
  const [printingSettingsModal, setPrintingSettingsModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [locationDetails, setLocationDetails] = useState('');

  const locationLSNode = 'vgrubs-admin-settings-brands-location';
  const locationFromLS = JSON.parse(read(locationLSNode));

  const [selectedLocation, setSelectedLocation] = useState(
    locationFromLS || null
  );

  async function getBrandSettings() {
    try {
      setLoading(true);

      const res = await api.get(
        `/v1/brands/${selectedBrand?.id}/footer-message`
      );
      setLocationDetails({ message: res.data.data.message });
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedLocation && selectedBrand) {
      getBrandSettings();
    }
  }, [selectedLocation, selectedBrand]);

  const handleLocationsSubmit = (l) => {
    store(locationLSNode, JSON.stringify(l));
  };

  return (
    <>
      {loading && <AdminSpinner />}
      <AnimatedDiv>
        <H3 $mb="24px">Brand Settings</H3>
        <Div $flex $alignCenter $between $gap="48px" $gapSM="16px" $columnSM>
          <Div $width="50%" $widthSM="100%">
            <AdminSingleLocationPicker
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              handleSubmit={(l) => handleLocationsSubmit(l)}
            />
          </Div>
          <Div $width="50%" $widthSM="100%">
            <AdminSingleBrandPicker
              selectedLocation={selectedLocation}
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
              preselectIfEmpty
              // handleSubmit={() => handleBrandsSubmit()}
            />
          </Div>
        </Div>
        <Div $box $p="24px 16px" $m="8px 0">
          <Div $flex $alignCenter $between>
            <H5 $color={lightTheme.primary} $mb="0">
              Printing Settings
            </H5>
            <Button
              $gray
              $selected
              $small
              $w="100px"
              onClick={() => {
                if (selectedBrand) {
                  setPrintingSettingsModal(true);
                } else {
                  ErrorToast('Brand not selected');
                }
              }}
            >
              Edit
            </Button>
          </Div>
        </Div>

        <BrandFooterMessageModal
          modalIsOpen={printingSettingsModal}
          setModalIsOpen={setPrintingSettingsModal}
          setLoading={setLoading}
          loading={loading}
          data={locationDetails}
          setData={setLocationDetails}
          id={selectedBrand?.id}
        />
      </AnimatedDiv>
    </>
  );
};

const minutes = [
  { name: '5 mins', value: 5 },
  { name: '10 mins', value: 10 },
  { name: '15 mins', value: 15 },
  { name: '20 mins', value: 20 },
  { name: '25 mins', value: 25 },
  { name: '30 mins', value: 30 },
  { name: '35 mins', value: 35 },
  { name: '40 mins', value: 40 },
  { name: '45 mins', value: 45 },
  { name: '50 mins', value: 50 },
  { name: '55 mins', value: 55 },
  { name: '60 mins', value: 60 },
];

const minutesOptions = (
  <optgroup label="Select Role">
    {minutes.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);
