import {
  AnimatedDiv,
  Div,
  H5,
  Img,
  capitalizeFirstLetter,
  money,
  setDocumentTitle,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useInView } from 'react-intersection-observer';
import {
  AdminDatePicker,
  AdminHeader,
  AdminSelectPagination,
  AdminSpinner,
} from '../../adminComponents';
import { useAuth } from '../../store/auth';
import { getCustomers, getPayouts } from './logic';
import { PayoutComponent } from './payoutComponent';

const INITIAL_CUSTOMERS_STATE = {
  customerId: null,
  user: {
    firstName: 'All',
    lastName: 'Customers',
  },
};

export const Payouts = () => {
  const [payouts, setPayouts] = useState([]);
  const [customers, setCustomers] = useState([INITIAL_CUSTOMERS_STATE]);

  const [selectedCustomer, setSelectedCustomer] = useState(
    INITIAL_CUSTOMERS_STATE
  );
  const [ref, inView] = useInView();
  const [dropdown, setDropdown] = useState(false);
  const [dropdownCustomers, setDropdownCustomers] = useState(false);

  const dd = new Date();

  const today = new Date();

  // Get current month
  const month = today.getMonth();

  // Set today's date back to previous month
  today.setMonth(month - 1);

  const [date, setDate] = useState([today, dd]);

  const [page, setPage] = useState(1);
  const [pageCustomers, setPageCustomers] = useState(1);
  const [limit] = useState(40);
  const [limitCustomers] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);

  const [loading, setLoading] = useState(false);

  const { role } = useAuth((state) => state.user);

  const handleGetCustomers = () => {
    if (role !== 'admin') return;
    getCustomers(
      pageCustomers,
      limitCustomers,
      hasMoreCustomers,
      setCustomers,
      setPageCustomers,
      setLoading,
      setHasMoreCustomers,
      setDropdownCustomers
    );
  };

  useEffect(() => {
    handleGetCustomers();
    if (date.length < 2) return;
    setPage(1);
    setPayouts([]);

    getPayouts(
      date,
      1,
      limit,
      true,
      setPayouts,
      setPage,
      setLoading,
      setHasMore,
      setDropdown,
      selectedCustomer,
      setDropdownCustomers
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  const handleDateApply = () => {
    if (date.length < 2) return;
    setPage(1);
    setPayouts([]);

    getPayouts(
      date,
      1,
      limit,
      true,
      setPayouts,
      setPage,
      setLoading,
      setHasMore,
      setDropdown,
      selectedCustomer,
      setDropdownCustomers
    );
  };

  useEffect(() => {
    if (inView && date.length > 1 && payouts.length > 0) {
      getPayouts(
        date,
        page,
        limit,
        hasMore,
        setPayouts,
        setPage,
        setLoading,
        setHasMore,
        setDropdown,
        selectedCustomer,
        setDropdownCustomers
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleDateChange = (date) => {
    if (date.length > 1) {
      setDate(date);
      // setDropdown(false);
    }
  };

  const dateFrom = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[0]);

  const dateTo = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[1]);

  setDocumentTitle(`Payouts | vGrubs ${capitalizeFirstLetter(role)} `);

  return (
    <>
      <AdminHeader>
        <AdminDatePicker
          mode="range"
          value={date}
          onChange={handleDateChange}
          dropdown={dropdown}
          setDropdown={setDropdown}
          click={handleDateApply}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </AdminHeader>
      {loading && <AdminSpinner />}
      <AnimatedDiv>
        <Div $flex $gap="24px" $gapSM="16px" $alignCenter $m="20px 0" $columnSM>
          {role === 'admin' && (
            <AdminSelectPagination
              dropdown={dropdownCustomers}
              setDropdown={setDropdownCustomers}
              onChange={setSelectedCustomer}
              value={selectedCustomer}
              options={customers}
              placeholder="Select Customer"
              get={handleGetCustomers}
              hasMore={hasMoreCustomers}
            />
          )}
        </Div>

        {payouts.map(({ createdAt, status, amount, bankName }, i) => (
          <PayoutComponent
            createdAt={createdAt}
            status={status}
            amount={amount}
            bankName={bankName}
            key={i}
          />
        ))}

        {!payouts.length && !hasMore && (
          <AnimatedDiv $column $center $flex>
            <Img
              src={money}
              alt="no payouts"
              $maxWidth={'100%'}
              $widthSM={'70%'}
              $width={'300px'}
              $mt="50"
              $mtSM="0"
            />
            <H5 $center>
              You don't have any payment
              <br /> for selected date
            </H5>
          </AnimatedDiv>
        )}
        <div ref={ref}></div>
      </AnimatedDiv>
    </>
  );
};
