import {
  AdminBrandsPicker,
  AdminDatePicker,
  AdminHeader,
  AdminLocationsPicker,
  AdminSpinner,
  AnimatedDiv,
  Div,
  ErrorToast,
  H5,
  api,
  isToday,
  setTimeTo,
  useAuth,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { GridStats } from '../Locations/LocationStyled';
import { LocationComponent } from './LocationComponent';
import { AllProvidersModalComponent } from './AllProvidersModalComponent';

const salesType = {
  VGRUBS_BRANDS: 'vGrubs',
  TDOC: 'TDOC',
  FUTURE_FOODS: 'FF',
  RESTAURANT_BRANDS: 'RB',
};

const renderSalesTitle = (type) => {
  switch (type) {
    case salesType.VGRUBS_BRANDS:
      return 'Vgrubs Brands';
    case salesType.TDOC:
      return 'TODC';
    case salesType.FUTURE_FOODS:
      return 'Future Foods';
    case salesType.RESTAURANT_BRANDS:
      return 'Restaurant Brands';

    default:
      break;
  }
};

export const Dashboard = () => {
  const [data, setData] = useState([]);
  const [dataBrands, setDataBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useAuth((state) => state.user);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [allProvidersModal, setAllProvidersModal] = useState(null);

  const today = new Date();

  const [date, setDate] = useState([
    setTimeTo(today, 'start'),
    setTimeTo(today, 'end'),
  ]);
  const [dateInput, setDateInput] = useState([today, today]);

  const dateFrom = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[0]);

  const dateTo = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[1]);

  const handleDateChange = (date) => {
    setDateInput(date);
  };

  async function getDashboardInfo(selectedBrands) {
    const locs = selectedLocations
      .map(({ id }) => `locationIds=${id}`)
      .join('&');

    const brnds = selectedBrands.map(({ id }) => `brandIds=${id}`).join('&');

    const queryStringNoLocations = new URLSearchParams({
      from: setTimeTo(date[0], 'start'),
      to: setTimeTo(date[1], 'end'),
    }).toString();

    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/locations/dashboard?${queryStringNoLocations}&${locs}&${brnds}`
      );
      const res = await api.get(
        `/v1/locations/dashboard-brands?${queryStringNoLocations}&${locs}&${brnds}`
      );
      setData(data.data);
      setDataBrands(res.data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const handleDateApply = () => {
    if (dateInput.length < 2) {
      setDate([dateInput[0], dateInput[0]]);
      setDateInput([dateInput[0], dateInput[0]]);
    } else {
      setDate(dateInput);
    }
  };

  useEffect(() => {
    if (user) {
      getDashboardInfo(selectedBrands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleLocationsSubmit = () => {
    if (user) {
      getDashboardInfo([]);
      setSelectedBrands([]);
    }
  };

  const handleBrandsSubmit = () => {
    if (user) {
      getDashboardInfo(selectedBrands);
    }
  };

  const renderDateLabel = () => {
    if (isToday(date[0]) && isToday(date[1])) {
      return 'Today';
    } else return `${dateFrom} - ${dateTo}`;
  };

  return (
    <>
      <AdminHeader>
        <AdminDatePicker
          mode="range"
          value={dateInput}
          onChange={handleDateChange}
          click={handleDateApply}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </AdminHeader>
      {loading && <AdminSpinner />}
      <AnimatedDiv
        $justifyEnd
        $flex
        $between
        $alignCenter
        $gap="32px"
        $gapSM="16px"
        $columnSM
        $borderBottom
        $p="32px 0"
        $mb="32"
      >
        <Div $width="30%" $widthSM="100%">
          <H5 $mb="0">{renderDateLabel()}</H5>
        </Div>
        <Div $width="35%" $widthSM="100%">
          <AdminLocationsPicker
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            handleSubmit={() => handleLocationsSubmit()}
          />
        </Div>
        <Div $width="35%" $widthSM="100%">
          <AdminBrandsPicker
            selectedLocations={selectedLocations}
            selectedBrans={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            handleSubmit={() => handleBrandsSubmit()}
          />
        </Div>
      </AnimatedDiv>
      {!loading && (
        <AnimatedDiv>
          <H5>Locations</H5>
          <GridStats>
            <LocationComponent
              title="Total Sales"
              data={data?.locationSales?.total}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Orders"
              data={data?.locationSales?.count}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Taxes"
              data={data?.locationSales?.tax}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Recovered"
              data={data?.locationSales?.recovered}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Canceled"
              data={data?.locationSales?.cancelled}
              setAllProvidersModal={setAllProvidersModal}
            />
          </GridStats>
          <H5>vGrubs Brands</H5>

          <GridStats>
            <LocationComponent
              title="Total Sales"
              data={data?.brandSales?.total}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Orders"
              data={data?.brandSales?.count}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Taxes"
              data={data?.brandSales?.tax}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Recovered"
              data={data?.brandSales?.recovered}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Canceled"
              data={data?.brandSales?.cancelled}
              setAllProvidersModal={setAllProvidersModal}
            />
          </GridStats>

          {dataBrands.map((d, i) => {
            return (
              <div key={i}>
                <H5>{renderSalesTitle(d.type)}</H5>
                <GridStats>
                  <LocationComponent
                    title="Total Sales"
                    data={d.sales?.total}
                    setAllProvidersModal={setAllProvidersModal}
                  />
                  <LocationComponent
                    title="Total Orders"
                    data={d.sales?.count}
                    setAllProvidersModal={setAllProvidersModal}
                  />
                  <LocationComponent
                    title="Total Taxes"
                    data={d.sales?.tax}
                    setAllProvidersModal={setAllProvidersModal}
                  />
                  <LocationComponent
                    title="Total Recovered"
                    data={d.sales?.recovered}
                    setAllProvidersModal={setAllProvidersModal}
                  />
                  <LocationComponent
                    title="Total Canceled"
                    data={d.sales?.cancelled}
                    setAllProvidersModal={setAllProvidersModal}
                  />
                </GridStats>
              </div>
            );
          })}
        </AnimatedDiv>
      )}
      <AllProvidersModalComponent
        allProvidersModal={allProvidersModal}
        setAllProvidersModal={setAllProvidersModal}
      />
    </>
  );
};
