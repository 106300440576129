import {
  ADMIN_EDIT_MANUAL_ORDER,
  AdminSpinner,
  ArrowRed,
  DeleteIconRed,
  Disclaimer,
  Div,
  EditPenIconRed,
  ErrorToast,
  H3,
  H5,
  Icon,
  Img,
  ModalFullScreen,
  OrderCourierIcon,
  OrderCustomerIcon,
  OrderDetailsIcon,
  OrderInfoIcon,
  OrderNotesIcon,
  OrderUtilsIcon,
  P,
  PrinterIcon,
  RecoveryIcon,
  UnderlineHoverLink,
  VerticalSeparator,
  api,
  centsToDollars,
  formatter,
  lightTheme,
  showStringIfNotNull,
  useOrders,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { providerLogo, providerName } from '../Locations/helpers';
import { CancelVDriveModal } from './CancelVDriveModal';
import { ChangeAddress } from './ChangeAddress';
import { CourierModal } from './CourierModal';
import { DeleteOrderModal } from './DeleteOrderModal';
import { PoweredByComponent } from './PoweredByComponent';
import { PrintReceiptModal } from './PrintReceiptModal';
import { RecoveryModal } from './RecoveryModal';
import { RecoveryStatusBadge } from './RecoveryStatusBadge';
import { VDriveAssignDriver } from './VDriveAssignDriver';
import { VDriveStatusComponent } from './VDriveStatusComponent';
import { WhatIsThisModal } from './WhatIsThisModal';

export const recoveryStatus = {
  RECOVERED: 'recovered',
  ADJUSTMENT: 'adjustment',
  PENDING: 'pending',
  NOT_ELIGIBLE: 'notEligible',
  EVIDENCE_NEEDED: 'evidenceNeeded',
};

export const OrderDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [courierModalIsOpen, setCourierModalIsOpen] = useState(false);
  const [assignDriverModal, setAssignDriverModal] = useState(false);
  const [noDriverModal, setNoDriverModal] = useState(false);
  const [whatIsThisModal, setWhatIsThisModal] = useState(false);
  const [changeAddressModal, setChangeAddressModal] = useState(false);
  const [cancelVDriveModal, setCancelVDriveModal] = useState(false);

  const {
    detailsModal,
    setDetailsModal,
    setDeleteManualOrderModal,
    setOrderToEdit,
    setPrintOrderModal,
  } = useOrders((state) => state);

  const id = detailsModal;

  async function getOrderDetails() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/orders/${id}`);
      setData(data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function getDrivers() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/orders/${id}/vdrive`);

      if (data?.data?.length > 0) {
        setAssignDriverModal(data.data);
      } else {
        setNoDriverModal(true);
      }

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      getOrderDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const SectionTitle = ({ Icon, title }) => {
    return (
      <Div $flex $gap="16px" $alignCenter $mb="5">
        <Icon />
        <H5 $m="0">{title}</H5>
      </Div>
    );
  };

  const SectionInfo = ({
    info,
    amount,
    hasAmount,
    item,
    itemChild,
    grayAmount,
    instructions,
  }) => {
    const formattedAmount = formatter.format(
      amount ? centsToDollars(amount) : 0
    );

    return (
      <>
        <Div $p="0 0 0 48px" $flex $gap="16px" $between $alignCenter>
          <P $bold={item} $semiBold={itemChild} $m="0">
            {info || '/'}
          </P>
          {hasAmount &&
            (grayAmount ? (
              <P $color={lightTheme.darkGray} $m="0">
                {formattedAmount}
              </P>
            ) : (
              <P $bold $color={lightTheme.primary} $m="0">
                {formattedAmount}
              </P>
            ))}
        </Div>
        {instructions && (
          <Disclaimer $color={lightTheme.lightGray} $m="0" $ml="48" $mb="4">
            {instructions}
          </Disclaimer>
        )}
      </>
    );
  };

  const closeDetailsModal = () => {
    setDetailsModal(null);
    setData(null);
  };

  const hasNotCourierInfo =
    data?.delivery?.courier?.name === null &&
    data?.delivery?.courier?.name === null &&
    data?.delivery?.courier?.name === null;

  const customerAddress = `${showStringIfNotNull(
    data?.delivery?.address?.address,
    true
  )} ${showStringIfNotNull(
    data?.delivery?.address?.city,
    true
  )} ${showStringIfNotNull(
    data?.delivery?.address?.state,
    false
  )} ${showStringIfNotNull(
    data?.delivery?.address?.zipCode,
    true
  )} ${showStringIfNotNull(data?.delivery?.address?.country)}`;

  const isManualOrder = data?.creationType === 'manual';
  const isNewOrder = data?.status === 'new';

  return (
    <>
      {loading && <AdminSpinner />}

      <ModalFullScreen
        isOpen={detailsModal && data}
        setIsOpen={() => closeDetailsModal()}
        zIndex={105}
      >
        {/* <AdminHeader title={'Order Details'}>
          <Div $flex $gap="32px"></Div>
        </AdminHeader> */}
        {data && (
          <>
            <Div $flex>
              <Div $flex $center $flexNone>
                <Img
                  src={providerLogo(data.provider)}
                  $width="120px"
                  $widthSM="60px"
                />
              </Div>
              <VerticalSeparator $m="0 24px" $mSM="0 16px" />
              <Div $flex $between $columnSM $gap="20px" $width="100%">
                <Div>
                  {!data.brand.isLocation && (
                    <UnderlineHoverLink to={`/brand/${data.brand.id}`}>
                      <H3 $mb="8">{data.brand?.name}</H3>
                    </UnderlineHoverLink>
                  )}
                  <UnderlineHoverLink to={`/locations/${data.location.id}`}>
                    <H5 $mb="8">{data.location?.name}</H5>
                  </UnderlineHoverLink>
                  <P $m="0">
                    Order ID: <span>{data.id}</span>
                  </P>
                  <P $m="0">Number: {data.number}</P>
                </Div>

                <Div $flex $column $between $desktop>
                  <Div $flex $justifyEnd $gap="16px" $wrap $alignCenter>
                    <Div
                      $flex
                      $justifyEnd
                      $gap="16px"
                      $alignCenter
                      onClick={() => setModalIsOpen((prev) => !prev)}
                      $pointer
                    >
                      <RecoveryStatusBadge recovery={data?.recovery} />
                      <Icon>
                        <RecoveryIcon />
                      </Icon>
                    </Div>
                    {isManualOrder && (
                      <Icon
                        $pointer
                        onClick={() => setDeleteManualOrderModal(data)}
                      >
                        <DeleteIconRed />
                      </Icon>
                    )}

                    {isManualOrder && (
                      <Icon
                        $pointer
                        onClick={() => {
                          setOrderToEdit(data);
                          closeDetailsModal();
                          navigate(ADMIN_EDIT_MANUAL_ORDER);
                        }}
                      >
                        <EditPenIconRed />
                      </Icon>
                    )}
                    {!isManualOrder && !isNewOrder && (
                      <Icon
                        // $sizeSM="28"
                        $pointer
                        onClick={() => setPrintOrderModal(data)}
                      >
                        <PrinterIcon />
                      </Icon>
                    )}
                  </Div>

                  {isManualOrder && (
                    <H5 $m="0" $mt="16" $right>
                      <span>Manual Order</span>
                    </H5>
                  )}
                </Div>

                <Div $mobile>
                  <RecoveryStatusBadge recovery={data?.recovery} />
                  <Div $flex $alignCenter $justifyEnd $gap="16px" $mt="16">
                    <Icon $sizeSM="30">
                      <RecoveryIcon />
                    </Icon>
                    {isManualOrder && (
                      <Icon
                        $sizeSM="28"
                        $pointer
                        onClick={() => setDeleteManualOrderModal(data)}
                      >
                        <DeleteIconRed />
                      </Icon>
                    )}

                    {isManualOrder && (
                      <Icon
                        $sizeSM="30"
                        $pointer
                        onClick={() => {
                          setOrderToEdit(data);
                          closeDetailsModal();
                          navigate(ADMIN_EDIT_MANUAL_ORDER);
                        }}
                      >
                        <EditPenIconRed />
                      </Icon>
                    )}
                    {!isManualOrder && !isNewOrder && (
                      <Icon
                        $sizeSM="28"
                        $pointer
                        onClick={() => setPrintOrderModal(data)}
                      >
                        <PrinterIcon />
                      </Icon>
                    )}
                  </Div>
                  {isManualOrder && (
                    <P $bold $m="0" $mt="16" $right>
                      <span>Manual Order</span>
                    </P>
                  )}
                </Div>
              </Div>
            </Div>

            <Div $flex $mt="32" $gap="32px" $columnSM $gapSM="0">
              <Div $width="50%" $widthSM="100%">
                <Div $borderTop $borderBottom $p="16px 0">
                  <SectionTitle Icon={OrderInfoIcon} title="Order Info" />
                  <SectionInfo
                    info={DateTime.fromISO(data.date)
                      .setZone(data.timeZone)
                      .toFormat('MMM dd, yyyy, hh:mm a')}
                  />
                  <SectionInfo info={providerName(data.provider)} />
                </Div>
                <Div $borderBottom $p="16px 0">
                  <Div>
                    <SectionTitle
                      Icon={OrderCustomerIcon}
                      title="Customer Details"
                    />
                  </Div>
                  <SectionInfo info={data?.customer?.name} />
                  {data?.customer?.phoneNumber && (
                    <a href={`tel:${data?.customer?.phoneNumber}`}>
                      <SectionInfo
                        info={`Phone: ${data?.customer?.phoneNumber}`}
                      />
                    </a>
                  )}

                  {data?.customer?.phoneCode && (
                    <SectionInfo
                      info={`Pin: ${data?.customer?.phoneCode || '/'}`}
                    />
                  )}
                  {data?.customer?.email && (
                    <SectionInfo info={`Email: ${data?.customer?.email}`} />
                  )}
                  {data?.delivery?.address?.address && (
                    <SectionInfo info={customerAddress} />
                  )}
                </Div>

                {data.type === 'pickup' && !isManualOrder && (
                  <Div $borderBottom $p="16px 0">
                    <SectionTitle
                      Icon={OrderCourierIcon}
                      title="Pickup Details"
                    />

                    <SectionInfo
                      info={`Pickup: ${DateTime.fromISO(
                        data.timestamps.pickupAt
                      )
                        .setZone(data.timeZone)
                        .toFormat('MMM dd, yyyy, hh:mm a')}`}
                    />
                  </Div>
                )}

                {data.type === 'delivery' && !isManualOrder && (
                  <Div $borderBottom>
                    <Div
                      $p="16px 0"
                      $flex
                      $between
                      $alignCenter
                      $columnSM
                      $gap="20px"
                    >
                      <Div $widthSM="100%">
                        {data.delivery.type !== 'restaurant' &&
                          (!hasNotCourierInfo ? (
                            <Div
                              $flex
                              $between
                              onClick={() => setCourierModalIsOpen(true)}
                              $pointer
                            >
                              <SectionTitle
                                Icon={OrderCourierIcon}
                                title="Courier Details"
                              />
                              <ArrowRed />
                            </Div>
                          ) : (
                            <SectionTitle
                              Icon={OrderCourierIcon}
                              title="Courier Details"
                            />
                          ))}

                        {data.delivery.type === 'restaurant' && (
                          <SectionTitle
                            Icon={OrderCourierIcon}
                            title="Courier Details"
                          />
                        )}

                        {data.delivery.type !== 'restaurant' && (
                          <>
                            {data?.delivery?.courier?.name && (
                              <SectionInfo
                                info={data?.delivery?.courier?.name}
                              />
                            )}

                            {data?.delivery?.courier?.phoneNumber && (
                              <SectionInfo
                                info={data?.delivery?.courier?.phoneNumber}
                              />
                            )}
                          </>
                        )}

                        <SectionInfo
                          info={`Pickup: ${DateTime.fromISO(
                            data.timestamps.pickupAt
                          )
                            .setZone(data.timeZone)
                            .toFormat('MMM dd, yyyy, hh:mm a')}`}
                        />

                        {data.delivery.type !== 'restaurant' && (
                          <SectionInfo
                            info={`Delivery by: ${providerName(data.provider)}`}
                          />
                        )}

                        {data.delivery.type === 'restaurant' &&
                          data.vDriveProvider === null && (
                            <SectionInfo info={`Delivery by: Restaurant`} />
                          )}

                        {data.delivery.type === 'restaurant' &&
                          data.vDriveProvider !== null && (
                            <SectionInfo info={`Delivery by: vDrive`} />
                          )}

                        {/* {data.delivery.type === 'restaurant' &&
                          data.vDriveEnabled &&
                          data.vDriveProvider === null && (
                            <P $m=" 8px 0 0 48px" $color={lightTheme.lightGray}>
                              Want to use vDrive?
                            </P>
                          )} */}
                      </Div>
                      <Div $widthSM="100%">
                        {/* {data.delivery.type === 'restaurant' &&
                        data.vDriveEnabled &&
                        data.vDriveProvider === null && (
                          <Div>
                            <Button $darkBlue $selected onClick={getDrivers}>
                              <Div $flex $alignCenter $gap="8px">
                                <VDriveWhiteIcon /> Assign Driver
                              </Div>
                            </Button>
                            <Div
                              $flex
                              $alignCenter
                              $mt="20"
                              $gap="8px"
                              $justifyEnd
                              $pointer
                              onClick={() => setWhatIsThisModal(true)}
                            >
                              <Icon>
                                <InfoDarkBlueIcon />
                              </Icon>
                              <P $right $mb="0" $underline>
                                What is this?
                              </P>
                            </Div>
                          </Div>
                        )}
                           */}

                        {data.delivery.type === 'restaurant' &&
                          data.vDriveEnabled &&
                          data.vDriveProvider !== null && (
                            <PoweredByComponent data={data} />
                          )}
                      </Div>
                    </Div>
                    {data.delivery.type === 'restaurant' &&
                      data.vDriveEnabled && (
                        <VDriveStatusComponent
                          data={data}
                          getDrivers={getDrivers}
                          setCancelVDriveModal={setCancelVDriveModal}
                          setChangeAddressModal={setChangeAddressModal}
                        />
                      )}
                  </Div>
                )}

                {data?.notes && (
                  <Div $borderBottom $p="16px 0">
                    <SectionTitle
                      Icon={OrderNotesIcon}
                      title="Customer Notes"
                    />
                    <SectionInfo info={data?.notes} />
                  </Div>
                )}
              </Div>
              <Div $width="50%" $widthSM="100%">
                <Div $borderTop $borderBottom $p="16px 0">
                  <Div $flex $alignCenter $between>
                    <SectionTitle
                      Icon={OrderUtilsIcon}
                      title="Bring Utensils & Napkins"
                    />
                    <P $m="0">{data?.addDisposableItems ? 'Yes' : 'No'}</P>
                  </Div>
                </Div>

                {!isManualOrder && (
                  <>
                    {' '}
                    <Div $p="16px 0 0 0">
                      <SectionTitle
                        Icon={OrderDetailsIcon}
                        title="Order Details"
                      />
                      <Div $mb="24">
                        {data?.items?.map(
                          (
                            { price, name, options, quantity, instructions },
                            i
                          ) => {
                            return (
                              <div key={i}>
                                <SectionInfo
                                  key={i}
                                  item
                                  info={`${name} (x${quantity})`}
                                  amount={price}
                                  hasAmount
                                  instructions={instructions}
                                />
                                <Div $ml="20">
                                  {options.map(
                                    (
                                      {
                                        modifierName,
                                        name,
                                        quantity,
                                        price,
                                        children,
                                      },
                                      i
                                    ) => {
                                      return (
                                        <>
                                          <SectionInfo
                                            key={i}
                                            info={`${
                                              modifierName
                                                ? `${modifierName}: `
                                                : ''
                                            }${name} (x${quantity})`}
                                            amount={price}
                                            hasAmount
                                            itemChild
                                            grayAmount
                                          />
                                          {children.map(
                                            ({ name, quantity, price }, i) => {
                                              return (
                                                <SectionInfo
                                                  key={i}
                                                  info={`+ ${
                                                    modifierName
                                                      ? `${modifierName}: `
                                                      : ''
                                                  }${name} (x${quantity})`}
                                                  amount={price}
                                                  hasAmount
                                                  grayAmount
                                                />
                                              );
                                            }
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                                </Div>
                              </div>
                            );
                          }
                        )}
                      </Div>

                      <SectionInfo
                        info={'Tax'}
                        amount={data?.charges?.tax || 0}
                        hasAmount
                      />
                      <SectionInfo
                        info={'Tips'}
                        amount={data?.charges?.tips || 0}
                        hasAmount
                      />
                      <SectionInfo
                        info={'Discount'}
                        amount={data?.charges?.discount || 0}
                        hasAmount
                      />
                      <SectionInfo
                        info={'Delivery Fee'}
                        amount={data?.charges?.deliveryFee || 0}
                        hasAmount
                      />
                    </Div>
                  </>
                )}

                <Div $flex $justifyEnd $m="24px 0">
                  <svg
                    width="62"
                    height="1"
                    viewBox="0 0 62 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="2.18557e-08"
                      y1="0.75"
                      x2="61.0132"
                      y2="0.750005"
                      stroke="#E61E29"
                      strokeWidth="0.5"
                    />
                  </svg>
                </Div>

                <H3 $right $mb="0">
                  Total:{' '}
                  <span>
                    {formatter.format(centsToDollars(data?.charges?.total))}
                  </span>
                </H3>
              </Div>
            </Div>
          </>
        )}
      </ModalFullScreen>

      <RecoveryModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        recovery={data?.recovery}
        setData={setData}
        data={data}
      />
      <CourierModal
        modalIsOpen={courierModalIsOpen}
        setModalIsOpen={setCourierModalIsOpen}
        courier={data?.delivery?.courier}
      />
      <DeleteOrderModal closeDetailsModal={closeDetailsModal} />

      {data && (
        <VDriveAssignDriver
          order={data}
          setOrder={setData}
          modalIsOpen={assignDriverModal}
          setModalIsOpen={setAssignDriverModal}
          noDriverModal={noDriverModal}
          setNoDriverModal={setNoDriverModal}
          setLoading={setLoading}
        />
      )}
      <PrintReceiptModal />
      <WhatIsThisModal
        modalIsOpen={whatIsThisModal}
        setModalIsOpen={setWhatIsThisModal}
      />

      <ChangeAddress
        modalIsOpen={changeAddressModal}
        setModalIsOpen={setChangeAddressModal}
        setLoading={setLoading}
        setData={setData}
      />

      <CancelVDriveModal
        cancelVDriveModal={cancelVDriveModal}
        setCancelVDriveModal={setCancelVDriveModal}
        setLoading={setLoading}
        setData={setData}
      />
    </>
  );
};
