import {
  AdminRadioInput,
  AdminSelect,
  Button,
  Div,
  ErrorToast,
  H5,
  Modal,
  Separator,
  SuccessToast,
  api,
  convert24HourTo12Hour,
  daysOfWeek,
  times,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

const timeFromOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      From
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

const timeToOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      To
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

export const AddWorkingHours = ({
  modalIsOpen,
  setModalIsOpen,
  selectedBrand,
  selectedLocation,
  getWorkingHours,
  setLoading,
  provider,
}) => {
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState([]);
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');

  const workingHourData = modalIsOpen;

  useEffect(() => {
    if (workingHourData.edit) {
      setSelectedDayOfWeek(workingHourData.edit.day);
      setTimeFrom(workingHourData.edit.startTime);
      setTimeTo(workingHourData.edit.endTime);
    } else {
      setSelectedDayOfWeek([]);
      setTimeFrom('');
      setTimeTo('');
    }
  }, [workingHourData]);

  async function handleAdd() {
    if (!selectedDayOfWeek || !timeFrom || !timeTo)
      return ErrorToast('Please fill in all information');

    const body = {
      provider,
      schedule: {
        day: selectedDayOfWeek,
        startTime: timeFrom,
        endTime: timeTo,
      },
    };

    if (workingHourData?.edit?.id) {
      body.existingId = workingHourData.edit.id;
    }

    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/${selectedBrand ? 'brands' : 'locations'}/${
          selectedBrand?.id || selectedLocation?.id
        }/${workingHourData.type}-working-hours`,
        body
      );
      getWorkingHours();
      setModalIsOpen(false);
      SuccessToast(data.message);
      setSelectedDayOfWeek([]);
      setTimeFrom('');
      setTimeTo('');
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header={`${workingHourData?.edit?.id ? 'Edit' : 'Add'} Day`}
      textAlign="left"
      width=""
    >
      <H5>Select Day</H5>
      <Separator />
      {daysOfWeek.map(({ name, value }) => (
        <div key={value}>
          <AdminRadioInput
            label={name}
            checked={selectedDayOfWeek === value}
            onClick={() => setSelectedDayOfWeek(value)}
          />
          <Separator />
        </div>
      ))}
      <H5 $mt="32" $mb="16">
        Select Hours From - To
      </H5>
      <Div $flex $gap="24px">
        <AdminSelect
          options={timeFromOptions}
          value={timeFrom}
          onChange={({ target }) => setTimeFrom(target.value)}
          placeholder="From"
        />
        <AdminSelect
          options={timeToOptions}
          value={timeTo}
          onChange={({ target }) => setTimeTo(target.value)}
          placeholder="To"
        />
      </Div>

      {/* <Flatpickr /> */}

      {/* <AdminDatePickerInput
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: 'h:i K',
          time_24hr: false,
        }}
      /> */}

      <Button $selected $w="100%" $mt="32" onClick={() => handleAdd()}>
        Confirm
      </Button>
    </Modal>
  );
};

// const AdminInputStyled = styled.div`
//   width: 100%;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   border: 1px solid ${({ theme }) => theme.darkGray}80;
//   border-radius: 200px;
//   font-size: 15px;
//   line-height: 18px;
//   padding: 0 24px;
//   display: flex;
//   align-items: center;
//   height: 45px;
//   -moz-appearance: none; /* Firefox */
//   -webkit-appearance: none; /* Safari and Chrome */
//   appearance: none;
//   background: none;

//   ${({ shadowDesign, theme }) =>
//     shadowDesign &&
//     `
//   background: ${theme.white};
//   border: 1px solid #e3e3e3;
//   box-shadow: ${theme.shadows.shadow1};
//   `}

//   ${({ $textCenter }) => $textCenter && `text-align: center`};

//   :focus {
//     outline: none !important;
//     border: 1px solid ${({ theme }) => theme.primary};
//   }

//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// `;
