import {
  ADMIN_VERIFICATIONS_AGENT,
  AdminHeader,
  AdminSpinner,
  AnimatedDiv,
  Button,
  Div,
  DownloadIcon,
  ErrorToast,
  H2,
  H3,
  Icon,
  ImagePreview,
  Img,
  P,
  PlusIcon,
  Separator,
  SuccessToast,
  api,
  downloadImage,
  lightTheme,
  moneyAndCoins,
  useVerifications,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { AddEarningPlan } from './AddEarningPlan';
import { EarningPlan } from './components/EarningPlanComponent';
import { KeyValueInfo } from './components/KeyValueInfo';

export const AgentDetails = () => {
  const [modalIsOpen, setModalIsOpen] = useState('');
  const [loading, setLoading] = useState('');
  const [agentDetails, setAgentDetails] = useState('');
  const [earningPlans, setEarningPlans] = useState([]);
  const [amountPerLocation, setAmountPerLocation] = useState(null);
  const navigate = useNavigate();

  const { getCount } = useVerifications((state) => state);

  const id = useMatch(ADMIN_VERIFICATIONS_AGENT).params.id;

  const agent = agentDetails?.agent;
  const user = agentDetails?.user;

  async function handleGetAgentDetails() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/agents`, {
        params: { userId: id },
      });
      setAgentDetails(data.data);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleGetEarningPlans() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/agents/${id}/earning-plans`);
      setEarningPlans(data.data.earningPlans);
      setAmountPerLocation(data.data.amountPerLocation);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleVerifyAgent() {
    const body = {
      earningPlans,
      amountPerLocation,
    };

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/agents/${agent.id}`, body);
      SuccessToast(data.message);
      getCount();
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleEditEarningPlans() {
    const body = {
      earningPlans,
      amountPerLocation,
    };

    try {
      setLoading(true);
      const { data } = await api.put(`/v1/agents/${id}/update-earning`, body);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      handleGetAgentDetails();
      handleGetEarningPlans();
    }
  }, []);

  return (
    <>
      <AdminHeader />

      {loading && <AdminSpinner />}
      {agentDetails && (
        <AnimatedDiv>
          <H2 $m="40px 0" $color={lightTheme.primary}>
            {user.firstName + ' ' + user.lastName}
          </H2>
          <Separator />

          <a href={`mailto:${user.mail}`}>
            <KeyValueInfo k="Email" v={user.email} />
          </a>
          <a href={`tel:${user.phoneNumber}`}>
            <KeyValueInfo k="phone" v={user.phoneNumber} />
          </a>

          <AnimatedDiv $flex $columnSM $gap="50px" $mt="40" $mtSM="20">
            <Div $width="50%" $widthSM="100%">
              <H3 $m="0">ID Card</H3>

              <Div $flex $columnSM $gap="20px" $mt="40" $mtSM="20">
                <Div $width="50%" $widthSM="100%">
                  <Div $box $p="16px" $pSM="16px" $mb="50" $mbSM="24">
                    <Div $flex $mb="16" $between>
                      <P $bold $mb="0">
                        Front
                      </P>

                      <Icon
                        $pointer
                        onClick={() =>
                          downloadImage(
                            agent.idCardFrontUrl,
                            `Agent ${
                              user.firstName + ' ' + user.lastName
                            } ID Card Front`
                          )
                        }
                      >
                        <DownloadIcon />
                      </Icon>
                    </Div>
                    <ImagePreview image={agent.idCardFrontUrl} />
                  </Div>
                </Div>
                <Div $width="50%" $widthSM="100%">
                  <Div $box $p="16px" $pSM="16px" $mb="50" $mbSM="24">
                    <Div $flex $mb="16" $between>
                      <P $bold $mb="0">
                        Back
                      </P>
                      <Icon
                        $pointer
                        onClick={() =>
                          downloadImage(
                            agent.idCardFrontUrl,
                            `Agent ${
                              user.firstName + ' ' + user.lastName
                            } ID Card Back`
                          )
                        }
                      >
                        <DownloadIcon />
                      </Icon>
                    </Div>
                    <ImagePreview image={agent.idCardBackUrl} />
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div $width="50%" $widthSM="100%">
              <Div $flex $between $alignCenter $mb="20">
                <H3 $m="0">Earning Plan</H3>
                <Icon $pointer onClick={() => setModalIsOpen(true)}>
                  <PlusIcon />
                </Icon>
              </Div>
              {earningPlans.length === 0 && (
                <Div $flex $justifyCenter>
                  <Img
                    src={moneyAndCoins}
                    alt="no earning plans"
                    $width="200px"
                  />
                </Div>
              )}
              {earningPlans
                .sort((a, b) => {
                  if (a.year === null) {
                    return 1; // Put null year at the end
                  }
                  if (b.year === null) {
                    return -1; // Put null year at the end
                  }
                  return a.year - b.year;
                })
                .map((plan, i) => (
                  <EarningPlan
                    plan={plan}
                    setEarningPlans={setEarningPlans}
                    setAmountPerLocation={setAmountPerLocation}
                    handleEditEarningPlans={handleEditEarningPlans}
                    index={i}
                  />
                ))}
              {amountPerLocation > 0 && (
                <EarningPlan
                  plan={{ amount: amountPerLocation }}
                  setEarningPlans={setEarningPlans}
                  setAmountPerLocation={setAmountPerLocation}
                  handleEditEarningPlans={handleEditEarningPlans}
                  index={null}
                />
              )}
            </Div>
          </AnimatedDiv>
          <Div $flex $justifyEnd $mt="50">
            <Button
              $selected
              $w="300px"
              $wSM="100%"
              onClick={handleVerifyAgent}
            >
              Verify Agent
            </Button>
          </Div>
        </AnimatedDiv>
      )}
      <AddEarningPlan
        setModalIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        earningPlans={earningPlans}
        amountPerLocation={amountPerLocation}
        setLoading={setLoading}
        setEarningPlans={setEarningPlans}
        setAmountPerLocation={setAmountPerLocation}
      />
    </>
  );
};
