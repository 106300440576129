import {
  AdminDollarInput2,
  AdminRadioInput,
  AdminSelect,
  AdminSwitch,
  Button,
  Div,
  ErrorToast,
  H5,
  Modal,
  Separator,
  convert24HourTo12Hour,
  daysOfWeek,
  daysOfWeekFrom1,
  isTimeFromEarlier,
  times,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';

const timeFromOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      From
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

const timeToOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      To
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

export const AddEditFundingTip = ({
  modalIsOpen,
  setModalIsOpen,
  edit,
  setFundedTipSchedule,
}) => {
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(0);

  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');

  const [fundedTip, setFundedTip] = useState(0);

  const data = modalIsOpen;

  const toggleDaySelection = (day) => {
    setSelectedDayOfWeek(day);
  };

  const handleFinishAction = () => {
    setSelectedDayOfWeek(0);
    setTimeFrom('');
    setTimeTo('');
    setFundedTip(0);
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (edit && modalIsOpen) {
      setFundedTip(data.amount);
      setSelectedDayOfWeek(data.dayOfTheWeek);
      setTimeFrom(data.startTime);
      setTimeTo(data.endTime);
    }
  }, [modalIsOpen]);

  const handleSubmit = () => {
    if (!timeFrom || !timeTo)
      return ErrorToast('Please fill in all information');

    if (!isTimeFromEarlier(timeFrom, timeTo))
      return ErrorToast('Time From must be earlier than Time To');

    if (edit) {
      setFundedTipSchedule((prev) => {
        return prev.map((item, i) => {
          if (i === data.index) {
            return {
              amount: item.amount,
              dayOfTheWeek: selectedDayOfWeek,
              startTime: timeFrom,
              endTime: timeTo,
            };
          } else return item;
        });
      });
    } else {
      setFundedTipSchedule((prev) => [
        ...prev,
        {
          amount: prev[0].amount,
          dayOfTheWeek: selectedDayOfWeek,
          startTime: timeFrom,
          endTime: timeTo,
        },
      ]);
    }

    handleFinishAction();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header={`${edit ? 'Edit' : 'Add'} Tip Funding`}
      textAlign="left"
      width="500px"
    >
      <>
        <Separator />
        {daysOfWeekFrom1.map(({ name, value }) => (
          <div key={value}>
            <AdminRadioInput
              label={name}
              checked={selectedDayOfWeek === value}
              onClick={() => toggleDaySelection(value)}
            />
            <Separator />
          </div>
        ))}
        <H5 $mt="32" $mb="16" $center>
          Select Hours
        </H5>
        <Div $flex $gap="24px">
          <AdminSelect
            options={timeFromOptions}
            value={timeFrom}
            onChange={({ target }) => setTimeFrom(target.value)}
            placeholder="From"
            $textCenter
            label="Time From"
          />
          <AdminSelect
            options={timeToOptions}
            value={timeTo}
            onChange={({ target }) => setTimeTo(target.value)}
            placeholder="To"
            $textCenter
            label="Time To"
          />
        </Div>
      </>

      <Button $selected $w="100%" $mt="32" onClick={handleSubmit}>
        Confirm
      </Button>
      <Button $selected $gray $w="100%" $mt="16" onClick={handleFinishAction}>
        Cancel
      </Button>
    </Modal>
  );
};
