import { Button, H3, Modal, P, Separator } from '@vgrubs/components';
import { Link } from 'react-router-dom';

export const BrandAddSuccessModal = ({ modalIsOpen, setModalIsOpen }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      successImage
    >
      <H3 $m="0">
        <span>Congratulations!</span>
        <br />
        {modalIsOpen?.name} brand is created
      </H3>

      <Separator $m="24px 0" />

      <P $mb="24">Choose delivery service provider.</P>

      <Link to={`/brands/manage-providers/${modalIsOpen?.id}`}>
        <Button $gray $selected $w="100%">
          Add Providers
        </Button>
      </Link>

      <P $m="12px 0">OR</P>

      <Button $selected $w="100%" onClick={() => setModalIsOpen(false)}>
        Continue
      </Button>
    </Modal>
  );
};
