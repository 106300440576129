import {
  Disclaimer,
  Div,
  H5,
  P,
  RenderBrandType,
  lightTheme,
} from '@vgrubs/components';
import { Link } from 'react-router-dom';
import { BrandImageComponent } from './BrandImageComponent';
import { BrandCampaignPill } from './BrandCampaignModal';

export const BrandComponent = ({ brand }) => {
  return (
    <Div $box $relative>
      <BrandImageComponent image={brand.imageUrl} />
      <H5 $mb="4">{brand.name}</H5>
      <P $mb="12">{RenderBrandType(brand.type)}</P>

      <span>
        <Div $flex $center>
          <BrandCampaignPill
            campaign={
              brand?.activeCampaignPercentage
                ? `Active Campaign ${brand?.activeCampaignPercentage}%`
                : null
            }
          />
        </Div>
      </span>
      <Link to={`/brand/${brand.id}`}>
        <Disclaimer $m="0" $mt="12" $underline $color={lightTheme.primary}>
          View Details
        </Disclaimer>
      </Link>
    </Div>
  );
};
