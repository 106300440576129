import {
  AdminSelect,
  Button,
  Div,
  H3,
  H5,
  InfoIcon,
  P,
  Separator,
  SvgInlineWrapper,
  VDriveLogo,
  lightTheme,
} from '@vgrubs/components';
import { Tooltip } from 'react-tooltip';

export const autoRechargeAmounts = [
  { name: '$25', value: 2500 },
  { name: '$50', value: 5000 },
  { name: '$100', value: 10000 },
  { name: '$200', value: 20000 },
];

const typeOptions = (
  <optgroup label="Select Amount">
    {autoRechargeAmounts.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

export const AutoRechargeComponent = ({
  autoRechargeAmount,
  setAutoRechargeAmount,
}) => (
  <>
    <Div $flex $between $alignCenter $width="100%">
      <P $m="0">
        Auto Recharge <br /> Card with{' '}
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-html="The dollar amount deposited when your card is topped up automatically."
          data-tooltip-place="top"
          style={{ color: lightTheme.darkGray }}
        >
          <SvgInlineWrapper>
            <InfoIcon />
          </SvgInlineWrapper>
        </a>
      </P>

      <Div $width="120px">
        <AdminSelect
          value={autoRechargeAmount}
          options={typeOptions}
          onChange={({ target }) => setAutoRechargeAmount(target.value)}
        />
      </Div>
    </Div>

    <Div $desktop>
      <Tooltip
        id="my-tooltip"
        style={{
          maxWidth: '500px',
          background: lightTheme.white,
          color: lightTheme.darkGray,
          padding: 16,
          borderRadius: 20,
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
          zIndex: '1',
        }}
        border="1px solid #e3e3e3"
      />
    </Div>
    <Div $mobile>
      <Tooltip
        id="my-tooltip"
        style={{
          maxWidth: '95%',
          background: lightTheme.white,
          color: lightTheme.darkGray,
          padding: 16,
          borderRadius: 20,
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
          zIndex: '1',
        }}
        border="1px solid #e3e3e3"
      />
    </Div>
  </>
);

export const AutoRechargeWhenComponent = ({
  autoRechargeWhenAmount,
  setAutoRechargeWhenAmount,
}) => (
  <>
    <Div $flex $between $alignCenter $width="100%">
      <P $m="0">
        When vDrive Balance <br /> Goes Lower Than{' '}
        <a
          data-tooltip-id="my-tooltip-2"
          data-tooltip-html="The minimum dollar amount that your vDrive balance must have in it before it is automatically topped up."
          data-tooltip-place="top"
          style={{ color: lightTheme.darkGray }}
        >
          <SvgInlineWrapper>
            <InfoIcon />
          </SvgInlineWrapper>
        </a>
      </P>

      <Div $width="120px">
        <AdminSelect
          value={autoRechargeWhenAmount}
          options={typeOptions}
          onChange={({ target }) => setAutoRechargeWhenAmount(target.value)}
        />
      </Div>
    </Div>

    <Div $desktop>
      <Tooltip
        id="my-tooltip-2"
        style={{
          maxWidth: '500px',
          background: lightTheme.white,
          color: lightTheme.darkGray,
          padding: 16,
          borderRadius: 20,
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
          zIndex: '1',
        }}
        border="1px solid #e3e3e3"
      />
    </Div>
    <Div $mobile>
      <Tooltip
        id="my-tooltip-2"
        style={{
          maxWidth: '95%',
          background: lightTheme.white,
          color: lightTheme.darkGray,
          padding: 16,
          borderRadius: 20,
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
          zIndex: '1',
        }}
        border="1px solid #e3e3e3"
      />
    </Div>
  </>
);

export const SetAutoRecharge = ({
  autoRechargeAmount,
  setAutoRechargeAmount,
  autoRechargeWhenAmount,
  setAutoRechargeWhenAmount,
  handleSubmitVDrive,
}) => {
  return (
    <>
      <Div $flex $column $alignCenter>
        <Div $flex $center>
          <VDriveLogo />
        </Div>
        <H3 $center $m="40px 0">
          <span>Set Auto Recharge</span>
        </H3>
        <H5 $center $mb="16">
          Keep Client’s Balance Topped Up
        </H5>
        <P $center $mb="50">
          Set up auto recharge to ensure client’s vDrive balance never runs low.
          The card will be automatically charged when the balance drops below
          the specified amount.
        </P>

        <AutoRechargeComponent
          autoRechargeAmount={autoRechargeAmount}
          setAutoRechargeAmount={setAutoRechargeAmount}
        />

        <Separator $m="8px 0" />

        <AutoRechargeWhenComponent
          autoRechargeWhenAmount={autoRechargeWhenAmount}
          setAutoRechargeWhenAmount={setAutoRechargeWhenAmount}
        />

        <Button
          $selected
          $w="100%"
          $wSM="100%"
          $mt="70"
          onClick={handleSubmitVDrive}
        >
          Confirm & Add Balance
        </Button>
      </Div>
    </>
  );
};
