import {
  AdminHeader,
  AdminInput,
  AdminSpinner,
  Div,
  ErrorToast,
  H3,
  H5,
  Img,
  api,
  handleSetUrlSearchParams,
  restaurantImage,
} from '@vgrubs/components';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { LocationComponent } from './LocationComponent';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios'; // Axios for checking if requests were canceled

export const Locations = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();
  const [count, setCount] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('query');

  const cancelTokenSourceRef = useRef(null); // Track the cancel token

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Previous request canceled.');
    }
    cancelTokenSourceRef.current = axios.CancelToken.source(); // Create a new cancel token
  };

  async function getAllLocations(search, page, hasMore) {
    if (!hasMore) return;

    cancelPreviousRequest(); // Cancel any ongoing request before making a new one

    try {
      setLoading(true);

      const { data } = await api.get(`/v1/locations/verified`, {
        cancelToken: cancelTokenSourceRef.current.token, // Attach the new cancel token
        params: {
          limit: 20,
          page: page,
          query: query?.length > 0 ? query : null,
        },
      });

      const { data: countResponse } = await api.get(
        `/v1/locations/verified/count`,
        {
          params: {
            query: query?.length > 0 ? query : null,
          },
        }
      );

      setCount(countResponse.data.count);

      if (!search) {
        if (data.data[0]) {
          setHasMore(true);
          setUsers((prevItems) => [...prevItems, ...data.data]);
        } else {
          setHasMore(false);
        }
      } else {
        if (data.data[0]) {
          setHasMore(true);
          setUsers(() => [...data.data]);
        } else {
          setUsers(() => [...data.data]);
          setHasMore(false);
        }
      }

      setPage((prevPage) => prevPage + 1);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log('Previous request canceled:', error.message);
      } else {
        ErrorToast(error?.response?.data?.message);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    getAllLocations(true, 1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (inView && users.length > 0) {
      getAllLocations(false, page, hasMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleChange = async (e) => {
    handleSetUrlSearchParams('query', e.target.value, setSearchParams);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminHeader />
      <Div $p="40px 0" $flex $alignCenter $between $columnSM $gap="24px">
        <Div $desktop>
          <H3 $m="0">All Locations ({count})</H3>
        </Div>
        <AdminInput
          defaultValue={query}
          maxWidth="350px"
          maxWidthSM="100%"
          search
          placeholder="Search Location"
          onChange={debouncedResults}
        />
      </Div>
      {users.map((location, i) => {
        return <LocationComponent key={i} location={location} />;
      })}

      {users.length === 0 && (
        <Div
          $flex
          $center
          $column
          $height="calc(70vh - 300px)"
          $heightSM="calc(60vh - 300px)"
        >
          <Img
            src={restaurantImage}
            alt="restaurant"
            $width="250px"
            $widthSM="150px"
          />
          <H5>No Locations</H5>
        </Div>
      )}

      <div ref={ref}></div>
    </>
  );
};
