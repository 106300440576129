import {
  AdminSpinner,
  AnimatedDiv,
  Button,
  Div,
  EditPenIcon,
  ErrorToast,
  H3,
  H5,
  Icon,
  P,
  PlusIcon,
  Separator,
  TrashIconRed,
  api,
  convert24HourTo12Hour,
  providers,
  renderDayOfWeek,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { AddWorkingHours } from '../AddWorkingHours';
import { DeleteWorkingHours } from '../DeleteWorkingHours';
import { Unavailable } from '../Unavailable';

export const WorkingHours = ({ selectedBrand, selectedLocation }) => {
  const [loading, setLoading] = useState(false);

  const provider = providers.DELIVERY;

  const [holidayHours, setHolidayHours] = useState([]);
  const [addDayModal, setAddDayModal] = useState(false);
  const [deleteDayModal, setDeleteDayModal] = useState(false);

  async function getWorkingHours() {
    if (!selectedLocation) return;

    const locationOrBrand = selectedBrand ? 'brands' : 'locations';
    const locationOrBrandId = selectedBrand
      ? selectedBrand.id
      : selectedLocation.id;

    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/${locationOrBrand}/${locationOrBrandId}/working-hours`,
        { params: { provider } }
      );
      setHolidayHours({ ...data.data });
    } catch (error) {
      if (
        error?.response?.data?.message === 'Provider delivery is not connected.'
      ) {
        return;
      } else {
        ErrorToast(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getWorkingHours();
  }, [selectedLocation, selectedBrand]);

  const { business, delivery } = holidayHours;

  return (
    <>
      {loading && <AdminSpinner />}
      <AnimatedDiv>
        {Array.isArray(delivery) && (
          <>
            <Div $flex $between $alignCenter $mt="40">
              <div>
                <H3 $m="0">Business Hours</H3>
                {business.length < 1 && (
                  <P $m="8px 0 0 0">
                    This location currently doesn't have business hours.
                  </P>
                )}
              </div>

              {business?.length < 1 ? (
                <Button
                  $selected
                  $small
                  onClick={() =>
                    setAddDayModal({ type: 'business', edit: null })
                  }
                >
                  Add
                </Button>
              ) : (
                <Icon
                  onClick={() =>
                    setAddDayModal({ type: 'business', edit: null })
                  }
                >
                  <PlusIcon />
                </Icon>
              )}
            </Div>

            <Separator $m="16px 0" />

            {business?.map((workingHour) => (
              <div key={workingHour.id}>
                <Div $flex $between $alignCenter>
                  <div>
                    <P $mb="4">{renderDayOfWeek(workingHour.day)}</P>
                    <H5 $mb="0">
                      {convert24HourTo12Hour(workingHour.startTime)} -{' '}
                      {convert24HourTo12Hour(workingHour.endTime)}
                    </H5>
                  </div>

                  <Div $flex $between $alignCenter $gap="24px">
                    <Icon
                      $pointer
                      onClick={() =>
                        setAddDayModal({
                          type: 'business',
                          edit: workingHour,
                        })
                      }
                    >
                      <EditPenIcon />
                    </Icon>
                    <Icon
                      $pointer
                      onClick={() => setDeleteDayModal(workingHour.id)}
                    >
                      <TrashIconRed />
                    </Icon>
                  </Div>
                </Div>
                <Separator $m="16px 0" />
              </div>
            ))}
          </>
        )}

        {Array.isArray(delivery) && (
          <>
            <Div $flex $between $alignCenter $mt="40">
              <div>
                <H3 $m="0">Delivery Hours</H3>
                {delivery.length < 1 && (
                  <P $m="8px 0 0 0">
                    This location currently doesn't have delivery hours.
                  </P>
                )}
              </div>

              {delivery?.length < 1 ? (
                <Button
                  $selected
                  $small
                  onClick={() =>
                    setAddDayModal({ type: 'delivery', edit: null })
                  }
                >
                  Add
                </Button>
              ) : (
                <Icon
                  onClick={() =>
                    setAddDayModal({ type: 'delivery', edit: null })
                  }
                >
                  <PlusIcon />
                </Icon>
              )}
            </Div>

            <Separator $m="16px 0" />

            {delivery?.map((workingHour) => (
              <div key={workingHour.id}>
                <Div $flex $between $alignCenter>
                  <div>
                    <P $mb="4">{renderDayOfWeek(workingHour.day)}</P>
                    <H5 $mb="0">
                      {convert24HourTo12Hour(workingHour.startTime)} -{' '}
                      {convert24HourTo12Hour(workingHour.endTime)}
                    </H5>
                  </div>

                  <Div $flex $between $alignCenter $gap="24px">
                    <Icon
                      $pointer
                      onClick={() =>
                        setAddDayModal({
                          type: 'delivery',
                          edit: workingHour,
                        })
                      }
                    >
                      <EditPenIcon />
                    </Icon>
                    <Icon
                      $pointer
                      onClick={() => setDeleteDayModal(workingHour.id)}
                    >
                      <TrashIconRed />
                    </Icon>
                  </Div>
                </Div>
                <Separator $m="16px 0" />
              </div>
            ))}
          </>
        )}
      </AnimatedDiv>

      <AddWorkingHours
        setLoading={setLoading}
        modalIsOpen={addDayModal}
        setModalIsOpen={setAddDayModal}
        selectedLocation={selectedLocation}
        selectedBrand={selectedBrand}
        business={business}
        delivery={delivery}
        getWorkingHours={getWorkingHours}
        provider={provider}
      />

      <DeleteWorkingHours
        setLoading={setLoading}
        modalIsOpen={deleteDayModal}
        setModalIsOpen={setDeleteDayModal}
        selectedLocation={selectedLocation}
        selectedBrand={selectedBrand}
        setHolidayHours={setHolidayHours}
        provider={provider}
      />
    </>
  );
};
