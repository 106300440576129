import {
  AdminInput,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
  useUsers,
} from '@vgrubs/components';
import { useState } from 'react';
import { getBankByUserId } from './UserCreditCard';

export const UserCreditCardAdd = ({ id }) => {
  const [customerId, setCustomerId] = useState('');
  const [fundingSourceId, setFundingSourceId] = useState('');

  const { addBankModal, setAddBankModal, setLoading, setBank } = useUsers(
    (state) => state
  );

  async function handleAddBank() {
    if (!customerId || !fundingSourceId) {
      return ErrorToast('Please fill in all information');
    }

    const body = {
      customerId,
      fundingSourceId,
    };

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/users/bank/${id}`, body);
      SuccessToast(data.message);
      setAddBankModal(false);
      setCustomerId('');
      setFundingSourceId('');

      getBankByUserId(id, setLoading, setBank);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={addBankModal}
      setIsOpen={setAddBankModal}
      logo={false}
      header="Add Bank"
    >
      <Div $flex $column $gap="20px" $gapSM="16px">
        <AdminInput
          label="Customer ID"
          placeholder="Enter here"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
        />
        <AdminInput
          label="Funding Source ID"
          placeholder="Enter here"
          value={fundingSourceId}
          onChange={(e) => setFundingSourceId(e.target.value)}
        />
      </Div>

      <Button $selected $w="100%" $mt="50" $mtSM="30" onClick={handleAddBank}>
        Add Bank
      </Button>
    </Modal>
  );
};
