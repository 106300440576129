import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import { Div, P } from '@vgrubs/components';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorStyle = styled.div`
  border: 1px solid ${({ theme }) => theme.darkGray};
  border-radius: 20px;
  padding: 24px;

  :focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }

  .toolbar {
    border: none;
    background: none;
    padding: 0 !important;
    border-bottom: 1px dashed #e3e3e3;
    padding-bottom: 16px !important;
  }

  .editor {
    min-height: 100px;
  }
`;

export const AdminEditor = ({ onChange, label, value }) => {
  const [editor, setEditor] = useState(EditorState.createEmpty());

  const handleEditorChange = (data) => {
    setEditor(data);
    const dataForDatabase = draftToHtml(convertToRaw(data.getCurrentContent()));
    onChange(dataForDatabase);
  };

  useEffect(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      setEditor(EditorState.createWithContent(content));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div $width="100%">
      {label && (
        <P $mb="10">
          <b>{label}</b>
        </P>
      )}
      <EditorStyle>
        <Editor
          editorState={editor}
          onEditorStateChange={handleEditorChange}
          toolbarClassName="toolbar"
          wrapperClassName="wrapper"
          editorClassName="editor"
          stripPastedStyles="true"
          toolbar={{
            options: [
              'inline',
              'blockType',
              'list',
              'textAlign',
              // 'colorPicker',
              'link',
              'history',
            ],
            blockType: {
              inDropdown: true,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: [
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'superscript',
                'subscript',
              ],
            },
          }}
        />
      </EditorStyle>
    </Div>
  );
};
