import {
  AdminSpinner,
  AnimatedDiv,
  ErrorToast,
  H3,
  Img,
  P,
  agentImage,
  api,
  lightTheme,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { AgentComponent } from '../components/AgentComponent';
import { useInView } from 'react-intersection-observer';

export const AgentsSection = () => {
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();

  async function getAgents() {
    if (!hasMore) return;

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/agents/all`, {
        params: {
          limit: 20,
          page: page,
        },
      });

      if (data.data[0]) {
        setPage((prevPage) => prevPage + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      setAgents((prevItems) => [...prevItems, ...data.data]);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && agents.length > 0) {
      getAgents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {loading && <AdminSpinner />}

      {agents.map((agent, i) => {
        return <AgentComponent key={i} agent={agent} />;
      })}

      <div ref={ref}></div>

      {!hasMore && agents.length === 0 && (
        <AnimatedDiv $height="70vh" $flex $column $alignCenter $justifyCenter>
          <Img src={agentImage} $width="200px" />
          <H3 $center $mb="16" $mt="20" $color={lightTheme.primary}>
            No Agents!
          </H3>
          <P $center>
            You currently do not have any <br /> agents to verify.
          </P>
        </AnimatedDiv>
      )}
    </>
  );
};
