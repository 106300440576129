import {
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H3,
  H5,
  LocationPinIcon,
  Modal,
  ModalFullScreen,
  P,
  Separator,
  api,
  renderRole,
  useAuth,
  useUsers,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KeyValueInfo } from '../Verifications/components/KeyValueInfo';
import { UserCreditCard } from './Bank/UserCreditCard';
import { UserCreditCardAdd } from './Bank/UserCreditCardAdd';
import { UserCreditCardDelete } from './Bank/UserCreditCardDelete';
import { UserCreditCardEditBalance } from './Bank/UserCreditCardEditBalance';

export const UsersAndAccessDetailsModal = ({
  modalIsOpen,
  setModalIsOpen,
  handleOnDelete,
  handleOnEdit,
  noActions,
  callGetUser,
}) => {
  const [user, setUser] = useState(null);
  const [locations, setLocaitions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { loading: loadingBank } = useUsers((state) => state);

  const { role } = useAuth((state) => state.user);

  const hasLocations = user?.role !== 'admin' && user?.role !== 'support';

  const hasBank = user?.role === 'partner' && role === 'admin';

  async function getLocationsByUserId() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/users/${modalIsOpen?.id}/locations`);

      setLocaitions(data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function getUserById() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/users/${modalIsOpen?.id}`);

      setUser(data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (modalIsOpen?.id) {
      setUser(modalIsOpen);

      if (callGetUser) {
        getUserById();
      }
      if (hasLocations) {
        getLocationsByUserId();
      }
    }
  }, [modalIsOpen]);

  //TODO REFACTOR MODALS

  return (
    <>
      {(loading || loadingBank) && <AdminSpinner />}
      {hasBank ? (
        <ModalFullScreen
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          logo={false}
          // header="User Details"
        >
          <Div $flex $column>
            <H3 $mb="35">
              <span>{user?.firstName + ' ' + user?.lastName}</span>
            </H3>
            <Div
              $flex
              $alignCenter
              $width="100%"
              $gap="50px"
              $gapSM="32px"
              $columnSM
              $mb="32px"
            >
              <Div $width="100%">
                <Separator />

                {user?.email && <KeyValueInfo k="Email" v={user?.email} />}

                {user?.phoneNumber && (
                  <KeyValueInfo k="Phone" v={user?.phoneNumber} />
                )}

                <KeyValueInfo k="Role" v={renderRole(user?.role)} />
              </Div>
              {hasBank && (
                <Div $widthSM="100%" $flex $column $center $gap="32px">
                  <UserCreditCard id={user?.id} locations={locations} />
                  {/* <AdminInput
                    tooltip="The GoCardless Customer ID will be used for processing client payments related to outstanding balances on the vDrive credit card. A 2% fee will be applied for each balance top-up. Payments will be automatically charged every Monday and Thursday night."
                    $textCenter
                    label="ACH Customer ID"
                    placeholder="Enter here"
                    value={user?.achCustomerId}
                    disabled
                  /> */}
                </Div>
              )}
            </Div>

            {hasLocations && (
              <>
                <H5 $mt="20" $mb="20">
                  Locations
                </H5>
                {locations.map(({ name, id }, i) => {
                  return (
                    <Link
                      to={`/locations/${id}`}
                      style={{ width: '100%' }}
                      key={i}
                    >
                      <Div
                        $box
                        $p="12px 20px"
                        $mb="8"
                        $flex
                        $between
                        $alignCenter
                      >
                        <H5 $m="0">{name}</H5>
                        <LocationPinIcon />
                      </Div>
                    </Link>
                  );
                })}
                {locations.length < 1 && <P>No Locations</P>}
              </>
            )}

            {!noActions && (
              <Div $flex $columnSM $gap="32px" $gapSM="16px" $mt="32">
                <Div $width="33%" $widthSM="100%">
                  <Link to={`/transactions/${user?.id}`} state={user}>
                    <Button $selected $w="100%">
                      View transactions
                    </Button>
                  </Link>
                </Div>
                <Div $width="33%" $widthSM="100%">
                  <Button
                    $selected
                    $gray
                    $w="100%"
                    onClick={() => handleOnEdit(user)}
                  >
                    Edit User
                  </Button>
                </Div>

                <Div $width="33%" $widthSM="100%">
                  <Button
                    $selected
                    $w="100%"
                    onClick={() => handleOnDelete(user)}
                  >
                    Delete User
                  </Button>
                </Div>
              </Div>
            )}
          </Div>
        </ModalFullScreen>
      ) : (
        <Modal
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          logo={false}
          header="User Details"
        >
          <Div $flex $column>
            <H3 $mb="35">
              <span>{user?.firstName + ' ' + user?.lastName}</span>
            </H3>
            <Div
              $flex
              $alignCenter
              $width="100%"
              $gap="50px"
              $gapSM="32px"
              $columnSM
              $mb="32px"
            >
              <Div $width="100%">
                <Separator />

                {user?.email && <KeyValueInfo k="Email" v={user?.email} />}

                {user?.phoneNumber && (
                  <KeyValueInfo k="Phone" v={user?.phoneNumber} />
                )}

                <KeyValueInfo k="Role" v={renderRole(user?.role)} />
              </Div>
              {hasBank && (
                <Div $widthSM="100%" $flex $center>
                  <UserCreditCard id={user?.id} locations={locations} />
                </Div>
              )}
            </Div>

            {hasLocations && (
              <>
                <H5 $mt="20" $mb="20">
                  Locations
                </H5>
                {locations.map(({ name, id }, i) => {
                  return (
                    <Link
                      to={`/locations/${id}`}
                      style={{ width: '100%' }}
                      key={i}
                    >
                      <Div
                        $box
                        $p="12px 20px"
                        $mb="8"
                        $flex
                        $between
                        $alignCenter
                      >
                        <H5 $m="0">{name}</H5>
                        <LocationPinIcon />
                      </Div>
                    </Link>
                  );
                })}
                {locations.length < 1 && <P>No Locations</P>}
              </>
            )}

            {!noActions && (
              <Div $flex $column $gap="16px" $mt="32">
                <Div $width="100%" $widthSM="100%">
                  <Link to={`/transactions/${user?.id}`} state={user}>
                    <Button $selected $w="100%">
                      View transactions
                    </Button>
                  </Link>
                </Div>
                <Div $width="100%">
                  <Button
                    $selected
                    $gray
                    $w="100%"
                    onClick={() => handleOnEdit(user)}
                  >
                    Edit User
                  </Button>
                </Div>
                <Div $width="100%">
                  <Button
                    $selected
                    $w="100%"
                    onClick={() => handleOnDelete(user)}
                  >
                    Delete User
                  </Button>
                </Div>
              </Div>
            )}
          </Div>
        </Modal>
      )}
      <UserCreditCardAdd id={user?.id} />
      <UserCreditCardDelete id={user?.id} />
      <UserCreditCardEditBalance id={user?.id} />
    </>
  );
};
