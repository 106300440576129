import {
  AnimatedDiv,
  Chevron,
  Div,
  H5,
  Icon,
  Logo,
  P,
} from '@vgrubs/components';
import { RestaurantStyled } from '../VerificationsStyled';
import { Link } from 'react-router-dom';

export const RestaurantComponent = ({ location }) => {
  return (
    <Link to={`/verifications/restaurant/${location.id}`}>
      <AnimatedDiv>
        <RestaurantStyled>
          <Div $flex $gap="24px" $alignCenter>
            <Logo width="70" height="70" />
            <Div>
              <H5 $mb="4">{location.name}</H5>
              <P $m="0">{location.address}</P>
            </Div>
          </Div>
          <Icon style={{ rotate: '90deg' }}>
            <Chevron />
          </Icon>
        </RestaurantStyled>
      </AnimatedDiv>
    </Link>
  );
};
