import {
  AdminSelect,
  Button,
  Div,
  ErrorToast,
  H5,
  Modal,
  SuccessToast,
  api,
  convert24HourTo12Hour,
  times,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';

const timeFromOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      From
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

const timeToOptions = (
  <optgroup label="Select Time">
    <option disabled value="" hidden>
      To
    </option>
    {times.map((t, i) => (
      <option value={t} key={i}>
        {convert24HourTo12Hour(t)}
      </option>
    ))}
  </optgroup>
);

export const AddHolidayHours = ({
  modalIsOpen,
  setModalIsOpen,
  selectedBrand,
  selectedLocation,
  getHolidayHours,
  setLoading,
  provider,
}) => {
  const today = new Date();

  const [date, setDate] = useState([today]);
  const [timeFromBusiness, setTimeFromBusiness] = useState('');
  const [timeToBusiness, setTimeToBusiness] = useState('');
  const [timeFromDelivery, setTimeFromDelivery] = useState('');
  const [timeToDelivery, setTimeToDelivery] = useState('');

  const holidayHourData = modalIsOpen;

  useEffect(() => {
    if (holidayHourData.edit) {
      const chosenDate = DateTime.fromISO(holidayHourData.edit.date).toJSDate();

      setDate([chosenDate]);
      setTimeFromBusiness(holidayHourData.edit.business.startTime);
      setTimeToBusiness(holidayHourData.edit.business.endTime);
      setTimeFromDelivery(holidayHourData.edit.delivery.startTime);
      setTimeToDelivery(holidayHourData.edit.delivery.endTime);
    } else {
      setDate([]);
      setTimeFromBusiness('');
      setTimeToBusiness('');
      setTimeFromDelivery('');
      setTimeToDelivery('');
    }
  }, [holidayHourData]);

  async function handleAdd() {
    if (
      !date ||
      !timeFromBusiness ||
      !timeToBusiness ||
      !timeFromDelivery ||
      !timeToDelivery
    )
      return ErrorToast('Please fill in all information');

    const chosenDate = DateTime.fromJSDate(date[0]).toISODate();

    const body = {
      provider,
      date: chosenDate,
      business: {
        startTime: timeFromBusiness,
        endTime: timeToBusiness,
      },
      delivery: {
        startTime: timeFromDelivery,
        endTime: timeToDelivery,
      },
    };

    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/${selectedBrand ? 'brands' : 'locations'}/${
          selectedBrand?.id || selectedLocation?.id
        }/holiday-hours`,
        body
      );
      getHolidayHours();
      setModalIsOpen(false);
      SuccessToast(data.message);
      setDate([]);
      setTimeFromBusiness('');
      setTimeToBusiness('');
      setTimeFromDelivery('');
      setTimeToDelivery('');
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header={`${holidayHourData?.edit?.date ? 'Edit' : 'Add'} Day`}
      textAlign="left"
      width=""
    >
      <H5>Select Day</H5>
      <Div $flex $justifyCenter>
        <AdminDatePickerWrapperStyled>
          <AdminDatePickerStyled
            value={date}
            onChange={(date) => setDate(date)}
            options={{
              dateFormat: 'M d, Y',
              inline: true,
              minDate: 'today',
            }}
            className="form-control"
          />
        </AdminDatePickerWrapperStyled>
      </Div>

      <H5 $mt="32" $mb="16">
        Select Business Hours From - To
      </H5>
      <Div $flex $gap="24px">
        <AdminSelect
          options={timeFromOptions}
          value={timeFromBusiness}
          onChange={({ target }) => setTimeFromBusiness(target.value)}
          placeholder="From"
        />
        <AdminSelect
          options={timeToOptions}
          value={timeToBusiness}
          onChange={({ target }) => setTimeToBusiness(target.value)}
          placeholder="To"
        />
      </Div>
      <H5 $mt="32" $mb="16">
        Select Delivery Hours From - To
      </H5>
      <Div $flex $gap="24px">
        <AdminSelect
          options={timeFromOptions}
          value={timeFromDelivery}
          onChange={({ target }) => setTimeFromDelivery(target.value)}
          placeholder="From"
        />
        <AdminSelect
          options={timeToOptions}
          value={timeToDelivery}
          onChange={({ target }) => setTimeToDelivery(target.value)}
          placeholder="To"
        />
      </Div>

      <Button $selected $w="100%" $mt="32" onClick={() => handleAdd()}>
        Confirm
      </Button>
    </Modal>
  );
};

const AdminDatePickerWrapperStyled = styled.div`
  position: relative;
  gap: 16px;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.darkGray};
  display: inline-block;
  padding: 12px 12px 16px 12px;
  border: ${({ theme }) => theme.borders.border1};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: 20px;

  .flatpickr-calendar.rangeMode.animate.inline {
    margin: 0 !important;
  }

  .flatpickr-day.inRange {
    background: #f7f7f7;
    border-color: #f7f7f7;
    border-radius: 50%;
    box-shadow: none;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #e61e29;
    border-color: #e61e29;
    color: #fff;
    border-radius: 50%;
    box-shadow: none !important;
  }

  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange {
    border-radius: 50%;
  }

  .flatpickr-day.today {
    border-color: #e61e29;
  }

  .flatpickr-calendar {
    border: none;
    border: none;
    box-shadow: none;
  }

  .flatpickr-weekday {
    font-weight: normal !important;
  }

  .flatpickr-weekdays {
    height: 50px;
  }

  .flatpickr-input {
    display: none;
    pointer-events: none;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    -moz-appearance: none !important; /* Firefox */
    -webkit-appearance: none !important; /* Safari and Chrome */
    appearance: none !important;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: none;
    color: #e61e29;
  }
`;

const AdminDatePickerStyled = styled(Flatpickr)`
  width: 250px;
  border: none;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 12.5px 24px 12.5px 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  cursor: pointer;

  :focus {
    outline: none !important;
    /* border: 1px solid ${({ theme }) => theme.primary}; */
  }
`;
