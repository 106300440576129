import {
  AdminInput,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const PrintingSettingsModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setData,
  data,
  id,
}) => {
  const [message, setMessage] = useState('');
  const [frontCopies, setFrontCopies] = useState(1);
  const [backCopies, setBackCopies] = useState(1);

  useEffect(() => {
    if (data) {
      setMessage(data.message);
      setFrontCopies(data.frontCopies);
      setBackCopies(data.backCopies);
    }
  }, [data]);

  async function handlePrintingSettings() {
    try {
      setLoading(true);
      const res = await api.post(`/v1/locations/${id}/printing-settings`, {
        message,
        frontCopies,
        backCopies,
        onlyBrandReceipt: data.onlyBrandReceipt,
      });

      setData((prev) => {
        return { ...prev, message, frontCopies, backCopies };
      });

      // setData(res.data.data);
      SuccessToast(res.data.message);
      setModalIsOpen(false);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Printing Settings"
    >
      <AdminInput
        label="Footer Message"
        type="textarea"
        placeholder="Enter your message here.."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <Div $flex $gap="16px" $mt="24">
        <AdminInput
          onChange={(e) => setFrontCopies(e.target.value)}
          value={frontCopies}
          label="Front Receipts"
          type="number"
          $textCenter
        />
        <AdminInput
          onChange={(e) => setBackCopies(e.target.value)}
          value={backCopies}
          label="Back Receipts"
          type="number"
          $textCenter
        />
      </Div>

      <Button $selected $w="100%" $mt="40" onClick={handlePrintingSettings}>
        Update
      </Button>
    </Modal>
  );
};
