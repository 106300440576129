import {
  AdminHeader,
  AdminSpinner,
  AnimatedDiv,
  ErrorToast,
  api,
  useUIState,
  useVerifications,
} from '@vgrubs/components';
import { Tab } from './VerificationsStyled';
import { AgentsSection } from './sections/Agents';
import { RestaurantsSection } from './sections/Restaurants';
import { VDriveVerifications } from './sections/VDriveVerifications';
import { useEffect, useState } from 'react';

export const Verifications = () => {
  const { verificationsTab, setVerificationsTab } = useUIState(
    (state) => state
  );

  const { count, loading, getCount } = useVerifications((state) => state);

  useEffect(() => {
    getCount();
  }, [verificationsTab]);

  return (
    <>
      {loading && <AdminSpinner />}

      <AdminHeader />

      <AnimatedDiv $flex $gap="24px" $gapSM="16px" $p="32px 0">
        <Tab
          selected={verificationsTab === 1}
          onClick={() => setVerificationsTab(1)}
        >
          Restaurants ({count.locations})
        </Tab>
        <Tab
          selected={verificationsTab === 2}
          onClick={() => setVerificationsTab(2)}
        >
          Agents ({count.agents})
        </Tab>
        <Tab
          selected={verificationsTab === 3}
          onClick={() => setVerificationsTab(3)}
        >
          vDrive ({count.vDrive})
        </Tab>
      </AnimatedDiv>

      {verificationsTab === 1 && <RestaurantsSection />}
      {verificationsTab === 2 && <AgentsSection />}
      {verificationsTab === 3 && <VDriveVerifications />}
    </>
  );
};
