import {
  AdminInput,
  AdminSpinner,
  Disclaimer,
  Div,
  DropdownMenuComponent,
  ErrorToast,
  H5,
  Icon,
  Img,
  LocationPinIcon,
  Overlay,
  P,
  Radio,
  api,
  lightTheme,
  overlayAnimationVariant,
  restaurantImage,
  useDisableBodyScroll,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { AnimatePresence } from 'framer-motion';
import debouce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  LocationsPicker,
  ScrollableWrapper,
} from './AdminLocationsPickerStyled';

export const AdminSingleLocationPicker = ({
  selectedLocation,
  setSelectedLocation,
  handleSubmit,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [ref, inView] = useInView();
  const [hasMore, setHasMore] = useState(true);
  const [isInit, setIsInit] = useState(true);

  useDisableBodyScroll(dropdown);

  async function getLocations(search, page, hasMore, query) {
    if (!hasMore) return;
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/locations/verified`, {
        params: {
          query,
          page,
          limit: 10,
        },
      });

      if (!search) {
        if (data.data[0]) {
          setHasMore(true);
          setData((prevItems) => [...prevItems, ...data.data]);
        } else {
          setHasMore(false);
        }
      } else {
        if (data.data[0]) {
          setHasMore(true);
          setData(() => [...data.data]);
        } else {
          setData(() => [...data.data]);
          setHasMore(false);
        }
      }

      if (data.data.length > 0 && isInit && selectedLocation === null) {
        setSelectedLocation(data.data[0]);
        handleSubmit(data.data[0]);
        setIsInit(false);
      }

      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      setHasMore(false);
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (data.length < 1) {
      getLocations(false, page, hasMore, query);
    }
  }, []);

  useEffect(() => {
    if (inView && data.length > 0) {
      getLocations(false, page, hasMore, query);
    }
  }, [inView]);

  const handleSelectLocation = (newItem) => {
    setSelectedLocation(newItem);
    setDropdown((prev) => !prev);
    handleSubmit(newItem);
  };

  function isLocationSelected(item) {
    return selectedLocation?.id === item.id;
  }

  function renderLocationInputPlaceholder() {
    if (!selectedLocation) {
      return 'Select Location';
    } else if (selectedLocation) {
      return selectedLocation?.name;
    }
  }

  const handleChange = async (e) => {
    setHasMore(true);
    setPage(1);
    setQuery(e.target.value);
    getLocations(true, 1, true, e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <AnimatePresence>
      <Div $relative $height="45px" $heightSM="45px">
        <LocationsPicker
          $opened={dropdown}
          onClick={() => setDropdown((prev) => !prev)}
        >
          <Div>{renderLocationInputPlaceholder()}</Div>

          <Icon>
            <LocationPinIcon />
          </Icon>
        </LocationsPicker>

        <DropdownMenuComponent
          isOpen={dropdown}
          setIsOpen={setDropdown}
          maxHeight="70vh"
          padding="0"
          fullWidth
        >
          {loading && <AdminSpinner absolute small />}

          <Div $p="20px">
            <AdminInput
              search
              onChange={debouncedResults}
              placeholder="Search Location"
            />
          </Div>
          <ScrollableWrapper>
            {data.length === 0 && !hasMore && (
              <Div
                $flex
                $center
                $column
                $height="calc(70vh - 300px)"
                $heightSM="calc(60vh - 300px)"
              >
                <Img
                  src={restaurantImage}
                  alt="restaurant"
                  $width="250px"
                  $widthSM="150px"
                />
                <H5>No Locations</H5>
              </Div>
            )}
            {data.map((l, i) => {
              if (data.length === i + 1) {
                return (
                  <Div
                    ref={ref}
                    key={i}
                    $box
                    $p="14px 24px"
                    $mb="8"
                    $flex
                    $alignCenter
                    $between
                    onClick={() => handleSelectLocation(l)}
                    $gap="16px"
                    $pointer
                  >
                    <div>
                      <P
                        $bold
                        $mb="8"
                        $color={isLocationSelected(l) && lightTheme.primary}
                        $lineHeight="1.2"
                      >
                        {l.name}
                      </P>
                      <Disclaimer $mb="0">{l.address}</Disclaimer>
                    </div>

                    <Radio checked={isLocationSelected(l)} />
                  </Div>
                );
              } else
                return (
                  <Div
                    key={i}
                    $box
                    $p="14px 24px"
                    $mb="8"
                    $flex
                    $alignCenter
                    $between
                    onClick={() => handleSelectLocation(l)}
                    $gap="16px"
                    $pointer
                  >
                    <div>
                      <P
                        $bold
                        $mb="8"
                        $color={isLocationSelected(l) && lightTheme.primary}
                        $lineHeight="1.2"
                      >
                        {l.name}
                      </P>
                      <Disclaimer $mb="0">{l.address}</Disclaimer>
                    </div>

                    <Radio checked={isLocationSelected(l)} />
                  </Div>
                );
            })}
            <div></div>
          </ScrollableWrapper>
        </DropdownMenuComponent>
      </Div>

      {dropdown && (
        <Overlay
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};
