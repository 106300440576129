import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useUsers = create(
  devtools((set) => ({
    loading: false,
    setLoading: (value) => set(() => ({ loading: value })),
    bank: null,
    setBank: (value) => set(() => ({ bank: value })),
    addBankModal: false,
    setAddBankModal: (value) => set(() => ({ addBankModal: value })),
    deleteBankModal: false,
    setDeleteBankModal: (value) => set(() => ({ deleteBankModal: value })),

    editBalanceModal: false,
    setEditBalanceModal: (value) => set(() => ({ editBalanceModal: value })),
  }))
);
