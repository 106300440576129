import {
  Button,
  CalendarIconRed,
  Div,
  DropdownMenuComponent,
  Overlay,
  P,
  lightTheme,
  overlayAnimationVariant,
} from '@vgrubs/components';
import 'flatpickr/dist/flatpickr.css';
import { AnimatePresence } from 'framer-motion';
import { DateTime } from 'luxon';
import { useState } from 'react';
import styled from 'styled-components';
import {
  AdminDatePickerStyled,
  FlatpickrStyled,
} from './AdminDatePickerStyled';

export const AdminDatePickerInput = ({
  valueInput,
  click,
  centerFixed,
  ...props
}) => {
  const [dropdown, setDropdown] = useState(false);

  // Get today's date
  const today = DateTime.now();

  // Subtract one day from today's date
  const yesterday = today.minus({ days: 1 }).toJSDate();

  return (
    <AnimatePresence>
      <Div $relative>
        {props.label && (
          <P $mb="8" $ml="24" $mr="24" $bold $center={props.$textCenter}>
            {props.label}{' '}
          </P>
        )}
        <AdminInputStyled
          shadowDesign
          onClick={() => setDropdown(true)}
          {...props}
        >
          {DateTime.fromISO(valueInput).toLocal().toFormat('MMM dd, yyyy')}
          {/* <CalendarIconRed /> */}
        </AdminInputStyled>
        <DropdownMenuComponent
          isOpen={dropdown}
          setIsOpen={setDropdown}
          toLeft={!centerFixed}
          centerFixed={centerFixed}
        >
          <FlatpickrStyled>
            <AdminDatePickerStyled
              {...props}
              options={{
                mode: 'single',
                dateFormat: 'M d, Y',
                inline: true,
                locale: { rangeSeparator: ' - ' },
                maxDate: yesterday,
              }}
              className="form-control"
            />
          </FlatpickrStyled>

          <Button
            $selected
            $w="100%"
            $mt="24"
            $wSM="100%"
            onClick={() => {
              click();
              setDropdown(false);
            }}
          >
            Apply
          </Button>
        </DropdownMenuComponent>
      </Div>

      {dropdown && (
        <Overlay
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};

const AdminInputStyled = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid ${({ theme }) => theme.darkGray}80;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 45px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: none;

  ${({ shadowDesign, theme }) =>
    shadowDesign &&
    `
  background: ${theme.white};
  border: 1px solid #e3e3e3;
  box-shadow: ${theme.shadows.shadow1};
  `}

  ${({ $textCenter }) => $textCenter && `text-align: center`};

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.primary};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;
