import {
  AdminRadioInput,
  AdminSpinner,
  Button,
  CopyIcon,
  Div,
  ErrorToast,
  H3,
  H5,
  Icon,
  Img,
  Modal,
  P,
  Separator,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useState } from 'react';
import { ProviderLogoWrapper } from './LocationStyled';
import { providerLogo, providerStatus } from './helpers';

export const ProviderDetailsModal = ({
  modalIsOpen,
  setModalIsOpen,
  id,
  provider,
  pathPrefix,
  setConnectionStatuses,
  setStatuses,
  status,
  credentials,
}) => {
  const [minutes, setMinutes] = useState(null);
  const [credentialsModal, setCredentialsModal] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handlePause(action) {
    var body = {
      provider,
      minutes,
    };

    if (action === 'unpause') delete body.minutes;

    try {
      setLoading(true);
      const res = await api.post(
        `/v1/${pathPrefix}/${id}/provider/${action}`,
        body
      );

      setStatuses((prev) => {
        return prev.map((item) => {
          if (item.provider === provider) {
            return {
              ...item,
              status:
                action === 'pause'
                  ? providerStatus.OFFLINE
                  : providerStatus.ONLINE,
            };
          }
          return item;
        });
      });

      SuccessToast(res.data.message);
      setModalIsOpen(false);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function handleDisconnect() {
    try {
      setLoading(true);
      const res = await api.post(
        `/v1/${pathPrefix}/${id}/provider/disconnect`,
        {
          provider,
        }
      );

      setConnectionStatuses((prev) =>
        prev.filter((item) => item.provider !== provider)
      );

      setStatuses((prev) => prev.filter((item) => item.provider !== provider));

      SuccessToast(res.data.message);
      setModalIsOpen(false);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const timeOptions = [
    { name: 'All Day', value: null },
    { name: '2 hours', value: 120 },
    { name: '1 hour', value: 60 },
    { name: '30 mins', value: 30 },
  ];

  return (
    <>
      {loading && <AdminSpinner />}

      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        header="Provider Details"
      >
        <Div $flex $center>
          <ProviderLogoWrapper>
            <span>
              <Img $width={'90px'} src={providerLogo(provider)} />
            </span>
          </ProviderLogoWrapper>
        </Div>

        {status?.status === providerStatus.ONLINE && (
          <>
            <H3 $mb="16">Pause</H3>
            <Div $flex $column $gap="8px">
              {timeOptions.map(({ name, value }, i) => (
                <AdminRadioInput
                  key={i}
                  shadowDesign
                  label={name}
                  checked={value === minutes}
                  onClick={() => setMinutes(value)}
                />
              ))}
            </Div>

            <Button
              $selected
              $w="100%"
              $mt="20"
              onClick={() => handlePause('pause')}
            >
              Confirm Pause
            </Button>
          </>
        )}

        {status?.status === providerStatus.OFFLINE && (
          <>
            {
              <H5>
                The provider is currently paused. Click on the resume button to
                unpause it.
              </H5>
            }

            <Button
              $selected
              $w="100%"
              $mt="20"
              onClick={() => handlePause('unpause')}
            >
              Resume
            </Button>
          </>
        )}

        <Separator $m="20px 0" />

        {credentials && (
          <Button
            $selected
            $yellow
            $w="100%"
            $mb="20"
            onClick={() => setCredentialsModal(true)}
          >
            Show Credentials
          </Button>
        )}

        <Button $selected $gray $w="100%" onClick={() => handleDisconnect()}>
          Disconnect
        </Button>
      </Modal>
      <CredentialsModal
        credentials={credentials}
        modalIsOpen={credentialsModal}
        setModalIsOpen={setCredentialsModal}
      />
    </>
  );
};

const CredentialsModal = ({ modalIsOpen, setModalIsOpen, credentials }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Credentials"
      zIndex={200}
      textAlign={'left'}
    >
      <Div $flex $alignCenter $between>
        <div>
          <H5 $m="0">Login</H5>
          <P $m="0">{credentials?.username}</P>
        </div>
        <Icon $pointer onClick={() => copyToClipboard(credentials?.username)}>
          <CopyIcon />
        </Icon>
      </Div>
      <Separator $m="20px 0" />

      <Div $flex $alignCenter $between>
        <div>
          <H5 $m="0">Password</H5>
          <P $m="0">{credentials?.password}</P>
        </div>
        <Icon $pointer onClick={() => copyToClipboard(credentials?.password)}>
          <CopyIcon />
        </Icon>
      </Div>
      <Separator $m="20px 0" />

      <Div $flex $alignCenter $between>
        <div>
          <H5 $m="0">Store ID</H5>
          <P $m="0">{credentials?.storeId}</P>
        </div>
        <Icon $pointer onClick={() => copyToClipboard(credentials?.storeId)}>
          <CopyIcon />
        </Icon>
      </Div>
    </Modal>
  );
};

function copyToClipboard(value) {
  // Copy the text inside the text field
  navigator.clipboard.writeText(value);

  // Alert the copied text
  SuccessToast('Copied to clipboard');
}
