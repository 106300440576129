import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { DropdownMenuComponent } from '@vgrubs/components';
import { useOnClickOutside } from '@vgrubs/components';
import { Div } from '@vgrubs/components';

const SelectedStyled = styled.div`
  width: 250px;
  font-size: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: 32px;
`;

const SelectOption = styled.div`
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 20px;

  ${({ selected, theme }) => selected && `background-color: ${theme.gray};`}

  :hover {
    background-color: ${({ theme }) => theme.gray};
  }
`;

const AdminSelectWrapperStyled = styled.div`
  position: relative;
  border-radius: 200px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 6px 50px 6px 20px;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.darkGray};
  /* padding: 0 8px; */

  background-color: ${({ theme }) => theme.white};
  border: 1px solid #e3e3e3;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};

  ${({ $opened, theme }) => $opened && `border: 1px solid ${theme.primary};`}

  @media (max-width: 800px) {
    width: 100%;
    flex: none;
  }
`;

const ChevronStyled = styled.div`
  position: absolute;
  right: 16px;
  left: auto;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  height: 16px;
  pointer-events: none;
`;

export const AdminSelectPagination = ({
  dropdown,
  setDropdown,
  onChange,
  value,
  options,
  placeholder,
  get,
  hasMore,
}) => {
  const [ref, inView] = useInView();

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => dropdown && setDropdown(false));

  useEffect(() => {
    if (inView && hasMore) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <AdminSelectWrapperStyled
      $opened={dropdown}
      onClick={() => setDropdown(true)}
      ref={dropdownRef}
    >
      <Div $alignCenter $flex>
        <Div $flex $center>
          <SelectedStyled>
            {value
              ? `${value?.user?.firstName} ${value?.user?.lastName}`
              : placeholder}
          </SelectedStyled>
          <DropdownMenuComponent
            isOpen={dropdown}
            setIsOpen={setDropdown}
            maxHeight={'200px'}
          >
            {options.map((o, i) => (
              <SelectOption
                selected={o === value}
                onClick={() => {
                  onChange(o);
                  setDropdown(false);
                }}
                key={i}
              >
                {o.user.firstName + ' ' + o.user.lastName}
              </SelectOption>
            ))}
            <div ref={ref}></div>
          </DropdownMenuComponent>
        </Div>

        <ChevronStyled>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.29365 12.7066C7.68428 13.0973 8.31865 13.0973 8.70928 12.7066L14.7093 6.70664C15.0999 6.31602 15.0999 5.68164 14.7093 5.29102C14.3187 4.90039 13.6843 4.90039 13.2937 5.29102L7.9999 10.5848L2.70615 5.29414C2.31553 4.90352 1.68115 4.90352 1.29053 5.29414C0.899902 5.68477 0.899902 6.31914 1.29053 6.70977L7.29053 12.7098L7.29365 12.7066Z"
              fill="#4C4C4C"
            />
          </svg>
        </ChevronStyled>
      </Div>
    </AdminSelectWrapperStyled>
  );
};
