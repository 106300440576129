import {
  AdminDatePicker,
  AdminHeader,
  AdminInput,
  AdminSpinner,
  Div,
  ErrorToast,
  H3,
  H5,
  P,
  api,
  handleSetUrlSearchParams,
  lightTheme,
  renderDateLabelWithToday,
  setTimeStartEnd,
} from '@vgrubs/components';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios'; // Axios for checking if requests were canceled
import { DateTime } from 'luxon';
import { UsersAndAccessDetailsModal } from '../UsersAndAccess/UsersAndAccessDetails';

export const AuditLog = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [userDetailsModal, setUserDetailsModal] = useState(null);
  const [ref, inView] = useInView();

  const today = new Date();

  const INITIAL_DATE = [
    setTimeStartEnd(today, 'start'),
    setTimeStartEnd(today, 'end'),
  ];

  const INITIAL_PARAMS = {
    from: INITIAL_DATE[0],
    to: INITIAL_DATE[1],
    page: 1,
    limit: 20,
  };

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);

  const query = searchParams.get('query');
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const page = searchParams.get('page');

  const cancelTokenSourceRef = useRef(null); // Track the cancel token

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Previous request canceled.');
    }
    cancelTokenSourceRef.current = axios.CancelToken.source(); // Create a new cancel token
  };

  async function getAllLocations(search, page, hasMore) {
    if (!hasMore) return;

    cancelPreviousRequest(); // Cancel any ongoing request before making a new one

    try {
      setLoading(true);

      const { data } = await api.get(`/v1/audit-logs`, {
        cancelToken: cancelTokenSourceRef.current.token, // Attach the new cancel token
        params: searchParams,
      });

      if (!search) {
        if (data.data[0]) {
          setHasMore(true);
          setUsers((prevItems) => [...prevItems, ...data.data]);
        } else {
          setHasMore(false);
        }
      } else {
        if (data.data[0]) {
          setHasMore(true);
          setUsers(() => [...data.data]);
        } else {
          setUsers(() => [...data.data]);
          setHasMore(false);
        }
      }

      setPage(Number(page) + 1);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.log('Previous request canceled:', error.message);
      } else {
        ErrorToast(error?.response?.data?.message);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    getAllLocations(true, 1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, from, to]);

  useEffect(() => {
    if (inView && users.length > 0) {
      getAllLocations(false, page, hasMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const debouncedResults = useMemo(() => {
    return debounce((e) => {
      handleSetUrlSearchParams('query', e.target.value, setSearchParams);
    }, 500);
  }, [setSearchParams]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  const [dateInput, setDateInput] = useState([today, today]);

  const setDate = (date) => {
    handleSetUrlSearchParams('from', date[0], setSearchParams);
    handleSetUrlSearchParams('to', date[1], setSearchParams);
  };

  const setPage = (page) => {
    handleSetUrlSearchParams('page', page, setSearchParams);
  };

  const handleDateChange = (date) => {
    setDateInput(date);
  };

  const handleDateApply = () => {
    setUsers([]);

    if (dateInput.length < 2) {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[0], 'end'),
      ]);
      setDateInput([dateInput[0], dateInput[0]]);
    } else {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[1], 'end'),
      ]);
    }
  };

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminHeader title={'Audit Log'}>
        <AdminDatePicker
          mode="range"
          value={dateInput}
          onChange={handleDateChange}
          click={handleDateApply}
        />
      </AdminHeader>
      <Div $p="40px 0" $flex $alignCenter $between $columnSM $gap="24px">
        <Div $desktop>
          <H3 $m="0">Users Activity</H3>
        </Div>
        <AdminInput
          defaultValue={query}
          maxWidth="350px"
          maxWidthSM="100%"
          search
          placeholder="Search Action or User"
          onChange={debouncedResults}
        />
      </Div>
      <H5 $mb="32">{renderDateLabelWithToday(from, to)}</H5>

      <Div
        $background={lightTheme.primary}
        $mb="8"
        $box
        $p="16px 24px"
        $flex
        $alignCenter
        $gap="32px"
        $gapSM="16px"
        $desktop
      >
        <Div $width="20%">
          <P $m="0" $color={lightTheme.white}>
            DATE AND TIME
          </P>
        </Div>
        <Div $width="60%" $color={lightTheme.white}>
          <P $m="0" $color={lightTheme.white}>
            ACTION
          </P>
        </Div>
        <Div $width="20%" $color={lightTheme.white}>
          <P $m="0" $color={lightTheme.white}>
            USER
          </P>
        </Div>
      </Div>

      {users.map((log, i) => {
        const formattedDate = DateTime.fromISO(log.createdAt)
          .setZone('local') // Optional: Convert to local time zone
          .toFormat('MM/dd/yyyy - h:mm a');
        return (
          <div key={i}>
            <Div
              $mb="8"
              $box
              $p="16px 24px"
              $flex
              $alignCenter
              $columnSM
              $alignStartSM
              $gap="32px"
              $textAlignLeft
              $gapSM="16px"
              $desktop
            >
              <Div $width="20%" $widthSM="100%">
                <P $m="0">{formattedDate}</P>
              </Div>
              <Div $width="60%" $widthSM="100%">
                <P $m="0">{log.info} </P>
              </Div>
              <Div $width="20%" $widthSM="100%">
                <P
                  $pointer
                  $m="0"
                  onClick={() => setUserDetailsModal(log.user)}
                  $hoverUnderline
                >
                  {log.user.firstName} {log.user.lastName}
                </P>
              </Div>
            </Div>

            <Div
              $mb="8"
              $box
              $p="16px 24px"
              $flex
              $alignCenter
              $columnSM
              $alignStartSM
              $gap="32px"
              $textAlignLeft
              $gapSM="16px"
              $mobile
            >
              <Div $width="20%" $widthSM="100%">
                <P $m="0">
                  <b>DATE AND TIME:</b> <br /> {formattedDate}
                </P>
              </Div>
              <Div $width="60%" $widthSM="100%">
                <P $m="0">
                  <b>ACTION:</b> <br /> {log.info}{' '}
                </P>
              </Div>
              <Div $width="20%" $widthSM="100%">
                <P
                  $pointer
                  $m="0"
                  onClick={() => setUserDetailsModal(log.user)}
                  $hoverUnderline
                >
                  <b>USER:</b> <br /> {log.user.firstName} {log.user.lastName}
                </P>
              </Div>
            </Div>
          </div>
        );
      })}

      {users.length === 0 && (
        <Div
          $flex
          $center
          $column
          $mt="32"
          // $height="calc(70vh - 300px)"
          // $heightSM="calc(60vh - 300px)"
        >
          {/* <Img
            src={restaurantImage}
            alt="restaurant"
            $width="250px"
            $widthSM="150px"
          /> */}
          <H5>No Logs</H5>
        </Div>
      )}

      <div ref={ref}></div>

      <UsersAndAccessDetailsModal
        modalIsOpen={userDetailsModal}
        setModalIsOpen={setUserDetailsModal}
        noActions
        callGetUser
      />
    </>
  );
};
