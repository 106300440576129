import {
  AdminHeader,
  AdminSingleLocationPicker,
  AdminSpinner,
  AnimatedDiv,
  Div,
  ErrorToast,
  H3,
  H5,
  HeaderIconWrapper,
  Icon,
  Img,
  P,
  PlusIcon,
  TrashIconRed,
  api,
  calendarImage,
  formatDate,
  lightTheme,
  read,
  store,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { AddClosingDays } from './AddClosingDays';
import { ClosingDaysDelete } from './ClosingDaysDelete';
import { DateTime } from 'luxon';

export const ClosingDays = () => {
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [closingDays, setClosingDays] = useState([]);
  const [timezone, setTimezone] = useState('');
  const locationLSNode = 'vgrubs-admin-closing-days-location';
  const locationFromLS = JSON.parse(read(locationLSNode));
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(
    locationFromLS || null
  );

  async function getClosingDays(page, hasMore) {
    if (!hasMore) return;

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/closing-days`, {
        params: {
          limit: 20,
          page: page,
          locationId: selectedLocation.id,
        },
      });

      if (data.data.items[0]) {
        setPage((prevPage) => prevPage + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      setClosingDays((prevItems) => [...prevItems, ...data.data.items]);
      setTimezone(data.data.timeZone);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedLocation) {
      getClosingDays(1, true);
      setPage(1);
      setClosingDays([]);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (inView && closingDays.length > 0) {
      getClosingDays(page, hasMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleAddSubmit = (l) => {
    getClosingDays(1, true);
    setPage(1);
    setClosingDays([]);
  };

  const handleLocationsSubmit = (l) => {
    store(locationLSNode, JSON.stringify(l));
  };

  const formatDateToDayOfWeek = (dateString, targetTimezone) => {
    // Create a Date object from the input string
    const inputDate = new Date(dateString);

    // Convert the date to the target timezone
    const options = { timeZone: targetTimezone };
    inputDate.toLocaleString('en-US', options);

    // Get the day of the week
    const dayOfWeekNumber = inputDate.getDay();
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayOfWeekName = daysOfWeek[dayOfWeekNumber];

    return dayOfWeekName;
  };

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminHeader title={'Closing Days'}>
        <HeaderIconWrapper $opened={false} onClick={() => setAddModal(true)}>
          <PlusIcon />
        </HeaderIconWrapper>
      </AdminHeader>
      <AnimatedDiv>
        <Div $mb="32" $mt="32">
          <AdminSingleLocationPicker
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            handleSubmit={(l) => handleLocationsSubmit(l)}
          />
        </Div>

        {closingDays && (
          <>
            {' '}
            {closingDays.map((day, i) => {
              return (
                <AnimatedDiv
                  key={i}
                  $borderBottom
                  $borderTop
                  $flex
                  $alignCenter
                  $between
                  $p="12px 0"
                >
                  <Div>
                    <P $mb="4">{formatDateToDayOfWeek(day.date)}</P>
                    <H5 $mb="0">
                      {DateTime.fromISO(day.date)
                        .setZone(timezone)
                        .toFormat('MMM dd, yyyy')}
                    </H5>
                  </Div>
                  <Icon
                    $pointer
                    $background={lightTheme.white}
                    onClick={() => setDeleteModal(day)}
                  >
                    <TrashIconRed />
                  </Icon>
                </AnimatedDiv>
              );
            })}
          </>
        )}

        {closingDays.length < 1 && (
          <Div $flex $center $column $mt="100">
            <Img src={calendarImage} alt=" No closing days!" $width="200px" />
            <H3 $color={lightTheme.primary} $mb="20">
              No closing days!
            </H3>
            <P $bold $center>
              This location currently doesn't <br /> have closed days.
            </P>
          </Div>
        )}
      </AnimatedDiv>
      <div ref={ref}></div>
      <AddClosingDays
        modalIsOpen={addModal}
        setModalIsOpen={setAddModal}
        setLoading={setLoading}
        setData={setClosingDays}
        locationId={selectedLocation?.id}
        handleAddSubmit={handleAddSubmit}
        closingDays={closingDays}
        timezone={timezone}
      />
      <ClosingDaysDelete
        setLoading={setLoading}
        setModalIsOpen={setDeleteModal}
        modalIsOpen={deleteModal}
        setClosingDays={setClosingDays}
      />
    </>
  );
};
