import {
  AdminInput,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { ImageInput, ImageWithDownload } from './ImageInput';

export const BankEdit = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setData,
  data,
}) => {
  const [lastEin, setLastEin] = useState('');
  const [lastBank, setLastBank] = useState('');

  useEffect(() => {
    if (data) {
      setLastEin(data.configuration.einLast4);
      setLastBank(data.configuration.bankAccountLast4);
    }
  }, [modalIsOpen]);

  async function handleConfig() {
    var configuration = {
      einLast4: lastEin,
      bankAccountLast4: lastBank,
    };

    try {
      setLoading(true);

      const res = await api.put(
        `/v1/locations/${data.id}/configuration`,
        configuration
      );
      setData((prev) => {
        return {
          ...prev,
          configuration,
        };
      });
      SuccessToast(res.data.message);
      setModalIsOpen(false);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!lastEin) return ErrorToast('Please enter Last 4 EIN');
    if (!lastBank) return ErrorToast('Please enter Last 4 Bank');

    handleConfig();
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Edit Bank Details"
      footer={
        <>
          <ImageInput data={data} setData={setData} setLoading={setLoading}>
            <Button $selected $gray $w="100%" $mtSM="30">
              Upload New Voided Check
            </Button>
          </ImageInput>

          <Button
            $selected
            $w="100%"
            $mt="20"
            $mtSM="16"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </>
      }
    >
      <>
        <Div $flex $column $gap="24px" $mb="32">
          <AdminInput
            label="Last 4 EIN"
            placeholder="Enter here"
            value={lastEin}
            onChange={(e) => setLastEin(e.target.value)}
          />

          <AdminInput
            label="Last 4 Bank"
            placeholder="Enter here"
            value={lastBank}
            onChange={(e) => setLastBank(e.target.value)}
          />
        </Div>
        <Div $flex $column $gap="24px" $gapSM="20px" $width="100%">
          {data?.images.map((img, i) => {
            return <ImageWithDownload src={img.url} key={i} />;
          })}
        </Div>
      </>
    </Modal>
  );
};
