import React from 'react';
import { Delete, Edit } from '@vgrubs/components';
import { Div, H5, Icon, P, lightTheme } from '@vgrubs/components';
import {
  ItemDetails,
  ItemImage,
  ItemSeparator,
  ItemStyled,
} from './AdminGridItem.styled';

export const AdminGridItem = ({
  image,
  title,
  info,
  onClickEdit,
  onClickDelete,
  imageSize = 'cover',
}) => {
  return (
    <ItemStyled>
      <ItemImage
        onClick={onClickEdit}
        imageSize={imageSize}
        image={image}
      ></ItemImage>
      <ItemDetails>
        {title && (
          <H5 $m="0" onClick={onClickEdit}>
            {title}
          </H5>
        )}
        {info && (
          <P $mb="20" onClick={onClickEdit}>
            {info}
          </P>
        )}

        <ItemSeparator />
        <Div
          $flex
          $between
          $alignCenter
          $m="20px 0 0 0"
          $mSM="12px 0 0 0"
          $mLG="40px 0 0 0"
        >
          <P $m="0" $mLG="0" onClick={onClickEdit}>
            VIEW
          </P>
          <Div $flex $gap="24px">
            <Icon
              onClick={onClickEdit}
              color={lightTheme.lightGray}
              hoverColor={lightTheme.darkGray}
            >
              <Edit />
            </Icon>
            <Icon
              onClick={onClickDelete}
              color={lightTheme.lightGray}
              hoverColor={lightTheme.primary}
            >
              <Delete />
            </Icon>
          </Div>
        </Div>
      </ItemDetails>
    </ItemStyled>
  );
};
