import { Navigate, Outlet, useLocation } from 'react-router';
import { AdminAnimation } from '@vgrubs/components';
import { ADMIN_LOGIN, ADMIN_OTP } from '@vgrubs/components';
import { useAuth } from '../../store/auth';
import { AdminAuthLayout } from './AdminLayout/AdminAuthLayout';
import { AdminLayout } from './AdminLayout/AdminLayout';

function PrivateRoute({ children }) {
  const user = useAuth((state) => state.user);
  return user ? <>{children}</> : <Navigate to={ADMIN_LOGIN} />;
}

export const AdminLayoutRoot = ({ routes }) => {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname === ADMIN_LOGIN || pathname === ADMIN_OTP)
    return (
      <AdminAuthLayout>
        <AdminAnimation key="admin-layout-auth-outlet">
          <Outlet />
        </AdminAnimation>
      </AdminAuthLayout>
    );

  return (
    <PrivateRoute>
      <AdminLayout routes={routes}>
        <AdminAnimation key="admin-layout-outlet">
          <Outlet />
        </AdminAnimation>
      </AdminLayout>
    </PrivateRoute>
  );
};
