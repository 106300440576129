import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  AdminInput,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  Modal,
  StripeLogo,
  SuccessToast,
  VDriveLogo,
  api,
} from '@vgrubs/components';
import { useState } from 'react';
import { PaymentCreditCard } from './CreditCardComponent';

export const EditCard = ({
  setModalIsOpen,
  modalIsOpen,
  setLoading,
  setBackupCard,
}) => {
  const PUBLISHABLE = process.env.NX_STRIPE_PUBLISHABLE_KEY;
  const [stripePromise] = useState(() => loadStripe(PUBLISHABLE));

  return (
    <Elements stripe={stripePromise}>
      <Form
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        setLoading={setLoading}
        setBackupCard={setBackupCard}
      />
    </Elements>
  );
};

const Form = ({ setModalIsOpen, modalIsOpen, setLoading, setBackupCard }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [adminPassword, setAdminPassword] = useState('');

  const handleSubmitStripe = async () => {
    if (!adminPassword) return ErrorToast('Please Enter Password');
    setLoading(true);

    const cardNumberElement = elements?.getElement('cardNumber');

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (error) {
      ErrorToast(error.message || '');
      setLoading(false);
    }

    if (token) {
      await handleUpdateBackupCard(token.id);
    }
  };

  async function handleUpdateBackupCard(stripeToken) {
    const body = {
      stripeToken,
      adminPassword,
    };

    try {
      const { data } = await api.post(`/v1/vdrive/backup-card`, body);
      setBackupCard(data.data.last4);
      SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
      setModalIsOpen(false);
      setAdminPassword('');
    }
  }

  return (
    <>
      <Modal
        // header="Edit Card"
        vDriveLogo
        logo={false}
        setIsOpen={setModalIsOpen}
        isOpen={modalIsOpen}
      >
        <Div $width="100%">
          <H3 $center>
            <span>Change Card</span>
          </H3>
          <PaymentCreditCard />

          <AdminInput
            $textCenter
            placeholder="Enter Password"
            type="password"
            label="Admin Password"
            value={adminPassword}
            onChange={({ target }) => setAdminPassword(target.value)}
          />

          <Div $flex $center $gap="8px" $mt="24">
            <Disclaimer $m="0">Powered by:</Disclaimer>
            <StripeLogo />
          </Div>

          <Button
            $selected
            $w="100%"
            $wSM="100%"
            $mt="24"
            onClick={handleSubmitStripe}
          >
            Save
          </Button>
          <Button
            $selected
            $gray
            $w="100%"
            $wSM="100%"
            $mt="16"
            onClick={() => setModalIsOpen(false)}
          >
            Cancel
          </Button>
        </Div>
      </Modal>
    </>
  );
};
