import styled from 'styled-components';

export const InfoBox = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px 24px;
  margin-bottom: 12px;

  div:first-child {
    flex: none;
  }

  div:nth-child(2) {
    border-left: 1px solid #e3e3e3;
    padding-left: 24px;
  }

  @media (max-width: 800px) {
    padding: 16px;

    div:first-child {
      svg {
        width: 40px;
      }
    }
  }

  ${({ $absoluteCenter }) =>
    $absoluteCenter &&
    `
      text-align: center;
      width: 200px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 8px 12px;
      display: flex;
      justify-content: center;
      z-index: 1;
    `}
`;

export const ChartTooltip = styled(InfoBox)`
  padding: 8px 12px;
  border-radius: 12px;
`;

export const IconPl = () => {
  return (
    <svg
      width='54'
      height='54'
      viewBox='0 0 54 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='27' cy='27' r='27' fill='#F6AE00' />
      <g clipPath='url(#clip0_2622_3065)'>
        <path
          d='M28.2344 40.6579C30.9062 37.3581 37 29.3606 37 24.8684C37 19.4202 32.5208 15 27 15C21.4792 15 17 19.4202 17 24.8684C17 29.3606 23.0938 37.3581 25.7656 40.6579C26.4062 41.4443 27.5938 41.4443 28.2344 40.6579ZM27 21.5789C27.8841 21.5789 28.7319 21.9255 29.357 22.5424C29.9821 23.1593 30.3333 23.996 30.3333 24.8684C30.3333 25.7408 29.9821 26.5775 29.357 27.1944C28.7319 27.8113 27.8841 28.1579 27 28.1579C26.1159 28.1579 25.2681 27.8113 24.643 27.1944C24.0179 26.5775 23.6667 25.7408 23.6667 24.8684C23.6667 23.996 24.0179 23.1593 24.643 22.5424C25.2681 21.9255 26.1159 21.5789 27 21.5789Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2622_3065'>
          <rect
            width='20'
            height='26.3158'
            fill='white'
            transform='translate(17 15)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconVr = () => {
  return (
    <svg
      width='54'
      height='54'
      viewBox='0 0 54 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='27' cy='27' r='27' fill='#E61E29' />
      <g clipPath='url(#clip0_2622_3032)'>
        <path
          d='M36.3675 25.5906C36.1914 25.6121 36.0109 25.625 35.8213 25.625C34.6477 25.625 33.6004 25.1352 32.8827 24.3789C32.165 25.1352 31.1178 25.625 29.9397 25.625C28.7616 25.625 27.7143 25.1352 26.9966 24.3789C26.2789 25.1352 25.2362 25.625 24.0536 25.625C22.88 25.625 21.8327 25.1352 21.115 24.3789C20.3973 25.1352 19.3501 25.625 18.1702 25.625C17.9869 25.625 17.8023 25.6121 17.6231 25.5906C15.126 25.2727 13.9445 22.4711 15.2799 20.4602L17.8677 16.5642C18.0979 16.2139 18.5092 16 18.9484 16H35.0539C35.4918 16 35.9025 16.2139 36.1327 16.5642L38.7192 20.4602C40.0598 22.4754 38.8727 25.2727 36.3675 25.5906ZM36.557 26.9527C36.7105 26.9312 36.9317 26.8969 37.1123 26.8539V35.25C37.1123 36.7668 35.8168 38 34.2234 38H19.7789C18.1837 38 16.89 36.7668 16.89 35.25V26.8539C17.0647 26.8969 17.2448 26.9312 17.4303 26.9527H17.4358C17.6732 26.9828 17.9205 27 18.1702 27C18.7317 27 19.2734 26.9184 19.7789 26.768V32.5H34.2234V26.7723C34.7289 26.9184 35.2661 27 35.8213 27C36.0741 27 36.3178 26.9828 36.557 26.9527Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2622_3032'>
          <rect
            width='26'
            height='22'
            fill='white'
            transform='translate(14 16)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconInt = () => {
  return (
    <svg
      width='54'
      height='54'
      viewBox='0 0 54 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='27' cy='27' r='27' fill='#E61E29' />
      <g clipPath='url(#clip0_2553_3138)'>
        <path
          d='M27 26C25.4845 26 24.031 25.4205 22.9594 24.3891C21.8878 23.3576 21.2857 21.9587 21.2857 20.5C21.2857 19.0413 21.8878 17.6424 22.9594 16.6109C24.031 15.5795 25.4845 15 27 15C28.5155 15 29.969 15.5795 31.0406 16.6109C32.1122 17.6424 32.7143 19.0413 32.7143 20.5C32.7143 21.9587 32.1122 23.3576 31.0406 24.3891C29.969 25.4205 28.5155 26 27 26ZM26.3348 30.4344L25.5045 29.1023C25.2188 28.6426 25.5625 28.0625 26.1161 28.0625H27H27.8795C28.433 28.0625 28.7768 28.6469 28.4911 29.1023L27.6607 30.4344L29.1518 35.7582L30.7589 29.4461C30.8482 29.098 31.1964 28.8703 31.558 28.9605C34.6875 29.7168 37 32.441 37 35.6809C37 36.4113 36.3839 37 35.6295 37H29.7455C29.6518 37 29.567 36.9828 29.4866 36.9527L29.5 37H24.5L24.5134 36.9527C24.433 36.9828 24.3438 37 24.2545 37H18.3705C17.6161 37 17 36.407 17 35.6809C17 32.4367 19.317 29.7125 22.442 28.9605C22.8036 28.8746 23.1518 29.1023 23.2411 29.4461L24.8482 35.7582L26.3393 30.4344H26.3348Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2553_3138'>
          <rect
            width='20'
            height='22'
            fill='white'
            transform='translate(17 15)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconRec = () => {
  return (
    <svg
      width='54'
      height='54'
      viewBox='0 0 54 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='27.001' cy='27' r='27' fill='#F6AE00' />
      <path
        d='M37.6331 25.9374H38.0481C38.6975 25.9374 39.22 25.415 39.22 24.7655V18.5155C39.22 18.0419 38.9368 17.6122 38.4973 17.4316C38.0579 17.2509 37.5549 17.3486 37.218 17.6855L35.1868 19.7167C30.9094 15.4931 24.0198 15.5077 19.7668 19.7655C15.4944 24.038 15.4944 30.9618 19.7668 35.2343C24.0393 39.5068 30.9631 39.5068 35.2356 35.2343C35.8459 34.6239 35.8459 33.6327 35.2356 33.0224C34.6252 32.412 33.634 32.412 33.0237 33.0224C29.9719 36.0741 25.0256 36.0741 21.9739 33.0224C18.9221 29.9706 18.9221 25.0243 21.9739 21.9726C25.011 18.9355 29.9182 18.9208 32.9749 21.9238L30.968 23.9355C30.6311 24.2724 30.5334 24.7753 30.7141 25.2148C30.8948 25.6542 31.3245 25.9374 31.7981 25.9374H37.6331Z'
        fill='white'
      />
    </svg>
  );
};
