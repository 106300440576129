import {
  Disclaimer,
  Div,
  H3,
  H5,
  P,
  displayCentsInDollars,
  formatterDollarShort,
  lightTheme,
  renderMonthName,
} from '@vgrubs/components';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FormSeparator } from '../../../adminComponents';
import { ChartTooltip, IconPl, IconVr, InfoBox } from '../concierge.styled';

export const Section1 = ({ data }) => {
  const format = (value) => `${formatterDollarShort.format(value / 100)}`;

  const totalSales = data.reduce((result, item) => result + item.totalSales, 0);

  const physicalLocationTotalSales = data.reduce(
    (result, item) => result + item.physicalSales,
    0
  );

  const vrTotalSales = data.reduce(
    (result, item) => result + item.virtualSales,
    0
  );

  const vrTotalOrders = data.reduce(
    (result, item) => result + item.virtualOrders,
    0
  );

  return (
    <>
      <Div $m="20px 0">
        <H5 $m="0">Completed Orders</H5>
      </Div>
      <Div $flex $gap="50px" $gapSM="20px" $reverseMD>
        <Div $width="70%" $widthMD="100%" $relative>
          {totalSales === 0 && (
            <InfoBox $absoluteCenter>
              <P $m="0">No Data Available</P>
            </InfoBox>
          )}
          <ResponsiveContainer width="100%" aspect={9 / 4} maxHeight={330}>
            <BarChart data={data} fontSize={14}>
              <defs>
                <linearGradient id="colorPl" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={lightTheme.yellow}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={lightTheme.yellow}
                    stopOpacity={0.1}
                  />
                </linearGradient>
                <linearGradient id="colorVr" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={lightTheme.primary}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={lightTheme.primary}
                    stopOpacity={0.1}
                  />
                </linearGradient>
              </defs>

              <CartesianGrid strokeDasharray="3 3" />

              {totalSales !== 0 && (
                <>
                  <Tooltip
                    cursor={{ fill: lightTheme.gray }}
                    content={<CustomChartTooltip />}
                  />
                  <XAxis
                    dataKey="monthYear"
                    // tickFormatter={(thic) => renderMonthName(thic)}
                  />
                  <YAxis tickFormatter={format} />
                </>
              )}

              <Bar stackId="a" dataKey="totalSales" fill="url(#colorPl)" />
              <Bar
                stackId="a"
                dataKey="virtualSales"
                fill={lightTheme.primary}
              />
            </BarChart>
          </ResponsiveContainer>
        </Div>
        <Div $width="30%" $widthMD="100%">
          <InfoBox>
            <Div>
              <IconVr />
            </Div>
            <Div>
              <P $m="0">VR Total Sales:</P>
              <H3 $m="0" $mb="12">
                {displayCentsInDollars(vrTotalSales)}
              </H3>
              <P $m="0">VR Total Orders:</P>
              <H3 $m="0">{vrTotalOrders}</H3>
            </Div>
          </InfoBox>
          <InfoBox>
            <Div>
              <IconPl />
            </Div>
            <Div>
              <P $m="0">Physical Location Total Sales:</P>
              <H3 $m="0">
                {displayCentsInDollars(physicalLocationTotalSales)}
              </H3>
            </Div>
          </InfoBox>
        </Div>
      </Div>
      <FormSeparator m="20px 0" />
    </>
  );
};

const CustomChartTooltip = (props) => {
  // console.log(props.payload);
  if (props.active && props.payload && props.payload.length) {
    return (
      <ChartTooltip>
        <div>
          <P $m="0">{props.label}</P>
          <Disclaimer
            $color={lightTheme.yellow}
            $uppercase
            $m="0"
            $bold
          >{`${'Total sales'} : ${displayCentsInDollars(
            props.payload[0].value
          )}`}</Disclaimer>
          <Disclaimer
            $color={lightTheme.primary}
            $uppercase
            $m="0"
            $bold
          >{`${'Virtual sales'} : ${displayCentsInDollars(
            props.payload[1].value
          )}`}</Disclaimer>
        </div>
      </ChartTooltip>
    );
  }

  return null;
};
