import { AdminHeader, AnimatedDiv, useUIState } from '@vgrubs/components';
import { Tab } from '../Verifications/VerificationsStyled';
import { BrandSettings } from './sections/BrandSettings';
import { GeneralSettings } from './sections/GeneralSettings';
import { LocationSettings } from './sections/LocationSettings';

export const Settings = () => {
  const { settingsTab, setSettingsTab } = useUIState((state) => state);

  return (
    <>
      <AdminHeader title={'Settings'}></AdminHeader>

      <AnimatedDiv $flex $gap="24px" $p="32px 0">
        <Tab selected={settingsTab === 1} onClick={() => setSettingsTab(1)}>
          General
        </Tab>
        <Tab selected={settingsTab === 2} onClick={() => setSettingsTab(2)}>
          Location
        </Tab>
        <Tab selected={settingsTab === 3} onClick={() => setSettingsTab(3)}>
          Brand
        </Tab>
      </AnimatedDiv>

      {settingsTab === 1 && <GeneralSettings />}
      {settingsTab === 2 && <LocationSettings />}
      {settingsTab === 3 && <BrandSettings />}
    </>
  );
};
