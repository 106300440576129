import { motion } from 'framer-motion';
import styled from 'styled-components';

export const LocationsPicker = styled(motion.div)`
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0px 16px 0px 24px;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.darkGray};
  background-color: ${({ theme }) => theme.white};
  border: 1px solid #e3e3e3;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  width: 100%;
  height: 45px;

  /* top: 0; */

  ${({ $opened }) => $opened && `z-index: 10;`}
  ${({ $opened }) => $opened && `position: absolute;`}

  @media (max-width: 800px) {
    width: 100%;
    flex: none;
  }

  > * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const ScrollableWrapper = styled(motion.div)`
  overflow-y: auto;
  height: calc(70vh - 300px);
  padding: 0 20px 30px 20px;

  @media (max-width: 800px) {
    height: calc(70vh - 300px);
  }
`;
