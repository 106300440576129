import {
  AdminInput,
  AdminLocationsPicker,
  AdminPhoneInput,
  AdminSelect,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useState } from 'react';

const roles = [
  { name: 'Admin', value: 'admin' },
  { name: 'Support', value: 'support' },
  { name: 'Partner', value: 'partner' },
  { name: 'Manager', value: 'manager' },
  { name: 'Staff', value: 'staff' },
];

const typeOptions = (
  <optgroup label="Select Role">
    {roles.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

export const UsersAndAccessAddModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  // setUsers,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState(roles[0].value);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const hasLocations = role !== 'admin' && role !== 'support';

  async function handleVerifyLocation() {
    if (!firstName && !lastName && !email && !phoneNumber && !role) {
      ErrorToast('Please fill in all information');
      return;
    }

    const locationIds = selectedLocations.map(({ id }) => id);

    const body = {
      firstName,
      lastName,
      phoneNumber,
      role,
      email,
      locationIds: hasLocations ? locationIds : null,
    };

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/users`, body);
      SuccessToast(data.message);
      // setUsers((prev) => [{ user: body }, ...prev]);
      setModalIsOpen(false);

      setSelectedLocations([]);
      setRole(roles[0].value);
      setPhoneNumber('');
      setEmail('');
      setLastName('');
      setFirstName('');
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Add User"
      scrollable
    >
      <Div $flex $column $gap="20px" $gapSM="16px">
        <AdminInput
          label="First Name"
          placeholder="Enter here"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <AdminInput
          label="Last Name"
          placeholder="Enter here"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <AdminInput
          label="Email"
          placeholder="Enter here"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <AdminPhoneInput
          value={phoneNumber}
          onChange={setPhoneNumber}
          label="Phone"
        />

        <AdminSelect
          value={role}
          options={typeOptions}
          onChange={({ target }) => setRole(target.value)}
          placeholder="Select location"
          label="Role"
          chevron
        />

        {hasLocations && (
          <AdminLocationsPicker
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            handleSubmit={() => null}
            toTop
          />
        )}
      </Div>

      <Button
        $selected
        $w="100%"
        $mt="50"
        $mtSM="30"
        onClick={handleVerifyLocation}
      >
        Add
      </Button>
    </Modal>
  );
};
