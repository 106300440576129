import {
  ADMIN_EDIT_MANUAL_ORDER,
  AdminCustomProviderSelect,
  AdminDatePickerInput,
  AdminDollarInput2,
  AdminHeader,
  AdminInput,
  AdminSelect,
  AdminSingleBrandPicker,
  AdminSingleLocationPicker,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H3,
  P,
  Separator,
  SuccessToast,
  api,
  read,
  store,
  useOrders,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { useLocation, useNavigate } from 'react-router';
import { recoveryStatus } from './RecoveryModal';

const orderTypeOptions = [
  { name: 'Delivery', value: 'delivery' },
  { name: 'Pickup', value: 'pickup' },
  // { name: 'Dine-in', value: 'dinein' },
];

const renderTypeOptions = (
  <optgroup label="Select Order Type">
    {orderTypeOptions.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

const statuses = [
  { name: 'Adjustment', value: recoveryStatus.ADJUSTMENT },
  { name: 'Evidence Needed', value: recoveryStatus.EVIDENCE_NEEDED },
  { name: 'Not Eligible', value: recoveryStatus.NOT_ELIGIBLE },
  { name: 'Pending', value: recoveryStatus.PENDING },
  { name: 'Recovered', value: recoveryStatus.RECOVERED },
];

const typeOptions = (
  <optgroup label="Recovery Status">
    {statuses.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

export const AddManualOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationLSNode = 'vgrubs-admin-orders-location';
  const locationFromLS = JSON.parse(read(locationLSNode));

  // Get today's date
  const today = DateTime.now();

  // Subtract one day from today's date
  const yesterday = today.minus({ days: 1 }).toJSDate();
  const [provider, setProvider] = useState('');
  const [selectedRecoveryStatus, setSelectedRecoveryStatus] = useState(
    recoveryStatus.PENDING
  );
  const [selectedLocation, setSelectedLocation] = useState(
    locationFromLS || null
  );
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState(orderTypeOptions[0].value);
  const [orderNumber, setOrderNumber] = useState('');
  const [customerFullName, setCustomerFullName] = useState('');
  const [total, setTotal] = useState(0);
  const [recoveryAmount, setRecoveryAmount] = useState(0);
  const [date, setDate] = useState([yesterday]);
  const [dateToSend, setDateToSend] = useState(
    DateTime.fromJSDate(yesterday)
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .setZone(selectedLocation?.timeZone)
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .toUTC()
      .toISO()
  );

  const handleLocationsSubmit = (l) => {
    setSelectedBrand(null);
    store(locationLSNode, JSON.stringify(l));
  };

  const { orderToEdit } = useOrders((state) => state);

  const isEdit = location.pathname === ADMIN_EDIT_MANUAL_ORDER;

  useEffect(() => {
    if (orderToEdit && isEdit) {
      console.log(orderToEdit);
      console.log(location.pathname);

      setOrderType(orderToEdit.type);
      setOrderNumber(orderToEdit.number);
      setProvider(orderToEdit.provider);
      setCustomerFullName(orderToEdit.customer.name);
      setTotal(orderToEdit.charges.total);
      setRecoveryAmount(orderToEdit.recovery.amount);
      setSelectedRecoveryStatus(orderToEdit.recovery.status);
      if (orderToEdit.brand) {
        setSelectedBrand(orderToEdit.brand);
      }
      setDate([DateTime.fromISO(orderToEdit.date).toLocal().toJSDate()]);
      setDateToSend(orderToEdit.date);
    }
  }, [orderToEdit]);

  const pageGoBack = () => navigate(-1);

  async function handleAddManualOrder() {
    const body = {
      type: orderType,
      orderNumber,
      provider,
      brandId: selectedBrand?.id || null,
      customerName: customerFullName,
      total,
      recoveryAmount,
      recoveryStatus: selectedRecoveryStatus,
      createdAt: dateToSend,
      locationId: selectedLocation?.id,
    };

    if (
      !orderType ||
      !orderNumber ||
      !provider ||
      !customerFullName ||
      !total ||
      !recoveryStatus ||
      !date ||
      !selectedLocation
    ) {
      ErrorToast('Please fill in all information');
      return;
    }

    try {
      setLoading(true);

      if (isEdit) {
        delete body.brandId;
        delete body.locationId;

        const { data } = await api.put(
          `/v1/orders/${orderToEdit.id}/manual`,
          body
        );
        SuccessToast(data.message);
      } else {
        const { data } = await api.post(`/v1/orders/manual`, body);
        SuccessToast(data.message);
      }

      pageGoBack();
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleValueRecoveryChange = useCallback((val) => {
    setRecoveryAmount(val);
  }, []);

  const handleValueTotalChange = useCallback((val) => {
    setTotal(val);
  }, []);

  const BrandComponent = () => {
    return (
      <div>
        <P $mb="8" $ml="24" $mr="24">
          <b>{'Select Brand'}</b>
        </P>
        <AdminSingleBrandPicker
          selectedLocation={selectedLocation}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
        />
      </div>
    );
  };

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminHeader title={'Create Order'}></AdminHeader>
      <H3 $m="40px 0" $mSM="32px 0">
        Order Details
      </H3>
      <Div $flex $gap="40px" $gapSM="20px" $columnSM>
        <Div $width="50%" $widthSM="100%" $flex $column $gap="20px">
          {!isEdit && (
            <div>
              <P $mb="8" $ml="24" $mr="24">
                <b>{'Select Location'}</b>
              </P>
              <AdminSingleLocationPicker
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                handleSubmit={(l) => handleLocationsSubmit(l)}
              />
            </div>
          )}
          {!isEdit && (
            <Div $mobile>
              <BrandComponent />
            </Div>
          )}
          <AdminCustomProviderSelect
            value={provider}
            onChange={setProvider}
            label="Provider"
          />
          <AdminSelect
            value={orderType}
            options={renderTypeOptions}
            onChange={({ target }) => setOrderType(target.value)}
            shadowDesign
            label="Order Type"
          />
          <div>
            <P $mb="8" $ml="24" $mr="24">
              <b>Order Date</b>
            </P>
            <AdminDatePickerInput
              onChange={setDate}
              value={date}
              valueInput={dateToSend}
              click={() => {
                setDateToSend(
                  DateTime.fromJSDate(date[0])
                    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
                    .setZone(selectedLocation?.timeZone)
                    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
                    .toUTC()
                    .toISO()
                );
              }}
            />
          </div>
        </Div>
        <Div $width="50%" $widthSM="100%" $flex $column $gap="20px">
          {!isEdit && (
            <Div $desktop>
              <BrandComponent />
            </Div>
          )}
          <AdminInput
            value={orderNumber}
            onChange={({ target }) => setOrderNumber(target.value)}
            label="Order Number"
            placeholder="Enter here"
          />

          <AdminInput
            value={customerFullName}
            onChange={({ target }) => setCustomerFullName(target.value)}
            label="Customer Full Name"
            placeholder="Enter here"
          />

          <AdminDollarInput2
            label="Total"
            onValueChange={handleValueTotalChange}
            value={total}
          />
        </Div>
      </Div>
      <Separator $m="32px 0" $mSM="24px 0" />
      <Div $flex $gap="40px" $columnSM>
        <Div $width="50%" $widthSM="100%" $desktop></Div>
        <Div $width="50%" $widthSM="100%" $flex $gap="20px" $columnSM>
          <AdminSelect
            value={selectedRecoveryStatus}
            options={typeOptions}
            onChange={({ target }) => setSelectedRecoveryStatus(target.value)}
            placeholder="Select location"
            label="Recovery Status"
            chevron
            shadowDesign
          />
          <AdminDollarInput2
            label="Recovery Amount"
            onValueChange={handleValueRecoveryChange}
            value={recoveryAmount}
          />
        </Div>
      </Div>
      <Div $flex $gap="40px" $mt="50" $columnSM>
        <Div $width="50%" $widthSM="100%" $desktop></Div>
        <Div $width="50%" $widthSM="100%" $flex $gap="20px" $columnSM>
          <Button $selected $gray $w="100%" onClick={pageGoBack}>
            Cancel
          </Button>
          <Button $selected $w="100%" onClick={handleAddManualOrder}>
            Save
          </Button>
        </Div>
      </Div>
    </>
  );
};
