import { Logo, plate } from '@vgrubs/components';
import { BrandImage } from './LocationStyled';

export const BrandImageComponent = ({ image }) => {
  return (
    <BrandImage>
      <img src={plate} alt="plate" />
      <div>
        {image ? (
          <img src={image} alt="brand" />
        ) : (
          <Logo height="100" width="100" />
        )}
      </div>
    </BrandImage>
  );
};
