import {
  Chevron,
  Disclaimer,
  Div,
  Icon,
  P,
  Radio,
  Separator,
  TrashIconRed,
  convert24HourTo12Hour,
  renderDayOfWeek,
} from '@vgrubs/components';
import { useRef, useState } from 'react';
import { Collapsible } from '../../MenyStyled';

export const AvailabilityComponent = ({
  item,
  availability,
  setAvailability,
  edit,
}) => {
  const ref = useRef(null);
  const elementHeight = ref?.current?.clientHeight;
  const [expanded, setExpanded] = useState(false);

  function isSelected(item) {
    return availability?.some((a) => a.id === item.id);
  }

  const handleSelect = (newItem) => {
    const index = availability?.findIndex((item) => item === newItem);
    if (index === -1) {
      // Item doesn't exist, so add it
      setAvailability((prevIds) => [...prevIds, newItem]);
    } else {
      // Item exists, so remove it
      setAvailability((prevIds) => prevIds.filter((item) => item !== newItem));
    }
  };

  return (
    <>
      <Div $box $p="16px 24px" $flex $alignCenter $between $mb="8">
        <Div
          $pointer
          $flex
          $alignCenter
          $gap="8px"
          onClick={() => handleSelect(item)}
        >
          {edit && <Radio checked={isSelected(item)} />}
          <P $bold $m="0">
            {item.name}
          </P>
        </Div>

        <Div $flex $alignCenter $gap="16px">
          {!edit && (
            <Icon $pointer onClick={() => handleSelect(item)}>
              <TrashIconRed />
            </Icon>
          )}

          <Icon
            $rotate={expanded ? '360deg' : '180deg'}
            onClick={() => setExpanded((prev) => !prev)}
          >
            <Chevron />
          </Icon>
        </Div>
      </Div>
      <Collapsible expanded={expanded} elementHeight={elementHeight}>
        <div ref={ref}>
          {item?.hours?.map((item, i) => {
            return <ModifierComponent item={item} key={i} />;
          })}
          {item?.hours?.length === 0 ? (
            <P $m="0" $center>
              No modifiers
            </P>
          ) : null}
        </div>
      </Collapsible>
    </>
  );
};

const ModifierComponent = ({ item }) => {
  return (
    <>
      <Separator />
      <Div $p="16px 20px">
        <div>
          <Disclaimer $mb="4">{renderDayOfWeek(item.day)}</Disclaimer>
          <P $m="0">
            {convert24HourTo12Hour(item.startTime)} -{' '}
            {convert24HourTo12Hour(item.endTime)}
          </P>
        </div>
      </Div>
    </>
  );
};
