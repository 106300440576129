import styled from 'styled-components';
import { ImagePlaceholderIcon, UploadIcon } from '@vgrubs/components';

const UploadedImageStyled = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-flow: column wrap;
  background-color: ${({ theme }) => theme.gray};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.gray};
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.ease300ms};

  img {
    height: 200px;
    max-width: 100%;
    object-fit: contain;
    transition: ${({ theme }) => theme.transitions.ease300ms};
  }

  :hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.primary};
    > div {
      visibility: visible;
      opacity: 1;
      scale: 1;
    }

    > span > div {
      visibility: hidden;
      opacity: 0;
      scale: 0;
    }

    img {
      filter: opacity(25%);
    }
  }

  /* SVG */

  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    transition: ${({ theme }) => theme.transitions.ease300ms};
    transform-origin: 0 0;
    scale: 0;
  }

  > span > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    transition: ${({ theme }) => theme.transitions.ease300ms};
  }

  /* SVG */
`;

export const UploadedImage = ({ onClick, image }) => {
  const imageUrl =
    typeof image === 'string' ? image : URL.createObjectURL(image);

  return (
    <UploadedImageStyled onClick={onClick}>
      {image && <img src={imageUrl} alt="" />}

      <div>
        <UploadIcon />
      </div>

      {!image && (
        <span>
          <div>
            <ImagePlaceholderIcon />
          </div>
        </span>
      )}
    </UploadedImageStyled>
  );
};
