import React from 'react';
import { OrderRecoveryStatusBadge } from './OrdersStyled';
import { recoveryStatus } from './RecoveryModal';
import {
  Disclaimer,
  Div,
  P,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';

const recoveryStatusColor = (status) => {
  switch (status) {
    case recoveryStatus.ADJUSTMENT:
      return '#FC8800';
    case recoveryStatus.EVIDENCE_NEEDED:
      return '#0056D8';
    case recoveryStatus.NOT_ELIGIBLE:
      return lightTheme.primary;
    case recoveryStatus.PENDING:
      return '#C28831';
    case recoveryStatus.RECOVERED:
      return lightTheme.green;

    default:
      break;
  }
};

const recoveryStatusName = (status) => {
  switch (status) {
    case recoveryStatus.ADJUSTMENT:
      return 'Adjustment';
    case recoveryStatus.EVIDENCE_NEEDED:
      return 'Evidence Needed';
    case recoveryStatus.NOT_ELIGIBLE:
      return 'Not Eligible';
    case recoveryStatus.PENDING:
      return 'Pending';
    case recoveryStatus.RECOVERED:
      return 'Recovered';

    default:
      break;
  }
};

export const RecoveryStatusBadge = ({ recovery }) => {
  if (recovery === null) return null;

  return (
    <OrderRecoveryStatusBadge $color={recoveryStatusColor(recovery.status)}>
      <Div $mr="5" $flex $center>
        <svg
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0022 6.60856H13.1456C12.4478 5.03715 10.8361 3.93555 8.95853 3.93555C6.43297 3.93555 4.40588 5.92816 4.40588 8.37438C4.40588 10.8368 6.44959 12.8132 8.95853 12.8132C10.8361 12.8132 12.4312 11.7116 13.1456 10.1402H11.0022C10.5038 10.691 9.77269 11.0312 8.95853 11.0312C7.44652 11.0312 6.21697 9.83239 6.21697 8.35818C6.21697 6.88397 7.44652 5.68516 8.95853 5.68516C9.77269 5.70136 10.5038 6.05776 11.0022 6.60856Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5341 9.26398H13.4116H15.2559C15.2061 9.57178 15.1396 9.86338 15.0566 10.155C14.2756 12.7308 11.8332 14.5938 8.94206 14.5938C5.41958 14.5938 2.56171 11.8074 2.56171 8.37297C2.56171 4.93855 5.41958 2.15213 8.94206 2.15213C11.8332 2.15213 14.2756 4.03134 15.0566 6.59096H16.9341C16.1033 3.02694 12.8467 0.370117 8.94206 0.370117C4.40603 0.370117 0.734009 3.95034 0.734009 8.37297C0.734009 12.7956 4.40603 16.3758 8.94206 16.3758C12.8467 16.3758 16.12 13.719 16.9341 10.155C17.0006 9.86338 17.0504 9.57178 17.0837 9.26398C17.1169 8.97238 17.1335 8.68077 17.1335 8.37297C17.1335 8.06517 17.1169 7.77357 17.0837 7.48197H15.2559H13.4116H11.5341H8.95868V9.26398H11.5341Z"
            fill="white"
          />
        </svg>
      </Div>
      <Div $desktop>
        <P $m="0" $color={lightTheme.white} $uppercase>
          {formatter.format(centsToDollars(recovery.amount))}{' '}
          {recoveryStatusName(recovery.status)}
        </P>
      </Div>
      <Div $mobile>
        <Disclaimer $m="0" $color={lightTheme.white} $uppercase>
          {formatter.format(centsToDollars(recovery.amount))}{' '}
          {recoveryStatusName(recovery.status)}
        </Disclaimer>
      </Div>
    </OrderRecoveryStatusBadge>
  );
};
