import {
  Div,
  EditPenIcon,
  H5,
  Icon,
  P,
  TrashIconRed,
  lightTheme,
} from '@vgrubs/components';

export const CategoryComponent = ({ item, onClick, onClickDelete }) => {
  return (
    <Div $box $p="12px 24px " $flex $alignCenter $between $mb="8">
      <Div $width="100%" onClick={() => onClick(item)} $pointer>
        <H5 $m="0" $color={lightTheme.primary}>
          {item.name}
        </H5>
        {item.description && (
          <P $color={lightTheme.lightGray} $mb="0">
            {item.description}
          </P>
        )}
        <P $mb="0">
          {item.children.length} {item.children.length === 1 ? 'item' : 'items'}
        </P>
      </Div>
      <Div $flex $alignCenter $between $gap="24px" $gapSM="12px">
        <Icon $shadow $pointer onClick={() => onClick(item)}>
          <EditPenIcon />
        </Icon>
        <Icon $pointer onClick={() => onClickDelete(item.id)}>
          <TrashIconRed />
        </Icon>
        {/* <Icon>{item.paused ? <ResumeLogo /> : <PauseLogo />}</Icon> */}
      </Div>
    </Div>
  );
};
