import {
  Chevron,
  Disclaimer,
  Div,
  H5,
  Icon,
  P,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import { useEffect, useRef, useState } from 'react';
import {
  Collapsible,
  showAmountColor,
  showPlusMinus,
  showStatusColor,
  showTransactionIcon,
  showTransactionTitle,
} from './TransactonsStyled';
import { DateTime } from 'luxon';
import { useSearchParams } from 'react-router-dom';

export const TransactionDateComponent = ({
  item,
  setIncomeModal,
  setWithdrawalModal,
  setDepositModal,
  setTransactionsModal,
  index,
}) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
    setHeight(ref?.current?.clientHeight);
  };

  useEffect(() => {
    if (index === 0) {
      setExpanded(true);
      setHeight(ref?.current?.clientHeight);
    }
  }, [item, index]);

  return (
    <>
      <Div
        $box
        $p="16px 24px"
        $flex
        $alignCenter
        $between
        $mb="8"
        $background={expanded && lightTheme.primary}
        $pointer
        onClick={handleExpand}
      >
        <Div $flex $alignCenter $gap="8px">
          <P $bold $m="0" $color={expanded && lightTheme.white}>
            {DateTime.fromISO(item.date).toFormat('MMM dd, yyyy')}
          </P>
        </Div>
        <Icon
          $pointer
          $rotate={expanded ? '360deg' : '180deg'}
          color={expanded ? lightTheme.white : lightTheme.darkGray}
        >
          <Chevron />
        </Icon>
      </Div>

      <Collapsible expanded={expanded} elementHeight={height}>
        <div ref={ref}>
          {item?.items?.map((item, i) => {
            return (
              <TransactionComponent
                key={i}
                item={item}
                setIncomeModal={setIncomeModal}
                setWithdrawalModal={setWithdrawalModal}
                setDepositModal={setDepositModal}
                setTransactionsModal={setTransactionsModal}
              />
            );
          })}
        </div>
      </Collapsible>
    </>
  );
};

export const TransactionComponent = ({
  item,
  setIncomeModal,
  setWithdrawalModal,
  setDepositModal,
  setTransactionsModal,
}) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const handleOpenModal = () => {
    if (type === 'Incomes') return setIncomeModal(item);
    if (type === 'Withdrawals') return setWithdrawalModal(item);
    if (type === 'Deposits') return setDepositModal(item);
    if (type === 'Transactions') return setTransactionsModal(item);
  };

  return (
    <Div
      $box
      $p="12px 24px "
      $flex
      $alignCenter
      $between
      $mb="8"
      $pointer
      onClick={handleOpenModal}
    >
      <Div $flex $alignCenter $gap="32px" $gapSM="24px">
        {showTransactionIcon(type)}
        <Div $width="100%" $pointer>
          <P $m="0">{showTransactionTitle(type)}</P>
          <Disclaimer $color={lightTheme.lightGray} $mb="0">
            {DateTime.fromISO(item.createdAt).toFormat('hh:mm a')}
          </Disclaimer>
        </Div>
      </Div>
      <Div $gap="24px" $gapSM="12px">
        <H5 $right $mb="0" $color={showAmountColor(type)}>
          {showPlusMinus(type)}
          {formatter.format(centsToDollars(item.amount))}
        </H5>
        {type === 'Withdrawals' && (
          <P $right $m="0" $capitalize $color={showStatusColor(item?.status)}>
            {item.status}
          </P>
        )}
      </Div>
    </Div>
  );
};
