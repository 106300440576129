import {
  AdminInput,
  AdminSelect,
  Button,
  ConnectGrubconModal,
  Div,
  ErrorToast,
  GrubconConnect,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { BrandImageInput } from './BrandImageInput';

const brandProvidersOptions = [
  { name: 'Restaurant Brands', value: 'RB' },
  { name: 'TODC', value: 'TDOC' },
  { name: 'Future Foods', value: 'FF' },
];

const typeOptions = (
  <optgroup>
    {brandProvidersOptions.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

export const BrandAddModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  onAddBrandSuccess,
  setData,
  data,
  type = 'add',
}) => {
  const [brandName, setBrandName] = useState('');

  const [grubconConnectModal, setGrubconConnectModal] = useState(false);
  const [sip, setSip] = useState(null);
  const [crmApiKey, setCrmApiKey] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [brandProvider, setBrandProvider] = useState(
    brandProvidersOptions[0].value
  );
  const [image, setImage] = useState('');

  const isGrubconConnected = crmApiKey !== null;

  const isVGrubsBrand = data?.type === 'vGrubs';

  const isEdit = type === 'edit';
  const isAdd = type === 'add';

  useEffect(() => {
    if (data && isEdit) {
      setBrandName(data.name);
      setSip(data.sip);
      setCrmApiKey(data.crmApiKey);

      if (isVGrubsBrand) {
        setToggle(true);
      } else {
        setToggle(false);
        setBrandProvider(data?.type);
      }

      setImage(data.imageUrl);
    }
  }, [modalIsOpen]);

  async function handleAddEdit() {
    var brand = {
      name: brandName,
      sip,
      crmApiKey,
      locationId: data.id,
      type: toggle ? 'vGrubs' : brandProvider,
    };

    if (!sip?.server || !sip?.user || !sip?.password || !sip?.phoneNumber) {
      delete brand.sip;
    }

    if (isEdit) delete brand.locationId;

    let formData = new FormData();
    if (typeof image !== 'string' && image !== null) {
      formData.append('image', image);
    }

    if (isVGrubsBrand && type !== 'add') {
      delete brand.name;
      delete brand.type;
      formData.delete('image');
    }

    formData.append('brand', JSON.stringify(brand));

    try {
      setLoading(true);

      if (isAdd) {
        const { data } = await api.post(`/v1/brands`, formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
        setData((prev) => {
          return { ...prev, brands: [data.data, ...prev.brands] };
        });
        onAddBrandSuccess(data.data);
        setBrandName('');
        setSip(null);
        setCrmApiKey(null);
        setBrandProvider(brandProvidersOptions[0].value);
        setImage('');
      } else {
        const res = await api.put(`/v1/brands/${data.id}`, formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
        setData(res.data.data);
        SuccessToast(res.data.message);
        setModalIsOpen(false);
      }

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!brandName) return ErrorToast('Please enter Brand Name');

    handleAddEdit();
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        header={`${isEdit ? 'Edit' : 'Add'} Brand`}
      >
        <form onSubmit={handleSubmit}>
          <Div $flex $justifyCenter>
            <BrandImageInput
              image={image}
              onChange={(img) => setImage(img)}
              disabled={isVGrubsBrand}
            />
          </Div>

          <Div $mb="24">
            <AdminInput
              label="Brand Name"
              placeholder="Enter here"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              disabled={isVGrubsBrand}
            />
          </Div>

          {!toggle && (
            <AdminSelect
              value={brandProvider}
              options={typeOptions}
              onChange={({ target }) => setBrandProvider(target.value)}
              label="Choose Brand Provider"
            />
          )}

          <GrubconConnect
            isGrubconConnected={isGrubconConnected}
            setGrubconConnectModal={setGrubconConnectModal}
            setSip={setSip}
            setCrmApiKey={setCrmApiKey}
          />

          <Button $selected $w="100%" $mt="50" $mtSM="30" type="submit">
            Submit
          </Button>
        </form>
      </Modal>

      <ConnectGrubconModal
        modalIsOpen={grubconConnectModal}
        setModalIsOpen={setGrubconConnectModal}
        setSip={setSip}
        setCrmApiKey={setCrmApiKey}
        sipForEdit={sip}
        crmApiKeyForEdit={crmApiKey}
        type={isGrubconConnected ? 'edit' : 'add'}
      />
    </>
  );
};
