import {
  chowNowLogo,
  deliveryLogo,
  doordashLogo,
  eatstreetLogo,
  gloriaLogo,
  grubhubLogo,
  lightTheme,
  localForYouLogo,
  menufyLogo,
  uberLogo,
} from '@vgrubs/components';

export const statusColor = (s) => {
  switch (s) {
    case providerStatus.ONLINE:
      return lightTheme.green;
    case providerStatus.OFFLINE:
      return lightTheme.yellow;
    case providerStatus.WAITING:
      return lightTheme.lightGray;
    case providerStatus.UNKNOWN:
      return lightTheme.lightGray;
  }
};

export const providerStatus = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  WAITING: 'waiting',
  UNKNOWN: 'unknown',
};

export const provider = {
  UBEREATS: 'ubereats',
  GRUBHUB: 'grubhub',
  DOORDASH: 'doordash',
  CHOWNOW: 'chownow',
  GLORIAFOOD: 'gloriafood',
  DELIVERY: 'delivery',
  EATSTREET: 'eatstreet',
  MENUFY: 'menufy',
  GRUBHUBPOS: 'grubhubPos',
  DOORDASHPOS: 'doordashPos',
  LOCAL_FOR_YOU: 'localForYou',
};

export const providerPhone = (p) => {
  switch (p) {
    case provider.UBEREATS:
      return '+1 833 275 3287';
    case provider.DOORDASH:
      return '+1 855 973 1040';
    case provider.GRUBHUB:
      return '+1 877 799 0790';
    case provider.CHOWNOW:
      return '+1 888 707 2469';
    case provider.GLORIAFOOD:
      return '+40 737 017 876';
    case provider.DELIVERY:
      return '+1 800 709 7191';
    case provider.EATSTREET:
      return '+1 866 654 8777';
    case provider.MENUFY:
      return '+1 913 738 9399';
    case provider.GRUBHUBPOS:
      return '+1 877 799 0790';
    case provider.DOORDASHPOS:
      return '+1 855 973 1040';
    case provider.LOCAL_FOR_YOU:
      return '+61 1800 394 664';
  }
};

export const providerName = (p) => {
  switch (p) {
    case provider.UBEREATS:
      return 'Uber Eats';
    case provider.DOORDASH:
      return 'DoorDash';

    case provider.GRUBHUB:
      return 'Grubhub';
    case provider.CHOWNOW:
      return 'ChowNow';
    case provider.GLORIAFOOD:
      return 'GloriaFood';
    case provider.DELIVERY:
      return 'Delivery.com';
    case provider.EATSTREET:
      return 'EatStreet';
    case provider.MENUFY:
      return 'Menufy';
    case provider.GRUBHUBPOS:
      return 'GrubhubPos';
    case provider.DOORDASHPOS:
      return 'DoorDashPos';
    case provider.LOCAL_FOR_YOU:
      return 'Local For You';
  }
};

export const providerLogo = (p) => {
  switch (p) {
    case provider.UBEREATS:
      return uberLogo;
    case provider.DOORDASH:
      return doordashLogo;
    case provider.GRUBHUB:
      return grubhubLogo;
    case provider.CHOWNOW:
      return chowNowLogo;
    case provider.GLORIAFOOD:
      return gloriaLogo;
    case provider.DELIVERY:
      return deliveryLogo;
    case provider.EATSTREET:
      return eatstreetLogo;
    case provider.MENUFY:
      return menufyLogo;
    case provider.GRUBHUBPOS:
      return grubhubLogo;
    case provider.DOORDASHPOS:
      return doordashLogo;
    case provider.LOCAL_FOR_YOU:
      return localForYouLogo;
  }
};

export const statusName = (s) => {
  switch (s) {
    case providerStatus.ONLINE:
      return 'Online';
    case providerStatus.OFFLINE:
      return 'Offline';
    case providerStatus.WAITING:
      return 'Waiting';
    case providerStatus.UNKNOWN:
      return 'Unknown';
  }
};
