import {
  Button,
  Div,
  ErrorToast,
  H3,
  Modal,
  ModalIconStyle,
  P,
  SuccessToast,
  TrashIcon,
  api,
} from '@vgrubs/components';

export const DeleteWorkingHours = ({
  setLoading,
  modalIsOpen,
  setModalIsOpen,
  selectedBrand,
  selectedLocation,
  setHolidayHours,
  provider,
}) => {
  const id = modalIsOpen;

  async function handleDelete() {
    const body = { provider, id };

    try {
      setLoading(true);
      const { data } = await api.delete(
        `/v1/${selectedBrand ? 'brands' : 'locations'}/${
          selectedBrand?.id || selectedLocation?.id
        }/working-hours`,
        { data: body }
      );
      setHolidayHours((prev) => {
        const filteredBusiness = prev.business.filter((d) => d.id !== id);
        const filteredDelivery = prev.delivery.filter((d) => d.id !== id);
        return {
          ...prev,
          business: filteredBusiness,
          delivery: filteredDelivery,
        };
      });
      SuccessToast(data.message);
      setModalIsOpen(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} logo={false}>
      <Div $flex $justifyCenter>
        <ModalIconStyle>
          <TrashIcon />
        </ModalIconStyle>
      </Div>
      <H3 $mb="8">Delete Working Hour?</H3>
      <P $mb="16">Are you sure you want to delete this working hour?</P>

      <Div $flex $gap="20px">
        <Div $width="50%">
          <Button $selected $w="100%" $mt="24" onClick={handleDelete}>
            Yes
          </Button>
        </Div>
        <Div $width="50%">
          <Button
            $selected
            $gray
            $w="100%"
            $mt="24"
            onClick={() => setModalIsOpen(false)}
          >
            No
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
